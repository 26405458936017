<template>
    <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="pl-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                v-if="selectable">
                            </th>
                            <th @click="order('requests')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                v-if="showRequests">
                                Requests
                                <span v-show="orderBy[0] == 'requests' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'requests' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click="order('week')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                v-if="showWeek">
                                Week
                                <span v-show="orderBy[0] == 'week' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'week' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th v-if="showClient"
                                @click="order('client')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Client
                                <span v-show="orderBy[0] == 'client' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'client' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th v-if="showSprint"
                                @click="order('sprint')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Work Order
                                <span v-show="orderBy[0] == 'sprint' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'sprint' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th v-if="showUser"
                                @click="order('user')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Team Member
                                <span v-show="orderBy[0] == 'user' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'user' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th v-if="showType"
                                @click="order('type')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Work Type
                                <span v-show="orderBy[0] == 'type' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'type' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th
                                @click="order('description')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Description
                                <span v-show="orderBy[0] == 'description' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'description' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th
                                @click="order('actual')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Hours
                                <span v-show="orderBy[0] == 'actual' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'actual' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th
                                @click="order('billableNow')" class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Total
                                <span v-show="orderBy[0] == 'billableNow' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'billableNow' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th class="px-6 py-3 bg-gray-50" v-if="editable"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <template v-for="(entry, idx) in sortedEntries" :key="entry.id">
                            <tr :class="{
                                'bg-gray-50': idx % 2 == 1
                            }">
                                <td v-if="selectable" class="pl-4 py-3 whitespace-nowrap text-sm text-left leading-5 text-gray-900">
                                    <input
                                        v-model="selected"
                                        :value="entry.id"
                                        type="checkbox"
                                        class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    >
                                </td>
                                <td v-if="showRequests"
                                    class="px-6 py-3 whitespace-nowrap text-sm text-left leading-5 text-gray-900"
                                >
                                    <template v-if="entry.request_sprint">New Sprint</template>
                                    <template v-if="entry.request_invoice">New Invoice</template>
                                </td>
                                <td v-if="showWeek"
                                    class="px-6 py-3 whitespace-nowrap text-sm text-left leading-5 text-gray-900"
                                >
                                    {{ entry.week }}
                                </td>
                                <td class="px-6 py-3 text-sm leading-5 text-gray-900 whitespace-nowrap" v-if="showClient">
                                    <Link
                                        v-if="entry.client_id"
                                        :href='"/payments/" + entry.client_id'
                                        class="font-medium text-gray-500 hover:underline"
                                    >
                                        {{ entry.client.name }}
                                    </Link>
                                    <span
                                        v-else
                                        class="font-medium text-gray-500"
                                    >--</span>
                                </td>
                                <td class="px-6 py-3 text-sm leading-5 text-gray-900 whitespace-nowrap"
                                    v-if="showSprint">
                                    <Link
                                        v-if="entry.sprint_id"
                                        :href='"/sprints/" + entry.sprint_id'
                                        class="font-medium text-gray-500 hover:underline"
                                    >
                                        {{ entry.sprint ? entry.sprint.name : "--" }}
                                    </Link>
                                    <span
                                        v-else
                                        class="font-medium text-gray-500"
                                    >--</span>
                                </td>
                                <td v-if="showUser" class="px-6 py-3 text-sm leading-5 text-gray-900 whitespace-nowrap">
                                    <Link
                                        v-if="entry.user_id"
                                        :href='"/users/" + entry.user_id'
                                        class="font-medium hover:underline"
                                    >{{ entry.user ? entry.user.name : ('User #' + entry.user_id) }}</Link>
                                </td>
                                <td class="px-6 py-3 text-sm leading-5 text-gray-900 whitespace-nowrap"
                                    v-if="showType"
                                >
                                    {{ type(entry.type) }}</td>
                                <td class="px-6 py-3 text-sm leading-5 max-w-1/2">
                                    <span v-if="entry.description"
                                        class="text-xs text-gray-700">{{ entry.description }}</span>
                                    <div class="mt-1 text-xs" v-if='entry.ticket_numbers' @click.stop>
                                        Ref:
                                        <span v-html='parseTicketNumbers(entry)' />
                                    </div>
                                </td>
                                <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                                    {{ num(entry.actual) }} <span class="font-black">@</span>
                                    ${{ num(entry.rate) }}/hr
                                </td>
                                <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                                    {{ money(entry.billableNow) }}
                                </td>
                                <td class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium"
                                    v-if="editable">
                                    <a href="#" class="text-blue-600 hover:text-blue-900"
                                        @click.stop.prevent='editEntry = entry'>Edit</a>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="showTotalsRow && entries.length > 0" class="bg-gray-100 border-gray-300 border-t" >
                            <td :colspan="numColumns-4">
                                <slot name='footer'></slot>
                            </td>
                            <td
                                class="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 text-right">
                                Totals
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                                <span>
                                    {{ num(totals.actual) }} <span class="font-black">@</span> ${{ totals.rate }}/hr
                                </span>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                                {{ money(totals.billableNow) }}
                                <span class="text-green-500" v-if='totalAdjustment && totalAdjustment > 0'> + ${{ num(totalAdjustment) }}</span>
                                <span class="text-red-500" v-if='totalAdjustment && totalAdjustment < 0'> - ${{ abs(num(totalAdjustment)) }}</span>
                                <span v-if='totalAdjustment'> = {{ money((Number(totals.billableNow) + totalAdjustment)) }}</span>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
                                v-if="editable">
                            </td>
                        </tr>
                        <tr v-if="!entries.length">
                            <td :colspan="numColumns">
                                <div class="p-3 text-sm text-gray-600">
                                    No entries have been added for this work order yet.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <modal :show="!!editEntry" @close='editEntry = null' :close-on-background-click='true'>
            <entry-form v-if="editEntry" :key="editEntry.id" v-model="editEntry" show-delete @deleted='refresh'
                @saved='refresh' @cancel='editEntry = null' />
        </modal>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import modal from "../Common/modal.vue"
import entryForm from "./entryForm.vue"
import { get, orderBy } from "lodash"

export default {
    name: 'EntriesTable',
    components: {
        modal,
        entryForm,
        Link
    },
    props: {
        totalAdjustment: {
            type: Number,
            default: null
        },
        modelValue: {
            type: Array,
            default(){
                return null;//[];
            }
        },
        showRequests: {
            type: Boolean,
            default: false
        },
        showClient: {
            type: Boolean,
            default: false
        },
        showSprint: {
            type: Boolean,
            default: false
        },
        showUser: {
            type: Boolean,
            default: true
        },
        showType: {
            type: Boolean,
            default: false
        },
        showWeek: {
            type: Boolean,
            default: false
        },
        showTotalsRow: {
            type: Boolean,
            default: true
        },
        entries: Array,
        types: [Array, Object],
        editable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selected: this.modelValue,
            editEntry: null,
            orderBy: [null, null]
        }
    },
    computed: {
        selectable() {
            return this.selected !== null
        },
        numColumns() {
            var num = 4;
            if (this.selected != null) num++
            if (this.showWeek) num++
            if (this.showRequests) num++
            if (this.showClient) num++
            if (this.showSprint) num++
            if (!this.showUser) num--
            if (this.showType) num++
            if (this.editable) num++
            return num;
        },
        totals() {
            const total = {
                count: 0,
                actual: 0,
                rate: 0,
                billableNow: 0
            }
            this.entries.forEach((entry) => {
                total.count++
                total.actual += Number(entry.actual)
                total.rate += Number(entry.rate)
                total.billableNow += Number(entry.billableNow)
            })
            total.rate = Math.round(total.rate / total.count * 100) / 100
            return total
        },
        sortedEntries () {
            if (this.orderBy[0]) {
                switch(this.orderBy[0]) {
                    case 'user':
                        return orderBy(this.entries, (entry) => {
                            return entry.user ? entry.user.name : ''
                        }, this.orderBy[1]);
                    case 'sprint':
                        return orderBy(this.entries, (entry) => {
                            return entry.sprint ? entry.sprint.name : ''
                        }, this.orderBy[1]);
                    case 'client':
                        return orderBy(this.entries, (entry) => {
                            return get(find(this.clients, { id: entry.client_id }), 'name', '')
                        }, this.orderBy[1]);
                    case 'type':
                        return orderBy(this.entries, (entry) => {
                            return this.type(entry.type)
                        }, this.orderBy[1]);
                    case 'requests':
                        return orderBy(this.entries, (entry) => {
                            if (entry.request_invoice) {
                                return 1
                            } else if(entry.request_sprint) {
                                return 2
                            }
                            return 0
                        }, this.orderBy[1]);
                    case 'actual':
                    case 'billableNow':
                        return orderBy(this.entries, (entry) => {
                            return Number(get(entry, this.orderBy[0], 0))
                        }, this.orderBy[1])
                    default:
                        return orderBy(this.entries, this.orderBy[0], this.orderBy[1])
                }
            }
            return this.entries
        }
    },
    methods: {
        abs(num) {
            return Math.abs(Number(num))
        },
        money(num) {
            var sign = num >= 0 ? '$' : '-$';
            num = String(num).replace(/[^\d.]/g, '')
            return sign + Math.abs(Number(num)).toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        },
        num(num) {
            return Number(num).toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        },
        parseTicketNumbers(entry) {
            const numbers = entry.ticket_numbers.split("\n");
            const parsed = numbers.map((url) => {
                if (url.match(/^\d+/)) {
                    // it's only a number, assume it's a zendesk ticket
                    return `<a href="https://legnd.zendesk.com/agent/tickets/${url}" target="_blank" class="underline">${url}</a>`
                } else if (url.match(/https?:\/\/legnd.zendesk.com\/agent\/tickets\/\d+$/)) {
                    const number = url.split("/").pop()
                    return `<a href="https://legnd.zendesk.com/agent/tickets/${number}" target="_blank" class="underline">${number}</a>`
                } else if (url.match(/https?:\/\/gitlab.com\/.*\/-\/issues\/\d+$/)) {
                    const regex = /https?:\/\/gitlab.com\/(.*)\/-\/issues\/(\d+)$/
                    let matches = regex.exec("https://gitlab.com/legnd-cms/forge-core/-/issues/280")
                    matches[1] = matches[1].replace(/legnd-cms(?:-sites)?\//, '')
                    return `<a href="${url}" target="_blank" class="underline">${matches[1]}#${matches[2]}</a>`
                } else {
                    return `<a href="${url}" target="_blank" class="underline">${url}</a>`
                }
            })
            return parsed.join(", ")
        },
        type(type) {
            if (type in this.types) {
                return this.types[type]
            } else if (type) {
                return type[0].toUpperCase() + type.substr(1)
            }
            return type;
        },
        refresh() {
            this.editEntry = null
            this.$inertia.reload()
        },
        order(key) {
          if (this.orderBy[0] == key) {
              if (this.orderBy[1] == 'asc') {
                this.orderBy = [key, 'desc']
              } else {
                this.orderBy = [null, null]
              }
          } else {
              this.orderBy = [key, 'asc']
          }
        }
    },
    watch: {
        selected() {
            this.$emit('update:modelValue', this.selected)
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
