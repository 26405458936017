<template>
    <div class="bg-[#f8f9f4]">
        <section class="pt-10 pb-0 md:pt-24 md:pb-0 f_cKTXR fc_0ada7 ">
            <div
                class="items-start container mx-auto"
            >
                <div class="col-span-1 text-center">
                    <h1
                        class="z-[50] order-first text-[70px] font-semibold text-center relative leading-[1]"
                    >
                        <span class="inline-block relative">
                            <span class="relative z-10 inline-block bg-full-spectrum bg-clip-text text-transparent">
                                <span>Get started now!</span>
                            </span>
                            <span
                                class="inline-block text-gray-900 relative z-20 max-md:mt-3 max-md:text-10xl"
                            ></span>
                        </span>
                    </h1>
                    <div class="items-center max-w-[460px] max-w-screen-lg mt-6 mx-auto px-4 sm:px-6 md:mt-8 md:px-0 lg:px-8 text-center flex flex-col gap-y-4">
                        <p class="text-2xl block">
                            It takes less than 10 minutes.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section
            class="bg-no-repeat bg-center md:bg-[length:100%_auto] bg-[length:200%_40%] min-h-[860px] !bg-top xl:bg-[length:100%_80%] z-40 bg-blend-darken min-[1920px]:py-40 relative pt-6 px-6 md:pt-10 md:px-0  bg-[#f8f9f4]"
            style="background-image: url('https://cdn.eversite.com/media/eversite.com/29790/image+34-1920-reverse.jpg'); background-position: 50% 50%"
        >
            <div class="container mx-auto relative">
                <div class="col-span-1">
                    <section
                        class="bg-no-repeat bg-blend-multiply bg-center min-[1920px]:py-60 min-[1920px]:-mt-80 min-[1920px]:-mb-20 md:bg-[length:100%_auto] bg-[length:200%_40%] py-6 md:pt-24 md:pb-12"
                    >
                        <div
                            class="items-start container w-full grid grid-cols-1 gap-10 md:grid-cols-1 md:gap-10 lg:gap-16"
                        >
                            <div class="col-span-1">
                                <div
                                    class="justify-center flex-wrap items-center mx-auto max-w-[840px] pb-8 flex flex-row f_cKUcs fc_ff6fb "
                                    id="logo-wrapper"
                                >
                                    <img
                                        src="https://cdn.eversite.com/media/eversite.com/17120/inc-5000-badge.png"
                                        alt="Inc 5000"
                                        style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                        src="https://cdn.eversite.com/media/eversite.com/17121/upcity-badge.png"
                                        alt="UpCity"
                                        style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                        src="https://cdn.eversite.com/media/eversite.com/17126/clutch-badge.png"
                                        alt="Clutch"
                                        style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                            src="https://cdn.eversite.com/media/eversite.com/17124/eversite-badge.png"
                                            alt="Eversite"
                                            style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                            src="https://cdn.eversite.com/media/eversite.com/17125/design-rush-badge.png"
                                            alt="Design Rush"
                                            style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                            src="https://cdn.eversite.com/media/eversite.com/17122/top-design-firms-badge.png"
                                            alt="Top Design Firms"
                                            style="object-position: 50% 50%;"
                                            loading="lazy"
                                            class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                    <img
                                        src="https://cdn.eversite.com/media/eversite.com/17123/expertise-badge.png"
                                        alt="Expertise"
                                            style="object-position: 50% 50%;"
                                        loading="lazy"
                                        class="aspect-[1/1] object-contain max-w-[80px] lg:max-w-[110px] mx-2 lg:mx-1"
                                    />
                                </div>
                                <form class="bg-white rounded p-4 max-w-[800px] mx-auto grid grid-cols-1md grid-cols-2 gap-4">
                                    <h3 class="md:col-span-2 text-2xl font-bold">Who should we contact?</h3>
                                    <div>
                                        <label for="first_name" class="block text-sm font-medium text-gray-700">First Name</label>
                                        <input type="text" id="first_name" name="first_name" class="mt-1 block w-full" required />
                                    </div>
                                    <div>
                                        <label for="last_name" class="block text-sm font-medium text-gray-700">Last Name</label>
                                        <input type="text" id="last_name" name="last_name" class="mt-1 block w-full" required />
                                    </div>
                                    <div>
                                        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                                        <input type="email" id="email" name="email" class="mt-1 block w-full" required />
                                    </div>
                                    <div>
                                        <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                                        <input type="tel" id="phone" name="phone" class="mt-1 block w-full" required />
                                    </div>
                                    <div>
                                        <label for="website" class="block text-sm font-medium text-gray-700">Website</label>
                                        <input type="url" id="website" name="website" class="mt-1 block w-full" required />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "OnboardingForm"
};
</script>

<style scoped>
.bg-full-spectrum {
    background-image: linear-gradient(149deg, #e2604d, #e7bd8f 33%, #73b59e 66%, #227b6e);
}
</style>
