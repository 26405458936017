<template>
    <Layout :auth='auth'>
        <Head title="API Tokens" />
        <template v-slot:title>API Tokens</template>
        <template v-slot:actions>
            <btn white @click='createNewToken = true'>Add New Token</btn>
        </template>

        <div class="overflow-hidden sm:rounded-md">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Name
                                    </th>
                                    <th
                                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                        Last Used
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50"></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <template v-for="(token, idx) in tokens"  :key="token.id">
                                    <tr :class="{
                                        'bg-gray-50': idx % 2 == 1
                                    }">
                                        <td
                                            class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                            {{ token.name }}
                                        </td>
                                        <td
                                            class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                            {{ token.last_used_at }}
                                        </td>
                                        <td class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium">
                                            <a
                                                href="#"
                                                @click.stop.prevent='revoke(token)'
                                                class="text-sm text-blue-800"
                                            >Revoke</a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <modal :show='createNewToken' @close='createNewToken = false'>
            <div v-if="token.token">
                <div class="mb-4">
                    <label for="name" class="block text-sm leading-5 font-medium text-gray-700">
                        Token Generated
                    </label>
                    <div
                        class="rounded-md shadow-sm mb-2"
                        v-clipboard:copy="token.token"
                        v-clipboard:success="() => token.copied = true"
                    >
                        <input
                            autocomplete="off"
                            type="text"
                            ref="token"
                            v-model="token.token"
                            class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            placeholder="Token Name"
                        />
                    </div>
                    <div class="text-sm text-gray-600" v-if="token.copied">
                        <i class="fal fa-check mr-2 text-green-700"></i>
                        The token was copied to your clipboard!
                    </div>
                    <div class="text-sm text-gray-600" v-else>
                        Copy the token above into a safe place as it cannot be displayed again.
                    </div>
                </div>
                <div class="text-center">
                    <btn
                        blue
                        @click='closeModal'
                    >Done</btn>
                </div>
            </div>
            <div v-else>
                <div class="mb-4">
                    <label for="name" class="block text-sm leading-5 font-medium text-gray-700">
                        Token Name
                    </label>
                    <div class="rounded-md shadow-sm">
                        <input
                            type="text"
                            autocomplete="off"
                            v-model="token.name"
                            class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            placeholder="Token Name"
                        />
                    </div>
                </div>
                <div class="text-center">
                    <btn
                        green
                        @click='addNewToken'
                        :loading='creating'
                    >Create Token</btn>
                </div>
            </div>
        </modal>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import modal from "../../Common/modal.vue"
import btn from "../../Common/btn.vue"
import axios from "axios"
import { Head } from '@inertiajs/vue3'

export default {
    name: "APITokens",
    props: {
        auth: Object,
        tokens: Array
    },
    data () {
        return {
            createNewToken: false,
            creating: false,
            token: {
                name: '',
                token: false,
                copied: false
            }
        }
    },
    components: { btn, modal, Head, Layout },
    methods: {
        closeModal () {
            this.token.name = ''
            this.token.token = false
            this.token.copied = false
            this.createNewToken = false
        },
        async addNewToken() {
            if (this.token.name) {
                this.creating = true
                const response = await axios.post(
                    this.$route("api.tokens.create"),
                    this.token
                )
                this.token.token = response.data.token
                this.creating = false
            }
        },
        async revoke(token) {
            if (confirm("Are you sure you want to revoke this token?")) {
                await axios.delete(
                    this.$route("api.tokens.revoke", {
                        token: token.id
                    })
                )
                await this.$inertia.reload({
                    only: ['tokens'],
                })
            }
        }
    }
}
</script>

<style lang="postcss" scoped></style>
