<template>
    <Layout :auth='auth'>
        <Head title="Billings Report" />
        <template v-slot:title>Billings Report</template>
        <template v-slot:actions>
            <btn class="mr-4" white outline href="/billing/sketchy">
                Sketchy Billing Report
            </btn>
        </template>
        <div class="-m-6" v-if="ready">
            <div
                v-for="(client) in clients"
                :key="client.id"
                class="p-6"
            >
                <div class="text-lg flex justify-between">
                    <div>
                        {{ client.name }}
                    </div>
                </div>
                <div>
                    <div v-if="client.time_entries" class="mt-3" >
                        <h3 class="font-bold mb-3">Unattached Time</h3>
                        <entries-table
                            selectable
                            show-week
                            show-type
                            show-requests
                            :types="types"
                            :entries='client.time_entries'
                            v-model='selectedEntriesFor[client.id]'
                        >
                            <template v-slot:footer>
                                <btn
                                    small
                                    gray
                                    class="ml-2"
                                    :disabled="selectedEntriesFor[client.id].length == 0"
                                    @click='createSprintFor = client.id'
                                >
                                    Create Invoice For {{ money(totalAmountSelected(client.id)) }}
                                </btn>
                            </template>
                        </entries-table>
                    </div>
                    <div v-if="client.sprints.length" class="mt-5" >
                        <span class="block font-bold text-sm px-6 mb-6">{{client.sprints.length}} Active Work Orders (<a href="#" class="text-blue-600" @click.prevent="expandClient(client)">View</a>)</span>
                        <sprints-table
                            :class="{'block': expandedTable[client.id], 'hidden' : !expandedTable[client.id]}"
                            :show-client="false"
                            :sprints='client.sprints'
                            :clients='clientList' />
                    </div>
                </div>
            </div>
        </div>
        <modal size='md' :show="createSprintFor !== null" @close='createSprintFor = null' :close-on-background-click='true'>
            <h2 class="text-lg font-medium mb-2">Please create an invoice in QBO:</h2>
            <dl class="mb-3">
                <dt class="font-medium">Client:</dt>
                <dd class="mb-2">{{ clientName(createSprintFor) }}</dd>

                <dt class="font-medium">Amount:</dt>
                <dd class="mb-2">{{ money(totalAmountSelected(createSprintFor)) }}</dd>

                <dt class="font-medium">
                    Description:
                    <a href="#"
                        @click.prevent
                        v-clipboard:copy="selectedDescription(createSprintFor)"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onCopyError"
                    ><i class="fal fa-clipboard"></i></a>
                    <span v-if='descriptionCopied == 1' class="text-green-500">Description Copied!</span>
                    <span v-if='descriptionCopied == 2' class="text-red-500">Copy Failed!</span>
                </dt>
                <dd class="mb-2">
                    <code class="whitespace-pre-line max-h-24 block overflow-auto p-4 bg-gray-100">{{ selectedDescription(createSprintFor) }}</code>
                </dd>

                <dt class="font-medium">And paste the invoice number here 👇</dt>
                <dd>
                    <inp v-model="newInvoiceNumber" />
                </dd>
            </dl>
            <alert
                icon="fad fa-do-not-enter"
                color="red"
                class="mb-2"
                v-if='createSprintError'
            >{{ createSprintError }}</alert>
            <btn
                @click='checkInvoice'
                block
                :disabled='!newInvoiceNumber'
            >
                <span v-if='checkingInvoice'>
                    <i class="fal fa-spinner-third fa-spin"></i>
                    <span class="inline-block ml-1">Checking... Please wait.</span>
                </span>
                <span v-else>Click here when the invoice has been created</span>
            </btn>
        </modal>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import entriesTable from "../../Common/entriesTable.vue"
import sprintsTable from "../../Common/sprintsTable.vue"
import paymentForm from "../../Common/paymentForm.vue"
import alert from '../../Common/alert.vue'
import btn from '../../Common/btn.vue'
import modal from '../../Common/modal.vue'
import inp from '../../Common/inp.vue'
import money from '../../Common/money.vue'
import { find } from "lodash"
import axios from "axios"
import { Head } from '@inertiajs/vue3'

export default {
    name: 'BillingsReport',
    components: {
        Layout,
        Head,
        hgselect,
        entriesTable,
        sprintsTable,
        paymentForm,
        alert,
        btn,
        modal,
        inp,
        money
    },
    props: {
        auth: Object,
        sprintsPerClient: Object,
        entriesPerClient: Object,
        users: Object,
        types: Array,
        clients: Array,
        clientList: Object,
    },
    data() {
        const selectedEntriesFor = {}
        this.clients.forEach((client) => {
            selectedEntriesFor[client.id] = [];
        })
        return {
            ready: false,
            createSprintFor: null,
            createSprintError: '',
            checkingInvoice: false,
            selectedEntriesFor,
            expandedTable: {},
            descriptionCopied: false,
            errors: null,
            newInvoiceNumber: '',
            sprint_id: 'new',
            sprint: {
                name: '',
                status: 'finished',
                total: 0,
                description: '',
                payment_id: null
            }
        }
    },
    mounted() {
        this.ready = true
    },
    computed: {
        options() {
            return {
                sprints: {
                    'new': 'Create New Work Order',
                    ...((this.optionsData && this.optionsData.activeSprints) ? this.optionsData.activeSprints : {})
                },
                payments: this.optionsData ? this.optionsData.payments : [],
                statuses: {
                    active: 'Active',
                    finished: 'Finished',
                    paid: 'Paid',
                    archived: 'Archived',
                }
            }
        },
        canCreateSprint() {
            return this.sprint.total && this.sprint.description
        }
    },
    methods: {
        money (num) {
            if (Number(num) >= 0) {
                let value = Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
                return `$${value}`
            } else {
                let value = Math.abs(Number(num)).toLocaleString(undefined, {minimumFractionDigits: 2})
                return `-$${value}`
            }
        },
        onCopy() {
            this.descriptionCopied = 1
            setTimeout(() => {
                this.descriptionCopied = false
            }, 2500)
        },
        onCopyError() {
            this.descriptionCopied = 2
            setTimeout(() => {
                this.descriptionCopied = false
            }, 2500)
        },
        expandClient(client) {
            this.expandedTable[client.id] = !this.expandedTable[client.id];
        },
        clientName(client_id) {
            const client = find(this.clients, {id: client_id})
            return client ? client.name : '--'
        },
        selectedDescription(client_id) {
            let descriptions = []
            if (this.selectedEntriesFor[client_id]) {
                const client = find(this.clients, {id: client_id})
                descriptions = this.selectedEntriesFor[client_id].reduce(function(descriptions, entry_id) {
                    const entry = find(client.time_entries, {id: entry_id})
                    if (entry.description) {
                        descriptions.push(entry.description)
                    }
                    return descriptions
                }, []);
            }
            return descriptions.join("\n")
        },
        totalAmountSelected(client_id) {
            if (this.selectedEntriesFor[client_id]) {
                const client = find(this.clients, {id: client_id})
                return this.selectedEntriesFor[client_id].reduce(function(sum, entry_id) {
                    const entry = find(client.time_entries, {id: entry_id})
                    return sum + Number(entry ? entry.billableNow : 0)
                }, 0);
            }
            return 0
        },
        async checkInvoice() {
            this.checkingInvoice = true
            const { data } = await axios.post(this.$route("qbo.invoice.check"), {
                number: this.newInvoiceNumber,
                apply: this.selectedEntriesFor[this.createSprintFor]
            })
            this.checkingInvoice = false

            if (data.success) {
                this.selectedEntriesFor[this.createSprintFor] = []
                this.createSprintFor = null
                this.newInvoiceNumber = ''
                this.$inertia.reload()
            } else {
                this.createSprintError = data.message
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
