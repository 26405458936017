import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import Fuse from 'fuse.js'

import MentionList from './MentionList.vue'

export default (mentionables) => {
  const searchable = Object.keys(mentionables).map(key => {
    return {
      id: key,
      label: mentionables[key]
    }
  });

  return {
    items: ({ query }) => {
      const options = {
        includeScore: true,
        keys: ['label']
      }
      const fuse = new Fuse(searchable, options)
      const result = fuse.search(query)
      return result.sort((a, b) => {
        if (a.score > b.score) {
          return 1
        }
        if (a.score < b.score) {
          return -1
        }
        return 0
      }).map(item => item.item.id)
    },
    allowSpaces: true,
    render: () => {
      let component
      let popup

      return {
        onStart: props => {
          component = new VueRenderer(MentionList, {
            props: {
              mentionables,
              ...props,
            },
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()

            return true
          }

          return component.ref.onKeyDown(props)
        },

        onExit() {
          if (popup[0] && !popup[0].state.isDestroyed) {
            popup[0].destroy()
          }
          component.destroy()
        },
      }
    },
  }
}