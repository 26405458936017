<template>
    <span>
        <span v-if="user && user.photo">
            <img :src="user.photo" :class="'h-'+size+' w-'+size+' min-h-'+size+' min-w-'+size+'  rounded-full overflow-hidden bg-gray-100'" />
        </span>
        <span v-else
            class="flex items-center justify-center rounded-full uppercase text-lg text-white font-light"
            :class="'h-'+size+' w-'+size+' min-h-'+size+' min-w-'+size+' bg-'+(user.accent ? user.accent : 'gray')+ '-500'" v-text="user.name[0]">
        </span>
        <template v-if="0">
            <div class="h-6 w-6 min-h-6 min-w-6" />
            <div class="h-8 w-8 min-h-8 min-w-8" />
            <div class="h-10 w-10 min-h-10 min-w-10" />
            <div class="h-12 w-12 min-h-12 min-w-12" />
        </template>
    </span>
</template>

<script>

export default {
    name: "Avatar",
    props: {
        user: Object,
        size: {
            type: [Number, String],
            default: 10
        }
    }
}
</script>
