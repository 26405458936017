<script>
import ThrobTable from "./Table.vue"
import ThrobPie from "./Pie.vue"
import ThrobLine from "./Line.vue"
import ThrobHeader from "./Header.vue"
import ThrobBar from "./Bar.vue"
import ThrobRow from "./Bar.vue"
import ThrobStatistic from "./Statistic.vue"

export default {
    name: 'ThrobAccordion',
    props: {
        title: {
            type: String,
            default: 'Accordion'
        },
        data: {
            type: Object,
            default: () => ({
            })
        }
    },
    components: {
        ThrobTable,
        ThrobPie,
        ThrobLine,
        ThrobHeader,
        ThrobBar,
        ThrobRow,
        ThrobStatistic
    },
    data() {
        return {
            cardKey: (new Date).getTime(),
            showing: Object.keys(this.data.sections)[0],
        }
    }
}
</script>

<template>
    <section class="flex flex-col gap-4">
        <div class="flex items-center justify-between border-b pb-3 mb-3">
            <h1 class="text-3xl font-semibold leading-6 text-gray-900">
                {{ data.title }}
            </h1>
            <select v-model="showing">
                <option v-for="(section, key) in data.sections" :key="key" v-text="key"></option>
            </select>
        </div>
        <section class="grid grid-cols-6 lg:grid-cols-12 gap-6 items-stretch">
            <component
                v-for="(card, idx) in data.sections[showing]"
                :key="'card-' + showing + '-' + idx + '-' + cardKey"
                class="flex flex-col"
                :class="'col-span-' + card.size.columns + ' row-span-' + card.size.rows"
                :is="'Throb' + card.class"
                :title="card.title"
                :data="card.data"
                :meta="card.meta"
            />
        </section>
    </section>
</template>

<style>
</style>
