<template>
    <Layout ref="layout" :auth='auth' :showHeader="false" :hideGradient="true" :container="false" :noContainerPadding="true">
        <section class="flex flex-col h-screen">
            <div class="w-full h-full bg-gray-400 shadow border bg-opacity-50 backdrop-blur-sm">
                <iframe
                    ref="editorFrame"
                    :src="frameUrl"
                    class="w-full h-full"
                    @click.stop
                ></iframe>
            </div>
            <aside class="p-6 bg-[#fef6df] min-h-[130px]">
                <section v-if="isTeamMember" class="bg-white flex flex-col md:flex-row gap-4 items-center p-4 rounded border border-gray-200 w-full">
                    <div class="w-full md:w-1/2 lg:w-2/3 text-sm">
                        Review or click to edit the content above. Once its ready to go, click <b>Approve Post</b> to send the post to the client!<br>
                        Alternatively, click <b>Regenerate Post</b> to delete this post and generate a new one.<br>
                        <Link :href="'/activity/' + memo.id" class="text-xs text-green-600">Back To Ticket</Link>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 flex gap-x-4 gap-y-2">
                        <template v-if="!reviewBlogPostAction">
                            <Alert class="w-full">No actions are available for this post.</Alert>
                        </template>
                        <template v-else>
                            <btn
                                class="w-full"
                                block
                                @click="approvePost"
                                :disabled="publishing"
                                :loading="publishing"
                            >Approve Post</btn>
                            <btn
                                class="w-full"
                                block
                                outline red
                                @click="deletePost"
                            >Regenerate Post</btn>
                        </template>
                    </div>
                </section>
                <section v-else class="bg-white flex flex-col md:flex-row gap-4 items-center p-4 rounded border border-gray-200 w-full">
                    <template v-if="showEditor">
                        <editor
                            class="w-full border-none no-padding h-full"
                            v-model="comment"
                            id="comment"
                            name="comment"
                            placeholder="Type your request here..."
                        />
                        <div class="flex gap-4 whitespace-nowrap">
                            <btn
                                block
                                @click="rejectPost"
                            >Send Request</btn>
                            <btn
                                block
                                red outline
                                @click="showEditor = false"
                                class="max-w-[40px] aspect-1 flex items-center justify-center"
                            >
                                <span><i class="fa fa-times"></i></span>
                            </btn>
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-full md:w-1/2 lg:w-2/3 text-sm">
                            Review or click to edit the content above. Once its ready to go, click <b>Publish Post</b> and it's live!<br>
                            Alternatively, click <b>Request Edits</b> to request changes to be made by our team.<br>
                            <Link :href="'/activity/' + memo.id" class="text-xs text-green-600">Back To Ticket</Link>
                        </div>
                        <div class="w-full md:w-1/2 lg:w-1/3 flex gap-x-4 gap-y-2">
                            <template v-if="!publishBlogPostAction">
                                <Alert class="w-full">No actions are available for this post.</Alert>
                            </template>
                            <template v-else>
                                <btn
                                    class="w-full"
                                    block
                                    @click="publishPost"
                                    :disabled="publishing"
                                    :loading="publishing"
                                >Publish Post</btn>
                                <btn
                                    class="w-full"
                                    block
                                    outline green
                                    @click="showEditor = true"
                                >Request Edits</btn>
                            </template>
                        </div>
                    </template>
                </section>
            </aside>
        </section>
    </Layout>
</template>

<script>
import btn from "@/Common/btn.vue"
import axios from "axios"
import Alert from "@/Common/alert.vue"
import editor from "@/Common/editor.vue"
import Layout from "@/Common/Layout.vue"
import { Link } from "@inertiajs/vue3"

export default {
    name: 'MemoBlogPostEditor',
    mixins: [],
    components: {
        btn, editor, Layout, Alert, Link
    },
    props: {
        auth: Object,
        memo: Object,
        actions: Array
    },
    data() {
        return {
            userAuthToken: null,
            publishing: false,
            showEditor: false,
            comment: ''
        }
    },
    computed: {
        isTeamMember() {
            return this.auth.user.type === 'team';
        },
        frameUrl() {
            if (this.memo.meta && this.memo.meta.url) {
                const url = new URL(this.memo.meta.url);
                let host = url.origin;
                let path = url.pathname;
                let redirect = `${host}/mosaic/editor?mode=inline&path=${encodeURIComponent(path)}`;
                let token = this.userAuthToken;
                return `${host}/api/auth/relay?token=${token}&redirect=${encodeURIComponent(redirect)}`;
            }
            return null;
        },
        publishBlogPostAction() {
            return this.actions.find((action) => {
                if (action.completed_at) {
                    return false;
                }
                if (action.type == 'PublishBlogPost') {
                    return true;
                }
            })
        },
        reviewBlogPostAction() {
            return this.actions.find((action) => {
                if (action.completed_at) {
                    return false;
                }
                if (action.type == 'ReviewBlogPost') {
                    return true;
                }
            })
        },

    },
    async mounted() {
        this.userAuthToken = await axios.get(this.$route('user.auth.token')).then(res => res.data.token);
    },
    beforeUnmount() {
    },
    methods: {
        publishPost() {
            this.publishing = true;
            this.$refs.editorFrame.contentWindow.postMessage({
                action: 'publishNow'
            }, '*');

            let published = null
            published = async ($event) => {
                if ($event.data.published) {
                    this.$refs.layout.notify({
                        color: 'green',
                        icon: "fal fa-thumbs-up",
                        message: `The blog post has been published!`,
                        persist: true,
                        actions: [{
                            label: 'Open Post',
                            callback: () => {
                                window.open(this.memo.meta.url, '_blank');
                            }
                        }]
                    })

                    this.publishing = false;

                    if (this.publishBlogPostAction) {
                        await axios.post(
                            this.$route("actions.done"),
                            { id: this.publishBlogPostAction.id }
                        )

                        this.publishBlogPostAction.completed_at = new Date().toISOString();

                        // Resolve the memo if it's not already
                        if (this.memo.status !== 'resolved') {
                            await axios.post(
                                this.$route("activity.memo.update", this.memo.id),
                                {
                                    '_method': 'PUT',
                                    status: 'resolved',
                                    note: 'Blog post was published'
                                }
                            )
                        }
                    }
                } else {
                    this.publishing = false;
                    alert("Something went wrong while published, please try again.")
                }
            };
            window.addEventListener('message', published);
        },
        async rejectPost() {
            // Send back for review
            if (this.publishBlogPostAction) {
                await axios.post(
                    this.$route("actions.done"),
                    {
                        id: this.publishBlogPostAction.id,
                        action: 'reject',
                        data: {
                            comment: this.comment
                        }
                    }
                )
                this.publishBlogPostAction.completed_at = new Date().toISOString();
            } else {
                // Record it as a comment
                await axios.post(
                    this.$route("activity.store", this.memo.id),
                    {
                        type: 'comment',
                        details: {
                            comment: this.comment
                        },
                    }
                )
            }

            this.$refs.layout.notify({
                color: 'green',
                icon: "fal fa-thumbs-down",
                message: `Your feedback about the blog post has been sent to the Eversite team.`,
                persist: true
            })

            this.comment = '';
        },
        async approvePost() {
            if (!confirm("Are you sure you want to approve this post and send it to the client?")) {
                return;
            }

            if (this.reviewBlogPostAction) {
                await axios.post(
                    this.$route("actions.done"),
                    {
                        id: this.reviewBlogPostAction.id,
                        action: 'approve',
                    }
                )
                this.reviewBlogPostAction.completed_at = new Date().toISOString();

                this.$refs.layout.notify({
                    color: 'green',
                    icon: "fal fa-thumbs-up",
                    message: `The blog post has been approved and sent to the client.`,
                    persist: true
                })
            } else {
                alert("Oops! The Review Blog Post action was not found for this post. Please contact support.")
            }
        },
        async deletePost() {
            if (!confirm("Are you sure you want to delete this post and generate a new one?")) {
                return;
            }

            if (this.reviewBlogPostAction) {
                await axios.post(
                    this.$route("actions.done"),
                    {
                        id: this.reviewBlogPostAction.id,
                        action: 'reject',
                    }
                )

                this.reviewBlogPostAction.completed_at = new Date().toISOString();

                this.$refs.layout.notify({
                    color: 'red',
                    icon: "fal fa-thumbs-down",
                    message: `The blog post has been rejected and a new one will be generated soon.`,
                    persist: true
                })
            } else {
                alert("Oops! The Review Blog Post action was not found for this post. Please contact support.")
            }
        },
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
.no-padding:deep(div.tiptap) {
    padding: 0 !important;
}
</style>
