<template>
    <div class="fixed inset-0 pointer-events-none" style="z-index: 100">
        <template
            v-for="(ghost, idx) in ghosts"
            :key="'ghost-' + idx"
        >
            <template v-if="!ghost.dead">
                <div
                    class="absolute transition-all ease-in-out pointer-events-auto p-12"
                    :style="{
                        top: `${ghost.top}px`,
                        left: `${ghost.left}px`,
                        transform: `rotate(${ghost.rotation}deg) scale(${ghost.scale})`,
                        opacity: `${ghost.opacity}`,
                        'transition-duration': `${ghost.dying ? '1s' : '6s'}`
                    }">
                        <img :src="asset('/images/ghost.svg')" class="w-12 h-12" @click="kill(ghost)" style="cursor: crosshair;" />
                </div>
            </template>
        </template>
        <div class="flex gap-x-4 absolute bottom-[10px] left-[10px]" style="z-index: 101">
            <span v-if="count > 0 && !paused" class=" text-xl text-red-600 font-bold">
                You're a ghostbuster! Busted: {{ count }}
            </span>
            <span @click="stop" v-if="ghosts.length > 5" class="cursor-pointer underline pointer-events-auto text-xl text-red-600 font-bold">
                Stop The Madness
            </span>
        </div>

    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    data() {
        return {
            paused: false,
            count: 0,
            ghosts: []
        }
    },
    mounted() {
        if (Cookies.get('stop-the-madness')) {
            this.paused = true;
        }
        setTimeout(() => {
            this.addGhost()
        }, 5000);
    },
    methods: {
        stop() {
            Cookies.set('stop-the-madness', 'true')
            this.ghosts = [];
            this.paused = true;
        },
        addGhost() {
            if (this.paused) {
                return;
            }
            if (this.ghosts.length < 10) {
                this.ghosts.push({
                    dying: false,
                    dead: false,
                    top: Math.random() * window.innerHeight,
                    left: Math.random() * window.innerWidth,
                    rotation: Math.random() * 90 - 45,
                    opacity: Math.random() * 0.5 + 0.5,
                    scale: Math.random() * 0.5 + 0.5,
                })
                const ghostIdx = this.ghosts.length - 1
                setTimeout(() => {
                    this.moveGhost(ghostIdx)
                }, 250);
                setInterval(this.moveGhost.bind(this, ghostIdx), 5000)
            }
            setTimeout(this.addGhost, Math.random() * 20000)
        },
        kill(ghost) {
            ghost.dying = true
            this.count++
            ghost.scale = 5000
            ghost.opacity = 0
            setTimeout(() => {
                ghost.dead = true
            }, 1000);
        },
        moveGhost(idx) {
            if (!this.ghosts.dying) {
                this.ghosts[idx].top = Math.random() * window.innerHeight
                this.ghosts[idx].left = Math.random() * window.innerWidth
                this.ghosts[idx].rotation = Math.random() * 90 - 45
                this.ghosts[idx].opacity = Math.random() * 0.5 + 0.5
                this.ghosts[idx].scale = Math.random() * 0.5 + 0.5
            }
        }
    }
}
</script>