<template>
    <Layout :auth='auth'>
        <Head :title="archived ? 'Archived Team Members' : 'Team Members'" />
        <template v-slot:title>
            <template v-if="archived">Archived Team Members</template>
            <template v-else>Team Members</template>
        </template>
        <template v-slot:actions>
            <btn white :href="$route('users.create')" v-if="hasPermission('manage:users')">Add New Team Member</btn>
        </template>

        <div class="overflow-hidden sm:rounded-md">
            <ul>
                <li
                    v-for="(user, idx) in users.data"
                    :key="user.id"
                    :class="{
                        'border-t border-gray-200': idx > 0
                    }"
                >
                    <Link
                        :href="$route('users.show', user.id)"
                        class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                    >
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 flex items-center">
                                <div class="flex-shrink-0">
                                    <avatar v-if="user" :size='12' :user='user' />
                                </div>
                                <div
                                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4"
                                >
                                    <div class="flex flex-col">
                                        <div
                                            class="text-lg leading-5 font-medium text-blue-900 truncate"
                                        >
                                            {{ user.name }}
                                        </div>
                                        <div
                                            class="text-base leading-5 text-blue-900 truncate"
                                        >
                                            {{ user.email }}
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-center" v-if="user.type == 'team'">
                                        <div
                                            class="text-lg leading-5 font-medium text-blue-900 truncate"
                                        >
                                            {{ (user.teams || []).join(", ") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg
                                    class="h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </li>
                <li v-if="users.length == 0">
                    <alert color="blue" icon="fad fa-empty-set">
                        There are no team members here.
                    </alert>
                </li>
            </ul>
            <nav
                class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            >
                <div class="hidden sm:block">
                    <p class="text-sm leading-5 text-gray-700">
                        Showing
                        <span class="font-medium">{{ users.from }}</span>
                        to
                        <span class="font-medium">{{ users.to }} </span>
                        of
                        <span class="font-medium">{{ users.total }} </span>
                        results.
                        <a
                            v-if="!archived"
                            :href="$route('users.index') + '?archived'"
                            class="ml-2 underline"
                        >
                            Show Archived Team Members
                        </a>
                        <a
                            v-else
                            :href="$route('users.index')"
                            class="ml-2 underline"
                        >
                            Show Active Team Members
                        </a>
                    </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                    <Link
                        :href="users.prev_page_url || ''"
                        :disabled="!users.prev_page_url"
                        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        :class="{ 'opacity-50': !users.prev_page_url }"
                    >
                        Previous
                    </Link>
                    <Link
                        :href="users.next_page_url || ''"
                        :disabled="!users.next_page_url"
                        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        :class="{ 'opacity-50': !users.next_page_url }"
                    >
                        Next
                    </Link>
                </div>
            </nav>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import alert from "../../Common/alert.vue"
import auth from "../../Common/Mixins/auth"
import btn from "../../Common/btn.vue"
import avatar from "../../Common/avatar.vue"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"

export default {
    name: "UsersIndex",
    mixins: [auth],
    props: {
        auth: Object,
        archived: Boolean,
        users: Object
    },
    components: { alert, btn, Layout, Head, Link, avatar },
    methods: {
        team(team) {
            switch(team) {
                case 'dev':
                    return 'Development Team'
                case 'design':
                    return 'Design Team'
                case 'ateam':
                    return 'A-Team'
                case 'admin':
                    return 'Admin Team'
            }
        },
        date(date) {
            return moment(date)
                .local()
                .format("MMM Do, YYYY")
        }
    }
}
</script>

<style lang="postcss" scoped></style>
