<template>
<div class="flex flex-col pb-12">
    <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-200 shadow-sm">
            <table class="table-fixed min-w-full divide-y divide-gray-200 select-none dashboard-table">
                <colgroup>
                    <col class="w-12">
                    <col class="w-12">
                    <col class="w-48">
                    <col>
                    <col style="width: 5%;">
                    <col style="width: 5%;">
                    <col style="width: 5%;">
                    <col style="width: 5%;">
                    <col style="width: 5%;">
                    <col style="width: 5%;">
                </colgroup>
                <thead>
                    <tr class="bg-gray-100 text-center text-xs leading-2 text-gray-500 uppercase tracking-wide">
                        <th class="relative">
                            <input type="checkbox" class="" :checked="allChecked" @click='checkAll()'>
                        </th>
                        <th @click='order("user_id")' class="font-medium cursor-pointer text-center">
                            <i class="fas fa-user"></i>
                            <span v-show="orderBy[0] == 'user_id' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'user_id' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("client_id")' class="p-2 font-medium cursor-pointer text-left">
                            Client
                            <span v-show="orderBy[0] == 'client_id' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'client_id' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th class="p-2 font-medium cursor-pointer text-left">
                            Task Summary
                        </th>
                        <th @click='order("billable")' class="p-2 font-medium cursor-pointer text-center">
                            <i class="far fa-dollar-sign"></i>
                            <span v-show="orderBy[0] == 'billable' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'billable' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("planned")' class="p-2 font-medium cursor-pointer text-center">
                            Planned
                            <span v-show="orderBy[0] == 'planned' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'planned' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("actual")' class="p-2 font-medium cursor-pointer text-center">
                            Actual
                            <span v-show="orderBy[0] == 'actual' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'actual' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("rate")' class="p-2 font-medium cursor-pointer text-center">
                            Rate
                            <span v-show="orderBy[0] == 'rate' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'rate' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("billableNow")' class="p-2 font-medium cursor-pointer text-center">
                            Billable
                            <span v-show="orderBy[0] == 'billableNow' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'billableNow' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                        <th @click='order("billableGoal")' class="p-2 font-medium cursor-pointer text-center">
                            Goal
                            <span v-show="orderBy[0] == 'billableGoal' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                            <span v-show="orderBy[0] == 'billableGoal' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th colspan="10" class="border-t border-gray-200">
                            <div v-if='group.label' class='px-4 py-2 bg-gray-50 text-gray-600 border-b border-gray-200 flex items-center justify-start'>
                                <span v-if="!selected.length" class="py-1">{{ group.label }}</span>
                                <div v-else>
                                    <ul class="block">
                                        <li @click="copySelected" class="inline-block mr-2 px-2 py-1 border rounded border-gray-300 cursor-pointer text-gray-500 hover:text-gray-800 text-xs font-normal">
                                            <i class="fal fa-copy mr-1"></i> Copy
                                        </li>
                                        <li @click="removeSelected" class="inline-block px-2 py-1 border rounded border-gray-300 cursor-pointer text-gray-500 hover:text-gray-800 text-xs font-normal">
                                            <i class="fal fa-trash mr-1"></i> Delete
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <template v-for="(entry, idx) in sortedEntries" :key="entry.id">
                        <tr :class="rowClasses(entry, idx)">
                            <td class="text-sm leading-5 text-gray-900 text-center cursor-pointer">
                                <div class="flex items-center justify-between">
                                    <span class="pl-2" v-html="status(entry.status)"></span>
                                    <input type="checkbox" class="ml-2" :checked="isSelected(entry)" @click.stop='toggle(entry)'>
                                </div>
                            </td>
                            <td class="p-2 text-sm leading-5 text-gray-900 text-center cursor-pointer" @click='activateEntry(entry, "user")'>
                                <avatar :user="user(entry)" :size="8" />
                            </td>
                            <td class="p-2 text-sm leading-5 text-gray-900 cursor-pointer" @click='activateEntry(entry, "client")'>
                                <div>{{ clientName(entry) }}</div>
                            </td>
                            <td class="p-2 text-sm leading-5 text-gray-900 cursor-pointer" @click='activateEntry(entry, "client")'>
                                <div class="flex flex-col gap-2 items-start w-full">
                                    <div class="flex w-full">
                                        <div class="mt-1 line-clamp-2" v-if='entry.description'>{{ entry.description }}</div>
                                        <div class="ml-2 w-md inline-block text-sm leading-5 font-medium text-gray-900 client-name">
                                            <div class="flex items-center">
                                                <i class="fas fa-star text-yellow-300 text-lg mr-1" v-if="entry.priority > 0"></i>
                                                <div class="ml-1 inline-block" v-if='entry.temporary'>
                                                    <i class="fal fa-spinner-third fa-spin"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-xs" v-if='entry.ticket_numbers' @click.stop>
                                        Ref:
                                        <span v-html='parseTicketNumbers(entry)' />
                                    </div>
                                    <div class="flex gap-2">
                                        <div v-if="entry.sprint_id" class="text-xs leading-5 text-gray-500" v-html="displaySprint(entry)" @click="openOverageModal($event, entry)" />
                                        <span class="text-gray-500">{{ type(entry.type) }}</span>
                                        <ul v-if="canEdit(entry)" class="icons ml-1 text-gray-500 text-sm">
                                            <li @click.stop="cloneRow(entry)" class="hover:text-red-700" title="Clone Row">
                                                <i class="fal fa-clone"></i>
                                            </li>
                                            <li class="hover:text-red-700" :class="{
                                                'text-green-700 hover:text-green-700': copySuccess == entry.id,
                                                'text-red-700 hover:text-red-700': copyError == entry.id,
                                            }" title="Copy Row" @click.stop="copy(entry)" v-clipboard:copy="copyable(entry)" v-clipboard:success="() => copySuccess = entry.id" v-clipboard:error="() => copyError = entry.id">
                                                <i class="fal fa-copy"></i>
                                            </li>
                                            <li @click.stop="showHistory(entry)" class="hover:text-red-700" title="Show History">
                                                <i class="fal fa-history"></i>
                                            </li>
                                            <li @click.stop="removeRow(entry)" class="hover:text-red-700" title="Remove Row">
                                                <i class="fal fa-trash"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td class="p-2 text-sm leading-5 text-center cursor-pointer whitespace-nowrap" @click='toggleBillable(entry)'>
                                <span v-show="entry.billable">
                                    <i class="far fa-dollar-sign text-green-500"></i>
                                </span>
                                <span v-show="!entry.billable">
                                    <i class="far fa-dollar-sign text-gray-300"></i>
                                </span>
                            </td>
                            <td class="p-2 text-sm leading-5 text-gray-900 text-center whitespace-nowrap" :class="{
                                        'bg-gray-100': active(idx, 'planned')
                                    }" @click="activate(idx, 'planned', $event)" :ref="`tds-${idx}-planned`">
                                <number v-if="active(idx, 'planned')" v-model='entry.planned' class="p-1 text-xs text-center bg-transparent outline-none" autofocus @keydown.enter="deactivate(idx, 'planned')" @keydown.tab.exact.prevent="tabNext(idx, 'planned')" @keydown.shift.tab.prevent="tabPrev(idx, 'planned')" @blur="deactivate(idx, 'planned')" />
                                <span class="block p-2 text-center bg-transparent outline-none" v-else>{{ number(entry.planned) }}</span>
                            </td>
                            <td class="p-2 text-sm leading-5 text-gray-900 text-center whitespace-nowrap" :class="{
                                        'bg-gray-100': active(idx, 'actual')
                                    }" @click="activate(idx, 'actual')" :ref="`tds-${idx}-actual`">
                                <number v-if="active(idx, 'actual')" v-model='entry.actual' class="p-1 text-xs text-center bg-transparent outline-none" autofocus @keydown.enter="deactivate(idx, 'actual')" @keydown.tab.exact.prevent="tabNext(idx, 'actual')" @keydown.shift.tab.prevent="tabPrev(idx, 'actual')" @blur="deactivate(idx, 'actual')" />
                                <span class="block p-2 text-center bg-transparent outline-none" v-else>{{ number(entry.actual) }}</span>
                            </td>
                            <td class="text-sm leading-5 text-gray-900 whitespace-nowrap" :class="{
                                        'bg-gray-100': active(idx, 'rate'),
                                        'read-only': !entry.billable
                                    }" @click="entry.billable && activate(idx, 'rate')" :ref="`tds-${idx}-rate`">
                                <template v-if="entry.billable">
                                    <money v-if="active(idx, 'rate')" v-model='entry.rate' class="p-1 text-xs text-center bg-transparent outline-none" autofocus @keydown.enter="deactivate(idx, 'rate')" @keydown.tab.exact.prevent="tabNext(idx, 'rate')" @keydown.shift.tab.prevent="tabPrev(idx, 'rate')" @blur="deactivate(idx, 'rate')" />
                                    <span class="block p-2 text-center bg-transparent outline-none" v-else>{{ money(entry.rate) }}</span>
                                </template>
                            </td>
                            <td class="text-sm leading-5 text-gray-900 whitespace-nowrap" :class="{
                                        'bg-gray-100': active(idx, 'billableNow'),
                                        'read-only': !entry.billable || !entry.flat_rate
                                    }" @click="entry.billable && entry.flat_rate && activate(idx, 'billableNow')" :ref="`tds-${idx}-billableNow`">
                                <template v-if="entry.billable">
                                    <money v-if="active(idx, 'billableNow')" v-model='entry.billableNow' class="p-1 text-xs text-center bg-transparent outline-none" autofocus @keydown.enter="deactivate(idx, 'billableNow')" @keydown.tab.exact.prevent="tabNext(idx, 'billableNow')" @keydown.shift.tab.prevent="tabPrev(idx, 'billableNow')" @blur="deactivate(idx, 'billableNow')" />
                                    <span class="block p-2 text-center bg-transparent outline-none" v-else>{{ money(entry.billableNow) }}</span>
                                </template>
                            </td>
                            <td class="text-sm leading-5 text-gray-900 whitespace-nowrap" :class="{
                                        'bg-gray-100': active(idx, 'billableGoal'),
                                        'read-only': !entry.billable || !entry.flat_rate
                                    }" @click="entry.billable && entry.flat_rate && activate(idx, 'billableGoal')" :ref="`tds-${idx}-billableGoal`">
                                <template v-if="entry.billable">
                                    <money v-if="active(idx, 'billableGoal')" v-model='entry.billableGoal' class="p-1 text-xs text-center bg-transparent outline-none" autofocus @keydown.enter="deactivate(idx, 'billableGoal')" @keydown.tab.exact.prevent="tabNext(idx, 'billableGoal')" @keydown.shift.tab.prevent="tabPrev(idx, 'billableGoal')" @blur="deactivate(idx, 'billableGoal')" />
                                    <span class="block p-2 text-center bg-transparent outline-none" v-else>{{ money(entry.billableGoal) }}</span>
                                </template>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot class="bg-gray-100">
                    <tr>
                        <td colspan="2" class="p-2 text-sm">
                        </td>
                        <td colspan="3" class="font-bold text-right">Totals</td>
                        <td class="p-2 text-sm leading-5 text-gray-900 text-center">
                            {{ number(totals.planned) }}
                        </td>
                        <td class="p-2 text-sm leading-5 text-gray-900 text-center">
                            {{ number(totals.actual) }}
                        </td>
                        <td class="text-sm leading-5 text-gray-900">
                            <span class="block p-2 text-center bg-transparent outline-none">
                                {{ money(totals.rate) }}
                            </span>
                        </td>
                        <td class="text-sm leading-5 text-gray-900">
                            <span class="block p-2 text-center bg-transparent outline-none">
                                {{ money(totals.billableNow) }}
                            </span>
                        </td>
                        <td class="text-sm leading-5 text-gray-900">
                            <span class="block p-2 text-center bg-transparent outline-none">
                                {{ money(totals.billableGoal) }}
                            </span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <modal :show='pickUser' @close='pickUser = false;' overflowVisible>
        <nav>
            <a v-for="(user, idx) in users" :key="user.id" href="#" @click.prevent="addRow(null, user.id); pickUser = false;" class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150" :class="{
                        'mt-1': idx > 0,
                    }">
                <span class="truncate">
                    {{ user.name }}
                </span>
            </a>
        </nav>
    </modal>
    <modal :show='showHistoryFor != null' @close='showHistoryFor = null' overflowVisible @keypress.stop size='xl'>
        <entry-history v-if="showHistoryFor" :key="'entry-history-' + showHistoryFor.id" :clientName="clientName" :sprintName="sprintName" :entry="showHistoryFor" />
    </modal>
    <modal :show='activeEntry != null' @close='activeEntry = null' overflowVisible @keypress.stop :closeOnBackgroundClick='["user", "client", "status", "type"].includes(editWhat)'>
        <div v-if='activeEntry && editWhat == "client"'>
            <entry-form ref="entryForm" :key='activeEntry.id' v-model='activeEntry' @reload='reload' @reloadWithSprints='reloadWithSprints' @saved='activeEntrySaved' @deleted='activeEntryDeleted' @cancel='activeEntry = null' />
        </div>
        <div v-else-if='activeEntry && editWhat == "user"'>
            <nav>
                <a v-for="(user, idx) in users" :key="user.id" href="#" @click.prevent="activeEntry.user_id = user.id; saveActiveEntry()" class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150" :class="{
                            'bg-green-200': activeEntry.user_id == user.id,
                            'mt-1': idx > 0,
                        }">
                    <i class="fal fa-check mr-2" v-if="activeEntry.user_id == user.id"></i>
                    <span class="truncate">
                        {{ user.name }}
                    </span>
                </a>
            </nav>
        </div>
        <div v-else-if='activeEntry && editWhat == "type"'>
            <nav>
                <a v-for="(type, tKey) in options.types" :key="tKey" href="#" @click.prevent="activeEntry.type = tKey; saveActiveEntry()" class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150" :class="{
                            'bg-green-200': activeEntry.type == tKey
                        }">
                    <i class="fal fa-check mr-2" v-if="activeEntry.type == tKey"></i>
                    <span class="truncate">
                        {{ type }}
                    </span>
                </a>
            </nav>
        </div>
        <div v-else-if='activeEntry && editWhat == "status"'>
            <nav>
                <a v-for="(status, sKey) in options.statuses" :key="sKey" href="#" @click.prevent="activeEntry.status = sKey; saveActiveEntry()" class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150" :class="{
                            'bg-green-200': activeEntry.status == sKey
                        }">
                    <i class="fal fa-check mr-2" v-if="activeEntry.status == sKey"></i>
                    <span class="truncate">
                        {{ status }}
                    </span>
                </a>
            </nav>
        </div>
        <div v-if='activeEntry && !["user", "client", "status", "type"].includes(editWhat)' class="text-center mt-5">
            <btn blue class="mr-2" @click='saveActiveEntry'>Save Changes</btn>
            <btn red outline @click='cancelActiveEntry'>Cancel</btn>
        </div>
    </modal>
    <overage-modal v-if="overage" :sprint="overage.sprint" :entry="overage.entry" :sprints="sprints" @close="overageFinished()"></overage-modal>
</div>
</template>

<script>
import { nextTick } from 'vue'
import auth from "./Mixins/auth"
import { get, find, findIndex, clone, orderBy } from 'lodash'
import btn from '../Common/btn.vue'
import avatar from "../Common/avatar.vue"
import modal from '../Common/modal.vue'
import money from '../Common/money.vue'
import number from '../Common/number.vue'
import overageModal from "../Common/overageModal.vue"
import axios from 'axios'
import moment from 'moment'
import entryForm from "./entryForm.vue"
import entryHistory from "./entryHistory.vue"

export default {
    name: 'DashboardTable',
    mixins: [ auth ],
    components: { btn, money, number, modal, entryForm, entryHistory, avatar, overageModal },
    props: {
        auth: Object,
        rates: Object,
        group: Object,
        entries: Array,
        clients: Array,
        sprints: Array,
        users: Array,
        types: Array,
        highlight: Array,
        deleteSelected: Function,
        canAddRows: {
            type: Boolean,
            default: true
        },
        newRow: {
            type: Object,
            default: {}
        },
        showBilling: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dropdownOpen: {},
            editWhat: null,
            activatingEntry: false,
            activeEntry: null,
            showHistoryFor: null,
            selected: [],
            pickUser: false,
            activePaths: {},
            copySuccess: null,
            copyError: null,
            orderBy: [null, null],
            overage: null
        }
    },
    mounted() {
        const matches = window.location.search.match(/id=([^&]+)/)
        if (matches) {
            const timeEntryId = matches[1]
            const entry = find(this.entries, { id: timeEntryId })
            if (entry) {
                const newPath = (window.location.pathname + window.location.search).replace(matches[0], "").replace(/&$/, '')
                window.history.replaceState({}, document.title, newPath)
                nextTick(() => {
                    this.activateEntry(entry)
                })
            }
        }
    },
    computed: {
        canSort() {
            return this.orderBy[0] == null
        },
        allChecked() {
            if (this.entries.length == 0) {
                return false;
            }
            return this.entries.length == this.selected.length
        },
        userTeamsMap() {
            const map = {}
            this.users && this.users.forEach(user => (map[user.id] = user.teams))
            return map
        },
        totals () {
            const total = {
                count: 0,
                actual: 0,
                planned: 0,
                rate: 0,
                billableNow: 0,
                billableGoal: 0
            }
            this.entries.forEach((entry) => {
                total.actual += entry.actual ? Number(entry.actual) : 0
                total.planned += entry.planned ? Number(entry.planned) : 0
                if (entry.billable) {
                    total.count++
                    total.rate += entry.rate ? Number(entry.rate) : 0
                    total.billableNow += entry.billableNow ? Number(entry.billableNow) : 0
                    total.billableGoal += entry.billableGoal ? Number(entry.billableGoal) : 0
                }
            })
            total.rate = Math.round((total.count > 0 ? total.rate / total.count : 0) * 4) / 4

            total.actual = Math.round(total.actual * 100) / 100
            total.planned = Math.round(total.planned * 100) / 100
            total.rate = Math.round(total.rate * 100) / 100
            total.billableNow = Math.round(total.billableNow * 100) / 100
            total.billableGoal = Math.round(total.billableGoal * 100) / 100
            return total
        },
        options() {
            var opts = {
                priorities: {
                    4: 'Urgent A',
                    3: 'Urgent B',
                    2: 'Urgent C',
                    1: 'Urgent D',
                    0: 'Normal'
                },
                billable: {
                    [true]: 'Yes',
                    [false]: 'No'
                },
                clients: {},
                sprints: {},
                users: {},
                types: {},
                statuses: {
                    active: "Active",
                    pending: "Pending",
                    complete: "Complete"
                },
                groupBy: {
                    client: 'Client',
                    team: 'Team',
                    user: 'Team Member',
                    type: 'Type of Work',
                }
            }

            this.sprints.forEach(s => {
                if (this.activeEntry && this.activeEntry.client_id === s.client_id) {
                    const amount = this.money(s.amount)
                    if (s.maximum) {
                        var maximum = this.money(s.maximum)
                        opts.sprints[s.id] = `${s.name} - <span class="text-gray-600"><span class="font-medium">${amount}</span> / ${maximum}</span>`
                    } else {
                        opts.sprints[s.id] = `${s.name} - <span class="text-gray-600 font-medium">${amount}</span>`
                    }
                }
            })
            this.types.forEach(t => (opts.types[t.slug] = t.name))
            this.clients.forEach(c => (opts.clients[c.id] = c.name))
            this.users.forEach(u => (opts.users[u.id] = u))

            return opts
        },
        sortedEntries() {
            if (this.orderBy[0]) {
                switch(this.orderBy[0]) {
                    case 'user_id':
                        return orderBy(this.entries, (user) => {
                            return get(find(this.users, { id: user.user_id }), 'name', '')
                        }, this.orderBy[1]);
                    case 'client_id':
                        return orderBy(this.entries, (client) => {
                            return get(find(this.clients, { id: client.client_id }), 'name', '')
                        }, this.orderBy[1]);
                    case 'actual':
                    case 'planned':
                    case 'rate':
                    case 'billableNow':
                    case 'billableGoal':
                        return orderBy(this.entries, (entry) => {
                            return Number(get(entry, this.orderBy[0], 0))
                        }, this.orderBy[1])
                    default:
                        return orderBy(this.entries, this.orderBy[0], this.orderBy[1])
                }
            } else {
                return orderBy(this.entries, [(user) => {
                            return get(find(this.users, { id: user.user_id }), 'name', '')
                }, 'order'], ['asc', 'asc']);
            }
        }
    },
    methods: {
        datetime(dt) {
            return moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM Do YYYY, h:mma')
        },
        money(num) {
            const sign = num >= 0 ? '' : '-'
            return (
                `${sign}$` +
                Math.abs(Number(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        number(num) {
            return Number(num).toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        },
        reload() {
            this.$emit('reload')
        },
        reloadWithSprints() {
            this.$emit('reloadWithSprints')
        },
        overageFinished() {
            this.overage = null;
            this.$emit('reloadWithSprints')
        },
        async orderChanged(ev) {
            // We cannot filter the sortedEntries because oldIndex and newIndex
            // are relative to the entire list
            let order = this.sortedEntries.map(e => e.id)
            let entry = order.splice(ev.oldIndex, 1)
            order.splice(ev.newIndex, 0, entry[0])

            let myEntries = this.sortedEntries.filter(e => e.user_id == this.auth.user.id).map(e => e.id)

            // We need remove everyone elses entries now
            order = order.filter(id => myEntries.indexOf(id) >= 0)

            this.entries.filter(e => e.user_id == this.auth.user.id).forEach((entry) => {
                let newOrder = order.indexOf(entry.id)
                if (newOrder != entry.order) {
                    entry.order = newOrder
                    this.$emit("changed", entry)
                }
            })
        },
        order(key) {
            if (this.orderBy && this.orderBy[0] === key) {
                if (this.orderBy[1] == 'asc') {
                    this.orderBy = [key, 'desc']
                } else {
                    this.orderBy = [null, null]
                }
            } else {
                this.orderBy = [key, 'asc']
            }
        },
        async createSprint(query) {
            if (query && this.activeEntry.client_id) {
                const response = await axios.post(
                    this.$route("sprints.store"),
                    {
                        client_id: this.activeEntry.client_id,
                        name: query,
                        status: 'active'
                    }
                )
                this.$emit('reloadSprints')
                this.activeEntry.sprint_id = response.data.id
            }
        },
        selectAll() {
            this.entries.forEach((entry) => {
                this.toggle(entry, true)
            })
        },
        unselectAll() {
            clone(this.selected).forEach((id) => {
                this.toggle(find(this.entries, {id}))
            })
        },
        showHistory(entry) {
            this.showHistoryFor = entry
        },
        isSelected(entry) {
            const idx = this.selected.indexOf(entry.id)
            return idx >= 0
        },
        toggleBillable(entry) {
            var e = find(this.entries, {id: entry.id})
            e.billable = e.billable ? false : true
            this.$emit('changed', e)
        },
        toggle(entry, force) {
            const idx = this.selected.indexOf(entry.id)
            if (force === undefined) {
                if (idx >= 0) {
                    this.selected.splice(idx, 1)
                } else {
                    this.selected.push(entry.id)
                }
                this.$emit('toggle', entry)
            } else {
                if (force && idx === -1) {
                    this.selected.push(entry.id)
                    this.$emit('toggle', entry)
                } else if (force === false && idx >= 0) {
                    this.selected.splice(idx, 1)
                    this.$emit('toggle', entry)
                }
            }
        },
        copyable(entry) {
            return JSON.stringify(entry)
        },
        copy(entry) {
            const entries = [{...entry}]
            this.doCopy(entries, null)
        },
        async copySelected(event) {
            const entries = []
            this.selected.forEach((id) => {
                entries.push(find(this.entries, { id }))
            })
            this.doCopy(entries, event)
        },
        doCopy(entries, event) {
            if (event && event.clipboardData) {
                event.clipboardData.setData('text', JSON.stringify(entries))
            } else {
                this.manualCopy(JSON.stringify(entries))
            }
            this.$emit('copy', entries)

            event && event.preventDefault()
            this.unselectAll()
        },
        manualCopy(str) {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        canEdit(entry) {
            if (entry.temporary) {
                return false
            }

            if (entry.user_id == this.auth.user.id) {
                return true
            } else {
                const teams = this.userTeamsMap[entry.user_id]
                if (teams) {
                    for(let team of teams) {
                        if (this.hasPermission(`edit:${team}-entries`)) {
                            return true;
                        }
                    }
                    return false
                }
            }
            return true
        },
        deactivate(idx, key) {
            this.activePaths[`${idx}:${key}`] = false
            this.changed(idx)
        },
        changed(idx) {
            this.$emit('changed', idx === undefined ? this.entries : this.sortedEntries[idx])
        },
        checkAll() {
            if (this.allChecked) {
                this.selected.forEach((id) => {
                    this.$emit('toggle', { id })
                })
                this.selected = []
            } else {
                this.selected = this.entries.map(e => e.id)
                this.selected.forEach((id) => {
                    this.$emit('toggle', { id })
                })
            }
        },
        tabPrev(idx, key) {
            this.deactivate(idx, key)
            nextTick(() => {
                this.activate(key == 'planned' ? idx - 1 : idx, this.prevKey(key))
            })
        },
        addRow(index, userId) {
            if (index === null) {
                let max = -1
                this.entries.forEach(e => {
                    max = Math.max(max, e.order)
                })
                index = max
            }

            const newRow = clone(this.newRow)

            if (userId !== undefined) {
                newRow.user_id = userId
            }

            // If we are creating a new row, but the template doesn't have a
            // user set, then the first thing we want to do is pick a user
            if ('user_id' in newRow && newRow.user_id == null) {
                if (this.users.length == 1) {
                    newRow.user_id = this.users[0].id
                } else {
                    this.pickUser = true;
                    return
                }
            }

            const user = find(this.users, {
                id: newRow.user_id
            })

            newRow.type = user.default_work_type

            if (user.default_work_type) {
                const type = find(this.types, {slug: user.default_work_type})
                if (type) {
                    newRow.rate = type.rate
                }
            }

            this.$emit('add', {
                order: index + 1,
                ...newRow
            })
        },
        cloneRow(entry) {
            this.$emit('clone', entry)
        },
        removeRow(entry, force) {
            this.$emit('remove', {entry, force})
        },
        removeSelected() {
            this.$emit('removeSelected')
        },
        prevKey(key) {
            switch(key) {
                case 'planned':
                    return 'billableGoal';
                case 'actual':
                    return 'planned';
                case 'rate':
                    return 'actual';
                case 'billableNow':
                    return 'rate';
                case 'billableGoal':
                    return 'billableNow';
            }
        },
        tabNext(idx, key) {
            this.deactivate(idx, key)
            nextTick(() => {
                this.activate(key == 'billableGoal' ? idx + 1 : idx, this.nextKey(key))
            })
        },
        nextKey(key) {
            switch(key) {
                case 'planned':
                    return 'actual';
                case 'actual':
                    return 'rate';
                case 'rate':
                    return 'billableNow';
                case 'billableNow':
                    return 'billableGoal';
                case 'billableGoal':
                    return 'planned';
            }
        },
        activate(idx, key) {
            if (this.canEdit(this.sortedEntries[idx])) {
                if (key) {
                    let width = null
                    if (this.$refs[`tds-${idx}-${key}`]) {
                        width = this.$refs[`tds-${idx}-${key}`][0].clientWidth
                    }

                    this.activePaths[`${idx}:${key}`] = true
                    if (width) {
                        width -= 20; //remove padding
                        nextTick(() => {
                            try {
                                this.$refs[`tds-${idx}-${key}`][0].childNodes[0].style.width = `${width}px`
                            } catch(e) {
                                // pass
                            }
                        });
                    }
                }
            }
        },
        active(idx, key) {
            return `${idx}:${key}` in this.activePaths ? this.activePaths[`${idx}:${key}`] : false
        },
        userCellClass(entry) {
            const user = find(this.users, {
                id: entry.user_id
            })
            const classes = []
            if (user && user.accent) {
                classes.push(`bg-${user.accent}-100`)
            }
            return classes.join(' ')
        },
        rowClasses(entry, idx) {
            const classes = ['transition transition-colors'];
            if (entry.user_id == this.auth.user.id) {
                classes.push("mine")
            }
            if (entry.id in this.highlight && this.highlight[entry.id]) {
                classes.push("bg-green-100")
            } else if (idx % 2) {
                //classes.push('bg-gray-50')
            }

            if (!this.canEdit(entry)) {
                classes.push("read-only")
            }

            return classes.join(' ')
        },
        activateEntry(entry, editWhat) {
            if (this.canEdit(entry)) {
                this.activatingEntry = true
                this.activeEntry = clone(entry)
                this.editWhat = editWhat || 'client'
                nextTick(() => {
                    this.activatingEntry = false
                })
            }
        },
        isRowEmpty(entry) {
            return entry.client_id === null
                && entry.description === null
                && entry.actual == 0
                && entry.planned == 0;
        },
        cancelActiveEntry() {
            if (this.isRowEmpty(this.activeEntry)) {
                this.removeRow(this.activeEntry, true);
            }
            this.activeEntry = null
        },
        activeEntryDeleted(activeEntry) {
            this.$emit("removed", activeEntry)
            this.activeEntry = null
        },
        activeEntrySaved(activeEntry) {
            this.activeEntry = null
            nextTick(() => {
                const idx = findIndex(this.entries, {id: activeEntry.id})
                if (idx >= 0) {
                    this.entries[idx] = activeEntry
                }
            })
        },
        async saveActiveEntry() {
            const activeEntry = clone(this.activeEntry)
            if (!activeEntry.billable) {
                activeEntry.rate = null;
                activeEntry.billableNow = null;
                activeEntry.billableGoal = null;
            }
            if (activeEntry.sprint_id == 'new') {
                let sprint = clone(this.$refs.entryForm.sprint)
                let response = await axios.post(
                    this.$route("sprints.store"),
                    sprint
                )
                activeEntry.sprint_id = response.data.id
            }
            activeEntry.ticket_numbers = activeEntry.tickets.join("\n").trim()
            this.$emit('changed', activeEntry);
            this.activeEntrySaved(activeEntry);
        },
        type(t) {
            const type = find(this.types, { slug: t })
            return type ? type.name : ''
        },
        status(status) {
            const statuses = {
                active: `<span title="Active" class="inline-block rounded-full w-2 h-2 bg-green-500"></span>`,
                pending: `<span title="Pending" class="inline-block rounded-full w-2 h-2 bg-yellow-400"></span>`,
                complete: `<span title="Complete" class="inline-block rounded-full w-4 h-4 bg-green-500 text-white flex items-center justify-center mx-auto"><i class="fas fa-xs fa-check relative top-[2px]"/></span>`
            }
            return status in statuses ? statuses[status] : status
        },
        openOverageModal($event, entry) {
            const sprint = find(this.sprints, {
                id: entry.sprint_id
            })
            if (sprint) {
                if (sprint.maximum && sprint.amount > sprint.maximum) {
                    this.overage = {
                        sprint,
                        entry
                    }
                    $event.stopPropagation()
                }
            }
        },
        displaySprint(entry) {
            const sprint = find(this.sprints, {
                id: entry.sprint_id
            })
            if (sprint) {
                if (sprint.maximum) {
                    const amount = this.money(sprint.amount)
                    const maximum = sprint.maximum ? this.money(sprint.maximum) : 0
                    const remaining = this.money(Math.abs(sprint.maximum - sprint.amount));
                    if (maximum) {
                        const color = sprint.amount > sprint.maximum ? 'red' : 'green'
                        const hover = sprint.amount > sprint.maximum ? 'hover:bg-red-200 hover:border-red-300' : ''
                        const suffix = sprint.amount > sprint.maximum ? 'over' : 'left'
                        return `<span title="${sprint.name}" class="inline-block text-center bg-${color}-100 ${hover} text-${color}-800 border border-${color}-200 rounded-full px-2">${remaining} ${suffix}</span>`
                    }
                    return `${sprint.name}<br><span class="text-green-600">${amount}</span>`
                } else {
                    return `${sprint.name}`
                }
            }
            return '--'
        },
        clientName(entry) {
            const client = find(this.clients, {
                id: entry.client_id
            })
            if (client) {
                return client.name
            }
            return '--'
        },
        sprintName(entry) {
            const sprint = find(this.sprints, {
                id: entry.sprint_id
            })
            if (sprint) {
                return sprint.name
            }
            return '--'
        },
        user(entry) {
            const user = find(this.users, {
                id: entry.user_id
            })
            if (user) {
                return user;
            }
            return {name:'Unknown', accent:'gray', id: 0};
        },
        userName(entry) {
            const user = find(this.users, {
                id: entry.user_id
            })
            if (user) {
                return user.name
            }
            return '--'
        },
        parseTicketNumbers(entry) {
            const numbers = entry.ticket_numbers.split("\n");
            const parsed = numbers.map((url) => {
                if (url.match(/^\d+/)) {
                    // it's only a number, assume it's a zendesk ticket
                    return `<a href="https://legnd.zendesk.com/agent/tickets/${url}" target="_blank" class="underline">${url}</a>`
                } else if (url.match(/https?:\/\/legnd.zendesk.com\/agent\/tickets\/\d+$/)) {
                    const number = url.split("/").pop()
                    return `<a href="https://legnd.zendesk.com/agent/tickets/${number}" target="_blank" class="underline">${number}</a>`
                } else if (url.match(/https?:\/\/gitlab.com\/.*\/-\/issues\/\d+$/)) {
                    const regex = /https?:\/\/gitlab.com\/(.*)\/-\/issues\/(\d+)$/
                    let matches = regex.exec("https://gitlab.com/legnd-cms/forge-core/-/issues/280")
                    matches[1] = matches[1].replace(/legnd-cms(?:-sites)?\//, '')
                    return `<a href="${url}" target="_blank" class="underline">${matches[1]}#${matches[2]}</a>`
                } else {
                    return `<a href="${url}" target="_blank" class="underline">${url}</a>`
                }
            })
            return parsed.join(", ")
        },
        updateEntryTickets() {
            let tickets = clone(this.activeEntry.tickets)
            tickets = tickets.filter(txt => !!txt)
            if (tickets.length == 0) {
                tickets.push("")
            } else if(tickets[tickets.length - 1] != "") {
                tickets.push("")
            }
            this.activeEntry.tickets = tickets
        }
    },
    watch: {
        copySuccess () {
            if (this.copySuccess) {
                setTimeout(() => {
                    this.copySuccess = null
                }, 4000)
            }
        },
        copyError () {
            if (this.copyError) {
                setTimeout(() => {
                    this.copyError = null
                }, 4000)
            }
        },
        'activeEntry.flat_rate'() {
            if (this.activeEntry && !this.activeEntry.flat_rate) {
                this.activeEntry.billableNow = (this.activeEntry.rate * this.activeEntry.actual);
                this.activeEntry.billableGoal = (this.activeEntry.rate * this.activeEntry.planned);
            }
        },
        'activeEntry.rate'() {
            if (this.activeEntry && !this.activeEntry.flat_rate) {
                this.activeEntry.billableNow = (this.activeEntry.rate * this.activeEntry.actual);
                this.activeEntry.billableGoal = (this.activeEntry.rate * this.activeEntry.planned);
            }
        },
        'activeEntry.actual'() {
            if (this.activeEntry && !this.activeEntry.flat_rate) {
                this.activeEntry.billableNow = (this.activeEntry.rate * this.activeEntry.actual);
            }
        },
        'activeEntry.planned'() {
            if (this.activeEntry && !this.activeEntry.flat_rate) {
                this.activeEntry.billableGoal = (this.activeEntry.rate * this.activeEntry.planned);
            }
        },
        'activeEntry.type'(to, from) {
            if (this.activeEntry && !this.activatingEntry) {
                const fromType =  find(this.types, {slug: from})
                const type =  find(this.types, {slug: to})
                if (type) {
                    const client = find(this.clients, {id: this.activeEntry.client_id})
                    const clientRates = get(client, 'rates', {}) || {}

                    const oldTypeRate = fromType ? (fromType.slug in clientRates ? clientRates[fromType.slug] : fromType.rate) : 0
                    const newTypeRate = type ? (type.slug in clientRates ? clientRates[type.slug] : type.rate) : 0

                    // If the rate is in sync with whatever it should have been, then update it
                    if (!oldTypeRate || Number(this.activeEntry.rate) == Number(oldTypeRate) || Number(this.activeEntry.rate) === 0) {
                        this.activeEntry.rate = newTypeRate
                    }
                }
            }
        },
        'activeEntry.client_id'(from) {
            if (this.activeEntry && !this.activatingEntry) {
                if (this.activeEntry.sprint_id && this.activeEntry.client_id) {
                    const sprint = find(this.sprints, {id: this.activeEntry.sprint_id})
                    if (sprint && sprint.client_id != this.activeEntry.client_id) {
                        this.activeEntry.sprint_id = null
                    }
                } else {
                    this.activeEntry.sprint_id = null
                }

                const fromClient = from ? find(this.clients, {id: from}) : null
                const fromClientRates = fromClient ? get(fromClient, 'rates', {}) : null
                const client = find(this.clients, {id: this.activeEntry.client_id})
                const clientRates = get(client, 'rates', {})
                const type =  find(this.types, {id: this.activeEntry.type})

                if (client && type) {
                    const oldTypeRate = fromClient ? (type.slug in fromClientRates ? fromClientRates[type.slug] : type.rate) : 0
                    const newTypeRate = type ? (type.slug in clientRates ? clientRates[type.slug] : type.rate) : 0

                    // If the rate is in sync with whatever it should have been, then update it
                    if (!oldTypeRate || Number(this.activeEntry.rate) == Number(oldTypeRate) || Number(this.activeEntry.rate) === 0) {
                        this.activeEntry.rate = newTypeRate
                    }
                }
            }
        },
        activeEntry () {
            if (this.activeEntry) {
                const tickets = this.activeEntry.ticket_numbers ? this.activeEntry.ticket_numbers.split("\n") : []
                if (tickets.length == 0) {
                    tickets.push("")
                } else if(tickets[tickets.length - 1] != "") {
                    tickets.push("")
                }
                this.activeEntry['tickets'] = tickets
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
table.dashboard-table {
    border-collapse: separate;
    border-spacing: 0;
    thead {
        th {

            border-bottom: 0;
        }
    }
    tbody {
        td {
            font-size: 0.8rem;

            ul.icons {
                display: inline-block;
                opacity: 0;
                pointer-events: none;
                li {
                    display: inline-block;
                    cursor: pointer;
                    @apply mr-2;
                }
            }
        }
        tr.read-only {
            td {
                cursor: default !important;
            }
        }
        tr:not(.read-only) {
            td:not(.read-only):hover {
                border-color: theme("colors.yellow.400");
                cursor: pointer;
            }
            &:not(.read-only):hover {
                td {
                    background-color: theme("colors.gray.50") !important;
                    ul.icons {
                        opacity: 1;
                        pointer-events: initial;
                    }
                }
            }
        }
    }
}
</style>
