<template>
<div class="flex flex-col">
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6">
    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th @click='order("created_at")' class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Created At
              <span v-show="localOrderBy[0] == 'created_at' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'created_at' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("due_date")' class="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Due
              <span v-show="localOrderBy[0] == 'due_date' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'due_date' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("paid_at")' class="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Paid
              <span v-show="localOrderBy[0] == 'paid_at' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'paid_at' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("number")' class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Number
              <span v-show="localOrderBy[0] == 'number' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'number' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th v-if="isTeamMember && showClient" @click='order("client")' class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Client
              <span v-show="localOrderBy[0] == 'client' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'client' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th v-if="isTeamMember" class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
              Work Orders
            </th>
            <th @click='order("amount")' class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap cursor-pointer">
              Total
              <span v-show="localOrderBy[0] == 'amount' && localOrderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="localOrderBy[0] == 'amount' && localOrderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th class="px-6 py-3 bg-gray-50" v-if="editable && isTeamMember"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <template v-for="(payment, idx) in orderedPayments" :key="payment.id">
            <tr
              @click.stop.prevent='isTeamMember ? $emit("paymentClicked", payment) : null'
              :class="{
                'bg-gray-50': idx % 2 == 1,
                'hover:bg-gray-100 cursor-pointer': isTeamMember
              }"
              :title="payment.description"
            >
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                {{ datetime(payment.created_at) }}
              </td>
              <td class="px-6 py-3 text-center whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <span v-if='payment.due_date' :class="{'text-red-500': !payment.is_paid && overdue(payment)}">
                    {{ date(payment.due_date) }}
                </span>
                <span v-else>--</span>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 text-center">
                <span v-if="payment.is_paid" class="text-green-500">
                    <span v-if="payment.paid_at">
                        {{ datetime(payment.paid_at) }}
                    </span>
                    <span v-else><i class="fal fa-check text-green-500"></i></span>
                </span>
                <span v-else><i class="fal fa-times text-red-500"></i></span>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                {{ payment.number }}
              </td>
              <td v-if="isTeamMember && showClient" class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <Link
                    v-if="payment.client_id"
                    :href='"/clients/" + payment.client_id'
                    class="hover:underline truncate max-w-xs block"
                >{{ payment.client ? payment.client.name : '--' }}</Link>
                <span v-else>--</span>
              </td>
              <td v-if="isTeamMember" class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-500">
                <ul class="space-y-1">
                    <li v-for="sprint in payment.sprints" :key="sprint.id">
                        <Link
                            :href='"/sprints/" + sprint.id'
                            class="hover:underline flex flex-col"
                            @click.stop.prevent='$emit("sprintClicked", sprint.id)'
                        >
                          {{ sprint ? sprint.name : '--' }}
                          <span
                            v-if="sprint.project_id && sprint.client_id && sprint.client_id in projectsByClientId && projectsByClientId[sprint.client_id].length > 1"
                            class="text-xs text-gray-400"
                          >
                            {{ projectName(sprint.project_id) }}
                          </span>
                        </Link>
                    </li>
                </ul>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                ${{ num(payment.amount) }}
              </td>
              <td
                class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium"
                v-if="editable && isTeamMember"
              >
                <a
                  href="#"
                  class="text-blue-600 hover:text-blue-900"
                  @click.stop.prevent='editPayment = payment'
                >Edit</a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <template v-if="isTeamMember">
    <modal :show='editPayment != null'>
      <paymentForm
        v-if="editPayment"
        :payment="editPayment"
        :for-client="editPayment.client_id"
        show-delete
        @saved='paymentSaved'
        @deleted='paymentDeleted'
        @cancel='editPayment = null'
      />
    </modal>
  </template>
</div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import modal from "./modal.vue"
import moment from "moment"
import paymentForm from "./paymentForm.vue"
import {orderBy, groupBy, findIndex, get} from "lodash"

export default {
  name: 'PaymentsTable',
  components: { modal, paymentForm, Link },
  props: {
    auth: {
      type: Object,
      default: () => ({})
    },
    localOrdering: {
      type: Boolean,
      default: true,
    },
    showClient: {
      type: Boolean,
      default: true,
    },
    clients: Array,
    payments: Array,
    projects: Array,
    orderBy: Array,
    editable: {
        type: Boolean,
        default: false,
    }
  },
  data () {
      return {
          editPayment: null,
          localOrderBy: this.orderBy ? this.orderBy : ['created_at', 'desc'],
      }
  },
  methods: {
    projectName(project_id) {
        const idx = findIndex(this.projects, {id: project_id})
        if (idx >= 0) {
          return this.projects[idx].name
        }
        return '--'
    },
    overdue(payment) {
        let date = moment.utc(payment.due_date, 'YYYY-MM-DD HH:mm:ss').local()
        return date.isBefore(moment())
    },
    paymentSaved() {
      this.$emit('refresh')
      this.editPayment = null
    },
    paymentDeleted() {
      this.$emit('refresh')
      this.editPayment = null
    },
    clientName(client_id) {
        if (Array.isArray(this.clients)) {
            const idx = findIndex(this.clients, {id: client_id})
            return this.clients[idx].name
        } else if(this.clients) {
            return this.clients[client_id]
        }
    },
    order(key) {
        if (this.localOrderBy[0] == key) {
            if (this.localOrderBy[1] == 'asc') {
            this.localOrderBy = [key, 'desc']
            } else {
            this.localOrderBy = [null, null]
            }
        } else {
            this.localOrderBy = [key, 'asc']
        }
    },
    datetime(dt) {
        let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
        if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
            return date.format('MMM Do, h:mma')
        }
        return date.format('MMM Do YYYY, h:mma')
    },
    date(dt) {
        let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
        if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
            return date.format('MMM Do')
        }
        return date.format('MMM Do YYYY')
    },
    status (status) {
        switch(status) {
            case 'active':
                return 'Active';
            case 'finished':
                return 'Finished';
            case 'paid':
                return 'Paid';
            case 'archived':
                return 'Archived';
        }
        return status
    },
    num (num) {
      return Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
    }
  },
  watch: {
    localOrderBy: {
      deep: true,
      handler() {
        this.$emit('order', this.localOrderBy)
      }
    }
  },
  computed: {
      projectsByClientId() {
        return groupBy(this.projects, 'client_id')
      },
      isTeamMember() {
        return get(this.auth, 'user.type', '') == 'team'
      },
      orderedPayments() {
          if (this.localOrdering && this.localOrderBy[0]) {
                switch(this.localOrderBy[0]) {
                    case 'client':
                        return orderBy(this.payments, 'client.name', this.localOrderBy[1]);
                    case 'sprint':
                        return orderBy(this.payments, 'sprint.name', this.localOrderBy[1]);
                    default:
                        return orderBy(this.payments, this.localOrderBy[0], this.localOrderBy[1])
                }
            } else {
                return this.payments
            }
      }
  }
}
</script>

<style lang="postcss" scoped>
</style>
