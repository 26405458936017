<template>
    <div :class="`rounded-md border border-${color}-200 bg-${color}-50 p-4`">
        <div class="flex items-center">
            <div v-if="icon" class="flex-shrink-0 mr-2">
                <i :class="icon + ' text-' + color + '-400'"></i>
            </div>
            <div :class="'text-sm leading-5 text-' + color + '-700'">
                <slot />
            </div>
        </div>
        <div v-if="0">
            <span class="border-gray-200 bg-gray-50"></span>
            <span class="border-blue-200 bg-blue-50"></span>
            <span class="border-green-200 bg-green-50"></span>
            <span class="border-red-200 bg-red-50"></span>
            <span class="border-yellow-200 bg-yellow-50"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Alert",
    props: {
        color: {
            type: String,
            default: "blue"
        },
        icon: String
    }
}
</script>

<style lang="postcss" scoped></style>
