<template>
    <Layout ref='layout' :auth='auth'>
        <template v-slot:title>Client Analytics</template>
        <Head title="Client Analytics" />
        <div>
            This client doesn't have a Google Analytics property id set.
        </div>
    </Layout>
</template>

<script>
import { Head } from '@inertiajs/vue3'
import Layout from "../../Common/Layout.vue"
import auth from "../../Common/Mixins/auth"

export default {
    mixins: [auth],
    components: { Head, Layout, },
    props: {
        auth: Object,
        cards: Array
    },
    computed: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    watch: {
    }
}
</script>
