<template>
    <Head title="Forgot Your Password?" />
    <div
        class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    >
        <div class="sm:mx-auto sm:w-full sm:max-w-md text-center">
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900 flex gap-2">
                <img class="w-8 object-contain" :src="asset('/images/logo-mark.svg')" />
                <span>{{ welcomeMessage }}</span>
            </h2>
        </div>

        <div class="sm:mx-auto sm:w-full sm:max-w-md mt-4" v-if="messages.length">
            <template v-for="(msg, idx) in messages" :key="'msg-' + idx">
                <div :class="'bg-'+msg.color+'-50 border-l-4 border-'+msg.color+'-400 p-4'">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg :class="'h-5 w-5 text-'+msg.color+'-400'" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p :class="'text-sm leading-5 text-'+msg.color+'-700'">
                                {{ msg.message }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form :action="token ? '/password/reset' : '/password/email'" method="post">
                    <input type="hidden" name="_token" :value="csrfToken" />
                    <div>
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Email address
                        </label>
                        <div
                            class="mt-1 rounded-md"
                            :class="{
                                'shadow-sm': !token
                            }"
                        >
                            <input
                                name="email"
                                id="email"
                                type="email"
                                :value="email"
                                :readonly="token"
                                required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                :class="{
                                    'border-none pl-0': token,
                                    'focus:shadow-outline-blue focus:border-blue-300': !token
                                }"
                            />
                        </div>
                    </div>

                    <div v-if="token">
                        <input type="hidden" name="token" :value="token">
                        <div class="mt-6">
                            <label
                                for="password"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                New Password
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>
                         <div class="mt-6">
                            <label
                                for="password_confirmation"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Confirm Password
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    required
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mt-6 flex items-center justify-between">
                        <div class="flex items-center">
                        </div>

                        <div class="text-sm leading-5">
                            <Link
                                href="/login"
                                class="font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                            >
                                Remember your password?
                            </Link>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span class="block w-full rounded-md shadow-sm">
                            <button
                                type="submit"
                                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out"
                            >
                                <i
                                    v-if="loading"
                                    class="fa fa-spinner-third fa-spin"
                                ></i>
                                Reset Password
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'

export default {
    components: { Head, Link },
    props: {
        csrfToken: String,
        token: String,
        email: String,
        flash: Object,
        errors: Object
    },
    data() {
        return {
            loading: false,
            remember: false
        }
    },
    computed: {
        messages() {
            const alerts = []
            if (this.errors) {
               Object.keys(this.errors).forEach((key) => {
                   this.errors[key].forEach((message) => {
                        alerts.push({
                            color: 'red',
                            message
                        })
                   })
                })
            }
            if (this.flash && this.flash.status) {
                alerts.push({
                    color: 'green',
                    message: this.flash.status
                })
            }
            return alerts
        },
        welcomeMessage() {
            const hours = (new Date).getHours()
            if (hours < 12) {
                return "Good Morning! ☕"
            } else if (hours < 15) {
                return "Good Afternoon! 🌞"
            } else if (hours > 20) {
                return "Burning the midnight oil! 🌚"
            } else {
                return "Good Evening! 🌆"
            }
        }
    },
    methods: {
        login() {
            this.loading = true
            this.$inertia.post(this.$route("login"), {
                email: this.email,
                password: this.password,
                remember: this.remember
            }).then(() => {
                this.password = ''
                this.loading = false
            })
        }
    }
}
</script>

<style lang="postcss" scoped></style>
