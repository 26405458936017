<template>
    <Layout ref='layout' :auth='auth' @paste="onPaste">
        <Head title="Search" />
        <template v-slot:title>Entry Searching</template>
        <template v-slot:actions>
            <div class="relative w-1/2 md:w-1/4">
                <i class="fas fa-search text-gray-300 absolute mx-2 mt-3"></i>
                <input
                    type="text"
                    class="w-full pl-8"
                    v-model="searchQuery"
                    placeholder="Search descriptions..."
                    @keydown.enter="search"
                >
            </div>
        </template>
        <div>
            <h1 class="mb-3 text-lg" v-if="query">Showing top 50 results for <span class="font-bold">{{ query }}</span> below:</h1>
            <h1 class="mb-3 text-lg" v-else>Showing top 50 newest entries below:</h1>
            <entries-table
                show-week
                show-sprint
                show-type
                :types="typeOptions"
                :entries='entries'
            />
        </div>
    </Layout>
</template>

<script>
    import { Head } from '@inertiajs/vue3'
    import Layout from "../Common/Layout.vue"
    import auth from "../Common/Mixins/auth"
    import entriesTable from "../Common/entriesTable.vue"

    export default {
        mixins: [auth],
        components: { Head, Layout, entriesTable },
        props: {
            auth: Object,
            types: Array,
            entries: Array,
            query: String
        },
        computed: {
            typeOptions() {
                const types = {}
                this.types.forEach((type) => {
                    if (type) {
                        types[type.slug] = type.name
                    }
                })
                return types
            },
        },
        data() {
            return {
                searchQuery: this.query,
            }
        },
        methods: {
            search() {
                this.$inertia.visit(`/search?query=${this.searchQuery}`)
            }
        }
    }
</script>
