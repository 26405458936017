<template>
    <Layout :auth='auth'>
        <Head title="Sketchy Billings Report" />
        <template v-slot:title>Sketchy Billings Report</template>
        <div >
            <entriesTable
                :entries='entries'
                show-client
                show-week
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import entriesTable from "../../Common/entriesTable.vue"
import { Head } from '@inertiajs/vue3'

export default {
    name: 'SketchyBillingsReport',
    components: {
        Head,
        Layout,
        entriesTable,
    },
    props: {
        auth: Object,
        entries: Array
    },
    data() {
        return {
        }
    },
    methods: {
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
</style>
