<template>
    <ul class="divide-y divide-gray-200">
        <li class="py-4 flex" v-for="log in history" :key="log.id">
            <avatar v-if="log.user" :size='10' :user='log.user' />
            <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">{{ log.user.name }}</p>
                <p class="text-xs text-gray-500">{{ datetime(log.created_at) }}</p>
                <div class="">
                    <div v-if='log.action == "created"' class="font-bold">
                        Created Row
                    </div>
                    <ul v-if='log.action == "updated"'>
                        <li v-for="(value, key) in log.data" :key="log.id + key">
                            <span class="font-bold">{{ translateLogKey(key) }}:</span> <span v-html="displayLogValue(key, value)"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import avatar from "../Common/avatar.vue"

export default {
    name: 'EntryHistory',
    props: {
        clientName: Function,
        sprintName: Function,
        entry: Object
    },
    components: {avatar},
    data() {
        return {
            history: []
        }
    },
    async mounted() {
        this.history = null
        const response = await axios.get(
            this.$route("time_entries.history", {
                time_entry: this.entry.id
            })
        )
        this.history = response.data
    },
    methods: {
        datetime(dt) {
            return moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM Do YYYY, h:mma')
        },
        money(num) {
            return (
                "$" +
                Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        number(num) {
            return Number(num).toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        },
        displayLogValue(key, value) {
            if (Array.isArray(value) && value.length == 2) {
                return this.displayLogValue(key, value[0]) + ' &rarr; ' + this.displayLogValue(key, value[1])
            }
            switch(key) {
                case 'actual':
                case 'planned':
                    return this.number(value)
                case 'rate':
                case 'billableNow':
                case 'billableGoal':
                    return this.money(value)
                case 'sprint_id':
                    return this.sprintName({sprint_id: value})
                case 'client_id':
                    return this.clientName({client_id: value})
            }
            return value
        },
        translateLogKey(key) {
            switch(key) {
                case 'sprint_id':
                    return 'Sprint'
                case 'client_id':
                    return 'Client'
            }
            key = key.replace(/([A-Z])/g, " $1")
            key = key.replace("_", " ")
            return key[0].toUpperCase() + key.slice(1)
        }
    }
}
</script>

<style lang="postcss" scoped>

</style>
