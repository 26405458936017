<template>
    <Layout ref='layout' :auth='auth' @paste="onPaste">
        <Head :title="isASwifty ? 'You\'re on your own kid' : 'Dashboard'" />
        <template v-slot:title>
            <span class="inline-block relative">
                Dashboard
                <section v-if="isASwifty">
                    <img
                        :src="'/images/swift/0.png'"
                        class="absolute w-16 left-32 -top-2 transition duration-1000"
                        :class="{
                            'translate-y-full': swiftState < 2 || swiftState > 5,
                        }"
                    />
                </section>
            </span>
        </template>
        <template v-slot:actions>
            <div class="flex overflow-hidden mr-2">
                <template v-for="(user, idx) in here" :key="'here-' + user.id">
                    <avatar
                        :class="idx > 0 ? '-ml-2' : ''"
                        :user="user"
                    />
                </template>
            </div>
            <hgselect v-model="week" :options="weeks" class="max-w-sm">
                <template v-slot:value-prefix>
                    <span class="font-medium">Week of:</span>
                </template>
            </hgselect>
        </template>
        <div v-if="unattached.length" class="mb-6 bg-red-700 text-white rounded-lg">
            <div class="flex items-center">
                <div class="p-4 text-center w-full">
                    You have <strong>{{unattached.length}} unattached time entries</strong>! Please attach each entry to an existing workorder or request an invoice be sent. <a @click.prevent="showUnattachedEntries = !showUnattachedEntries" href="#" class="inline-block px-3 py-1 bg-red-800 hover:bg-red-900 ml-3 text-white rounded"><span v-text="showUnattachedEntries ? 'Hide' : 'View'"></span> Entries</a>
                </div>
            </div>
            <div v-if="showUnattachedEntries" class="p-3">
                <entries-table :show-totals-row="false" :show-user="false" show-client show-week :types="types" :entries='unattached'/>
            </div>
        </div>

        <div class="flex justify-between md:-mx-3 mb-3">
            <div class="w-full md:w-1/2 lg:w-1/3 md:px-3">
                <div class="bg-white shadow px-6 p-5 rounded-lg">
                    <dl>
                        <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                            Billable (Non-SEO)
                        </dt>
                        <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                            {{ money(stats.non.now) }}
                            <span class="text-gray-400 mx-1">/</span>
                            {{ money(stats.non.planned) }}
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 md:px-3">
                <div class="bg-white shadow px-6 p-5 rounded-lg">
                    <dl>
                        <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                            Billable (SEO)
                        </dt>
                        <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                            {{ money(stats.seo.now) }}
                            <span class="text-gray-400 mx-1">/</span>
                            {{ money(stats.seo.planned) }}
                        </dd>
                    </dl>
                </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/3 md:px-3">
                <div class="flex flex-col justify-between">
                    <div>
                        <hgselect class="mb-4" v-model="client" :options="{
                                all: 'All Clients',
                                ...options.clients
                            }">
                            <template v-slot:value-prefix>
                                <span class="font-medium">
                                    Showing:
                                </span>
                            </template>
                        </hgselect>
                    </div>
                    <div class="flex justify-between md:-mx-2">
                        <div class="md:w-1/2 md:px-2">
                            <hgselect class="mb-4" v-model="showing" :options="showingOpts">
                                <template v-slot:value-prefix>
                                    <span class="font-medium">
                                        Showing:
                                    </span>
                                </template>
                            </hgselect>
                        </div>
                        <div class="md:w-1/2 md:px-2">
                            <hgselect class="mb-4" v-model="groupBy" :options="{
                                    none: 'Nothing',
                                    ...options.groupBy
                                }">
                                <template v-slot:value-prefix>
                                    <span class="font-medium">
                                        Group By:
                                    </span>
                                </template>
                            </hgselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-sm table-wrapper" ref="tableWrapper">
            <div v-for='group in groups' :key="'group-' + group.label">

                <div class="flex items-center justify-right mb-3 text-sm">
                    <a v-if="canAddRows" href="#" class="text-center p-1 px-2 mr-2 bg-white border rounded border-gray-200 text-gray-500 hover:text-gray-600 hover:shadow focus:text-green-600" @click.prevent="addRow()">+ Add Entry</a>
                    <dashboardDropdown
                        class="text-center p-1 px-2 bg-white border rounded border-gray-200 hover:shadow hover:text-gray-600 focus:outline-none text-gray-500"
                        @checkingTrello='trelloCards = null; showTrelloModal = true'
                        @loadTrello='importTrello'
                    />
                </div>

                <dashboardTable ref="tables" :auth="auth" :highlight="highlight" :group="group" :entries="group.entries"
                    :clients="clients" :sprints="sprints" :types="types" :users="group.users || users" :showBilling="showBilling"
                    :canAddRows='canAddRows(group)' :newRow='newRowTemplate(group)' :rates='rates' @notify='notify'
                    @reload='reload' @reloadSprints='reloadSprints' @reloadWithSprints='reloadWithSprints' @changed='entryChanged' @add='addRow'
                    @clone='cloneRow' @removed='entryDeleted' @remove='removeRow' @removeSelected='deleteSelected' @toggle='toggleRow' />
            </div>
        </div>
        <modal :show="showWhosGotFreeTime" @close='showWhosGotFreeTime = false'>
            <h3 class="text-center text-lg font-medium mb-2">Who's got free time?</h3>
            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th
                            class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                        </th>
                        <th
                            class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Planned
                        </th>
                        <th
                            class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Actual
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="user in users" :key="'user-' + user.id">
                        <td class="px-3 py-2 whitespace-nowrap border-b border-gray-200">
                            <div class="flex items-center">
                                <div class="flex-shrink-0 h-6 w-6">
                                    <avatar v-if="user" :size='6' :user='user' />
                                </div>
                                <div class="ml-4">
                                    <div class="text-sm leading-5 font-medium text-gray-900">
                                        {{ user.name }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="px-3 py-2 whitespace-nowrap border-b border-gray-200 text-center">
                            {{ freeTime[user.id].planned }}
                        </td>
                        <td class="px-3 py-2 whitespace-nowrap border-b border-gray-200 text-center">
                            {{ freeTime[user.id].actual }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </modal>
        <modal :show='showTrelloModal' @close='showTrelloModal = false' size='4xl'>
            <template v-if="trelloCards != null" v-slot:footer>
                <div class="p-4 text-center border-t border-gray-100">
                    <span class="inline-flex rounded-md shadow-sm">
                        <button
                            @click="importTrelloCards"
                            :disabled="selectedTrelloCards.length == 0 || importingTrelloCards"
                            type="button"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                        >
                            <i class="fal fa-spinner-third fa-spin" v-if='importingTrelloCards'></i>
                            <span class="ml-2">
                                Import {{ selectedTrelloCards.length }} Card<span v-if="selectedTrelloCards.length != 1">s</span>
                            </span>
                        </button>
                    </span>
                    <span class="inline-flex rounded-md shadow-sm ml-4">
                        <button
                            @click='showTrelloModal = false'
                            type="button"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150">
                            Never mind
                        </button>
                    </span>
                </div>
            </template>
            <div
                v-if="trelloCards == null"
                class="text-center font-3xl p-4"
            >
                <i class="fal fa-spinner-third fa-spin"></i>
                <span class="inline-block ml-2">Loading your trello cards...</span>
            </div>
            <fieldset
                v-else
                class="min-w-0"
            >
                <div class="bg-white rounded-md -space-y-px">
                    <div
                        v-for="(card, idx) in trelloCards"
                        :key="'trello-' + card.id"
                        class="relative border p-4 flex"
                        :class="{
                            'rounded-tl-md rounded-tr-md': idx == 0,
                            'bg-blue-50 border-blue-200 z-10': selectedTrelloCards.indexOf(card.id) >= 0,
                            'border-gray-200': selectedTrelloCards.indexOf(card.id) == -1
                        }"
                    >
                        <div class="flex items-center h-5 mt-3">
                            <input
                                :value="card.id"
                                v-model="selectedTrelloCards"
                                type="checkbox"
                                class="h-4 w-4 text-blue-600 transition duration-150 ease-in-out cursor-pointer"
                            >
                        </div>
                        <label class="ml-3 flex flex-col cursor-pointer w-full">
                            <div
                                class="flex justify-between items-center text-sm leading-5 font-medium w-full mb-2"
                                :class="{
                                    'text-blue-900': selectedTrelloCards.indexOf(card.id) >= 0,
                                    'text-gray-900': selectedTrelloCards.indexOf(card.id) == -1
                                }"
                            >
                                <select class="mr-3 text-sm w-1/2" v-model="card.project_id">
                                    <option>Pick a project...</option>
                                    <option
                                        v-for="project in projects"
                                        :value="project.id"
                                        :key="project.id"
                                    >{{ clientName(project.client_id) }} - {{ project.name }}</option>
                                </select>
                                <div class="flex flex-col">
                                    <span class="text-gray-500 whitespace-nowrap truncate max-w-[250px]">Board: <span class="text-gray-900">{{ card.board }}</span></span>
                                    <div class="flex gap-x-2" v-if="card.weeks.length > 0">
                                        <span class="text-gray-500 whitespace-nowrap">Also On: </span>
                                        <ul class="flex flex-wrap">
                                            <li v-for="(week, idx) in card.weeks" :key="card.id + '-' + week">
                                                <Link :href="'/?week=' + week">
                                                    <span v-if="idx > 0">, </span>{{ week }}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <span
                                class="block text-xs leading-5 max-h-20 break-all overflow-hidden bg-gray-50 p-2"
                                :class="{
                                    'text-blue-700': selectedTrelloCards.indexOf(card.id) >= 0,
                                    'text-gray-500': selectedTrelloCards.indexOf(card.id) == -1
                                }"
                            >{{ card.description }}</span>
                        </label>
                    </div>
                </div>
            </fieldset>
        </modal>
    </Layout>
</template>

<script>
    import { Head, Link } from '@inertiajs/vue3'
    import { nextTick } from "vue"
    import moment from "moment"
    import { get, clone, find, findIndex } from "lodash"
    import axios from "axios"
    import Cookies from 'js-cookie'
    import auth from "../Common/Mixins/auth"
    import btn from "../Common/btn.vue"
    import avatar from "../Common/avatar.vue"
    import modal from "../Common/modal.vue"
    import Layout from "../Common/Layout.vue"
    import hgselect from "../Common/hgselect.vue"
    import dashboardTable from "../Common/dashboardTable.vue"
    import entriesTable from "../Common/entriesTable.vue"
    import dashboardDropdown from "../Common/dashboardDropdown.vue"
    import {diceCoefficient} from 'dice-coefficient'

    export default {
        mixins: [auth],
        components: {
            btn,
            avatar,
            Head,
            Layout,
            modal,
            hgselect,
            dashboardTable,
            entriesTable,
            dashboardDropdown,
            Link
        },
        props: {
            auth: Object,
            entries: Array,
            unattached: Array,
            clients: Array,
            projects: Array,
            sprints: Array,
            types: Array,
            users: Array,
            current: Object,
            rates: Object,
            teams: Object,
        },
        data() {
            return {
                highlight: [],
                here: [],
                renderKey: (new Date).getTime(),
                ready: false,
                showWhosGotFreeTime: false,
                overageSprint: null,
                creatingOverageSprint: false,
                showSprintOverageModal: false,
                showUnattachedEntries: false,
                sprintOverage: null,
                week: get(this.current, 'week', moment().startOf("week").format('YYYY-MM-DD')),
                width: 100,
                groupBy: 'team',
                client: get(this.current, 'client', 'all'),
                showing: get(this.current, 'showing', 'all'),
                canUser: {
                    bill: true,
                    viewOthers: {
                    },
                    editOthers: {
                    },
                },
                showBilling: false,
                selected: [],
                deleted: null,
                trelloCards: null,
                selectedTrelloCards: [],
                showTrelloModal: false,
                importingTrelloCards: false,
                swiftState: 0,
            }
        },
        mounted() {
            if (this.isASwifty) {
                setInterval(() => {
                    this.swiftState = (this.swiftState + 1) % 30;
                }, 1000)
            }
            this.pullFromCookie()
            this.width = this.$refs.tableWrapper.offsetWidth
            this.canUser.bill = this.hasPermission('manage:billing')
            Object.keys(this.teams).forEach((slug) => {
                this.canUser.viewOthers[slug] = this.hasPermission(`view:${slug}-entries`)
                this.canUser.editOthers[slug] = this.hasPermission(`edit:${slug}-entries`)
            });

            if (window.location.search.match(/^\?trello/)) {
                this.importTrello();
                window.history.replaceState({}, document.title, window.location.pathname);
            }
            nextTick(() => {
                this.ready = true
                this.joinChannel()
            })
            document.addEventListener('copy', this.onCopy)
            document.addEventListener('keypress', this.handleKeyPress)
        },
        beforeUnmount() {
            document.removeEventListener('copy', this.onCopy)
            document.removeEventListener('keypress', this.handleKeyPress)
            this.leaveChannel(this.week)
        },
        computed: {
            isASwifty() {
                return window.location.hostname == 'swift.eversite.com'
            },
            selectedCopyable() {
                let entries = []
                this.selected.forEach(id => entries.push(find(this.entries, {
                    id
                })))
                return JSON.stringify(entries)
            },
            freeTime() {
                let freeTime = {}
                this.users.forEach((user) => {
                    freeTime[user.id] = {
                        planned: 0,
                        actual: 0
                    }
                })
                this.entries.forEach((entry) => {
                    if (entry.user_id in freeTime) {
                        if (!isNaN(entry.planned)) {
                            freeTime[entry.user_id].planned += Number(entry.planned)
                        }
                        if (!isNaN(entry.actual)) {
                            freeTime[entry.user_id].actual += Number(entry.actual)
                        }
                    }
                })
                return freeTime
            },
            groups() {
                if (this.groupBy == 'none') {
                    return [{
                        label: null,
                        entries: this.filteredEntries
                    }]
                } else {
                    var groupBy = null
                    var groups = {}
                    var labels = {}
                    // valid users for the group, if left blank,
                    // it will default to all of the users
                    var users = {}
                    const myTeam = this.auth.user.teams ? this.auth.user.teams[0] : ''

                    switch (this.groupBy) {
                        case 'client':
                            groupBy = 'client_id'
                            this.clients.forEach((c) => {
                                labels[c.id] = c.name
                            })
                            break;
                        case 'user':
                            groupBy = 'user_id'
                            if (this.showing == 'mine') {
                                labels[this.auth.user.id] = this.auth.user.name
                                groups[this.auth.user.id] = []
                            } else if (Object.keys(this.teams).indexOf(this.showing) >= 0) {
                                this.users.forEach((u) => {
                                    if (u.team === this.showing) {
                                        labels[u.id] = u.name
                                        groups[u.id] = []
                                    }
                                })
                            } else {
                                this.users.forEach((u) => {
                                    labels[u.id] = u.name
                                    groups[u.id] = []
                                })
                            }
                            break;
                        case 'team':
                            groupBy = (e) => {
                                const user = find(this.users, {
                                    id: e.user_id
                                })
                                const teams = get(user, 'teams', [])
                                if (teams.length == 0) {
                                    return ''
                                }
                                // if the user has more than one team, and we are showing
                                // that team, then return that as the user's primary team
                                if (teams.indexOf(this.showing) >= 0) {
                                    return this.showing
                                }
                                return teams[0];
                            }

                            labels = {
                                '': "No Team",
                                ...this.teams
                            }

                            if (this.showing === 'mine') {
                                labels = {
                                    [myTeam]: labels[myTeam]
                                }
                                groups = {
                                    [myTeam]: []
                                }
                                users = {
                                    [myTeam]: this.teamUserMap[myTeam]
                                }
                            } else if (this.showing in this.teams) {
                                labels = {
                                    [this.showing]: labels[this.showing]
                                }
                                groups = {
                                    [this.showing]: []
                                }
                                users = {
                                    [this.showing]: this.teamUserMap[this.showing]
                                }
                            } else {
                                groups = {}
                                users = {}
                                Object.keys(this.teams).forEach((teamSlug) => {
                                    groups[teamSlug] = []
                                    users[teamSlug] = this.teamUserMap[teamSlug]
                                });
                            }
                            break;
                    }
                    this.filteredEntries.forEach((e) => {
                        let g = typeof groupBy == 'string' ?
                            get(e, groupBy, null) :
                            groupBy(e);

                        if (!(g in groups)) {
                            groups[g] = []
                        }
                        groups[g].push(e)
                    })
                    const g = Object.keys(groups).filter((lbl) => {
                        if(!lbl && groups[lbl].length === 0) {
                            return false
                        }

                        // if we are grouping by user, and this
                        // user is inactive, and they have no entires
                        // then don't include them
                        if (this.groupBy === 'user') {
                            const user = find(this.users, {id: lbl})
                            return groups[lbl].length > 0 || (user && user.is_active)
                        }
                        return true
                    }).map((lbl) => {
                        return {
                            key: lbl,
                            label: labels[lbl],
                            entries: groups[lbl],
                            users: lbl in users ? users[lbl] : this.users
                        }
                    }).sort((a, b) => {
                        if (a.label > b.label) return 1
                        if (a.label < b.label) return -1
                        return 0
                    })

                    return g
                }
            },
            canViewOthers() {
                for (let key in this.canUser.viewOthers) {
                    if (this.canUser.viewOthers[key]) {
                        return true
                    }
                }
                return false
            },
            canEditOthers() {
                for (let key in this.canUser.editOthers) {
                    if (this.canUser.editOthers[key]) {
                        return true
                    }
                }
                return false
            },
            stats() {
                const stats = {
                    seo: {
                        planned: 0,
                        now: 0
                    },
                    non: {
                        planned: 0,
                        now: 0
                    }
                }

                this.entries.forEach(row => {
                    if (["SEO"].indexOf(row.type) > -1) {
                        stats.seo.planned += row.billableGoal ?
                            Number(row.billableGoal) :
                            0
                        stats.seo.now += row.billableNow ?
                            Number(row.billableNow) :
                            0
                    } else {
                        stats.non.planned += row.billableGoal ?
                            Number(row.billableGoal) :
                            0
                        stats.non.now += row.billableNow ?
                            Number(row.billableNow) :
                            0
                    }
                })
                return stats
            },
            teamUserMap() {
                const map = {}
                Object.keys(this.teams).forEach((team) => {
                    map[team] = []
                })

                this.users && this.users.forEach((user) => {
                    (user.teams || []).forEach((slug) => {
                        if (slug in map) {
                            map[slug].push(user)
                        }
                    })
                })
                return map
            },
            userMap() {
                const map = {}
                this.users && this.users.forEach(user => (map[user.id] = user))
                return map
            },
            filteredEntries() {
                let rowsToExclude = []

                if (this.showing in this.teams) {
                    this.entries.forEach((val, rowIndex) => {
                        if (
                            val.user_id &&
                            this.showing in this.teamUserMap &&
                            this.teamUserMap[this.showing].map(u => u.id).indexOf(val.user_id) == -1
                        ) {
                            rowsToExclude.push(rowIndex)
                        }
                    })
                } else {
                    switch (this.showing) {
                        case "all":
                            break
                        case "billable":
                            this.entries.forEach((val, rowIndex) => {
                                if (!val.billable) {
                                    rowsToExclude.push(rowIndex)
                                }
                            })
                            break
                        case "notBillable":
                            this.entries.forEach((val, rowIndex) => {
                                if (val.billable) {
                                    rowsToExclude.push(rowIndex)
                                }
                            })
                            break
                        case "mine":
                            this.entries.forEach((val, rowIndex) => {
                                if (val.user_id && val.user_id !== this.auth.user.id) {
                                    rowsToExclude.push(rowIndex)
                                }
                            })
                            break
                    }
                }

                if (this.showing.match(/^type:/)) {
                    var parts = this.showing.split(":")
                    this.entries.forEach((val, rowIndex) => {
                        if (val.type && val.type !== parts[1]) {
                            rowsToExclude.push(rowIndex)
                        }
                    })
                }

                if (this.client !== "all") {
                    this.entries.forEach((val, rowIndex) => {
                        if (val.client_id && val.client_id !== this.client) {
                            rowsToExclude.push(rowIndex)
                        }
                    })
                }
                if (rowsToExclude.length == 0) {
                    return this.entries
                }
                return this.entries.filter((v, idx) => rowsToExclude.indexOf(idx) == -1)
            },
            showingOpts() {
                let opts = {
                    all: 'All Entries',
                }

                if (this.canViewOthers) {
                    opts = {
                        ...opts,
                        '--': '--',
                        mine: 'My Entries',
                    }
                }

                opts = {
                    ...opts,
                    '---': '--',
                    billable: 'Billable Entries',
                    notBillable: 'Not Billable Entries',
                }

                if (this.canViewOthers) {
                    opts = {
                        ...opts,
                        '----': '--',
                    }
                    Object.keys(this.canUser.viewOthers).forEach((slug) => {
                        if (this.canUser.viewOthers[slug]) {
                            opts[slug] = this.teams[slug]
                        }
                    })
                }

                opts = {
                    ...opts,
                    '-----': '--',
                }

                Object.keys(this.options.types).forEach((slug) => {
                    const name = this.options.types[slug]
                    opts[`type:${slug}`] = name + ' Entries'
                })

                return opts;
            },
            weeks() {
                let weeks = []
                for (let i = -6; i < 204; i++) {
                    weeks.push(
                         moment()
                            .startOf("week")
                            .add(1, "day")
                            .subtract(i, 'weeks')
                            .format('YYYY-MM-DD')
                    )
                }
                return weeks
            },
            options() {
                var opts = {
                    clients: {},
                    sprints: {},
                    overageSprints: {
                        new: 'Create a new work order'
                    },
                    users: {},
                    types: {},
                    statuses: {
                        active: "Active",
                        pending: "Pending",
                        complete: "Complete"
                    },
                    groupBy: {
                        client: 'Client',
                        team: 'Team',
                        user: 'Team Member',
                        type: 'Type of Work',
                    }
                }

                this.types.forEach(t => (opts.types[t.slug] = t.name))
                this.sprints.forEach(s => (opts.sprints[s.id] = s))
                this.clients.forEach(c => (opts.clients[c.id] = c.name))
                this.users.forEach(u => (opts.users[u.id] = u))

                return opts
            }
        },
        methods: {
            money(num) {
                return (
                    "$" +
                    Number(num).toLocaleString(undefined, {
                        minimumFractionDigits: 2
                    })
                )
            },
            clientName(id) {
                const client = find(this.clients, { id })
                return client ? client.name : null
            },
            closetProject(boardId, board) {
                var clientNames = []
                this.clients.forEach((client) => {
                    clientNames.push(client.name)
                })

                var projectsByClient = {}
                this.projects.forEach((project) => {
                    if (!(project.client_id in projectsByClient)) {
                        projectsByClient[project.client_id] = []
                    }
                    projectsByClient[project.client_id].push(project)
                })

                // See if we have a project with this board
                const project = find(this.projects, (p) => p.trello_board && p.trello_board.id == boardId)
                if (project) {
                    return project.id
                }

                var closestClient = null;
                var closestScore = 0;
                this.clients.forEach((client) => {
                    const score = diceCoefficient(board, client.name)
                    if (score > 0.5 && score > closestScore) {
                        closestScore = score;
                        closestClient = client;
                    }
                })

                return closestClient && closestClient.id in projectsByClient ? projectsByClient[closestClient.id][0].id : null
            },
            toggleTrelloCard(card) {
                const idx = this.selectedTrelloCards.indexOf(card.id)
                if (idx == -1) {
                    this.selectedTrelloCards.push(card.id)
                } else {
                    this.selectedTrelloCards.splice(idx, 1)
                }
            },
            async importTrelloCards() {
                const newEntries = [];
                this.importingTrelloCards = true;
                this.selectedTrelloCards.forEach((cardId) => {
                    const card = find(this.trelloCards, {id: cardId})
                    newEntries.push({
                        user_id: this.auth.user.id,
                        week: this.week,
                        description: card.description,
                        trello_card_id: card.id,
                        client_id: card.client_id,
                        project_id: card.project_id,
                        actual: 0,
                        planned: 0,
                        billableNow: 0,
                        billableGoal: 0,
                        type: 'billable',
                        status: 'active'
                    })
                })
                await axios.post(
                    this.$route("time_entries.store.batch"), {
                        entries: newEntries
                    }
                )
                this.importingTrelloCards = false;
                this.showTrelloModal = false;
                this.trelloCards = null;
                this.selectedTrelloCards = [];
                this.reload()
            },
            async importTrello() {
                this.showTrelloModal = true
                this.trelloCards = null
                const {
                    data
                } = await axios.get(this.$route("trello.cards"))

                var clientProjectMap = {}
                this.projects.forEach((project) => {
                    clientProjectMap[project.id] = project.client_id
                })

                var trelloCards = Object.values(data).map((card) => {
                    card.project_id = this.closetProject(card.board_id, card.board)
                    card.client_id = card.project_id && clientProjectMap[card.project_id] ? clientProjectMap[card.project_id] : null;
                    return card
                })

                trelloCards.sort((a, b) => {
                    if (a.client_id && b.client_id) {
                        var aClientName = (this.clientName(a.client_id) || '').toLowerCase()
                        var bClientName = (this.clientName(b.client_id) || '').toLowerCase()
                        if (aClientName > bClientName) {
                            return 1
                        } else if (aClientName < bClientName) {
                            return -1
                        }
                        return 0
                    } else if (a.client_id && !b.client_id) {
                        return -1
                    } else if (!a.client_id && b.client_id) {
                        return 1
                    }
                    return 0;
                })

                this.trelloCards = trelloCards
            },
            pullFromCookie() {
                let opts = {}
                try {
                    opts = JSON.parse(Cookies.get('table-options')) || {}
                } catch (e) {
                    // pass
                }
                this.groupBy = opts.groupBy || 'team'
                this.showing = opts.showing || 'all'
            },
            storeInCookie() {
                Cookies.set('table-options', {
                    groupBy: this.groupBy,
                    showing: this.showing
                })
            },
            leaveChannel(week) {
                window.Echo.leave(`dashboard.${week}`)
            },
            async createSprintFromOverage() {
                if (this.sprintOverage) {
                    this.creatingOverageSprint = true
                    await axios.post(
                        this.$route("sprints.overage", {
                            sprint: this.sprintOverage.sprint.id
                        }), {
                            ...this.sprintOverage
                        }
                    )
                    this.newSprintName = ''
                    this.showSprintOverageModal = false
                    this.creatingOverageSprint = false
                    this.reload(true)
                }
            },
            sprintMaximumExceeded(event) {
                this.showSprintOverageModal = true
                this.sprintOverage = {
                    new: {
                        id: null,
                        name: ""
                    },
                    ...event
                }
            },
            eventChangedOnServer(event) {
                var idx = findIndex(this.entries, {
                    id: event.entry.id
                })
                if (idx >= 0) {
                    this.entries[idx] = event.entry
                } else {
                    this.entries.push(event.entry)
                    if (event.entry.user_id == this.auth.user.id) {
                        // Remove any temporary entries
                        this.entries = this.entries.filter((entry) => {
                            return entry.temporary !== true
                        })
                    }
                }

                this.highlight[event.entry.id] = true
                setTimeout(() => {
                    this.highlight[event.entry.id] = false
                }, 1000);
                this.reloadSprints();
            },
            joinChannel() {
                window.Echo.private(`user.${this.auth.user.id}`)
                    .listen('SprintMaximumExceeded', this.sprintMaximumExceeded)

                window.Echo.join(`dashboard.${this.week}`)
                    .here((users) => {
                        this.here = users
                    })
                    .joining((user) => {
                        this.here.push(user)
                    })
                    .leaving((user) => {
                        var idx = findIndex(this.here, {
                            id: user.id
                        })
                        this.here.splice(idx, 1)
                    })
                    .listen('EntryCreated', this.eventChangedOnServer)
                    .listen('EntryUpdated', this.eventChangedOnServer)
                    .listen('EntryDeleted', (event) => {
                        this.entryDeleted(event.entry)
                    })
            },
            notify(msg) {
                this.$refs.layout.notify(msg)
            },
            toggleRow(row) {
                const idx = this.selected.indexOf(row.id)
                if (idx >= 0) {
                    this.selected.splice(idx, 1)
                } else {
                    this.selected.push(row.id)
                }
            },
            async onCopy(event) {
                if (this.selected.length) {
                    const entries = []
                    this.selected.forEach((id) => {
                        entries.push(find(this.entries, {
                            id
                        }))
                    })

                    event.clipboardData.setData('text', JSON.stringify(entries))
                    event.preventDefault()
                    this.notify({
                        color: 'green',
                        icon: "fal fa-thumbs-up",
                        message: `Copied ${entries.length} row${this.selected.length == 1 ? '' : 's'}`
                    })
                    this.unselectAll()
                } else {
                    this.notify({
                        color: 'green',
                        icon: "fal fa-thumbs-up",
                        message: `Copied 1 row`
                    })
                }
            },
            handleKeyPress(event) {
                if (event.metaKey) {
                    if (event.key == 'a') {
                        event.preventDefault()
                        event.stopPropagation()
                        if (event.shiftKey) {
                            this.unselectAll()
                        } else {
                            this.selectAll()
                        }
                    } else if (event.key == 'n') {
                        event.preventDefault()
                        event.stopPropagation()
                        this.addRow({})
                    }
                    return false
                } else if (event.key == 'Backspace') {
                    event.preventDefault()
                    event.stopPropagation()
                    this.deleteSelected()
                    return false
                }
            },
            async onPaste(event) {
                try {
                    let objs = JSON.parse(event.clipboardData.getData('text'))
                    if (!Array.isArray(objs)) {
                        objs = [objs]
                    }
                    const newEntries = []
                    objs.forEach((obj) => {
                        const keys = ['id', 'actual', 'planned', 'client_id', 'user_id', 'week']
                        for (var idx = 0; idx < keys.length; idx++) {
                            if (!(keys[idx] in obj)) {
                                return false
                            }
                        }
                        delete obj.id
                        obj.week = this.week
                        newEntries.push(obj)
                    })
                    await this.entryChanged(newEntries)
                    this.notify({
                        color: 'green',
                        icon: "fal fa-thumbs-up",
                        message: `Pasted ${newEntries.length} row${newEntries.length == 1 ? '' : 's'}`
                    })
                } catch (e) {
                    // pass
                }
                this.unselectAll()
            },
            async deleteSelected() {
                if (this.selected.length) {
                    if (confirm('Are you sure you want to delete the selected row' + (this.selected.length == 1 ?
                            '' : 's') + '?')) {
                        await axios.post(
                            this.$route("time_entries.delete.batch"), {
                                entries: this.selected
                            }
                        )

                        this.deleted = this.selected.map((id) => {
                            return find(this.entries, {
                                id
                            })
                        })

                        this.notify({
                            color: 'green',
                            icon: "fal fa-thumbs-up",
                            message: `Deleted ${this.selected.length} row${this.selected.length == 1 ? '' : 's'}`,
                            actions: [{
                                label: 'Undo',
                                callback: this.unDelete
                            }]
                        })
                        this.unselectAll()
                        this.reload(true)
                    }
                }
            },
            unselectAll() {
                nextTick(() => {
                    this.$refs.tables.forEach((ref) => {
                        ref.selected = []
                    })
                    this.selected = []
                })
            },
            selectAll() {
                nextTick(() => {
                    this.$refs.tables.forEach((ref) => {
                        ref.selectAll()
                    })
                })
            },
            canAddRows(group) {
                if (this.auth.user) {
                    if (this.groupBy == 'client') {
                        return true;
                    } else if (this.groupBy == 'team') {
                        const myTeam = this.auth.user.teams ? this.auth.user.teams[0] : ''
                        return myTeam === group.key || this.hasPermission(`edit:${group.key}-entries`)
                    } else if (this.groupBy == 'user') {
                        const user = this.userMap[group.key]
                        return user && (this.auth.user.id === group.key || this.hasPermission(`edit:${user.team}-entries`));
                    } else {
                        return true;
                    }
                }
            },
            newRowTemplate(group) {
                const def = {
                    priority: 0,
                    billable: null
                }
                if (this.groupBy == 'client') {
                    return {
                        client_id: group.key,
                        ...def
                    }
                } else if (this.groupBy == 'team') {
                    const myTeam = this.auth.user.teams ? this.auth.user.teams[0] : ''
                    if (myTeam === group.key) {
                        return {
                            user_id: this.auth.user.id,
                            ...def
                        }
                    }
                    return {
                        user_id: null,
                        ...def
                    }
                } else if (this.groupBy == 'user') {
                    return {
                        user_id: group.key,
                        ...def
                    }
                }
                return {
                    ...def
                }
            },
            async reloadEntry() {},
            reloadWithSprints() {
                this.reload(true)
            },
            reload(withSprints) {
                return new Promise((resolve) => {
                    this.$inertia.reload({
                        only: withSprints ? ['entries', 'sprints'] : ['entries'],
                        preserveScroll: true,
                        onFinish: resolve
                    })
                })
            },
            renderStatus(status) {
                switch (status) {
                    case 'active':
                    case 'Active':
                        return `<span class="bg-blue-50 text-blue-500 p-1 rounded">${status}</span>`
                    case 'pending':
                    case 'Pending':
                        return `<span class="bg-orange-50 text-orange-500 p-1 rounded">${status}</span>`
                    case 'complete':
                    case 'Complete':
                        return `<span class="bg-green-50 text-green-500 p-1 rounded">${status}</span>`
                }
            },
            renderSprint(sprint) {
                const foundSprint = find(this.sprints, {
                    id: typeof sprint == 'object' ? sprint.id : sprint
                })
                if (foundSprint) {
                    return `${foundSprint.name} <span class='text-green-400'>($${foundSprint.amount})</span>`
                }
                return sprint
            },
            filterUsers(row, user) {
                if (this.auth.user.id == user.id) {
                    return true;
                }
                return user.team in this.canUser.editOthers && this.canUser.editOthers[user.team]
            },
            filterSprints(row, sprint) {
                return row[0] == sprint.client_id
            },
            async createNewSprint(query, data) {
                const response = await axios.post(
                    this.$route("sprints.store"), {
                        client_id: data[0],
                        name: query
                    }
                )
                await this.reloadSprints()
                return response.data.id
            },
            async reloadSprints() {
                await this.$inertia.reload({
                    only: ['sprints'],
                    preserveScroll: true
                })
            },
            async entryChanged(entry) {
                let response = null
                if (Array.isArray(entry)) {
                    // this is actually an array of entries
                    let newEntries = [];
                    let oldEntries = [];
                    entry.forEach((e) => {
                        const row = clone(e)
                        if (row.id) {
                            oldEntries.push(row)
                        } else {
                            newEntries.push(row)
                        }
                    })

                    if (newEntries.length) {
                        response = await axios.post(
                            this.$route("time_entries.store.batch"), {
                                entries: newEntries
                            }
                        )
                    }

                    if (oldEntries.length) {
                        response = await axios.post(
                            this.$route("time_entries.update.batch"), {
                                _method: 'PUT',
                                entries: oldEntries
                            }
                        )
                    }
                    this.reload(true);
                } else {
                    const row = clone(entry)
                    if (row.id) {
                        row._method = "PUT"
                        response = await axios.post(
                            this.$route("time_entries.update", row.id),
                            row
                        )
                        let idx = findIndex(this.entries, {
                            id: row.id
                        })
                        if (response.data.week != this.week) {
                            this.entries.splice(idx, 1)
                            this.reloadSprints();
                        } else {
                            this.entries[idx] = response.data
                            this.reloadSprints();
                        }
                    } else {
                        response = await axios.post(
                            this.$route("time_entries.store"),
                            row
                        )
                        this.reload(true);
                    }
                }
                return response.data;
            },
            async deleteEntry(entry) {
                let idx = findIndex(this.entries, {
                    id: entry.id
                })
                if (idx >= 0) {
                    this.entries.splice(idx, 1)
                }

                const response = await axios.delete(
                    this.$route("time_entries.destroy", entry.id)
                )
                this.reloadSprints()
                return response.data;
            },
            async addRow(data) {
                const entry = {
                    client_id: null,
                    sprint_id: null,
                    description: '',
                    user_id: this.auth.user.id,
                    type: this.auth.user.default_work_type,
                    status: 'active',
                    priority: 0,
                    planned: 0,
                    actual: 0,
                    rate: 0,
                    billableNow: 0,
                    billableGoal: 0,
                    order: 0,
                    week: this.week,
                    ...data
                }

                const user = find(this.users, {
                    id: entry.user_id
                })

                entry.type = user.default_work_type

                if (user.default_work_type) {
                    const type = find(this.types, {slug: user.default_work_type})
                    if (type) {
                        entry.rate = type.rate
                    }
                }

                // Push our new entry on the table, this will make it
                // appear instantly, but then will get replaced with the
                // real one once it loads
                this.entries.push({
                    temporary: true,
                    ...entry
                })

                let newEntry = await this.entryChanged(entry);
                await this.reload()
                nextTick(() => {
                    for(let gIdx = 0; gIdx < this.groups.length; gIdx++) {
                        const group = this.groups[gIdx];
                        var idx = findIndex(group.entries, {
                            id: newEntry.id
                        })
                        if (idx >= 0) {
                            this.$refs.tables[gIdx].activateEntry(newEntry)
                        }
                    }
                })
            },
            async cloneRow(data) {
                let entry = clone(data)
                delete entry.id
                await this.entryChanged(entry);
                await this.reload()
                this.reloadSprints()
            },
            entryDeleted(entry) {
                var idx = findIndex(this.entries, {
                    id: entry.id
                })
                if (idx >= 0) {
                    this.entries.splice(idx, 1)
                }
                this.reloadSprints();
            },
            async removeRow(data) {
                if (data.force || confirm('Are you sure you want to delete this row?')) {
                    await this.deleteEntry(data.entry);
                    this.deleted = [data.entry]
                    this.reload()
                    if (!data.force) {
                        this.notify({
                            color: 'green',
                            icon: "fal fa-thumbs-up",
                            message: `Deleted 1 row`,
                            actions: [{
                                label: 'Undo',
                                callback: this.unDelete
                            }]
                        })
                    }
                }
            },
            unDelete() {
                if (this.deleted) {
                    const entries = this.deleted.map((entry) => {
                        entry.id = null;
                        return entry
                    })
                    this.entryChanged(entries);
                    this.notify({
                        color: 'green',
                        icon: "fal fa-thumbs-up",
                        message: `Un-deleted ${this.deleted.length} row${this.deleted.length == 1 ? '' : 's'}`,
                    })
                }
            }
        },
        watch: {
            groupBy() {
                this.storeInCookie();
            },
            showing() {
                this.storeInCookie();
                // Use History API to remove the showing from the url search
                // without reloading
                const url = new URL(window.location.href)
                url.searchParams.delete('showing')
                window.history.pushState({}, '', url)
            },
            filteredRows() {
                this.$refs.table.hotInstance.deselectCell()
            },
            client() {
                // Use History API to remove the client from the url search
                // without reloading
                const url = new URL(window.location.href)
                url.searchParams.delete('client')
                window.history.pushState({}, '', url)
            },
            week(to, from) {
                this.leaveChannel(from)
                const thisWeek = moment()
                    .startOf("week")
                    .add(1, "day")
                    .format('YYYY-MM-DD')

                const data = {}
                if (this.week !== thisWeek) {
                    data.week = this.week
                }

                if (this.client !== 'all') {
                    data.client = this.client
                }

                if (this.showing !== 'all') {
                    data.showing = this.showing
                }

                this.$inertia.visit("/", { data } )
            }
        }
    }

</script>
