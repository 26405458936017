<template>
    <Layout :auth='auth'>
        <Head title="SEO Report" />
        <template v-slot:title>SEO Report</template>
        <div class="mb-6">
            <div class="grid grid-cols-4 gap-4">
                <div class="col-span-2">
                    <label>Work Order Collections</label>
                    <hgselect multiple :options='options' v-model='sprintTypes' />
                </div>
                <div>
                    <label>Date Range</label>
                    <hgselect :options='months' v-model='range.from' />
                </div>
                <div>
                    <label>&nbsp;</label>
                    <hgselect :options='months' v-model='range.to' />
                </div>
            </div>
        </div>
         <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="whitespace-nowrap cursor-pointer pl-6 pr-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
                                Payment Status
                            </th>
                            <th @click='order("client")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
                                Client
                                <span v-show="orderBy[0] == 'client' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'client' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("rate")' class="whitespace-nowrap cursor-pointer pl-3 pr-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Overall Monthly Rate
                                <span v-show="orderBy[0] == 'rate' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'rate' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("hoursSpent")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Hours Spent
                                <span v-show="orderBy[0] == 'hoursSpent' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'hoursSpent' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("hoursAvailable")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Hours Available
                                <span v-show="orderBy[0] == 'hoursAvailable' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'hoursAvailable' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("availableBudget")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Available Budget
                                <span v-show="orderBy[0] == 'availableBudget' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'availableBudget' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("budgetSpent")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Budget Spent
                                <span v-show="orderBy[0] == 'budgetSpent' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'budgetSpent' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                            <th @click='order("budgetRemaining")' class="whitespace-nowrap cursor-pointer px-3 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
                                Budget Remaining
                                <span v-show="orderBy[0] == 'budgetRemaining' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
                                <span v-show="orderBy[0] == 'budgetRemaining' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <template v-for="(client, idx) in sortedClients" :key="client.id">
                            <tr :class="{
                                'bg-gray-100': idx % 2 == 1 && budgetRemaining(client.id) >= 0,
                                'bg-red-200': budgetRemaining(client.id) < 0
                            }">
                                <td class="pl-6 pr-3 py-3 text-sm leading-5 font-medium text-gray-600">
                                    <div class="flex items-center">
                                        <div class="mr-3 -ml-3 cursor-pointer" @click='toggleDebug(client.id)'>
                                            <i :class="{
                                                'fa-fw fas fa-caret-right': !debug[client.id],
                                                'fa-fw fas  fa-caret-down': debug[client.id]
                                            }"></i>
                                        </div>
                                        <ul>
                                            <li v-for="payment in payments[client.id]" :key="payment.id">
                                                <div
                                                    class="flex"
                                                    :class="{
                                                        'text-green-500': payment.is_paid,
                                                        'text-yellow-400': !payment.is_paid && inTheFuture(payment.due_date),
                                                        'text-red-500': !payment.is_paid && !inTheFuture(payment.due_date),
                                                    }"
                                                >
                                                    <div class="mr-2 w-8">
                                                        <Link
                                                            :href='"/payments/" + payment.id'
                                                            class="hover:underline"
                                                        >
                                                            {{ payment.number }}
                                                        </Link>
                                                    </div>
                                                    <div class="text-right w-20 mr-2">{{ money(payment.amount) }}</div>
                                                    <div class="text-right">{{ date(payment.due_date) }}</div>
                                                    <div class="text-right ml-1" v-if="howLate(payment)">
                                                        <span class="text-gray-400"> ({{ howLate(payment) }}d late)</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="px-3 py-3 text-sm leading-5 font-medium text-gray-600 align-top">
                                    <span class="block w-48">
                                        <Link
                                            :href='"/clients/" + client.id'
                                            class="hover:underline"
                                        >{{ client.name }}</Link>
                                    </span>
                                </td>
                                <td class="pl-3 pr-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ money(monthlyRate(client.id)) }}
                                </td>
                                <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ hoursSpent(client.id) }}
                                </td>
                                <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ hoursAvailable(client.id) }}
                                </td>
                                <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ money(budgetAvailable(client.id)) }}
                                </td>
                                <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ money(budgetSpent(client.id)) }}
                                </td>
                                <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                    {{ money(budgetRemaining(client.id)) }}
                                </td>
                            </tr>
                            <tr :key="client.id + '-open'" v-if="debug[client.id]" :class="{
                                'bg-gray-100': idx % 2 == 1 && budgetRemaining(client.id) >= 0,
                                'bg-red-200': idx % 2 == 1 && budgetRemaining(client.id) < 0,
                            }">
                                <td colspan="8">
                                    <div class="ml-10 mb-6">
                                        <label>Included Work Orders</label>
                                        <table class="text-xs py-2 px-4">
                                            <tr v-for="sprint in sprints[client.id]" :key='sprint.id'>
                                                <td>
                                                    <Link
                                                        :href='"/sprints/" + sprint.id'
                                                        class="hover:underline"
                                                    >
                                                        {{ sprint.name }}
                                                    </Link>
                                                </td>
                                                <td class="pl-4">{{ money(sprint.maximum) }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr class="bg-gray-200 border-t-2">
                            <td class="pl-6 pr-3 py-3 text-sm leading-5 font-medium text-gray-600">
                            </td>
                            <td class="px-3 py-3 text-sm leading-5 font-medium text-gray-600 align-top">
                                Total
                            </td>
                            <td class="pl-3 pr-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ money(totalMonthlyRate()) }}
                            </td>
                            <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ totalHoursSpent() }}
                            </td>
                            <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ totalHoursAvailable() }}
                            </td>
                            <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ money(totalBudgetAvailable()) }}
                            </td>
                            <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ money(totalBudgetSpent()) }}
                            </td>
                            <td class="px-3 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600 align-top text-right">
                                {{ money(totalBudgetRemaining()) }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </Layout>
</template>

<script>
import auth from "../../Common/Mixins/auth"
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"
import { orderBy } from "lodash"

export default {
    name: 'SEOReport',
    mixins: [auth],
    components: {
        Layout,
        hgselect,
        Link,
        Head
    },
    props: {
        auth: Object,
        clients: Object,
        entries: Object,
        sprints: Object,
        currentSprints: Object,
        payments: Object,
        dates: Object,
        types: Array
    },
    data() {
        var thisMonth = moment().startOf('month')
        return {
            debug: {},
            orderBy: ["client", "desc"],
            sprintTypes: this.types,
            options: {
                ppc: 'PPC Mgmt',
                ppcAdSpend: 'PPC Ad Spend',
                social: 'Social Mgmt',
                socialAdSpend: 'Social Ad Spend',
                proReview: 'ProReview Mgmt',
                core: 'Core SEO',
                unlimited: 'Legnd Unlimited',
                seo: 'SEO Umbrella',
                eversite: 'Eversite'
            },
            range: {
                from: this.dates.from || thisMonth.format("YYYY-MM"),
                to: this.dates.to || thisMonth.add(1, 'month').format("YYYY-MM")
            }
        }
    },
    computed: {
        sortedClients() {
            let clients = Object.values(this.clients)
            switch(this.orderBy[0]) {
                case 'client':
                    return orderBy(clients, 'name', this.orderBy[1])
                case 'rate':
                    return orderBy(clients, (client) => {
                        return Number(this.payments[client.id][0].amount)
                    }, this.orderBy[1])
                case 'hoursSpent':
                    return orderBy(clients, (client) => {
                        return this.hoursSpent(client.id)
                    }, this.orderBy[1])
                case 'hoursAvailable':
                    return orderBy(clients, (client) => {
                        return this.hoursAvailable(client.id)
                    }, this.orderBy[1])
                case 'availableBudget':
                    return orderBy(clients, (client) => {
                        return this.budgetAvailable(client.id)
                    }, this.orderBy[1])
                case 'budgetSpent':
                    return orderBy(clients, (client) => {
                        return this.budgetSpent(client.id)
                    }, this.orderBy[1])
                case 'budgetRemaining':
                    return orderBy(clients, (client) => {
                        return this.budgetRemaining(client.id)
                    }, this.orderBy[1])
            }
            return clients
        },
        months() {
            var thisMonth = moment().startOf('month')
            var months = {}
            for (var i = -1; i < 24; i++) {
                var month = thisMonth.clone().subtract(i, 'month')
                months[month.format('YYYY-MM')] = month.format("MMM YYYY")
            }
            return months
        }
    },
    methods: {
        date(date) {
            return moment(date).format("MM/DD/YY");
        },
        money(num) {
            var symbol = num < 0 ? '-$' : '$'
            return (
                symbol +
                Number(Math.abs(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        howLate (payment) {
            const paidAt = payment.paid_at ? moment(payment.paid_at) : moment()
            const dueDate = moment(payment.due_date)
            const diff = paidAt.diff(dueDate, 'days')
            if (diff > 0) {
                return diff
            }
        },
        order(key) {
            if (this.orderBy[0] == key) {
                if (this.orderBy[1] == 'asc') {
                    this.orderBy = [key, 'desc']
                } else {
                    this.orderBy = [null, null]
                }
            } else {
                this.orderBy = [key, 'asc']
            }
        },
        toggleDebug(client_id) {
            this.debug[client_id] = !this.debug[client_id]
        },
        inTheFuture (date) {
            return moment().isBefore(moment(date))
        },
        monthlyRate (client_id) {
            return Object.values(this.currentSprints[client_id]).reduce((sum, sprint) => {
                return sum + Number(sprint.maximum)
            }, 0)
        },
        hoursSpent (client_id) {
            return Math.round(Object.values(this.entries[client_id]).reduce(function (sum, entry) {
                return sum + Number(entry.actual);
            }, 0) * 10) / 10;
        },
        hoursAvailable (client_id) {
            var rate = 135
            return Math.round((this.budgetAvailable(client_id) - this.budgetSpent(client_id)) / rate * 10) / 10
        },
        budgetSpent (client_id) {
            return Object.values(this.entries[client_id]).reduce(function (sum, entry) {
                return sum + Number(entry.billableNow);
            }, 0);
        },
        budgetAvailable (client_id) {
            if (!(client_id in this.sprints)) {
                return 0
            }
            var totalBudget = Object.values(this.sprints[client_id]).reduce(function(sum, sprint) {
                sum += Number(sprint.maximum)
                return sum
            }, 0);
            return totalBudget
        },
        budgetRemaining (client_id) {
            return this.budgetAvailable(client_id) - this.budgetSpent(client_id)
        },
        totalMonthlyRate() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.monthlyRate(client.id)
            }, 0)
        },
        totalHoursSpent() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.hoursSpent(client.id)
            }, 0)
        },
        totalHoursAvailable() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.hoursAvailable(client.id)
            }, 0)
        },
        totalBudgetAvailable() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.budgetAvailable(client.id)
            }, 0)
        },
        totalBudgetSpent() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.budgetSpent(client.id)
            }, 0)
        },
        totalBudgetRemaining() {
            return this.sortedClients.reduce((sum, client) => {
                return sum + this.budgetRemaining(client.id)
            }, 0)
        }
    },
    watch: {
        range: {
            deep: true,
            handler() {
                this.$inertia.visit(`/reports/seo?from=${this.range.from}&to=${this.range.to}`)
            }
        },
        sprintTypes(from, to) {
            if (from.join(",") !== to.join(",")) {
                this.$inertia.visit(`/reports/seo?types=${this.sprintTypes.join(",")}&from=${this.range.from}&to=${this.range.to}`)
            }
        }
    }
}
</script>

<style lang="postcss">
</style>
