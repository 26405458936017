<template>
    <Layout :auth="auth">
        <Head title="Activity Report" />
        <template v-slot:title>Activity Report</template>
        <div class="flex flex-wrap justify-between">
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        ref="timeSelect"
                        v-model="filter.time"
                        :options="options.timeRange"
                        @open="setupLitePicker"
                        :can-close="canCloseTimeSelector"
                        @close="tearDownLitePicker"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                Time Range:
                            </span>
                        </template>
                        <template v-slot:custom-value>
                            <span>
                                {{ customDateRange.from }} &mdash; {{ customDateRange.to }}
                            </span>
                        </template>
                        <template v-slot:custom>
                            <span>
                                <div @click.stop>
                                    <input
                                        class="litepicker focus:outline-none"
                                        type="text"
                                        ref="litepicker"
                                        :placeholder="customDateRangePlaceholder"
                                    />
                                </div>
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        v-model="filter.client"
                        :options="{
                            all: 'All Clients',
                            ...options.clients
                        }"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                For:
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        v-model="filter.user"
                        :options="options.users"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                Limit To:
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
        </div>
        <div class="flex items-start gap-4 mb-2">
            <div class="flex-grow aspect-[4]">
                <LineChart
                    :data="charts.line.data"
                    :options="charts.line.options"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 text-center">
                <div class="col-span-3">
                    <label class="font-medium opacity-75 uppercase text-sm">Total Resolved</label>
                    <div class="text-3xl">{{ totalResolved }}</div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import LineChart from "../../Charts/Line.vue"
import Layout from "../../Common/Layout.vue";
import { Head, Link } from "@inertiajs/vue3";
import auth from "../../Common/Mixins/auth"
import Litepicker from 'litepicker'
import { nextTick } from "vue";
import hgselect from "../../Common/hgselect.vue"
import { clone } from 'lodash'
import moment from "moment";

export default {
    name: "EversiteBillingsReport",
    components: {
        Layout,
        Head,
        Link,
        hgselect,
        LineChart
    },
    mixins: [ auth ],
    props: {
        auth: Object,
        clients: Object,
        users: Object,
        teams: Object,
        _filters: {
            type: Object,
            default: () => ({})
        },
        inactiveUsers: Array,
        types: Object,
        stats: Object
    },
    data() {
        return {
            picker: null,
            filter: {
                time: 'this-week',
                client: 'all',
                user: 'all',
                ...this._filters,
            },
            customDateRange: {
                from: null,
                to: null,
            },
        };
    },
    computed: {
        totalResolved() {
            return this.stats.resolvedByDay.reduce((acc, day) => {
                return acc + day.count
            }, 0)
        },
        customDateRangePlaceholder() {
            if (this.customDateRange.from && this.customDateRange.to) {
                return `Custom: ${this.customDateRange.from} - ${this.customDateRange.to}`
            }
            return 'Custom Date Range'
        },
        charts() {
            let minDate = null
            let maxDate = null
            switch (this.filter.time) {
                case 'custom':
                    minDate = this.customDateRange.from
                    maxDate = this.customDateRange.to
                    break;
                case 'this-week':
                    minDate = moment().startOf('week').format('YYYY-MM-DD')
                    maxDate = moment().endOf('week').format('YYYY-MM-DD')
                    break;
                case 'last-week':
                    minDate = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
                    maxDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
                    break;
                case 'this-month':
                    minDate = moment().startOf('month').format('YYYY-MM-DD')
                    maxDate = moment().endOf('month').format('YYYY-MM-DD')
                    break;
                case 'last-month':
                    minDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                    maxDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                    break;
                case 'this-year':
                    minDate = moment().startOf('year').format('YYYY-MM-DD')
                    maxDate = moment().endOf('year').format('YYYY-MM-DD')
                    break;
                case 'last-year':
                    minDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
                    maxDate = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
                    break;
            }

            // The max max date is today
            if (maxDate > moment().format('YYYY-MM-DD')) {
                maxDate = moment().format('YYYY-MM-DD')
            }

            let resolvedByDay = {}
            this.stats.resolvedByDay.forEach((day) => {
                resolvedByDay[day.day] = day.count
            })
            let openedByDay = {}
            this.stats.openedByDay.forEach((day) => {
                openedByDay[day.day] = day.count
            })

            // for minDate to now, fill in missing days
            const days = []
            const data = []
            const openData = []
            for (let d = new Date(minDate); d <= new Date(maxDate); d.setDate(d.getDate() + 1)) {
                const day = d.toISOString().split('T')[0];
                days.push(day)
                data.push(resolvedByDay[day] || 0)
                openData.push(openedByDay[day] || 0)
            }

            return {
                line: {
                    data: {
                        labels: days,
                        datasets: [{
                            label: "Open Tickets",
                            borderColor: '#E53E3E',
                            backgroundColor: '#E53E3E',
                            pointStyle: false,
                            data: openData
                        }, {
                            label: "Resolved Tickets",
                            borderColor: '#CEAADD',
                            backgroundColor: '#CEAADD',
                            pointStyle: false,
                            data: data
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                stacked: false,
                                display: true,
                            },
                            x: {
                                display: true,
                            }
                        },
                        interaction: {
                            mode: 'nearest',
                            axis: 'x',
                            intersect: false
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                mode: 'index'
                            }
                        },
                        responsive:true,
                        maintainAspectRatio: false
                    }
                }
            }
        },
        options() {
            var options = {
                clients: {
                    '-': '---',
                    ...this.clients
                },
                users: { },
                timeRange: {
                    'custom': 'Custom',
                    'this-week': 'This Week',
                    'last-week': 'Last Week',
                    'this-month': 'This Month',
                    'last-month': 'Last Month',
                    'this-year': 'This Year',
                    'last-year': 'Last Year',
                }
            }

            if (this.hasPermission('view:reports')) {
                options.users = {
                    'all': 'All Users',
                    '---': '---'
                }
            }

            var teams = this.teams;

            Object.keys(teams).forEach((team) => {
                options.users = {
                    ...options.users,
                    [team]: teams[team],
                }
            })

            const activeUsers = {}
            const inactiveUsers = {}
            Object.keys(this.users).forEach((uid) => {
                if (this.inactiveUsers.indexOf(uid) == -1) {
                    activeUsers[uid] = this.users[uid]
                } else {
                    inactiveUsers[uid] = this.users[uid]
                }
            })

            options.users = {
                ...options.users,
                '--': '---',
                ...activeUsers
            }

            if (Object.keys(inactiveUsers).length) {
                options.users = {
                    ...options.users,
                    'Inactive Users': '---',
                    ...inactiveUsers
                }
            }

            return options
        },
    },
    methods: {
        canCloseTimeSelector() {
            return !this.picker
        },
        setupLitePicker() {
            nextTick(() => {
                if (!this.picker) {
                    this.picker = new Litepicker({
                        element: this.$refs.litepicker,
                        singleMode: false,
                        numberOfColumns: 2,
                        numberOfMonths: 2,
                        setup: (picker) => {
                            picker.on('selected', (start, end) => {
                                this.customDateRange.from = start ? start.format('YYYY-MM-DD') : null
                                this.customDateRange.to = end ? end.format('YYYY-MM-DD') : null
                                this.filter.time = 'custom'
                                this.tearDownLitePicker()
                            });
                        }
                    })
                }
                if (this.customDateRange.from && this.customDateRange.to) {
                    this.picker.setDateRange(
                        this.customDateRange.from,
                        this.customDateRange.to
                    )
                }
            })
        },
        tearDownLitePicker() {
            if (this.picker) {
                this.picker.destroy()
                this.picker = null
            }
        },
        update() {
            const filter = clone(this.filter)
            if (filter.time == 'custom') {
                if (this.customDateRange.from && this.customDateRange.to) {
                    filter.time = clone(this.customDateRange)
                } else {
                    return
                }
            }

            this.$inertia.post(
                '/reports/activity',
                { filter },
                {
                    only: ['stats'],
                }
            )

            this.$refs.timeSelect.close()
        }
    },
    watch: {
        customDateRange: {
            deep: true,
            handler() {
                this.update()
            }
        },
        filter: {
            deep: true,
            handler() {
                if (this.filter.time !== 'custom') {
                    this.customDateRange.from = null
                    this.customDateRange.to = null
                }
                this.update()
            }
        }
    }
};
</script>

<style lang="postcss" scoped></style>
