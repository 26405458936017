<template>
    <Layout :auth='auth'>
        <Head :title="sprint ? sprint.name : 'Create Work Order'" />
        <template v-slot:title>
            <template v-if="sprint">{{ sprint.name }}</template>
            <template v-else>Create Work Order</template>
        </template>
        <template v-slot:actions>
            <btn v-if="sprint" white @click="save" :loading="saving">
                Save Work Order
            </btn>
            <btn v-else white @click="save" :loading="saving">
                Create Work Order
            </btn>
        </template>

        <form action="#" method="POST" @submit.prevent="save">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Work Order Information
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        This information will be used in various places in the
                        app.
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label
                            for="client_id"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Client
                        </label>
                        <div class="relative rounded-md shadow-sm mt-1">
                            <hgselect
                                ref="client-dropdown"
                                v-model="client_id"
                                :options="options.clients"
                            />
                        </div>
                        <error v-if="errors.client_id">{{
                            errors.client_id[0]
                        }}</error>
                    </div>
                    <div class="mt-4">
                        <label
                            for="name"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <div class="rounded-md shadow-sm">
                            <input
                                name="name"
                                v-model="name"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                        <error v-if="errors.name">{{
                            errors.name[0]
                        }}</error>
                    </div>
                    <div class="mt-4">
                        <label
                            for="status"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Status
                        </label>
                        <div class="relative rounded-md shadow-sm mt-1">
                            <hgselect
                                ref="status-dropdown"
                                v-model="status"
                                :options="options.statuses"
                            />
                        </div>
                        <error v-if="errors.status">{{
                            errors.status[0]
                        }}</error>
                    </div>
                    <div class="mt-4">
                        <label
                            for="status"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Maximum Amount
                        </label>
                        <div class="relative rounded-md shadow-sm mt-1">
                            <money
                                v-model="maximum"
                                class="block w-full sm:text-sm sm:leading-5"
                                placeholder="Maximum"
                            />
                        </div>
                        <error v-if="errors.maximum">{{
                            errors.maximum[0]
                        }}</error>
                    </div>

                    <div class="mt-4">
                        <label
                            for="adjustments"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Adjustments
                        </label>
                        <div class="grid grid-cols-2 gap-x-4">
                            <template v-for="(adjustment, idx) in adjustments"  :key='"adjustment-" + idx'>
                                <div>
                                    <div class="relative rounded-md shadow-sm mt-1">
                                        <money
                                            v-model="adjustment.amount"
                                            class="block w-full sm:text-sm sm:leading-5"
                                            placeholder="Amount"
                                            allow-negative
                                            @blur='checkAdjustment(idx)'
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div class="rounded-md shadow-sm">
                                        <input
                                            name="description"
                                            type="text"
                                            v-model="adjustment.desc"
                                            placeholder="Description"
                                            class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            @blur='checkAdjustment(idx)'
                                        />
                                    </div>
                                </div>
                            </template>
                            <div>
                                <div class="relative rounded-md shadow-sm mt-1">
                                    <money
                                        v-model="newAdjustment.amount"
                                        class="block w-full sm:text-sm sm:leading-5"
                                        placeholder="Amount"
                                        allow-negative
                                        @blur='addAdjustment'
                                    />
                                </div>
                            </div>
                            <div>
                                <div class="rounded-md shadow-sm">
                                    <input
                                        name="description"
                                        type="text"
                                        v-model="newAdjustment.desc"
                                        placeholder="Description"
                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        @blur='addAdjustment'
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="text-sm mt-2" v-if="lastSprints.length">
                            <span class="font-bold">Active Work Orders:</span>
                            <ul v-if="lastSprints.length">
                                <li v-for='sprint in lastSprints' :key='sprint.id'>
                                    {{ sprint.name }} &bull;
                                    {{ options.statuses[sprint.status] }} &mdash;
                                    {{ money(sprint.amount) }}
                                    <template v-if="sprint.maximum">
                                        <span class="mx-1">/</span>
                                        <span class="text-gray-600">{{ money(sprint.maximum) }}</span>
                                        <span v-if="sprint.amount > sprint.maximum" class="text-red-800">
                                            ({{ money(sprint.amount - sprint.maximum) }} over)
                                        </span>
                                    </template>
                                </li>
                            </ul>
                        </div>
                        <error v-if="errors.adjustments">{{
                            errors.adjustments[0]
                        }}</error>
                    </div>
                </div>
            </div>
        </form>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import error from "../../Common/error.vue"
import btn from "../../Common/btn.vue"
import toggle from "../../Common/toggle.vue"
import hgselect from "../../Common/hgselect.vue"
import money from "../../Common/money.vue"
import axios from 'axios'
import { get } from 'lodash'
import { Head, usePage } from '@inertiajs/vue3'

export default {
    components: { btn, toggle, Head, hgselect, money, Layout, error },
    props: {
        auth: Object,
        defaultMaximum: Number,
        clients: Array,
        sprint: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            lastSprints: [],
            saving: false,
            id: this.sprint ? this.sprint.id : null,
            name: this.sprint ? this.sprint.name : "",
            status: this.sprint ? this.sprint.status : "active",
            client_id: this.sprint ? this.sprint.client_id : "",
            maximum: this.sprint ? this.sprint.maximum : this.defaultMaximum,
            adjustments: this.sprint ? this.sprint.adjustments : [],
            recurring: {
                recurs: get(this.sprint, 'recurring.recurs', false),
                template: get(this.sprint, 'recurring.template', ''),
            },
            newAdjustment: {
                amount: '',
                desc: ''
            },
        }
    },
    computed: {
        errors() {
            return usePage().props.errors;
        },
        seoExample() {
            const mons = [
                "January", "February", "March", "April", "May",
                "June", "July", "August", "September", "October",
                "November", "December"
            ]
            return "SEO - " + mons[(new Date).getMonth()] + " " + (new Date).getFullYear()
        },
        options () {
            const clients = {}
            this.clients.forEach((client) => {
                clients[client.id] = client.name
            })
            return {
                clients,
                statuses: {
                    active: 'Active',
                    finished: 'Finished',
                    paid: 'Paid',
                    archived: 'Archived'
                }
            }
        }
    },
    methods: {
        money(num) {
            return (
                "$" +
                Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        checkAdjustment(idx) {
            var adjustment = this.adjustments[idx]
            if (!adjustment.amount && !adjustment.desc) {
                this.adjustments.splice(idx, 1)
            }
        },
        addAdjustment() {
            if (this.newAdjustment.amount) {
                this.adjustments.push({
                    amount: this.newAdjustment.amount,
                    desc: this.newAdjustment.desc
                })
                this.newAdjustment.amount = ''
                this.newAdjustment.desc = ''
            }
        },
        async save() {
            this.saving = true
            const data = new FormData()
            if (this.name) {
                data.append("name", this.name)
            }
            if (this.status) {
                data.append("status", this.status)
            }
            if (this.client_id) {
                data.append("client_id", this.client_id)
            }
            if (this.maximum) {
                data.append("maximum", this.maximum)
            }
            if (this.adjustments) {
                data.append("adjustments", this.adjustments)
            }
            if (this.recurring.recurs) {
                data.append("recurring[recurs]", this.recurring.recurs)
                data.append("recurring[template]", this.recurring.template)
            }
            try {
                if (this.id) {
                    data.append("_method", "PUT")
                    await axios.post(
                        this.$route("sprints.update", this.id),
                        data
                    )
                } else {
                    await axios.post(this.$route("sprints.store"), data)
                }
            } catch (err) {
                console.error(err)
            }
            this.saving = false
        }
    },
    watch: {
        async 'client_id'() {
            if (this.client_id) {
                const response = await axios.get(
                    this.$route("sprints.last", this.client_id)
                );
                this.lastSprints = response.data
            }
        }
    }
}
</script>

<style lang="postcss" scoped></style>
