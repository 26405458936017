<template>
    <div
        class="fixed z-10 inset-0 overflow-y-auto"
        :class="{
            'pointer-events-none': !show
        }"
        style="z-index: 1000"
        @mousedown="closeOnBackgroundClick && $emit('close')"
        @keydown.esc.prevent.stop="closeOnBackgroundClick && $emit('close')"
    >
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <transition enter-active-class="ease-out duration-300" enter-class="opacity-0" enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200" leave-class="opacity-100" leave-to-class="opacity-0">
                <div class="fixed inset-0 transition-opacity" v-show="show">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
            </transition>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <transition enter-active-class="ease-out duration-300"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="ease-in duration-200"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <!-- sm:max-w-sm sm:max-w-md sm:max-w-lg sm:max-w-xl sm:max-w-2xl sm:max-w-3xl sm:max-w-4xl -->
                <div
                    v-show="show"
                    class="modal-body inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
                    :class="{
                        ['sm:max-w-' + size]: true,
                        'overflow-hidden': !overflowVisible
                    }"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    @mousedown.stop
                >
                    <div
                        class="flex flex-col h-full"
                        :class="{
                            'overflow-auto': !overflowVisible,
                            'overflow-visible': overflowVisible
                    }">
                        <div
                            :class="{
                                'has-footer': $slots.footer,
                                'px-4 pt-5 pb-4 sm:p-6': !noPadding
                            }"
                        >
                            <slot></slot>
                        </div>
                        <slot name="footer"></slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal',
    props: {
        size: {
            type: String,
            default: 'lg'
        },
        overflowVisible: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: true
        },
        closeOnBackgroundClick: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang='postcss'>
.modal-body > div {
    div.has-footer {
        max-height: 80vh;
        overflow: auto;
    }
}
</style>
