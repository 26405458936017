<template>
    <span class="btn-group" :class="{
        'flex w-full': block,
        'inline-flex': !block
    }">
        <slot>Click Me</slot>
    </span>
</template>

<script>
export default {
    components: { },
    props: {
        block: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    computed: {
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
span:deep(> span:not(:first-child)) {
    button {
        border-left-width: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}
span:deep(> span:not(:last-child)) {
    button {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}
</style>
