<template>
    <span :class="{
        'flex w-full': block,
        'inline-flex': !block
    }">
        <Link
            v-if="href && link"
            :href="href"
            :class="classes"
            :disabled="disabled || showLoading"
            :target="target"
        >
            <span v-if="showLoading">
                <i class="mr-2 fa fa-spinner-third fa-spin"></i>
            </span>
            <slot>Click Me</slot>
        </Link>
        <a
            v-else-if="href && !link"
            :href="href"
            :class="classes"
            :disabled="disabled || showLoading"
            :target="target"
        >
            <span v-if="showLoading">
                <i class="mr-2 fa fa-spinner-third fa-spin"></i>
            </span>
            <slot>Click Me</slot>
        </a>
        <button
            v-else
            :type="type"
            :class="classes"
            :disabled="disabled || showLoading"
            @click.stop.prevent="$emit('click')"
        >
            <span v-if="showLoading">
                <i class="mr-2 fa fa-spinner-third fa-spin"></i>
            </span>
            <slot>Click Me</slot>
        </button>
    </span>
</template>

<script>
import { Link } from '@inertiajs/vue3'

export default {
    components: { Link },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        adjustClasses: {
            type: Function,
        },
        target: {
            type: String,
            default: ""
        },
        href: {
            type: String,
            default: ""
        },
        green: {
            type: Boolean,
            default: false
        },
        red: {
            type: Boolean,
            default: false
        },
        orange: {
            type: Boolean,
            default: false
        },
        yellow: {
            type: Boolean,
            default: false
        },
        white: {
            type: Boolean,
            default: false
        },
        black: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        link: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showLoading: false,
            showLoadingTimeout: null
        }
    },
    computed: {
        classes() {
            let cls = ""
            if (this.outline) {
                if (this.white) {
                    cls =
                        "inline-flex items-center border border-white font-medium text-white bg-transparent hover:text-gray-300 hover:border-gray-300 focus:outline-none focus:border-white focus:shadow-outline-blue active:text-gray-800 transition ease-in-out duration-150"
                } else if (this.black) {
                    cls = `inline-flex items-center border border-transparent font-medium text-white bg-white border-black hover:bg-gray-900 focus:outline-none focus:border-gray-600 focus:shadow-outline-black active:bg-gray-700 transition ease-in-out duration-150`
                } else {
                    let color = "green"
                    if (this.green) {
                        color = "green"
                    } else if (this.red) {
                        color = "red"
                    } else if (this.gray) {
                        color = "gray"
                    }

                    // for the purge --------
                    // text-blue-600 border-blue-600 hover:bg-blue-50 hover:text-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-200
                    // text-green-600 border-green-600 hover:bg-green-50 hover:text-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-200
                    // text-red-600 border-red-600 hover:bg-red-50 hover:text-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-200
                    // text-gray-600 border-gray-600 hover:bg-gray-50 hover:text-gray-500 focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-200
                    cls = `inline-flex items-center border font-medium text-${color}-600 border-${color}-600 hover:bg-${color}-50 hover:text-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} active:bg-${color}-200 transition ease-in-out duration-150`
                }
            } else {
                if (this.white) {
                    cls =
                        "inline-flex items-center border border-gray-300 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                } else if (this.black) {
                    cls = `inline-flex items-center border border-transparent font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:border-gray-600 focus:shadow-outline-black active:bg-gray-700 transition ease-in-out duration-150`
                } else {
                    let color = "green"
                    let textColor = "white"
                    if (this.green) {
                        color = "green"
                    } else if (this.red) {
                        color = "red"
                    } else if (this.gray) {
                        color = "gray"
                    } else if (this.yellow) {
                        color = "yellow"
                    }

                    // for the purge --------
                    // text-white bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700
                    // text-white bg-green-600 hover:bg-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-700
                    // text-white bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700
                    // text-white bg-gray-600 hover:bg-gray-500 focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700
                    // bg-yellow-600 hover:bg-yellow-500 focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700
                    cls = `inline-flex items-center border border-transparent font-medium text-${textColor} bg-${color}-600 hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} active:bg-${color}-700 transition ease-in-out duration-150`
                }
            }

            if (this.small) {
                cls += " px-2.5 py-1.5 text-xs rounded leading-4"
            } else if(this.large) {
                cls += " px-6 py-3 text-base rounded-md leading-6"
            } else {
                cls += " px-4 py-2 text-base rounded-md leading-6"
            }

            if (this.loading || this.disabled) {
                cls += " opacity-75 cursor-not-allowed"
            }

            if (this.block) {
                cls += " w-full justify-center"
            }

            return this.adjustClasses ? this.adjustClasses(cls) : cls
        }
    },
    watch: {
        loading: function() {
            if (this.loading) {
                this.showLoading = true
                // Set a timeout for 1sec that will either
                // toggle the show loading flag, or just clear
                // the timeout. This ensures that the spinner will
                // show for at least 1 second.
                this.showLoadingTimeout = setTimeout(() => {
                    if (!this.loading) {
                        this.showLoading = false
                    }
                    this.showLoadingTimeout = null
                }, 1000);
            } else {
                if (this.showLoadingTimeout === null) {
                    this.showLoading = false;
                }
            }
        }
    }
}
</script>

<style lang="postcss" scoped></style>
