<template>
    <Layout :auth='auth'>
        <Head title="Reports" />
        <template v-slot:title>Reports</template>

        <div class="flex flex-wrap justify-between">
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        ref="timeSelect"
                        v-model="filter.time"
                        :options="options.timeRange"
                        @open="setupLitePicker"
                        :can-close="canCloseTimeSelector"
                        @close="tearDownLitePicker"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                Time Range:
                            </span>
                        </template>
                        <template v-slot:custom-value>
                            <span>
                                {{ customDateRange.from }} &mdash; {{ customDateRange.to }}
                            </span>
                        </template>
                        <template v-slot:custom>
                            <span>
                                <div @click.stop>
                                    <input
                                        class="litepicker focus:outline-none"
                                        type="text"
                                        ref="litepicker"
                                        :placeholder="customDateRangePlaceholder"
                                    />
                                </div>
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        v-model="filter.client"
                        :options="{
                            all: 'All Clients',
                            ...options.clients
                        }"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                For:
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3">
                <div class="px-2 py-1">
                    <hgselect
                        class="mb-4"
                        v-model="filter.user"
                        :options="options.users"
                    >
                        <template v-slot:value-prefix>
                            <span class="font-medium">
                                Limit To:
                            </span>
                        </template>
                    </hgselect>
                </div>
            </div>
        </div>

        <div class="mb-8 aspect-[2]" v-if="charts.weekData.datasets[0].data.length">
            <LineChart
                :data="charts.weekData"
                :options="weekChartOptions"
            />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 report-grid">
            <div class="overflow-auto" v-if="charts.typeData.datasets[0].data.length">
                <div class="pie-cont">
                    <Pie
                        :data="charts.typeData"
                        :options="typePieOptions"
                    />
                </div>
                <table class="min-w-full divide-y divide-gray-200 mt-4">
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(stat, idx) in stats.typeStats" :key="'type-' + stat.type">
                            <td class="px-3 py-2">
                                <div class="h-4 w-4" :style="{backgroundColor: color(idx)}"></div>
                            </td>
                            <td class="px-3 py-2 text-left">
                                {{ typeLabel(stat.type) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatHours(stat.actual) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatMoney(stat.billableNow) }}
                            </td>
                        </tr>
                        <tr>
                            <td/>
                            <td class="px-3 py-2 text-left font-bold">Total</td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatHours(sum(stats.typeStats, 'actual')) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatMoney(sum(stats.typeStats, 'billableNow')) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="overflow-auto" v-if="charts.clientData.datasets[0].data.length">
                <div class="pie-cont">
                    <Pie
                        :data="charts.clientData"
                        :options="clientPieOptions"
                    />
                </div>
                <table class="w-full divide-y divide-gray-200 mt-4">
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(stat, idx) in sortedClientStats" :key="'client-' + stat.client_id">
                            <td class="px-3 py-2">
                                <div class="h-4 w-4" :style="{backgroundColor: color(idx)}"></div>
                            </td>
                            <td class="px-3 py-2 text-left">
                                {{ clientLabel(stat.client_id) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatHours(stat.actual) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatMoney(stat.billableNow) }}
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td class="px-3 py-2 text-left font-bold">Total</td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatHours(sum(stats.clientStats, 'actual')) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatMoney(sum(stats.clientStats, 'billableNow')) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="overflow-auto" v-if="charts.userData.datasets[0].data.length">
                <div class="pie-cont">
                    <Pie
                        :data="charts.userData"
                        :options="userPieOptions"
                    />
                </div>
                <table class="w-full divide-y divide-gray-200 mt-4">
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(stat, idx) in sortedUserStats" :key="'user-' + stat.user_id">
                            <td class="px-3 py-2">
                                <div class="h-4 w-4" :style="{backgroundColor: color(idx)}"></div>
                            </td>
                            <td class="px-3 py-2 text-left flex items-center truncate">
                                {{ userLabel(stat.user_id) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatHours(stat.actual) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right">
                                {{ formatMoney(stat.billableNow) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="px-3 py-2">Total</td>
                            <td class="px-3 py-2 text-left font-bold">Total</td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatHours(sum(stats.userStats, 'actual')) }}
                            </td>
                            <td class="px-3 py-2 whitespace-nowrap text-right font-bold">
                                {{ formatMoney(sum(stats.userStats, 'billableNow')) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Layout>
</template>

<script>
import Line from "../../Charts/Line.vue"
import Pie from "../../Charts/Pie.vue"
import auth from "../../Common/Mixins/auth"
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import { clone, get } from "lodash"
import { nextTick } from "vue"
import Litepicker from 'litepicker'
import { Head } from '@inertiajs/vue3'

const colors = [
    '#E53E3E', // red
    '#ED8936', // orange
    '#F6E05E', // yellow
    '#48BB78', // green
    '#38B2AC', // teal
    '#4299E1', // blue
    '#7F9CF5', // indigo
    '#805AD5', // purple
    '#ED64A6', // pink
]

const formatHours = function(num) {
    const hours = Number(Math.round(num * 10) / 10).toLocaleString()
    return hours + " hour" + (hours == 1 ? '' : 's')
}
const formatMoney = function(num) {
    return '$' + Number(Math.round(num * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
}

export default {
    name: 'ReportsIndex',
    mixins: [auth],
    components: {
        Layout,
        Head,
        hgselect,
        'LineChart': Line,
        Pie
    },
    props: {
        auth: Object,
        clients: Object,
        users: Object,
        inactiveUsers: Array,
        types: Object,
        stats: Object
    },
    data() {
        return {
            picker: null,
            filter: {
                time: 'this-week',
                client: 'all',
                user: 'all'
            },
            customDateRange: {
                from: null,
                to: null,
            },
            typePieOptions: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = ' ' + data.labels[tooltipItem.index] || '';

                            if (label) {
                                label += ': ';
                            }
                            label += formatHours(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            return label;
                        }
                    }
                }
            },
            clientPieOptions: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = ' ' + data.labels[tooltipItem.index] || '';

                            if (label) {
                                label += ': ';
                            }
                            label += formatHours(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            return label;
                        }
                    }
                }
            },
            userPieOptions: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = ' ' + data.labels[tooltipItem.index] || '';

                            if (label) {
                                label += ': ';
                            }
                            label += formatHours(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            return label;
                        }
                    }
                }
            },
            weekChartOptions: {
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'left',
                        id: 'actual-axis',
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return value + ' hours'
                            }
                        },
                    }, {
                        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: 'right',
                        id: 'billable-axis',
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return '$' + Number(value).toLocaleString()
                            }
                        },

                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                }
            }
        }
    },
    computed: {
        customDateRangePlaceholder() {
            if (this.customDateRange.from && this.customDateRange.to) {
                return `Custom: ${this.customDateRange.from} - ${this.customDateRange.to}`
            }
            return 'Custom Date Range'
        },
        charts() {
            const weekData = {
                datasets: [{
                    data: [],
                    label: "Actual Hours",
                    backgroundColor: colors[0],
                    borderColor: colors[0],
                    fill: false,
                    yAxisID: 'actual-axis',
                }, {
                    data: [],
                    label: "Billable Now",
                    backgroundColor: colors[1],
                    borderColor: colors[1],
                    fill: false,
                    yAxisID: 'billable-axis',
                }],
                labels: []
            }

            this.stats.weekStats.forEach((week) => {
                weekData.labels.push(week.week)
                weekData.datasets[0].data.push(Number(week.actual))
                weekData.datasets[1].data.push(Number(week.billableNow))
            })

            const typeData = {
                datasets: [{
                    data: [],
                    backgroundColor: [ ],
                }],
                labels: []
            }

            this.stats.typeStats.forEach((type, idx) => {
                typeData.labels.push(this.typeLabel(type.type))
                typeData.datasets[0].data.push(Number(type.actual))
                typeData.datasets[0].backgroundColor.push(this.color(idx))
            })

            const clientData = {
                datasets: [{
                    data: [],
                    backgroundColor: [ ],
                }],
                labels: []
            }

            this.sortedClientStats.forEach((client, idx) => {
                clientData.labels.push(this.clientLabel(client.client_id))
                clientData.datasets[0].data.push(Number(client.actual))
                clientData.datasets[0].backgroundColor.push(this.color(idx))
            })

            const userData = {
                datasets: [{
                    data: [],
                    backgroundColor: [ ],
                }],
                labels: []
            }

            this.sortedUserStats.forEach((user, idx) => {
                userData.labels.push(this.userLabel(user.user_id))
                userData.datasets[0].data.push(Number(user.actual))
                userData.datasets[0].backgroundColor.push(this.color(idx))
            })

            return {
                typeData,
                clientData,
                userData,
                weekData
            }
        },
        options() {
            var options = {
                clients: {
                    '-': '---',
                    ...this.clients
                },
                users: {
                },
                timeRange: {
                    'custom': 'Custom',
                    'this-week': 'This Week',
                    'last-week': 'Last Week',
                    'this-month': 'This Month',
                    'last-month': 'Last Month',
                    'this-year': 'This Year',
                    'last-year': 'Last Year',
                }
            }

            if (this.hasPermission('view:reports')) {
                options.users = {
                    'all': 'All Users',
                    '---': '---'
                }
            }

            var teams = {
                'dev': 'Development Team',
                'design': 'Design Team',
                'ateam': 'A-Team',
                'admin': 'Admin Team'
            }

            Object.keys(teams).forEach((team) => {
                if (this.hasPermission(`view:${team}-entries`)) {
                    options.users = {
                        ...options.users,
                        [team]: teams[team],
                    }
                }
            })

            const activeUsers = {}
            const inactiveUsers = {}
            Object.keys(this.users).forEach((uid) => {
                if (this.inactiveUsers.indexOf(uid) == -1) {
                    activeUsers[uid] = this.users[uid]
                } else {
                    inactiveUsers[uid] = this.users[uid]
                }
            })

            options.users = {
                ...options.users,
                '--': '---',
                ...activeUsers
            }

            if (Object.keys(inactiveUsers).length) {
                options.users = {
                    ...options.users,
                    'Inactive Users': '---',
                    ...inactiveUsers
                }
            }

            return options
        },
        sortedClientStats() {
            const stats = clone(this.stats.clientStats)
            stats.sort((a, b) => {
                const ca = get(this.clients, a.client_id, a.client_id)
                const cb = get(this.clients, b.client_id, b.client_id)
                if (ca > cb) {
                    return 1
                }
                if (ca < cb) {
                    return -1
                }
                return 0
            })
            return stats
        },
        sortedUserStats() {
            const stats = clone(this.stats.userStats)
            stats.sort((a, b) => {
                const ca = get(this.users, a.user_id, a.user_id)
                const cb = get(this.users, b.user_id, b.user_id)
                if (ca > cb) {
                    return 1
                }
                if (ca < cb) {
                    return -1
                }
                return 0
            })
            return stats
        }
    },
    methods: {
        formatHours,
        formatMoney,
        canCloseTimeSelector() {
            return !this.picker
        },
        setupLitePicker() {
            nextTick(() => {
                if (!this.picker) {
                    this.picker = new Litepicker({
                        element: this.$refs.litepicker,
                        singleMode: false,
                        numberOfColumns: 2,
                        numberOfMonths: 2,
                        setup: (picker) => {
                            picker.on('selected', (start, end) => {
                                this.customDateRange.from = start ? start.format('YYYY-MM-DD') : null
                                this.customDateRange.to = end ? end.format('YYYY-MM-DD') : null
                                this.filter.time = 'custom'
                                this.tearDownLitePicker()
                            });
                        }
                    })
                }
                if (this.customDateRange.from && this.customDateRange.to) {
                    this.picker.setDateRange(
                        this.customDateRange.from,
                        this.customDateRange.to
                    )
                }
            })
        },
        tearDownLitePicker() {
            if (this.picker) {
                this.picker.destroy()
                this.picker = null
            }
        },
        sum(collection, key) {
            return collection.reduce((sum, cur) => {
                return sum + Number(cur[key])
            }, 0);
        },
        typeLabel(t) {
            if (!t) {
                return 'No Type'
            }
            return t in this.types ? this.types[t] : t;
        },
        clientLabel(cid) {
            return cid in this.clients ? this.clients[cid] : cid;
        },
        userLabel(uid) {
            return uid in this.users ? this.users[uid] : uid;
        },
        update() {
            const filter = clone(this.filter)
            if (filter.time == 'custom') {
                if (this.customDateRange.from && this.customDateRange.to) {
                    filter.time = clone(this.customDateRange)
                } else {
                    return
                }
            }

            this.$inertia.post(
                '/reports',
                { filter },
                {
                    only: ['stats'],
                }
            )

            this.$refs.timeSelect.close()
        },
        color(idx) {
            return colors[idx % colors.length]
        }
    },
    watch: {
        customDateRange: {
            deep: true,
            handler() {
                this.update()
            }
        },
        filter: {
            deep: true,
            handler() {
                if (this.filter.time !== 'custom') {
                    this.customDateRange.from = null
                    this.customDateRange.to = null
                }
                this.update()
            }
        }
    }
}
</script>

<style lang="postcss">
.litepicker {
    @apply block text-sm bg-transparent w-full cursor-pointer;
    &::placeholder {
        @apply text-gray-800;
    }
}
</style>
