<template>
    <div class="mt-1 text-sm text-red-600">
        <slot />
    </div>
</template>

<script>
export default {}
</script>

<style lang="postcss" scoped></style>
