<template>
    <Layout :auth='auth'>
        <Head :title="archived ? 'Archived Clients' : 'Clients'"/>
        <template v-slot:title>
            <template v-if="archived">Archived Clients</template>
            <template v-else>Clients</template>
        </template>
        <template v-slot:actions>
            <btn white href="/clients/create">Add New Client</btn>
        </template>

        <form @submit.prevent.stop='search' class='mb-2 flex items-center'>
            <label class="mr-2">Search&nbsp;Clients:</label>
            <input
                autofocus
                type="text"
                v-model="query"
                class="w-full"
            />
            <btn class="ml-2" blue @click="search">
                <i class="fal fa-search mr-2"></i>
                Search
            </btn>
        </form>
        <div class="overflow-hidden sm:rounded-md">
            <ul>
                <li
                    v-for="(client, idx) in clients.data"
                    :key="client.id"
                    :class="{
                        'border-t border-gray-200': idx > 0
                    }"
                >
                    <Link
                        :href="$route('clients.show', client.id)"
                        class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                    >
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 flex items-center">
                                <div class="flex-shrink-0">
                                    <img
                                        v-if="client.logo"
                                        class="h-12 w-12 object-cover rounded-full"
                                        :src="client.logo"
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4"
                                >
                                    <div class="flex flex-col justify-center">
                                        <div
                                            class="text-lg leading-5 font-medium text-blue-900 truncate"
                                        >
                                            {{ client.name }}
                                        </div>
                                        <ul class="flex gap-x-1 mt-2">
                                            <li v-for="(tag, idx) in client.tags" :key='"tag-" + idx'>
                                                <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                                    {{ tag }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div v-if="client.contact_name" class="truncate">
                                            <i class="fa fa-user text-gray-400"></i>
                                            <span class="ml-2 flex-1">
                                                {{ client.contact_name }}
                                            </span>
                                        </div>
                                        <div v-if="client.contact_name" class="truncate">
                                            <i class="fa fa-envelope text-gray-400"></i>
                                            <span class="ml-2 flex-1">
                                                {{ client.contact_email }}
                                            </span>
                                        </div>
                                        <div v-if="client.contact_phone" class="truncate">
                                            <i class="fa fa-phone text-gray-400"></i>
                                            <span class="ml-2 flex-1">
                                                {{ client.contact_phone }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="hidden md:block">
                                        <div>
                                            <div
                                                class="mb-2 flex items-center text-sm leading-5 text-gray-500"
                                            >
                                                <template
                                                    v-if="client.is_active"
                                                >
                                                    <i
                                                        class="fa fa-check-circle mr-1.5 h-5 w-5 text-green-400"
                                                    ></i>
                                                    Active
                                                </template>
                                                <template v-else>
                                                    <i
                                                        class="fa fa-times-circle mr-1.5 h-5 w-5 text-red-400"
                                                    ></i>
                                                    Active
                                                </template>
                                            </div>
                                            <div
                                                class="text-sm leading-5 text-gray-900"
                                            >
                                                Created at
                                                {{ date(client.created_at) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg
                                    class="h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </li>
            </ul>
            <nav
                class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            >
                <div class="hidden sm:block">
                    <p class="text-sm leading-5 text-gray-700">
                        Showing
                        <span class="font-medium">{{ clients.from }}</span>
                        to
                        <span class="font-medium">{{ clients.to }} </span>
                        of
                        <span class="font-medium">{{ clients.total }} </span>
                        results.
                        <a
                            v-if="!archived"
                            :href="$route('clients.index') + '?archived'"
                            class="ml-2 underline"
                        >
                            Show Archived Clients
                        </a>
                    </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                    <Link
                        :href="clients.prev_page_url || $route('clients.index')"
                        :disabled="!clients.prev_page_url"
                        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        :class="{
                            'opacity-50 pointer-events-none': !clients.prev_page_url
                        }"
                    >
                        Previous
                    </Link>
                    <Link
                        :href="clients.next_page_url || $route('clients.index')"
                        :disabled="!clients.next_page_url"
                        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        :class="{ 'opacity-50 pointer-events-none': !clients.next_page_url }"
                    >
                        Next
                    </Link>
                </div>
            </nav>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import { Head, Link } from '@inertiajs/vue3'
import btn from "../../Common/btn.vue"
import moment from "moment"

export default {
    name: "ClientsIndex",
    props: {
        auth: Object,
        archived: Boolean,
        clients: Object,
        initialQuery: String
    },
    components: { btn, Layout, Head, Link },
    data() {
        return {
            query: this.initialQuery
        }
    },
    methods: {
        search() {
            if (this.query) {
                this.$inertia.visit(
                    this.$route("clients.index"),
                    { data: {query: this.query} }
                )
            } else {
                this.$inertia.visit(this.$route("clients.index"))
            }
        },
        date(date) {
            return moment(date)
                .local()
                .format("MMM Do, YYYY")
        }
    }
}
</script>

<style lang="postcss" scoped></style>
