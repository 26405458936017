<template>
<div class="flex flex-col">
  <div v-if="selectedSprints.length > 0" class="grid md:grid-cols-3 mb-6">
    <div>
      <label class="block text-sm leading-5 font-medium text-gray-700 mb-2">
        Perform bulk action on {{ selectedSprints.length }} work order{{ selectedSprints.length == 1 ? '' : 's' }}...
      </label>
      <div class="flex items-center">
        <hgselect v-model='bulkAction' :options='bulkActions' />
        <btn
          :loading='doingBulkAction'
          class="whitespace-nowrap ml-4"
          @click="doBulkAction"
        >
          Do it
          <span class="ml-2">
            <i class="fal fa-long-arrow-right"></i>
          </span>
        </btn>
      </div>
    </div>
  </div>
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th/>
            <th @click='order("name")' class="w-64 relative whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Name
              <span v-show="orderBy[0] == 'name' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'name' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("client")' v-if="showClient" class=" whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Client
              <span v-show="orderBy[0] == 'client' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'client' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("project")' v-if="showProject" class=" whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Project
              <span v-show="orderBy[0] == 'project' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'project' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("status")' class="w-16 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
              Status
              <span v-show="orderBy[0] == 'status' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'status' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("number")' class="w-16 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
              Payment
              <span v-show="orderBy[0] == 'number' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'number' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("amount")' class="w-32 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Sprint
              <span v-show="orderBy[0] == 'amount' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'amount' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("hours")' class="w-16 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Hours
              <span v-show="orderBy[0] == 'hours' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'hours' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("adjustments")' class="w-20 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Adjustments
              <span v-show="orderBy[0] == 'adjustments' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'adjustments' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("created_at")' class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
              Created At
              <span v-show="orderBy[0] == 'created_at' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'created_at' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th class="px-6 py-3 bg-gray-50" v-if="editable"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <template v-for="(sprint, idx) in orderedSprints" :key="sprint.id">
            <tr
                @click.stop.prevent='$emit("sprint-clicked", sprint)'
                :class="{
                    'cursor-pointer': true,
                    'bg-gray-50': idx % 2,
                    'opacity-75': sprint.status == 'finished'
                }"
            >
              <td class="pl-4 pr-0">
                <checkbox v-model="selected[sprint.id]" />
              </td>
              <td
                class="pl-2 pr-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
                >

                <Link
                    :href='"/sprints/" + sprint.id'
                    class="hover:underline"
                >
                    {{ sprint.name }}
                </Link>
              </td>
              <td
                v-if="showClient"
                class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
              >
                <div class="flex flex-col w-full">
                  <Link
                      :href='"/clients/" + sprint.client_id'
                      class="hover:underline"
                  >{{ clientName(sprint.client_id) }}</Link>
                  <Link
                      v-if="!showProject"
                      :href='"/projects/" + sprint.project_id'
                      class="hover:underline text-xs text-gray-500"
                  >{{ projectName(sprint.project_id) }}</Link>
                </div>
              </td>
              <td
                v-if="showProject"
                class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
              >
                <div class="flex flex-col w-full">
                  <Link
                      :href='"/projects/" + sprint.project_id'
                      class="hover:underline"
                  >{{ projectName(sprint.project_id) }}</Link>
                </div>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-center cursor-pointer" v-html="status(sprint.status)"></td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600">
                <template v-if="sprint.payment">
                    <i class="fal fa-check text-green-500 mr-1" title="Paid" v-if="sprint.payment.is_paid"></i>
                    <i class="fal fa-times text-red-500 mr-1" title="Not Paid" v-else></i>
                    <Link
                        v-if="sprint.payment"
                        :href='"/payments/" + sprint.payment_id'
                        class="hover:underline"
                    >
                        {{ sprint.payment.number }}
                    </Link>
                </template>
                <template v-else>
                    <div class="text-center">
                        <i class="fal fa-file-invoice-dollar text-red-500" title="Not In Quickbooks"></i>
                    </div>
                </template>
              </td>
              <td
                class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900"
                v-html="remaining(sprint)"
                @click.stop="fixOverage(sprint)"
              ></td>
              <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                {{ number(sprint.hours ) }}
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm text-right leading-5 text-gray-900">
                <span v-if="totalAdjustments(sprint) != 0">{{ money(totalAdjustments(sprint)) }}</span>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                {{ datetime(sprint.created_at) }}
              </td>
              <td
                class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium"
                v-if="editable"
              >
                <a
                  href="#"
                  class="text-blue-700 hover:text-blue-900"
                  @click.stop.prevent='editSprint = sprint'
                >Details</a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <overage-modal
      v-if="overage"
      :sprint="overage"
      :sprints="sprints"
      @close="overageFinished()"
  ></overage-modal>
  <modal size='2xl' :show="!!editSprint" @close='editSprint = null' :close-on-background-click='true'>
    <sprint-form
        v-if="editSprint"
        :key="editSprint.id"
        v-model="editSprint"
        @saved='sprintUpdated'
        @cancel='editSprint = null'
    />
  </modal>
</div>
</template>

<script>
import hgselect from "../Common/hgselect.vue"
import btn from "../Common/btn.vue"
import inp from "../Common/inp.vue"
import modal from "../Common/modal.vue"
import checkbox from "../Common/checkbox.vue"
import { Link } from '@inertiajs/vue3'
import sprintForm from "./sprintForm.vue"
import overageModal from "./overageModal.vue"
import moment from "moment"
import axios from "axios"
import { get, findIndex, orderBy } from "lodash"

export default {
  name: 'SprintsTable',
  components: {
    btn, inp, modal, sprintForm, hgselect, checkbox, Link, overageModal
  },
  props: {
    clients: [Array, Object],
    projects: [Array, Object],
    sprints: Array,
    showClient: {
      type: Boolean,
      default: true
    },
    showProject: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
      return {
          doingBulkAction: false,
          bulkAction: null,
          bulkActions: {
            finished: 'Mark as Finished',
            archived: 'Mark as Archived'
          },
          overage: null,
          editSprint: null,
          orderBy: ["created_at", "desc"],
          showFilters: false,
          selected: {}
      }
  },
  methods: {
      overageFinished() {
        this.overage = null
        this.$emit('reload')
      },
      fixOverage(sprint) {
        if (sprint.amount > sprint.maximum) {
          this.overage = sprint
        }
      },
      async doBulkAction() {
          if (this.selectedSprints.length && this.bulkAction) {
              this.doingBulkAction = true
              await axios.post(this.$route("sprints.bulk"), {
                action: this.bulkAction,
                ids: this.selectedSprints
              })
              this.doingBulkAction = false
              this.selected = {}
              this.$emit('reload')
          }
      },
      totalAdjustments(sprint) {
        return (sprint.adjustments || []).reduce(function(sum, adjustment) {
          return sum + adjustment.amount
        }, 0)
      },
      toggleSprint(sprint_id) {
          const idx = this.selected.indexOf(sprint_id)
          if (idx >= 0) {
              this.selected.splice(idx, 1)
          } else {
              this.selected.push(sprint_id)
          }
      },
      clientName(client_id) {
          if (Array.isArray(this.clients)) {
              const idx = findIndex(this.clients, {id: client_id})
              return idx >= 0 ? this.clients[idx].name : '--'
          } else if(this.clients) {
              return this.clients[client_id]
          }
      },
      projectName(project_id) {
          if (Array.isArray(this.projects)) {
              const idx = findIndex(this.projects, {id: project_id})
              return idx >= 0 ? this.projects[idx].name : '--'
          } else if(this.projects) {
              return this.projects[project_id]
          }
      },
      sprintUpdated(sprint) {
          let idx = findIndex(this.sprints, {id: sprint.id})
          this.sprints[idx] = sprint
          this.editSprint = null
          this.$emit("saved", sprint)
          this.$emit('reload')
      },
      remaining(sprint) {
          if (sprint.maximum) {
              const remaining = this.money(Math.abs(sprint.maximum - sprint.amount));
              const color = Number(sprint.amount) > Number(sprint.maximum) ? 'red' : 'green'
              const suffix = Number(sprint.amount) > Number(sprint.maximum) ? 'over' : 'left'
              const hover = Number(sprint.amount) > Number(sprint.maximum) ? 'hover:bg-red-200' : ''
              return `<span title="${sprint.name}" class="inline-block text-center bg-${color}-100 ${hover} text-${color}-800 border border-${color}-200 rounded-full px-2">${remaining} ${suffix}</span>`
          } else {
              return `<span title="${sprint.name}" class="inline-block text-center bg-yellow-100 text-yellow-800 border border-yellow-200 rounded-full px-2">${sprint.amount} unbilled</span>`
          }
      },
      status(status) {
          const statuses = {
              active: `<span title="Active" class="inline-block rounded-full w-2 h-2 bg-green-500"></span>`,
              finished: `<span title="Finished" class="inline-block rounded-full w-4 h-4 bg-green-500 text-white flex items-center justify-center mx-auto"><i class="fas fa-xs fa-check relative top-px"/></span>`,
              archived: `<span title="Archived" class="inline-block rounded-full w-2 h-2 bg-gray-400"></span>`
          }
          return status in statuses ? statuses[status] : status
      },
      order(key) {
          if (this.orderBy[0] == key) {
              if (this.orderBy[1] == 'asc') {
                this.orderBy = [key, 'desc']
              } else {
                this.orderBy = [null, null]
              }
          } else {
              this.orderBy = [key, 'asc']
          }
      },
      matchSprint(sprint, key, value) {
        switch(key) {
          case 'text':
          case 'name':
            if (!this.match(sprint.name, value)) {
              return false;
            }
            break;
          case 'client':
            if (!this.match(this.clientName(sprint.client_id), value)) {
              return false;
            }
            break;
          case 'payment':
            if (!sprint.payment || !this.match(sprint.payment.number, value)) {
              return false;
            }
            break;
        }
        return true;
      },
      match(one, two) {
        return String(one).toLowerCase().indexOf(String(two).toLowerCase()) >= 0
      },
      money (num) {
        if (Number(num) >= 0) {
            let value = Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `$${value}`
        } else {
            let value = Math.abs(Number(num)).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `-$${value}`
        }
      },
      number (num) {
        if (Number(num) >= 0) {
            let value = Number(num).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `${value}`
        } else {
            let value = Math.abs(Number(num)).toLocaleString(undefined, {minimumFractionDigits: 2})
            return `-${value}`
        }
      },
      datetime(dt) {
          let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
          if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
              return date.format('MMM Do, h:mma')
          }
          return date.format('MMM Do YYYY, h:mma')
      },
  },
  computed: {
      selectedSprints() {
          const selected = []
          Object.keys(this.selected).forEach(id => {
            if (this.selected[id]) {
              selected.push(id)
            }
          })
          return selected
      },
      orderedSprints() {
          if (this.orderBy[0]) {
                switch(this.orderBy[0]) {
                    case 'number':
                        return orderBy(this.sprints, (sprint) => {
                            return get(sprint, 'payment.number', '')
                        }, this.orderBy[1]);
                    case 'is_paid':
                        return orderBy(this.sprints, (sprint) => {
                            return get(sprint, 'payment.is_paid', false)
                        }, this.orderBy[1]);
                    case 'client':
                        return orderBy(this.sprints, (sprint) => {
                            return this.clientName(sprint.client_id)
                        }, this.orderBy[1]);
                    case 'adjustments':
                        return orderBy(this.sprints, (sprint) => {
                            if ((sprint.adjustments || []).length == 0) {
                              if (this.orderBy[1] == 'asc') {
                                return Number.MAX_SAFE_INTEGER
                              }
                              return -1 * Number.MAX_SAFE_INTEGER
                            }
                            return this.totalAdjustments(sprint)
                        }, this.orderBy[1]);
                    case 'hours':
                    case 'amount':
                        return orderBy(this.sprints, (sprint) => {
                            return Number(get(sprint, this.orderBy[0], 0))
                        }, this.orderBy[1])
                    case 'name':
                    case 'status':
                    default:
                        return orderBy(this.sprints, this.orderBy[0], this.orderBy[1])
                }
            } else {
                return this.sprints
            }
      }
  }
}
</script>

<style lang="postcss" scoped>
</style>
