<template>
    <a
        target="_blank"
        :href="url(log)"
        class="py-2 px-3 rounded-lg flex items-center gap-3 text-sm w-full"
        :style="{
            'backgroundColor': colors.types[log.type]
        }"
    >
        <template v-if="userFor(log)">
            <avatar
                :title="userName(userFor(log))"
                :user="userFor(log)"
                size="6"
            />
        </template>
        <template v-else>
            <div class="h-6 w-6 min-h-6 min-w-6 bg-green-200 rounded-full" />
        </template>
        <template v-if="log.type == 'memo'">
            <i class="fa-light fa-pen-to-square" />
            <div class="truncate">{{ log.data.subject }}</div>
            <div :style="{
                'backgroundColor': colors.status[log.data.status]
            }" class="px-2 py-[2px] text-xs rounded-full">{{ log.data.status }}</div>
        </template>
        <template v-if="log.type == 'payment'">
            <i class="fa-light fa-money-bill-transfer" />
            <div class="truncate">{{ log.data.description }}</div>
            <div class="text-gray-500">{{ money(log.data.amount) }}</div>
        </template>
        <template v-if="log.type == 'timeEntry'">
            <i class="fa-light fa-stopwatch" />
            <div class="truncate">{{ log.data.description }}</div>
            <div class="text-gray-500">{{ withoutTrailingZeros(log.data.actual) }}hrs</div>
        </template>
        <template v-if="log.type == 'sprint'">
            <div class="truncate">{{ log.data.name }}</div>
        </template>
        <template v-if="log.type == 'note'">
            <i class="fa-light fa-sticky-note" />
            <div
                v-html="log.data.note"
                @click="showFullNote = !showFullNote"
                class="cursor-pointer"
                :class="{
                    'line-clamp-2': !showFullNote,
                    'line-clamp-none': showFullNote
                }"
            />
        </template>
        <div
            class="text-xs text-gray-500 whitespace-nowrap ml-auto"
            :title="longDate(log.data.created_at)"
        >{{ shortDate(log.data.created_at) }}</div>
    </a>
</template>

<script setup>
import moment from 'moment'
import avatar from '../../Common/avatar.vue'
import { ref } from 'vue'

const $props = defineProps({
    log: {
        type: Object,
        required: true
    },
    usersById: {
        type: Object,
        required: true
    }
});

const showFullNote = ref(false)

const shortDate = (date) => {
    // if it's been less than a hour, show "x minutes ago"
    if (moment.utc(date).local().isBefore(moment.utc().local().subtract(1, 'hour'))) {
        return moment.utc(date).local().fromNow()
    }
    // if it's been less than a day, show the time
    if (moment.utc(date).local().isBefore(moment.utc().local().subtract(1, 'day'))) {
        return moment.utc(date).local().format('h:mm a')
    }
    // if it's been less than a week, show the dow with the time
    if (moment.utc(date).local().isBefore(moment.utc().local().subtract(1, 'week'))) {
        return moment.utc(date).local().format('ddd h:mm a')
    }

    // if it's been more than a week, show the full date
    return moment.utc(date).local().format('MMM D, YYYY h:mm a')
}

const longDate = (date) => {
    return moment.utc(date).local().format('MMM D, YYYY h:mm a')
}

const userName = (user) => {
    if (user) {
        return user.name || user.email
    }
    return 'Unknown User'
}

const userFor = (log) => {
    let user_id = null;
    if (log.type == 'memo') {
        user_id = log.data.created_by?.id
    }
    if (log.type == 'timeEntry') {
        user_id = log.data.user_id
    }
    if (log.type == 'note') {
        user_id = log.data.user_id
    }
    if (user_id) {
        return $props.usersById[user_id]
    }
    return null
}

const withoutTrailingZeros = (value) => {
    return value.toString().replace(/\.0+$/, '')
}

const money = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}

const url = (log) => {
    if (log.type == 'memo') {
        return `/activity/${log.data.id}`
    }
    if (log.type == 'payment') {
        return `/payments/${log.data.id}`
    }
    if (log.type == 'timeEntry') {
        return `/?week=${log.data.week}`
    }
    if (log.type == 'sprint') {
        return `/sprints/${log.data.id}`
    }
    return null
}

const colors = {
    status: {
        "new": "#CEAADD",
        "in-progress": "#95CCF1",
        "need-more-information": "#FFCF83",
        "waiting-on-client": "#FEE581",
        "resolved": "#93E9B7",
        "reopened": "#FF9F80"
    },
    types: {
        "memo": "#fbebf5",
        "payment": "#f4feec",
        "timeEntry": "#eaebfd",
        "sprint": "#ecf5fd",
        "note": "#fefcea",
    }
}
</script>
