<script setup>
import inp from '../Common/inp.vue'
import money from '../Common/money.vue'
import percentage from '../Common/percentage.vue'
import { ref, watch } from 'vue'

const $emits = defineEmits(['updated'])
const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    },
    meta: {
        type: Object,
        default: () => ({
        })
    },
    settings: {
        type: Object,
        default: () => ({
        })
    }
})

const variant = $props.meta.variant || 'default'
const dirty = ref(false)
const modelValue = ref(null);

if ($props.meta.editable) {
    modelValue.value = $props.settings[$props.meta.editable.key]
}

watch(modelValue, () => {
    dirty.value = true
    $emits('updated', {
        key: $props.meta.editable.key,
        value: modelValue.value
    })
})

const save = async () => {
    if (!$props.meta.editable.saveable) {
        return
    }
    await window.axios.post(
        window.route("settings.save", {
            key: $props.meta.editable.key
        }), {
            scope: $props.meta.editable.scope,
            scope_id: $props.meta.editable.scope_id,
            data: modelValue.value
        }
    )
    dirty.value = false
}

const pct = (value) => {
    return value > 0 ? `+${value}%` : `${value}%`
}

const handleKeydown = (event) => {
    if (event.key === 'Enter') {
        // save()
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
        event.stopPropagation()
        const type = 'type' in $props.meta ? $props.meta.type : 'text'
        if (type === 'percentage') {
            let inc = (event.key === 'ArrowUp' ? 0.01 : -0.01)
            if (event.shiftKey) {
                inc *= 10
            }
            modelValue.value = (parseFloat(modelValue.value) + inc).toFixed(2)
        } else if (type === 'currency') {
            const precision = 'precision' in $props.meta ? $props.meta.precision : 0
            let inc = (event.key === 'ArrowUp' ? 10 : -10)
            if (event.shiftKey) {
                inc *= 10
            }
            modelValue.value = (parseFloat(modelValue.value) + inc).toFixed(precision)
        }
    }
}

const format = (value) => {
    const type = 'type' in $props.meta ? $props.meta.type : 'text'

    if (typeof value === 'string') {
        if (value[0] == '=') {
            // We have a formula - lovely
            value.replace(/\$(.+?)\b/g, (match, key) => {
                value = value.replace(match, $props.settings[key])
            })
            value = eval(value.slice(1))
        }
    }

    if (!isNaN(parseFloat(value))) {
        const precision = 'precision' in $props.meta ? $props.meta.precision : 0
        value = parseFloat(value).toFixed(precision)
    }

    switch (type) {
        case 'currency':
            value = Number(value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })
            break;
        case 'percentage':
            value = `${value * 100}%`
            break;
    }

    const prefix = 'prefix' in $props.meta ? $props.meta.prefix : ''
    const suffix = 'suffix' in $props.meta ? $props.meta.suffix : ''
    return `${prefix}${value}${suffix}`
}
</script>

<template>
    <div class="rounded-lg overflow-hidden">
        <template v-if="variant == 'default'">
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 p-4 h-full">
                <dt
                    class="text-sm font-medium leading-4 text-gray-800 line-clamp-2 min-h-[24px]">{{ title }}</dt>
                <dd
                    v-if="data.change !== undefined"
                    class="text-xs font-medium"
                    :class="{
                        'text-gray-700': data.change == 0,
                        'text-green-500': data.change > 0,
                        'text-red-500': data.change < 0
                    }"
                >{{ pct(data.change) }}</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    <template v-if="meta.editable">
                        <div class="relative">
                            <money
                                v-if="meta.type === 'currency'"
                                v-model="modelValue"
                                class="block w-full"
                                @keydown="handleKeydown"
                            />
                            <percentage
                                key="percentage"
                                v-else-if="meta.type === 'percentage'"
                                v-model="modelValue"
                                class="block w-full"
                                @keydown="handleKeydown"
                            />
                            <inp
                                v-else
                                v-model="modelValue"
                                unstyled
                            />
                            <a
                                v-if="meta.editable.saveable"
                                href="#"
                                class="text-xl absolute top-1/2 -translate-y-1/2 right-3 text-gray-500 hover:text-gray-700"
                                :class="{
                                    'opacity-25 pointer-events-none': !dirty
                                }"
                                @click.prevent="save"
                            >
                                <i class="fad fa-save"></i>
                            </a>
                        </div>
                    </template>
                    <template v-else>
                        {{ format(data.value) }}
                    </template>
                </dd>
            </div>
        </template>
        <template v-if="variant == 'label-bottom'">
            <div class="flex flex-col flex-wrap items-baseline h-full">
                <dd
                    v-if="data.change !== undefined"
                    class="text-xs font-medium"
                    :class="{
                        'text-gray-700': data.change == 0,
                        'text-green-500': data.change > 0,
                        'text-red-500': data.change < 0
                    }"
                >{{ pct(data.change) }}</dd>
                <dd class="w-full text-3xl font-medium leading-10 tracking-tight text-gray-900 p-4 flex-grow flex items-center justify-center">
                    <template v-if="meta.editable">
                        <div class="relative">
                            <money
                                v-if="meta.type === 'currency'"
                                v-model="modelValue"
                                class="block w-full"
                                @keydown="handleKeydown"
                            />
                            <percentage
                                key="percentage"
                                v-else-if="meta.type === 'percentage'"
                                v-model="modelValue"
                                class="block w-full"
                                @keydown="handleKeydown"
                            />
                            <inp
                                v-else
                                v-model="modelValue"
                                unstyled
                            />
                            <a
                                v-if="meta.editable.saveable"
                                href="#"
                                class="text-xl absolute top-1/2 -translate-y-1/2 right-3 text-gray-500 hover:text-gray-700"
                                :class="{
                                    'opacity-25 pointer-events-none': !dirty
                                }"
                                @click.prevent="save"
                            >
                                <i class="fad fa-save"></i>
                            </a>
                        </div>
                    </template>
                    <template v-else>
                        {{ format(data.value) }}
                    </template>
                </dd>
                <dt
                    class="text-sm font-medium leading-4 text-gray-800 line-clamp-2 border-t border-gray-600/30 w-full px-4 py-2 flex items-center justify-center"
                    :style="{
                        ...(meta.styles && meta.styles.label ? meta.styles.label : {})
                    }"
                >{{ title }}</dt>
            </div>
        </template>
    </div>
</template>

<style>
</style>
