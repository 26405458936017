<template>
    <div v-if="s && optionsData" @click="closeDropdowns">
        <div>
            <label for="client_id" class="block text-sm leading-5 font-medium text-gray-700" >
                Client
            </label>
            <div class="relative rounded-md shadow-sm mt-1" v-if="!s.id">
                <hgselect
                    ref="client-dropdown"
                    v-model="s.client_id"
                    :options="options.clients"
                />
            </div>
            <div class="relative mt-1 mb-1" v-else>
                {{ options.clients[s.client_id] || "--" }}
            </div>
            <error v-if="errors.client_id">{{
                errors.client_id[0]
            }}</error>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-3">
            <div>
                <label
                    for="name"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Name
                </label>
                <div class="rounded-md shadow-sm">
                    <input
                        name="name"
                        type="text"
                        v-model="s.name"
                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                </div>
                <error v-if="errors.name">{{
                    errors.name[0]
                }}</error>
            </div>
            <div>
                <label
                    for="status"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Status
                </label>
                <div class="relative rounded-md shadow-sm mt-1">
                    <hgselect
                        ref="status-dropdown"
                        v-model="s.status"
                        :options="options.statuses"
                    />
                </div>
                <error v-if="errors.status">{{
                    errors.status[0]
                }}</error>
            </div>
        </div>
        <div class="grid grid-cols-5 gap-4 mt-3">
            <div class="col-span-2">
                <label
                    for="status"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >Amount</label>
                <div class="relative rounded-md shadow-sm mt-1">
                    <money
                        v-model="s.maximum"
                        class="block w-full sm:text-sm sm:leading-5"
                        placeholder="Maximum"
                    />
                </div>
                <error v-if="errors.maximum">{{
                    errors.maximum[0]
                }}</error>
            </div>
            <div class="col-span-2">
                <label
                    for="payment_id"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Attached Payment
                </label>
                <div class="relative rounded-md shadow-sm mt-1">
                    <hgselect
                        v-model="s.payment_id"
                        :options="options.payments"
                    />
                </div>
                <error v-if="errors.payment_id">{{
                    errors.payment_id[0]
                }}</error>
            </div>
            <div>
                <label
                    for="allow_overages"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Allow Overages
                </label>
                <div class="relative mt-2">
                    <toggle v-model="s.allow_overages" />
                </div>
                <error v-if="errors.payment_id">{{
                    errors.payment_id[0]
                }}</error>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-4 mt-3">
            <div>
                <label
                    for="adjustments"
                    class="block text-sm leading-5 font-medium text-gray-700"
                >
                    Adjustments
                </label>
                <div class="grid grid-cols-2 gap-x-4">
                    <template v-for="(adjustment, idx) in s.adjustments" :key='"adjustment-" + idx'>
                        <div>
                            <div class="relative rounded-md shadow-sm mt-1">
                                <money
                                    v-model="adjustment.amount"
                                    class="block w-full sm:text-sm sm:leading-5"
                                    placeholder="Amount"
                                    allow-negative
                                    @blur='checkAdjustment(idx)'
                                />
                            </div>
                        </div>
                        <div>
                            <div class="rounded-md shadow-sm">
                                <input
                                    name="description"
                                    type="text"
                                    v-model="adjustment.desc"
                                    placeholder="Description"
                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    @blur='checkAdjustment(idx)'
                                />
                            </div>
                        </div>
                    </template>
                    <div>
                        <div class="relative rounded-md shadow-sm mt-1">
                            <money
                                v-model="newAdjustment.amount"
                                class="block w-full sm:text-sm sm:leading-5"
                                placeholder="Amount"
                                allow-negative
                                @blur='addAdjustment'
                            />
                        </div>
                    </div>
                    <div>
                        <div class="rounded-md shadow-sm">
                            <input
                                name="description"
                                type="text"
                                v-model="newAdjustment.desc"
                                placeholder="Description"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                @blur='addAdjustment'
                            />
                        </div>
                    </div>
                </div>

                <div class="text-sm mt-2" v-if="lastSprints && lastSprints.length">
                    <span class="font-bold">Previous Work Orders:</span>
                    <ul>
                        <li v-for='sprint in lastSprints' :key='sprint.id'>
                            {{ sprint.name }} &bull;
                            {{ options.statuses[sprint.status] }} &mdash;
                            {{ money(sprint.amount) }}
                            <template v-if="sprint.maximum">
                                <span class="mx-1">/</span>
                                <span class="text-gray-600">{{ money(sprint.maximum) }}</span>
                                <span v-if="sprint.amount > sprint.maximum" class="text-red-800">
                                    ({{ money(sprint.amount - sprint.maximum) }} over)
                                </span>
                            </template>
                        </li>
                    </ul>
                </div>
                <error v-if="errors.adjustments">{{ errors.adjustments[0] }}</error>
            </div>
        </div>
        <div class="text-center mt-6" v-if="!hideButtons">
            <btn
                :loading="saving"
                blue
                @click='save'
            >Save Changes</btn>
            <btn
                class="ml-2"
                :disabled="saving"
                red outline
                @click='$emit("cancel")'
            >Cancel</btn>
        </div>
    </div>
</template>

<script>
import btn from "./btn.vue"
import money from "./money.vue"
import number from "./number.vue"
import toggle from "./toggle.vue"
import hgselect from "./hgselect.vue"
import axios from "axios"
import { clone } from "lodash"

export default {
    name: 'SprintForm',
    props: {
        modelValue: Object,
        errors: {
            type: Object,
            default() {
                return {}
            }
        },
        hideButtons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        btn, hgselect, money, number, toggle
    },
    data() {
        return {
            s: clone(this.modelValue),
            newAdjustment: {
                amount: '',
                desc: ''
            },
            lastSprints: [],
            saving: false,
            deleting: false,
            optionsData: null,
            syncSpin: {}
        }
    },
    computed: {
        options() {
            const options = {
                clients: this.optionsData ? this.optionsData.clients : [],
                payments: this.optionsData ? this.optionsData.payments : [],
                statuses: {
                    active: "Active",
                    finished: "Finished",
                    archived: "Archived"
                }
            }
            return options
        }
    },
    mounted() {
        this.loadOptions()
    },
    methods: {
        checkAdjustment(idx) {
            var adjustment = this.s.adjustments[idx]
            if (!adjustment.amount && !adjustment.desc) {
                this.s.adjustments.splice(idx, 1)
            }
        },
        addAdjustment() {
            if (this.newAdjustment.amount) {
                if (!Array.isArray(this.s.adjustments)) {
                    this.s['adjustments'] = []
                }
                this.s.adjustments.push({
                    amount: this.newAdjustment.amount,
                    desc: this.newAdjustment.desc
                })
                this.newAdjustment.amount = ''
                this.newAdjustment.desc = ''
            }
        },
        money(num) {
            return (
                "$" +
                Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        },
        async loadOptions() {
            const response = await axios.get('/sprints/get/options?client_id=' + this.s.client_id)
            this.optionsData = response.data
        },
        closeDropdowns() {
            const refs = [
                'client-dropdown'
            ]
            refs.forEach((ref) => {
                this.$refs[ref] && this.$refs[ref].close();
            })
        },
        async save() {
            this.saving = true
            let obj = clone(this.s)
            obj._method = "PUT"
            let response = await axios.post(
                this.$route("sprints.update", obj.id),
                obj
            )
            this.saving = false
            this.$emit("saved", response.data)
        }
    },
    watch: {
        async 's.client_id'() {
            this.loadOptions()
            if (this.s.client_id) {
                const response = await axios.get(
                    this.$route("sprints.last", this.s.client_id)
                );
                this.lastSprints = response.data
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
