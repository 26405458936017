<template>
    <Layout :auth='auth'>
        <Head title="PTO Tracker" />
        <template v-slot:title>
            PTO Tracker
        </template>
        <template v-slot:actions>
            <hgselect
                v-model="showPTOFor"
                :options="options.users"
                class="max-w-sm"
            >
                <template v-slot:value-prefix>
                    <span class="font-medium">
                        Show PTO For:
                    </span>
                </template>
            </hgselect>
            <btn
                v-if="showPTOFor == auth.user.id"
                white
                class="ml-4 whitespace-nowrap"
                @click="showScheduleModal = true"
            >Schedule PTO</btn>
        </template>
        <div>
            <div class="mb-6">
                <div class="flex justify-start md:-mx-3 mb-6">
                    <div class="w-full md:w-1/2 lg:w-1/3 md:px-3">
                        <div class="bg-white shadow px-6 p-5 rounded-lg">
                            <dl>
                                <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                                    PTO Used
                                </dt>
                                <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                    {{ stats.used }}
                                    <span class="text-gray-400 mx-1">/</span>
                                    {{ stats.total }} hours
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 md:px-3">
                        <div class="bg-white shadow px-6 p-5 rounded-lg">
                            <dl>
                                <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                                    PTO Scheduled
                                </dt>
                                <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                    {{ stats.scheduled }}
                                    <span class="text-gray-400 mx-1">/</span>
                                    {{ stats.total }} hours
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div
                    class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table v-if="ptos.length" class="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                    Date
                                </th>
                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                    Number of Hours
                                </th>
                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                    Description
                                </th>
                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            <template v-for="year in ptoYears" :key="'pto-' + year">
                                <tr class="bg-gray-100">
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-bold text-black" colspan='4'>
                                        <div class="flex items-center">
                                            {{ year }}
                                            <span class="ml-2 h-px bg-gray-300 w-full" />
                                        </div>
                                    </td>
                                </tr>
                                <tr class="odd:bg-gray-50 hover:bg-gray-100" v-for="pto in ptosByYear[year]" :key="pto.id">
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        {{ formatRange(pto) }}
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        {{ pto.numHours }}
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                        {{ pto.description }}
                                    </td>
                                    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 text-right">
                                        <a
                                            target="_blank"
                                            :href="calendarLink(pto)"
                                            class="text-blue-600 hover:text-blue-800 mr-2"
                                        >Add to Calendar</a>
                                        <a
                                            href="#"
                                            class="text-blue-600 hover:text-blue-800"
                                            @click.prevent="edit(pto)"
                                        >Edit</a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="rounded-md bg-green-50 p-4" v-else>
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <i class="fas fa-check text-green-400"></i>
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                                <p class="text-sm leading-5 text-green-700">
                                    <b>Good news!</b> You haven't scheduled any PTO yet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show='showScheduleModal' @close='showScheduleModal = false' overflowVisible @keypress.stop :closeOnBackgroundClick='false'>
            <div :key="'edit-pto-' + editPTO.id">
                <div class="mb-4">
                    <label for="range" class="block text-sm font-medium leading-5 text-gray-700 mb-1">PTO Date Range</label>
                    <date-range-picker
                        ref="picker"
                        v-model="editPTO.range"
                        :ranges="false"
                        class="w-full"
                        control-container-class=""
                        time-picker
                        :time-picker-increment="30"
                    >
                        <template v-slot:input="picker" style="min-width: 350px;">
                            {{ date(picker.startDate) }} - {{ date(picker.endDate) }}
                        </template>
                    </date-range-picker>
                </div>
                <div class="mb-4">
                    <label for="action" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Description</label>
                    <inp
                        v-model="editPTO.description"
                        multi-line
                        placeholder="Whatcha gonna do?"
                    />
                </div>
                <div class="mb-4" v-if="rangeSpansYears">
                    <div class="rounded-md bg-red-50 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <i class="fas fa-glass-cheers text-red-400"></i>
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                                <p class="text-sm leading-5 text-red-700">
                                    <b>Happy New Year!</b><br>
                                    Sorry, but your time off cannot span the New Year.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center">
                    <btn
                        red
                        outline
                        @click='showScheduleModal = false'
                    >Cancel</btn>
                    <btn
                        v-if='editPTO.id'
                        red
                        @click='removePTO'
                        class='ml-4'
                    >Delete</btn>
                    <div class="flex-grow text-right">
                        <btn
                            v-if='editPTO.id'
                            :disabled="!canSave"
                            :loading="saving"
                            blue
                            @click='update'
                        >Update PTO</btn>
                        <btn
                            v-else
                            :disabled="!canSave"
                            :loading="saving"
                            blue
                            @click='create'
                        >Schedule Time</btn>
                    </div>
                </div>
            </div>
        </modal>
    </Layout>
</template>


<script>
import btn from "../../Common/btn.vue"
import avatar from "../../Common/avatar.vue"
import inp from "../../Common/inp.vue"
import modal from "../../Common/modal.vue"
import Layout from "../../Common/Layout.vue"
import auth from "../../Common/Mixins/auth"
import hgselect from "../../Common/hgselect.vue"
import moment from 'moment';
import axios from "axios"
import { find, clone } from "lodash"
import { Head } from '@inertiajs/vue3'
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: 'PTOIndex',
    mixins: [auth],
    components: {
        btn,
        Layout,
        Head,
        hgselect,
        inp,
        modal,
        avatar
        // DateRangePicker
    },
    props: {
        showingUser: String,
        auth: Object,
        stats: Object,
        users: Array,
        ptos: Array,
    },
    data() {
        let date = moment.tz(this.auth.user.tz).add(7, 'days').startOf('week').hour(8).minute(0).second(0);
        return {
            showPTOFor: this.showingUser,
            showScheduleModal: false,
            saving: false,
            editPTO: {
                id: null,
                range: {
                    startDate: date.toDate(),
                    endDate: date.endOf('week').hour(17).minute(0).second(0).toDate()
                },
                description: ''
            }
        }
    },
    computed: {
        ptoYears() {
            let years = Object.keys(this.ptosByYear);
            years.sort().reverse();
            return years;
        },
        ptosByYear() {
            var years = {};
            this.ptos.forEach(pto => {
                const year = moment.utc(pto.start, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY')
                if (!(year in years)) {
                    years[year] = [];
                }
                years[year].push(pto)
            })
            return years;
        },
        rangeSpansYears() {
            if (this.editPTO.range.startDate && this.editPTO.range.endDate) {
                if (this.editPTO.range.startDate.getYear() !== this.editPTO.range.endDate.getYear()) {
                    return true
                }
            }
            return false
        },
        canSave() {
            if (this.editPTO.range.startDate && this.editPTO.range.endDate) {
                if (this.rangeSpansYears) {
                    return false
                }
                return true
            }
            return false
        },
        options() {
            const options = {
                users: {
                    'all': 'All Users',
                    '--': '---'
                }
            }
            this.users.forEach((user) => {
                options.users[user.id] = user.name
            })
            return options
        }
    },
    methods: {
        date(dt) {
            if (dt) {
                return moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM Do, h:mma')
            }

            return '--'
        },
        user(id) {
            const user = find(this.users, {id})
            return user
        },
        userName(user_id) {
            const user = find(this.users, {id: user_id})
            return user ? user.name : '??'
        },
        formatRange(pto) {
            const start = moment.utc(pto.start, 'YYYY-MM-DD HH:mm:ss').tz(this.auth.user.tz)
            const end = moment.utc(pto.end, 'YYYY-MM-DD HH:mm:ss').tz(this.auth.user.tz)

            if (start.format("YYYY-MM-DD") == end.format("YYYY-MM-DD")) {
                // same day
                return `${start.format('MMM Do, h:mma')} - ${end.format('h:mma')}`
            }

            const startStr = start.format("HH:mm") == '08:00' ? start.format('MMM Do') : start.format('MMM Do, h:mma')
            const endStr = end.format("HH:mm") == '17:00' ? end.format('MMM Do') : end.format('MMM Do, h:mma')

            return `${startStr} - ${endStr}`
        },
        calendarLink(pto) {
            var start = moment(pto.start).toDate().toISOString().replace(/-|:|\.\d\d\d/g,"");
            var end = moment(pto.end).toDate().toISOString().replace(/-|:|\.\d\d\d/g,"");

            var url = 'http://www.google.com/calendar/render'
            var query = {
                action: 'TEMPLATE',
                text: 'PTO - ' + this.userName(pto.user_id),
                details: pto.description,
                dates: `${start}/${end}`
            }
            return `${url}?` + this.serialize(query)
        },
        serialize(obj) {
            let str = []
            for(var p in obj) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }

            return str.join('&')
        },
        edit(pto) {
            this.editPTO.id = pto.id;
            this.editPTO.description = pto.description;
            this.editPTO.range.startDate = moment(pto.start).toDate();
            this.editPTO.range.endDate = moment(pto.end).toDate();
            this.showScheduleModal = true;
        },
        async removePTO() {
            this.saving = true;

            await axios.post(
                this.$route("pto.delete", [this.editPTO.id]),
                {
                    _method: 'DELETE',
                    id: this.editPTO.id
                }
            )

            this.reset()

            this.saving = false;
            this.showScheduleModal = false

            this.$inertia.reload({
                only: ['ptos', 'stats'],
            })
        },
        async update() {
            this.saving = true;
            const pto = clone(this.editPTO)
            pto.description = pto.description.replace("\n", "<br>")
            await axios.post(
                this.$route("pto.update", [pto.id]),
                pto
            )

            this.reset()

            this.saving = false;
            this.showScheduleModal = false

            this.$inertia.reload({
                only: ['ptos', 'stats'],
            })
        },
        async create() {
            if (this.editPTO.range.startDate && this.editPTO.range.endDate) {
                this.saving = true;
                const pto = clone(this.editPTO)
                pto.description = pto.description.replace("\n", "<br>")
                await axios.post(
                    this.$route("pto.store"),
                    pto
                )

                this.reset()

                this.saving = false;
                this.showScheduleModal = false

                this.$inertia.reload({
                    only: ['ptos', 'stats'],
                })
            }
        },
        reset() {
            let date = moment.tz(this.auth.user.tz).add(7, 'days').startOf('week').hour(8).minute(0);
            this.editPTO.id = null
            this.editPTO.range.startDate = date.toDate()
            this.editPTO.range.endDate = date.endOf('week').hour(17).minute(0).toDate()
            this.editPTO.range.description = ''
        }
    },
    watch: {
        showPTOFor() {
            this.$inertia.visit(
                this.$route('pto') + "?for=" + this.showPTOFor,
                {only: ['ptos', 'showingUser', 'stats'],}
            )
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
