<template>
    <span @click.stop="bool = !bool">
        <input
            type="checkbox"
            tabindex="0"
            :aria-checked="bool"
            :checked="bool"
            class="relative inline-block flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
            :class="{
                'bg-blue-800': bool,
                'bg-gray-200': !bool
            }"
        >
    </span>
</template>

<script>
export default {
    props: {
        modelValue: [Boolean, Number]
    },
    data() {
        return {
            bool: !!this.modelValue
        }
    },
    watch: {
        bool() {
            this.$emit("update:modelValue", this.bool ? true : false)
        },
        modelValue() {
            this.bool = !!this.modelValue
        }
    }
}
</script>

<style lang="postcss" scoped></style>
