<script setup>
import { ref, watch, computed } from 'vue'
import monthRange from '../Common/monthRange.vue';

const $emits = defineEmits(['updated'])
const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    },
    meta: {
        type: Object,
        default: () => ({
        })
    },
    settings: {
        type: Object,
        default: () => ({
        })
    }
})

// The End Date is exclusive, so we need to subtract 1 month
// in order for it to show the correct month in the picker
let endDate = $props.data.endDate;
if (endDate) {
    endDate = new Date(endDate + 'T00:00:00');
    endDate.setMonth(endDate.getMonth() - 1);
    endDate = endDate.toISOString().split('T')[0];
}

const modelValue = ref(null);
const selectedMonth = ref([
    $props.data.startDate,
    endDate
])

if ($props.meta.editable) {
    modelValue.value = $props.settings[$props.meta.editable.key]
}

watch(() => selectedMonth, () => {
    // Since the end month is exclusive, we need to add 1 month
    const start = selectedMonth.value[0];
    let end = selectedMonth.value[1];
    if (end) {
        end = new Date(end + 'T00:00:00');
        end.setMonth(end.getMonth() + 1);
        end = end.toISOString().split('T')[0];
    }
    $emits('updated', {
        key: 'dates',
        value: [start, end]
    })
}, { deep: true })

</script>

<template>
    <div>
        <div class="flex flex-wrap items-baseline justify-between items-end gap-x-4 gap-y-2 p-4 h-full">
            <dt class="text-sm font-medium leading-4 text-gray-500 line-clamp-2 min-h-[24px]">{{ title }}</dt>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                <monthRange v-model="selectedMonth" />
            </dd>
        </div>
    </div>
</template>

<style>
</style>
