<template>
    <Layout :auth='auth'>
        <Head :title="'Payment ' + payment.number" />
        <template v-slot:title>
            Payment #{{ payment.number }}
        </template>
        <div>
            <payment-form
                :payment="payment"
                :for-client="payment.client_id"
            />
        </div>
        <div class="py-5 border-b border-gray-200">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                <div class="flex items-center flex-wrap">
                    <span class="flex-grow">Work Orders</span>
                </div>
            </h3>
        </div>
        <div class="mt-5">
            <sprints-table
                :show-client="false"
                :sprints="sprints"
                :clients="clients"
                :projects="projects"
            />
        </div>
    </Layout>
</template>


<script>
import auth from "../../Common/Mixins/auth"
import btn from "../../Common/btn.vue"
import paymentForm from "../../Common/paymentForm.vue"
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import money from "../../Common/money.vue"
import sprintsTable from '../../Common/sprintsTable.vue'
import { Head } from '@inertiajs/vue3'

export default {
    name: 'PaymentShow',
    mixins: [ auth ],
    components: {
        btn,
        paymentForm,
        Layout,
        Head,
        hgselect,
        money,
        sprintsTable
    },
    props: {
        auth: Object,
        payment: Object,
        sprints: Array,
        clients: Array,
        projects: Array,
    },
    data() {
        return {
            createNewPayment: false
        }
    },
    computed: {
        seoExample() {
            const mons = [
                "January", "February", "March", "April", "May",
                "June", "July", "August", "September", "October",
                "November", "December"
            ]
            return "SEO - " + mons[(new Date).getMonth()] + " " + (new Date).getFullYear()
        },
        allDetails() {
            return this.entries
                        .filter((e) => e.description)
                        .map((e) => e.description)
                        .join("\n")
        },
        options() {
            return {
                payments: this.payments,
                statuses: {
                    active: 'Active',
                    finished: 'Finished',
                    paid: 'Paid',
                    archived: 'Archived',
                }
            }
        }
    },
    methods: {
        onCopy() {
            this.copyResponse = true
        },
        onCopyError() {
            this.copyResponse = false
        },
        newPaymentCreated() {
            this.$inertia.reload({
                only: ['payments']
            })
            this.createNewPayment = false
        },
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>

</style>
