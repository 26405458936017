<template>
<div class="flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white">
                <table v-if="actions.length" class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th
                                class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Action
                                <span style="display: none;"><i class="fas fa-caret-up"
                                        aria-hidden="true"></i></span> <span style="display: none;"><i
                                        class="fas fa-caret-down" aria-hidden="true"></i></span>
                            </th>
                            <th
                                class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Assigned To
                                <span style="display: none;"><i class="fas fa-caret-up"
                                        aria-hidden="true"></i></span> <span style="display: none;"><i
                                        class="fas fa-caret-down" aria-hidden="true"></i></span>
                            </th>
                            <th
                                class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Created
                                <span style="display: none;"><i class="fas fa-caret-up"
                                        aria-hidden="true"></i></span> <span style="display: none;"><i
                                        class="fas fa-caret-down" aria-hidden="true"></i></span>
                            </th>
                            <th
                                class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <tr class="even:bg-gray-50" v-for="action in actions" :key="action.id">
                            <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 action-cell">
                                <div v-if="action.notification" v-html="action.notification"></div>
                                <div
                                    class="py-2 text-xs"
                                    :class="{
                                        'mt-1 border-t border-gray-200': action.notification
                                    }"
                                >
                                    <span class="bg-gray-200 px-2 py-1 mr-1">ACTION:</span>
                                    <span v-html="action.action"></span>
                                </div>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                                <a href="#" @click.prevent="activeAction = action" class="flex flex-col gap-2">
                                    <template v-if="action.users && action.users.length > 0">
                                        <template v-for="user in action.users" :key="action.id + '-' + user.id">
                                            <div class="flex items-center">
                                                <avatar v-if="user" :size='6' :user='user' class="mr-1" />
                                                {{ user.name }}
                                            </div>
                                        </template>
                                    </template>
                                    <span v-else class="italic ">No One</span>
                                </a>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                                {{ dt(action.created_at) }}
                                <template v-if="action.created_by">
                                    <div class="text-gray-500 text-xs">
                                        {{ userName(action.created_by) }}
                                    </div>
                                </template>
                            </td>
                            <td class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium">
                                <template v-if="action.completed_at">
                                    <span class="text-green-600">
                                       Completed <span v-html="dt(action.completed_at)"></span> by {{ userName(action.completed_by) }}.
                                    </span>
                                </template>
                                <template v-else-if="actions">
                                    <div class="flex flex-col gap-2">
                                        <div v-for="(a, actionKey) in action.actions" :key="actionKey">
                                            <btn
                                                small
                                                @click="done(action, a)"
                                                :red="a.color == 'red'"
                                                :green="a.color == 'green'"
                                                :blue="a.color == 'blue'"
                                                :orange="a.color == 'orange'"
                                                :purple="a.color == 'purple'"
                                                :yellow="a.color == 'yellow'"
                                            >{{ a.label }}</btn>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <a
                                        href="#"
                                        @click.prevent="done(action)"
                                        class="bg-green-600 text-white hover:bg-green-800 text-center rounded p-1 px-2 inline-block whitespace-nowrap"
                                    >Mark as Done</a>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="rounded-md bg-green-50 p-4" v-else>
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <i class="fas fa-check text-green-400"></i>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm leading-5 text-green-700">
                                <b>Good news!</b> There is nothing here to do.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import avatar from "../../Common/avatar.vue"
import btn from "../../Common/btn.vue"
import auth from "../../Common/Mixins/auth"
import moment from "moment"
import { find } from "lodash"
import axios from "axios"

export default {
    name: 'ActionsIndex',
    mixins: [auth],
    components: {
        avatar,
        btn
    },
    props: {
        showingUser: String,
        auth: Object,
        users: [Object, Array],
        actions: Array,
        onlyDone: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        async done (action, actionToPerform) {
            if (actionToPerform && actionToPerform.confirm) {
                if (!confirm(actionToPerform.confirm)) {
                    return
                }
            }

            const data = { id: action.id }
            if (actionToPerform) {
                data.action = actionToPerform.action
            }

            await axios.post(
                this.$route("actions.done"),
                data
            )

            this.$emit('done', { action })
        },
        dt (value) {
            return moment.utc(value).fromNow();
        },
        user(id) {
            const user = find(this.users, {id})
            return user
        },
        userName(user_id) {
            if (user_id in this.users) {
                if (typeof this.users[user_id] === 'string') {
                    return this.users[user_id]
                }
                return this.users[user_id].name
            }
            const user = find(this.users, {id: user_id})
            return user ? user.name : '??'
        },
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>
    td.action-cell a {
        @apply text-blue-600;
    }
</style>
