<template>
    <div
        ref="input"
        class="relative w-full"
        @click.stop
        @mousedown.stop
        @keydown.delete.stop
        @keydown.enter.stop="selectHighlight"
        @keydown.down.stop="highlightDown"
        @keydown.up.stop="highlightUp"
    >
        <span class="inline-block w-full" :class="{
            'rounded-md shadow-sm': !unstyled
        }">
            <button
                :disabled="disabled"
                @click="open = !open"
                type="button"
                ref="dropdown"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                class="cursor-default relative w-full text-left pl-3 py-2 pr-10 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                :class="{
                    'opacity-50': disabled,
                    'rounded-md border border-gray-300 bg-white': !unstyled
                }"
            >
                <span v-if='loading' class="absolute inline-block">
                    <i class="fal fa-spin fa-spinner-third"></i>
                </span>
                <input
                    v-if="open"
                    ref="queryInput"
                    class="block truncate w-full focus:outline-none bg-transparent z-20 p-0 focus:border-none focus:shadow-none text-sm border-none"
                    :class="{
                        'ml-6': loading
                    }"
                    @keyup.stop.prevent
                    @keyup.enter.exact.stop.prevent='handleEnter'
                    v-model="query"
                    :placeholder="stripTags(display(prettyValue))"
                />
                <span v-else class="flex w-full" :class="{truncate: !multiple}">
                    <slot name="value-prefix" :selected="selected">
                        <span class="inline-block -ml-1"></span>
                    </slot>
                    <slot v-if="!multiple && selected.length" :name="selected[0] + '-value'">
                        <span class="flex flex-col w-full ml-1" v-html="display(prettyValue || placeholder)" />
                    </slot>
                    <span class="flex flex-col w-full ml-1" v-else v-html="display(prettyValue || placeholder)" />
                </span>
                <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                    <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                    >
                        <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </span>
            </button>
        </span>
        <Teleport to="body">
            <div
                class="absolute mt-1 w-full rounded-md bg-white shadow-lg text-sm z-9998"
                :style="{
                    top: position.top ? (position.top + 'px') : null,
                    bottom: position.bottom ? (position.bottom + 'px') : null,
                    left: position.left + 'px',
                    width: position.width + 'px'
                }"
                v-if="open"
            >
                <ul
                    tabindex="-1"
                    ref="list"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-item-3"
                    class="max-h-60 rounded-md pb-1 text-sm leading-5 shadow-xs overflow-auto focus:outline-none"
                >
                    <template v-for="(opt, key) in filteredOptions" :key="key + '-string'">
                        <li
                            v-if="typeof opt == 'string' && opt.match(/^-+$/)"
                            class="border-t border-gray-200 relative"
                            :class="{
                                'mt-3 mb-2': typeof key == 'string' && !key.match(/^-+$/)
                            }"
                        ><span
                            class="absolute text-xs uppercase bg-white text-gray-400 left-3 px-2"
                            style="top: -0.9em;"
                            v-if="typeof key == 'string' && !key.match(/^-+$/)">{{ key }}</span>
                        </li>
                        <li
                            v-else
                            role="option"
                            class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-6 pr-4 text-sm"
                            :class="{
                                'bg-gray-100':
                                    key == highlighted ||
                                    (typeof filteredOptions == 'object' && Object.keys(filteredOptions)[highlighted] === key)
                            }"
                            :ref="
                                key == highlighted ||
                                (typeof filteredOptions == 'object' && Object.keys(filteredOptions)[highlighted] === key)
                                    ? 'highlighted'
                                    : 'items'
                            "
                            :key="key + '-object'"
                            @click.stop="select(key, $event)"
                        >
                            <slot :name="key">
                                <span
                                    class="block truncate"
                                    :class="{
                                        'font-normal': selected.indexOf(key) === -1,
                                        'font-medium': selected.indexOf(key) >= 0
                                    }"
                                    v-html="display(opt)"
                                >
                                </span>
                            </slot>
                            <span
                                v-if="selected.indexOf(key) >= 0"
                                ref="selected"
                                class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                            >
                                <svg
                                    class="h-3 w-3"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </span>
                            <span
                                v-if="selected.indexOf(key) >= 0 && clearable"
                                ref="selected"
                                class="absolute inset-y-0 right-0 flex items-center pr-1.5 text-red-700"
                            >
                                <svg class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                    </template>
                    <li
                        v-if="noFilteredOptions"
                        class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-6 pr-4 text-sm"
                    >
                        <span class="font-medium block truncate" v-if="createNew">
                            Press enter to create
                        </span>
                        <span class="font-medium block truncate" v-else-if="loading">
                            ⏳ Loading...
                        </span>
                        <span class="font-medium block truncate" v-else>
                            🤷‍♂️ Nothing here!
                        </span>
                    </li>
                </ul>
            </div>
        </Teleport>
        <template v-if="open">
            <div class="fixed top-0 left-0 right-0 bottom-0 opacity-0 z-10" @click="open = false">
            </div>
        </template>
    </div>
</template>

<script>
import { nextTick } from "vue"
import {get} from "lodash"

export default {
    name: "HGSelect",
    props: {
        modelValue: [Array, Number, String, Object],
        options: [Object, Array],
        loading: {
            type: Boolean,
            default: false
        },
        objectId: {
            type: String,
            default: null
        },
        objectLabel: {
            type: String,
            default: null
        },
        unstyled: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Pick one...'
        },
        createNew: {
            type: Function,
            default: null
        },
        canClose: {
            type: Function,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        seperateWith: {
            type: String,
            default: ', '
        }
    },
    data: function() {
        return {
            open: false,
            query: "",
            // selected is always an array, if there isn't multiple, we just use the first element
            selected: [],
            highlighted: null,
            position: {
                popUp: false,
                top: 0,
                left: 0,
                width: 0,
            }
        }
    },
    mounted() {
        this.setSelected()
        document.addEventListener("click", this.close)
        document.addEventListener("keydown", this.close)
        // close on scroll or resize
        window.addEventListener("scroll", this.reposition)
        window.addEventListener("resize", this.reposition)
    },
    beforeUnmount() {
        document.removeEventListener("click", this.close)
        document.removeEventListener("keydown", this.close)
        window.removeEventListener("scroll", this.reposition)
        window.removeEventListener("resize", this.reposition)
    },
    methods: {
        reposition() {
            const offset = this.$refs.dropdown.getBoundingClientRect()
            this.position.popUp = window.innerHeight - offset.y < 300
            this.position.left = offset.x - window.scrollX
            this.position.width = offset.width
            if (this.position.popUp) {
                this.position.bottom = window.innerHeight - offset.y - window.scrollY
                this.position.top = null
            } else {
                this.position.top = offset.y + window.scrollY + offset.height
                this.position.bottom = null
            }
        },
        escapeRegex(string) {
            return string ? string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') : string;
        },
        stripTags(str) {
            return typeof str == 'string' ? str.replace(/(<([^>]+)>)/gi, "") : str
        },
        handleEnter() {
            if (this.query && this.createNew) {
                this.createNew(this.query)
                this.query = ''
                this.close()
            }
        },
        setSelected() {
            if (this.modelValue) {
                var selected = []
                var value = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]
                if (Array.isArray(this.options)) {
                    selected = []
                    value.forEach(v => {
                        var index = this.options.indexOf(v)
                        if (index >= 0) {
                            selected.push(index)
                        }
                    })
                } else {
                    selected = value
                }

                // if there are more than one selected, make
                // sure that one of them isn't null
                if (selected.length > 1) {
                    const nullIndex = selected.indexOf(null)
                    if (nullIndex >= 0) {
                        selected.splice(nullIndex, 1)
                    }
                }

                // Do a naive check to make sure we don't set this if it hasn't changed.
                if (JSON.stringify(selected) !== JSON.stringify(this.selected)) {
                    this.selected = selected
                }
            }
        },
        highlightUp() {
            let opts = Array.isArray(this.filteredOptions)
                ? this.filteredOptions
                : Object.keys(this.filteredOptions)

            if (opts) {
                if (this.highlighted == null) {
                    // Set the highlighted to either the last one
                    // or the one after the selected one
                    if (this.selected.length) {
                        const index = opts.indexOf(this.selected[0])
                        if (index >= 0) {
                            this.highlighted = index
                        }
                    }

                    if (!this.highlighted) {
                        this.highlighted = opts.length
                    }
                }

                this.highlighted = this.highlighted - 1
                if (this.highlighted < 0) {
                    this.highlighted = opts.length - 1
                }

                if (
                    this.highlightedOption &&
                    this.highlightedOption.match(/^-+$/)
                ) {
                    this.highlightUp()
                }
            }
        },
        highlightDown() {
            let opts = Array.isArray(this.filteredOptions)
                ? this.filteredOptions
                : Object.keys(this.filteredOptions)

            if (opts) {
                if (this.highlighted == null) {
                    // Set the highlighted to either the last one
                    // or the one after the selected one
                    if (this.selected.length) {
                        const index = opts.indexOf(this.selected[0])
                        if (index >= 0) {
                            this.highlighted = index
                        }
                    }

                    if (!this.highlighted) {
                        this.highlighted = 0
                    }
                }

                this.highlighted = this.highlighted + 1
                if (this.highlighted >= opts.length) {
                    this.highlighted = 0
                }

                if (
                    this.highlightedOption &&
                    this.highlightedOption.match(/^-+$/)
                ) {
                    this.highlightDown()
                }
            }
        },
        selectHighlight() {
            if (this.highlighted !== null) {
                if (Array.isArray(this.filteredOptions)) {
                    this.select(this.highlighted)
                } else {
                    this.select(Object.keys(this.filteredOptions)[
                        this.highlighted
                    ])
                }
            }
        },
        select(key, $event) {
            const dontSelectMultiple = $event && $event.metaKey
            const index = this.selected.indexOf(key)
            if (index >= 0) {
                if (this.multiple && dontSelectMultiple) {
                    this.selected = [key]
                } else if (this.multiple || this.clearable) {
                    this.selected.splice(index, 1)
                }
            } else {
                if (this.multiple && !dontSelectMultiple) {
                    this.selected.push(key)
                } else {
                    this.selected = [key]
                }
            }

            if (!this.multiple) {
                this.close()
            }
        },
        close($event) {
            if ($event && $event.type === "keydown" && $event.key !== "Escape") {
                return
            }
            if (!this.canClose || this.canClose()) {
                this.open = false
            }
        },
        key(val) {
            if (typeof val === 'string') {
                return val
            }
            if (typeof val === 'object') {
                const key = this.objectId || 'id'
                return get(val, key, val)
            }
            return val
        },
        display(val) {
            if (typeof val === 'string') {
                return val
            }
            if (typeof val === 'object') {
                const key = this.objectLabel || 'label'
                return get(val, key, val)
            }
            return val
        }
    },
    computed: {
        highlightedOption() {
            let opts = Array.isArray(this.filteredOptions)
                ? this.filteredOptions
                : Object.keys(this.filteredOptions)
            return this.highlighted !== null ? opts[this.highlighted] : null
        },
        prettyValue() {
            return this.selected.map((selected) => {
                return this.display(selected in this.filteredOptions ? this.filteredOptions[selected] : "")
            }).join(this.seperateWith)
        },
        noFilteredOptions() {
            if (Array.isArray(this.filteredOptions)) {
                return this.filteredOptions.length === 0
            }
            return Object.keys(this.filteredOptions).length === 0
        },
        filteredOptions() {
            if (this.query) {
                const regex = new RegExp(this.escapeRegex(this.query), "i")
                const opts = Array.isArray(this.options) ? [] : {}
                for (var key in this.options) {
                    var val = this.options[key]
                    if (regex.test(val)) {
                        opts[key] = this.options[key]
                    }
                }
                return opts
            }
            return this.options || {}
        }
    },
    watch: {
        query() {
            this.$emit('update', this.query)
        },
        open() {
            this.query = ""
            this.highlighted = null
            if (this.open) {
                this.reposition();
                nextTick(() => {
                    this.$refs.queryInput.focus()
                    if ("selected" in this.$refs) {
                        this.$refs.list.scrollTop = Math.max(
                            0,
                            this.$refs.selected[0].parentNode.offsetTop - 50
                        )
                    }
                })
                this.$emit("open")
            } else {
                this.$emit("close")
            }
        },
        highlighted() {
            if (this.open && this.highlighted) {
                nextTick(() => {
                    if ("highlighted" in this.$refs) {
                        this.$refs.list.scrollTop = Math.max(
                            0,
                            this.$refs.highlighted[0].offsetTop -
                                this.$refs.list.clientHeight / 2 +
                                this.$refs.highlighted[0].clientHeight / 2
                        )
                    }
                })
            }
        },
        modelValue() {
            this.setSelected()
        },
        options: {
            deep: true,
            handler() {
                this.setSelected()
            }
        },
        selected: {
            deep: true,
            handler() {
                if (!this.multiple) {
                    this.open = false
                }

                const selected = this.selected.map(selected => {
                    return Array.isArray(this.options)
                                ? this.options[selected]
                                : selected
                })

                if (selected.length) {
                    this.$emit(
                        "update:modelValue",
                        this.multiple ? selected : selected[0]
                    )
                } else {
                    this.$emit(
                        "update:modelValue",
                        this.multiple ? selected : null
                    )
                }
            }
        }
    }
}
</script>

<style lang="postcss" scoped></style>
