<template>
<div class="flex flex-col">
  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th/>
            <th @click='order("name")' class="w-64 relative whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
              Name
              <span v-show="orderBy[0] == 'name' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'name' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("email")' class=" whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap">
             Email
              <span v-show="orderBy[0] == 'email' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'email' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("phone")' class="w-16 whitespace-nowrap cursor-pointer px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2">
             Phone
              <span v-show="orderBy[0] == 'phone' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'phone' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th @click='order("last_login_at")' class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
              Last Login
              <span v-show="orderBy[0] == 'last_login_at' && orderBy[1] == 'asc'"><i class="fas fa-caret-up"></i></span>
              <span v-show="orderBy[0] == 'last_login_at' && orderBy[1] == 'desc'"><i class="fas fa-caret-down"></i></span>
            </th>
            <th class="px-6 py-3 bg-gray-50" v-if="editable"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <template v-for="(user, idx) in sortedUsers" :key="user.id">
            <tr
                @click.stop.prevent='$emit("user-clicked", user)'
                :class="{
                    'cursor-pointer': true,
                    'bg-gray-50': idx % 2,
                }"
            >
              <td class="pl-4 pr-0">
                <checkbox v-model="selected[user.id]" />
              </td>
              <td
                class="pl-2 pr-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
              >
                {{ user.name }}
              </td>
              <td
                class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
              >
                {{ user.email }}
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-center cursor-pointer">
                <a
                  v-if="user.phone"
                  :href="'tel:' + user.phone"
                  class="hover:underline"
                >{{ user.phone }}</a>
                <span v-else>--</span>
              </td>
              <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                <span v-if='user.last_login_at'>{{ datetime(user.last_login_at) }}</span>
                <span v-else>Never</span>
              </td>
              <td
                class="px-6 py-3 whitespace-nowrap text-right text-sm leading-5 font-medium"
                v-if="editable"
              >
                <Link
                  :href="$route('users.show', user.id)"
                  class="text-blue-700 hover:text-blue-900"
                >Details</Link>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <modal size='2xl' :show="!!editUser" @close='editUser = null' :close-on-background-click='true'>
    <user-form
        v-if="editUser"
        :key="editUser.id"
        v-model="editUser"
        @saved='userUpdated'
        @cancel='editUser = null'
    />
  </modal>
</div>

</template>

<script>
import hgselect from "../Common/hgselect.vue"
import btn from "../Common/btn.vue"
import inp from "../Common/inp.vue"
import modal from "../Common/modal.vue"
import checkbox from "../Common/checkbox.vue"
import userForm from "./userForm.vue"
import moment from "moment"
import { findIndex, orderBy } from "lodash"
import { Link } from '@inertiajs/vue3'

export default {
  name: 'UsersTable',
  components: {
    btn, inp, modal, hgselect, checkbox, userForm, Link
  },
  props: {
    client: Object,
    users: [Array, Object],
    editable: {
      type: Boolean,
      default: false
    },
  },
  data () {
      return {
          orderBy: ["created_at", "desc"],
          selected: {},
          editUser: null
      }
  },
  methods: {
      sprintUpdated(user) {
        let idx = findIndex(this.users, {id: user.id})
        this.users[idx] =user
        this.editUser = null
        this.$emit("saved", user)
        this.$emit('reload')
      },
      order(key) {
          if (this.orderBy[0] == key) {
              if (this.orderBy[1] == 'asc') {
                this.orderBy = [key, 'desc']
              } else {
                this.orderBy = [null, null]
              }
          } else {
              this.orderBy = [key, 'asc']
          }
      },
      datetime(dt) {
        let date = moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local()
        if (date.format('YYYY') == moment.utc().local().format('YYYY')) {
            return date.format('MMM Do, h:mma')
        }
        return date.format('MMM Do YYYY, h:mma')
      },
  },
  computed: {
    sortedUsers () {
        let users = this.users
        if (this.orderBy[0]) {
            users = orderBy(users, this.orderBy[0], this.orderBy[1])
        }
        return users
    }
  }
}
</script>

<style lang="postcss" scoped>
</style>
