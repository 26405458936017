<template>
    <Layout :auth='auth'>
        <Head :title="sprint.name + ' Work Order'" />
        <template v-slot:title>
            {{ sprint.name }} Work Order
            <span class="text-gray-500">&mdash;</span>
            <Link
                :href="'/sprints/client/' + client.id"
                class="text-gray-300 hover:underline"
            >{{ client.name }}</Link>
        </template>
         <template v-slot:actions>
             <btn
                red
                outline
                class="mr-2"
                :loading="deleting"
                @click='deleteSprint'
            >
                Delete Work Order
            </btn>
             <btn
                white
                outline
                class="mr-2"
                v-clipboard:copy="allDetails"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
            >
                <i class="fal fa-check text-green-200 mr-2" v-if="copyResponse === true"></i>
                <i class="fal fa-times text-red-200 mr-2" v-else-if="copyResponse === false"></i>
                Copy Details
            </btn>
            <btn
                white
                :loading="saving"
                @click="save"
            >Save Changes</btn>
        </template>
        <div>
            <div class="w-full mb-6">
                <div>
                    <div class="grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div>
                            <div class="px-2 py-1">
                                <dl>
                                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                                        Work Order Name
                                    </dt>
                                    <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                        <input
                                            type="text"
                                            v-model="sprint.name"
                                            class="block w-full sm:text-sm sm:leading-5"
                                            placeholder="Work Order Name"
                                        />
                                    </dd>
                                </dl>
                                <dl class="mt-4">
                                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                                        Status
                                    </dt>
                                    <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                        <hgselect
                                            v-model="sprint.status"
                                            :options="options.statuses"
                                        />
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div>
                            <div class="px-2 py-1">
                                <dl>
                                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                                        Amount
                                    </dt>
                                    <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                        <money
                                            v-model="sprint.maximum"
                                            class="block w-full sm:text-sm sm:leading-5"
                                            placeholder="Maximum"
                                        />
                                    </dd>
                                </dl>
                                <dl class="mt-4">
                                    <label
                                        for="adjustments"
                                        class="block text-sm leading-5 font-medium text-gray-700"
                                    >
                                        Adjustments
                                    </label>
                                    <div class="grid grid-cols-2 gap-x-4">
                                        <template v-for="(adjustment, idx) in sprint.adjustments" :key='"adjustment-" + idx'>
                                            <div>
                                                <div class="relative rounded-md shadow-sm mt-1">
                                                    <money
                                                        v-model="adjustment.amount"
                                                        class="block w-full sm:text-sm sm:leading-5"
                                                        placeholder="Amount"
                                                        allow-negative
                                                        @blur='checkAdjustment(idx)'
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div class="rounded-md shadow-sm">
                                                    <input
                                                        name="description"
                                                        type="text"
                                                        v-model="adjustment.desc"
                                                        placeholder="Description"
                                                        class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                        @blur='checkAdjustment(idx)'
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <div>
                                            <div class="relative rounded-md shadow-sm mt-1">
                                                <money
                                                    v-model="newAdjustment.amount"
                                                    class="block w-full sm:text-sm sm:leading-5"
                                                    placeholder="Amount"
                                                    allow-negative
                                                    @blur='addAdjustment'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="rounded-md shadow-sm">
                                                <input
                                                    name="description"
                                                    type="text"
                                                    v-model="newAdjustment.desc"
                                                    placeholder="Description"
                                                    class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    @blur='addAdjustment'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div>
                            <div class="px-2 py-1">
                                <dl>
                                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate flex justify-between">
                                        Attached Payment
                                        <a href="#" class="text-xs text-blue-800" @click.prevent='createNewPayment = true'>Create New Payment</a>
                                    </dt>
                                    <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                                        <hgselect
                                            :disabled="!hasPermission('manage:billing')"
                                            v-model="sprint.payment_id"
                                            :options="options.payments"
                                        />
                                    </dd>
                                </dl>
                                <dl class="mt-4">
                                    <dt class="text-sm leading-5 font-medium text-gray-500 truncate flex justify-between">
                                        Allow Overages
                                    </dt>
                                    <dd class="mt-2">
                                        <toggle
                                            :disabled="!hasPermission('manage:billing')"
                                            v-model="sprint.allow_overages"
                                        />
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-6">
                <entriesTable
                    :entries='entries'
                    :types='types'
                    show-week
                    show-type
                    :total-adjustment='totalAdjustments(sprint)'
                />
            </div>
        </div>
        <modal :show='createNewPayment'>
            <payment-form
                v-if="createNewPayment"
                :payment="null"
                :for-client="client.id"
                @saved='newPaymentCreated'
                @cancel='createNewPayment = false'
            />
        </modal>
    </Layout>
</template>


<script>
import auth from "../../Common/Mixins/auth"
import modal from "../../Common/modal.vue"
import btn from "../../Common/btn.vue"
import toggle from "../../Common/toggle.vue"
import paymentForm from "../../Common/paymentForm.vue"
import entriesTable from "../../Common/entriesTable.vue"
import { Head, Link } from '@inertiajs/vue3'
import Layout from "../../Common/Layout.vue"
import hgselect from "../../Common/hgselect.vue"
import money from "../../Common/money.vue"
import axios from "axios"
import { clone } from "lodash"

export default {
    name: 'SprintShow',
    mixins: [ auth ],
    components: {
        btn,
        modal,
        toggle,
        paymentForm,
        entriesTable,
        Layout,
        Head,
        Link,
        hgselect,
        money
    },
    props: {
        auth: Object,
        sprint: Object,
        client: Object,
        entries: Array,
        types: Array,
        payments: [Array, Object],
    },
    data() {
        return {
            deleting: false,
            saving: false,
            copyResponse: null,
            createNewPayment: false,
            newAdjustment: {
                amount: '',
                desc: ''
            }
        }
    },
    computed: {
        seoExample() {
            const mons = [
                "January", "February", "March", "April", "May",
                "June", "July", "August", "September", "October",
                "November", "December"
            ]
            return "SEO - " + mons[(new Date).getMonth()] + " " + (new Date).getFullYear()
        },
        allDetails() {
            return this.entries
                        .filter((e) => e.description)
                        .map((e) => e.description)
                        .join("\n")
        },
        options() {
            return {
                payments: this.payments,
                statuses: {
                    active: 'Active',
                    finished: 'Finished',
                    paid: 'Paid',
                    archived: 'Archived',
                }
            }
        }
    },
    methods: {
        totalAdjustments(sprint) {
            return (sprint.adjustments || []).reduce(function(sum, adjustment) {
                return sum + adjustment.amount
            }, 0)
        },
        checkAdjustment(idx) {
            var adjustment = this.sprint.adjustments[idx]
            if (!adjustment.amount && !adjustment.desc) {
                this.sprint.adjustments.splice(idx, 1)
            }
        },
        addAdjustment() {
            if (this.newAdjustment.amount) {
                if (!Array.isArray(this.sprint.adjustments)) {
                    this.sprint['adjustments'] = []
                }
                this.sprint.adjustments.push({
                    amount: this.newAdjustment.amount,
                    desc: this.newAdjustment.desc
                })
                this.newAdjustment.amount = ''
                this.newAdjustment.desc = ''
            }
        },
        toggleRecurs() {
            if (this.sprint.recurring) {
                this.sprint.recurring.recurs = !this.sprint.recurring.recurs
            } else {
                this.sprint['recurring'] = { recurs: true, template: '' }
            }
        },
        onCopy() {
            this.copyResponse = true
        },
        onCopyError() {
            this.copyResponse = false
        },
        async deleteSprint() {
            if (confirm("Are you sure you want to delete this work order? Any attached entries will be unattached.")) {
                this.deleting = true
                await axios.delete(this.$route("sprints.destroy", this.sprint.id))
                this.deleting = false
            }
        },
        newPaymentCreated() {
            this.$inertia.reload({
                only: ['payments']
            })
            this.createNewPayment = false
        },
        async save() {
            this.saving = true
            let obj = clone(this.sprint)
            obj._method = "PUT"
            await axios.post(
                this.$route("sprints.update", obj.id),
                obj
            )
            this.saving = false
        }
    },
    watch: {
    }
}
</script>

<style lang="postcss" scoped>

</style>
