<template>
<div>
    <div class="relative inline-block text-left">
        <div>
            <button @click.stop="open = true" class="flex items-center justify-center focus:outline-none"
                aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                <span class="leading-none">More</span>
                <i class="fas fa-caret-down ml-1"></i>
            </button>
        </div>

        <transition
            enter-class="'transition ease-out duration-100'"
            enter-active-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-class="transition ease-in duration-75"
            leave-active-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-if="open"
                class="origin-top-right absolute left-0 mt-3 -ml-2 w-56 rounded-md shadow-lg z-50"
            >
                <div class="rounded-md bg-white shadow-xs">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#"
                            @click.prevent='checkTrello'
                            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem">Add From Trello</a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
import axios from "axios"
export default {
    name: 'DashboardDropdown',
    data () {
        return {
            eventHandler: null,
            open: false,
        }
    },
    mounted() {
        this.eventHandler = document.addEventListener('click', () => {
            this.open = false
        })
    },
    beforeUnmount() {
        if (this.eventHandler) {
            document.removeEventListener(this.eventHandler)
        }
    },
    methods: {
        async checkTrello() {
            this.$emit('checkingTrello')
            const { data } = await axios.get(this.$route("trello.check"))
            if (data.result) {
                this.$emit('loadTrello')
            } else {
                window.location = '/trello';
            }
        }
    }
}
</script>

<style lang="postcss" scoped>

</style>
