<script setup>
import { ref, watch } from "vue";
import { Bar } from "vue-chartjs";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js";

import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);


const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    },
    meta: {
        type: Object,
        default: () => ({
        })
    },
    settings: {
        type: Object,
        default: () => ({
        })
    }
})

let chartData = ref({
    labels: [],
    datasets: {}
});

const chartKey = ref((new Date).getTime());

const colors = [
    // '#78a65a',
    '#00bc86',
    '#5182e8',
    '#F59E0B',
    '#EF4444',
    '#8B5CF6',
    '#6B7280',
    '#F472B6',
    '#34D399',
    '#FCD34D',
    '#F87171',
    '#818CF8',
    '#A5B4FC',
    '#D1FAE5',
    '#FEEBC8',
    '#FED7D7',
    '#F9A8D4',
    '#FECDD3',
    '#FEE2E2',
    '#FED7E2',
]

const evaluate = (value) => {
    if (typeof value === 'string') {
        if (value[0] == '=') {
            // We have a formula - lovely
            value.replace(/\$(.+?)\b/g, (match, key) => {
                value = value.replace(match, $props.settings[key])
            })
            value = eval(value.slice(1))
        }
    }
    return value
}

const getDataSets = () => {
    let labels = [];
    let datasets = {};
    let colorCounter = 0;
    Object.keys($props.data).forEach((label) => {
        labels.push(label);
        Object.keys($props.data[label]).forEach((key) => {
            if (!(key in datasets)) {
                const color = colors[colorCounter++];
                datasets[key] = {
                    label: key,
                    data: [],
                    borderColor: color,
                    backgroundColor: color
                };
            }
            datasets[key].data.push(evaluate($props.data[label][key]));
        });
    });
    return {
        labels,
        datasets: Object.values(datasets)
    }
}

chartData.value = getDataSets();

watch($props.settings, () => {
    chartData.value = getDataSets();
});

const options = {
    scales: {
        y: {
            stacked: 'stacked' in $props.meta ? $props.meta.stacked : false,
            display: true,
            ...(
                $props.meta.money ? {
                    ticks: {
                        callback: function(value) {
                            return '$' + Number(value).toLocaleString();
                        }
                    }
                } : {}
            ),
        },
        x: {
            stacked: 'stacked' in $props.meta ? $props.meta.stacked : false,
            display: true
        }
    },
    interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false
    },
    plugins: {
        datalabels: {
            color: '#FFFFFF',
            formatter: function(value) {
                if ($props.meta.money) {
                    return '$' + Number(value).toLocaleString();
                } else if (value) {
                    return value;
                }
                return '';
            }
        },
        legend: {
            display: 'legend' in $props.meta ? $props.meta.legend : true,
            position: 'top',
            align: 'end',
        },
        tooltip: {
            mode: "index",
            callbacks: {
                ...(
                    $props.meta.money ? {
                        label: function(context) {
                            return '$' + Number(context.raw).toLocaleString();
                        }
                    } : {}
                )
            },
        },
        title: {
            display: true,
            text: $props.title,
            align: 'start',
            padding: {
                top: 0,
                bottom: 15,//  ('legend' in $props.meta ? $props.meta.legend : true) ? -25 : 10,
            },
            color: '#2a2a2a',
            font: {
                size: 14,
                weight: 500,
                lineHeight: 1.5
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false
};
</script>

<template>
    <section class="flex flex-col">
        <div class="flow-root border bg-white px-4 pt-2 flex-grow">
          <Bar :data="chartData" :options="options" />
        </div>
    </section>
</template>

<style>
canvas {
  height: 100% !important;
  max-height: 100% !important;
  max-width: 100% !important;
}
</style>
