<script setup>
import { reactive, computed, watch } from 'vue'

const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    meta: {
        type: Object,
        default: () => ({
        })
    },
    data: {
        type: Array,
        default: () => ([])
    }
})

const $state = reactive({
    sortBy: [$props.meta.headers[0], 'asc'],
    perPage: 10,
    page: 1,
    collapsible: 'collapsible' in $props.meta ? $props.meta.collapsible : false,
    collapsed: 'collapsed' in $props.meta ? $props.meta.collapsed : false,
})

watch(() => $props.data, () => {
    $state.page = 1
})
watch(() => $state.sortBy, () => {
    $state.page = 1
})

const $sortedData = computed(() => {
    return $props.data.sort((a, b) => {
        const [key, direction] = $state.sortBy
        const modifier = direction === 'asc' ? 1 : -1
        if (typeof a[key] === 'string' && !a[key].match(/^\d+$/)) {
            return modifier * a[key].localeCompare(b[key])
        }
        return modifier * (a[key] - b[key])
    }).slice(($state.page - 1) * $state.perPage, $state.page * $state.perPage)
})
</script>

<template>
    <section class="flex flex-col">
        <h1
            class="text-base font-semibold leading-6 text-gray-900 mb-4"
            :class="{
                'cursor-pointer': $state.collapsible,
            }"
            @click.stop.prevent="$state.collapsed = !$state.collapsed"
        >
            <template v-if="$state.collapsible">
                <i
                    class="fas fa-angle-down cursor-pointer transition-transform duration-200"
                    :class="{
                        'rotate-0': !$state.collapsed,
                        '-rotate-90': $state.collapsed,
                    }"
                ></i>
            </template>
            {{ title }}
        </h1>
        <div class="flow-root border shadow flex-grow" v-if="!$state.collapsible || !$state.collapsed">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" >
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <template v-for="(value, key) in meta.headers">
                                    <th
                                        scope="col"
                                        class="whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                        :class="{
                                            'w-1': value.shrink,
                                        }"
                                        @click="() => $state.sortBy = [key, $state.sortBy[1] === 'asc' ? 'desc' : 'asc']"
                                    >
                                        <div
                                            class="flex items-center gap-x-2"
                                            :class="{
                                                'justify-start': value.align === 'left',
                                                'justify-center': value.align === 'center',
                                                'justify-end': value.align === 'right',
                                            }"
                                        >
                                            {{ value.label }}
                                            <i
                                                v-if="$state.sortBy[0] === key"
                                                :class="{
                                                    'fas fa-caret-up': $state.sortBy[1] === 'asc',
                                                    'fas fa-caret-down': $state.sortBy[1] === 'desc',
                                                }"
                                            ></i>
                                        </div>
                                    </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <template v-for="(item, index) in $sortedData" :key="index">
                                <tr>
                                    <template v-for="(value, key) in meta.headers" :key="index+'-'+key">
                                        <td
                                            class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500"
                                            :class="{
                                                'text-left': value.align === 'left',
                                                'text-center': value.align === 'center',
                                                'text-right': value.align === 'right',
                                                'font-mono': value.mono,
                                                'w-1': value.shrink,
                                            }"
                                        >
                                            {{ item[key] }}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <nav class="bg-white text-right text-sm p-2 flex items-center justify-end gap-2">
                <span>
                    <span class="text-sm text-gray-700">
                        Showing
                        <span class="font-medium">{{ ($state.page - 1) * $state.perPage + 1 }}</span>
                        to
                        <span class="font-medium">{{ Math.min($state.page * $state.perPage, $props.data.length) }}</span>
                        of
                        <span class="font-medium">{{ $props.data.length }}</span>
                    </span>
                </span>
                <a
                    href="#"
                    @click.stop.prevent="$state.page -= 1"
                    class="text-base"
                    :class="{
                        'pointer-events-none text-gray-400': $state.page === 1,
                    }"
                >
                    <i class="fa fa-angle-left"></i>
                </a>
                <a
                    href="#"
                    @click.stop.prevent="$state.page += 1"
                    class="text-base"
                    :class="{
                        'pointer-events-none text-gray-400': $state.page * $state.perPage >= $props.data.length,
                    }"
                >
                    <i class="fa fa-angle-right"></i>
                </a>
            </nav>
        </div>
    </section>
</template>

<style></style>
