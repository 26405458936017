<template>
  <section class="bg-white p-4 rounded-lg shadow-md">
    <a :href="$route('projects.show', project.id)">
      <h3 class="text-lg font-semibold flex items-start gap-4">
        <img :src="project.favicon" class="w-6 h-6 inline-block" v-if="project.favicon" />
        <span v-else class="w-6 h-6 inline-block bg-green-600 rounded-full"></span>
        <div class="flex flex-col gap-1">
          {{ project.name }}
          <div class="text-sm text-gray-500" v-if="project.domains && project.domains.length > 0">
            {{ project.domains.join(', ') }}
          </div>
          <ul class="flex flex-wrap gap-2 text-sm text-gray-500">
            <li
              v-for="service in project.services"
              :key="service.id"
              class="rounded-md px-2 py-1 text-xs"
              :class="{
                'bg-[#FFD700]': service.level == 'Gold',
                'bg-[#C0C0C0]': service.level == 'Silver',
                'bg-[#bc7f40] text-white': service.level == 'Bronze',
                'bg-[#EEE]': !(['Gold', 'Silver', 'Bronze'].includes(service.level)),
              }"
            >
              {{ service.name }}
            </li>
          </ul>
        </div>
      </h3>
    </a>
    <div class="flex gap-2 border-t pt-4 mt-4">
        <Link
            v-if="project.ga4_property_id || project.sem_rush_project_id"
            :href="'/analytics/' + project.id"
            class="text-xs text-gray-500 hover:bg-gray-100 rounded-md px-2 py-1"
        >View Site Analytics</Link>
        <Link
            v-if="project.ga4_property_id"
            :href="'/case-study/' + project.id"
            class="text-xs text-gray-500 hover:bg-gray-100 rounded-md px-2 py-1"
        >View Case Study</Link>
    </div>
  </section>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
  name: 'ProjectCard',
  components: { Link },
  props: {
    client: Object,
    project: Object,
  },
  data () {
      return {
      }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="postcss" scoped>
</style>
