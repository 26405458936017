<template>
    <Layout :auth='auth'>
        <Head title="Actions" />
        <template v-slot:title>
            Actions
        </template>
        <template v-slot:actions>
            <hgselect
                v-if="hasPermission('manage:actions')"
                v-model="showTasksFor"
                :options="options.users"
                class="max-w-sm"
            >
                <template v-slot:value-prefix>
                    <span class="font-medium">
                        Show Tasks For:
                    </span>
                </template>
            </hgselect>
            <btn
                white
                class="ml-4 whitespace-nowrap"
                @click="createAction = true"
            >Create Action</btn>
        </template>
        <div>
            <div class="mb-6">
                <h2 class="font-medium text-lg ml-1 mb-3 flex items-center">
                    <span v-if="!onlyDone">Actions</span>
                    <span v-else-if="onlyDone">Completed Actions</span>
                    &mdash;
                    <ul class="ml-2 inline-flex text-sm">
                        <li v-if="onlyDone" class="mx-2">
                            <Link :href="'/actions?for=' + showingUser" class="text-blue-800">Only Uncompleted</Link>
                        </li>
                        <li v-if="!onlyDone" class="mx-2">
                            <Link :href="'/actions?done&for=' + showingUser" class="text-blue-800">Only Completed</Link>
                        </li>
                    </ul>
                </h2>
                <List :actions="actions" :users="users" @done="done" />
            </div>
        </div>
        <modal :show='activeAction != null' @close='activeAction = null' overflowVisible @keypress.stop :closeOnBackgroundClick='false'>
            <nav v-if="activeAction">
                <a
                    v-for="(user, idx) in users"
                    :key="user.id"
                    href="#"
                    @click.prevent="activeAction.user_ids = [user.id]; saveActiveAction()"
                    class="group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150"
                    :class="{
                        'bg-green-200': activeAction.user_id.indexOf(user.id) >= 0,
                        'mt-1': idx > 0,
                    }"
                >
                    <i class="fal fa-check mr-2" v-if="activeAction.user_ids.indexOf(user.id) >= 0"></i>
                    <span class="truncate">
                        {{ user.name }}
                    </span>
                </a>
            </nav>
        </modal>
        <modal :show='createAction' @close='createAction = false' overflowVisible @keypress.stop :closeOnBackgroundClick='false'>
            <div class="mb-4">
                <label for="user_ids" class="block text-sm font-medium leading-5 text-gray-700">Assign To</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <hgselect
                        ref="week-dropdown"
                        multiple
                        v-model="newAction.user_ids"
                        :options="options.onlyUsers"
                    />
                </div>
            </div>
            <div class="mb-4">
                <label for="action" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Action Notification</label>
                <inp
                    v-model="newAction.notification"
                    placeholder="Description of what happened"
                />
            </div>
            <div class="mb-4">
                <label for="action" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Call To Action</label>
                <inp
                    v-model="newAction.action"
                    placeholder="Detailed description of what work needs to be done"
                />
            </div>
            <div class="flex justify-center">
                <btn
                    red outline
                    @click='createAction = false'
                >Cancel</btn>
                <div class="flex-grow text-right">
                    <btn
                        :loading="saving"
                        blue
                        @click='create'
                    >Create Action</btn>
                </div>
            </div>
        </modal>
    </Layout>
</template>


<script>
import btn from "../../Common/btn.vue"
import inp from "../../Common/inp.vue"
import modal from "../../Common/modal.vue"
import Layout from "../../Common/Layout.vue"
import auth from "../../Common/Mixins/auth"
import hgselect from "../../Common/hgselect.vue"
import { Head, Link } from '@inertiajs/vue3'
import axios from "axios"
import moment from "moment"
import { find, clone } from "lodash"
import List from "./List.vue"

export default {
    name: 'ActionsIndex',
    mixins: [auth],
    components: {
        btn,
        Layout,
        hgselect,
        inp,
        modal,
        Head,
        Link,
        List
    },
    props: {
        showingUser: String,
        auth: Object,
        users: Array,
        actions: Array,
        onlyDone: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            saving: false,
            showTasksFor: this.showingUser,
            createAction: false,
            activeAction: null,
            newAction: {
                user_ids: [],
                notification: '',
                action: ''
            }
        }
    },
    computed: {
        options() {
            const options = {
                onlyUsers: {},
                users: {
                    'all': 'All Users',
                    'unassigned': 'Unassigned Tasks',
                    '--': '---'
                }
            }
            this.users.forEach((user) => {
                options.users[user.id] = user.name
                options.onlyUsers[user.id] = user.name
            })
            return options
        }
    },
    methods: {
        dt (value) {
            return moment.utc(value).fromNow();
        },
        async done () {
            this.$inertia.reload({
                only: ['actions'],
            })
        },
        async create() {
            if (this.newAction.user_ids.length && this.newAction.action) {
                this.saving = true;
                const action = clone(this.newAction)
                action.action = action.action.replace("\n", "<br>")
                await axios.post(
                    this.$route("actions.store"),
                    action
                )
                this.newAction.action = ''
                this.saving = false;
                this.createAction = false

                this.$inertia.reload({
                    only: ['actions'],
                })
            }
        },
        numberOfNewLinesIn(str) {
            if (str) {
                let matches = str.match(/\n/g)
                if (matches) {
                    return Math.min(matches.length + 1, 5)
                }
            }
            return 1
        },
        user(id) {
            const user = find(this.users, {id})
            return user
        },
        userName(user_id) {
            const user = find(this.users, {id: user_id})
            return user ? user.name : '??'
        },
        async saveActiveAction() {
            const action = {
                _method: 'PUT',
                id: this.activeAction.id,
                user_ids: this.activeAction.user_ids
            }
            await axios.post(
                this.$route("actions.update", { action }),
                action
            )
            this.activeAction = null
        }
    },
    watch: {
        showTasksFor() {
            this.newAction.user_ids = [this.showTasksFor]
            this.$inertia.visit(
                this.$route('actions.index') + "?for=" + this.showTasksFor,
                {only: ['actions', 'showingUser'],}
            )
        }
    }
}
</script>

<style lang="postcss" scoped>
    td.action-cell a {
        @apply text-blue-600;
    }
</style>
