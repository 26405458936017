<template>
    <span @click.stop="bool = !bool">
        <span
            role="checkbox"
            tabindex="0"
            aria-checked="false"
            class="relative inline-block flex-shrink-0 h-6 w-11 mr-2 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
            :class="{
                'bg-blue-800': bool,
                'bg-gray-200': !bool
            }"
        >
            <span
                aria-hidden="true"
                class=" relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
                :class="{
                    'translate-x-5': bool,
                    'translate-x-0': !bool
                }"
            >
                <span
                    class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    :class="{
                        'opacity-0 ease-out duration-100': bool,
                        'opacity-100 ease-in duration-200': !bool
                    }"
                >
                    <svg
                        class="h-3 w-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                    >
                        <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </span>
                <span
                    class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    :class="{
                        'opacity-100 ease-in duration-200': bool,
                        'opacity-0 ease-out duration-100': !bool
                    }"
                >
                    <svg
                        class="h-3 w-3 text-blue-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                    >
                        <path
                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                        />
                    </svg>
                </span>
            </span>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        modelValue: [Boolean, Number]
    },
    data() {
        return {
            bool: !!this.modelValue
        }
    },
    watch: {
        bool() {
            this.$emit("update:modelValue", this.bool ? true : false)
        },
        modelValue() {
            this.bool = !!this.modelValue
        }
    }
}
</script>

<style lang="postcss" scoped></style>
