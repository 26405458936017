<template>
    <input
        ref="input"
        type="text"
        :value="value"
        @keydown="handle($event)"
    />
</template>

<script>
export default {
    props: {
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        modelValue: [Number, String],
        autofocus: {
            type: Boolean,
            default: false
        },
        allowNegative: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            initialized: false,
            value: String(this.modelValue),
        }
    },
    mounted() {
        this.applyMask(this.value) // initial mask
        if (this.autofocus) {
            this.$refs.input.focus()
        }
        this.$nextTick(() => {
            this.initialized = true
        })
    },
    methods: {
        handle($event) {
            const key = $event ? $event.key : '';
            const $input = this.$refs.input

            let caretPosition = $input.selectionStart
            let numberCharactersSelected = $input.selectionEnd - $input.selectionStart

            // characters that should just do the normal thing
            switch(key) {
                case 'Tab':
                case 'Shift':
                case 'ArrowUp':
                case 'ArrowDown':
                case 'ArrowLeft':
                case 'ArrowRight':
                    return true;
            }

            if ($event) {
                $event.preventDefault()
            }

            let value = this.value.split('')
            if (numberCharactersSelected > 0) {
                value.splice(caretPosition, numberCharactersSelected)
            }

            // Remove the prefix and adjust the caret position
            if (this.prefix) {
                value = value.slice(this.prefix.length)
                caretPosition -= this.prefix.length
                if (caretPosition < 0) {
                    caretPosition = 0
                }
            }

            // Remove the suffix and adjust the caret position, if needed
            if (this.suffix) {
                value = value.slice(0, -this.suffix.length)
                if (caretPosition > value.length) {
                    caretPosition = value.length
                }
            }

            // Loop through and remove any commas, adjusting the caret position as needed
            let i = 0
            while (i < value.length) {
                if (value[i] == ',') {
                    value.splice(i, 1)
                    if (i < caretPosition) {
                        caretPosition--
                    }
                } else {
                    i++
                }
            }

            // If we have a number or a decimal point, add it to the value at the caret position
            if (key.match(/^[0-9.]$/)) {
                // if the key is a decimal point, make sure there is only one in the value
                if (key !== '.' || value.indexOf('.') === -1) {
                    value.splice(caretPosition, 0, key)
                    caretPosition++
                }
            } else if(key === 'Backspace') {
                // if the key is backspace, remove the character before the caret
                if (caretPosition > 0) {
                    value.splice(caretPosition - 1, 1)
                    caretPosition--
                }
            } else if(key === 'Delete') {
                // if the key is delete, remove the character after the caret
                if (caretPosition < value.length && numberCharactersSelected === 0) {
                    value.splice(caretPosition, 1)
                }
            }

            value = value.join('')
            if (value == '.') {
                value = '0.'
                caretPosition = 2
            }
            this.applyMask(value, caretPosition)
        },
        applyMask(value, caretPosition = null) {
            // if the number starts with a zero, remove it
            if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
                value = value.slice(1)
                if (caretPosition !== null) {
                    caretPosition--
                }
            }

            // To add commas to the value, while maintaining the caret position
            // we need to know how many commas are going to be added before the caret
            // which we can do with some simple math
            const commasBeforeCaret = Math.floor(Math.max(0, (caretPosition - 1)) / 3)
            caretPosition += commasBeforeCaret
            value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

            // add prefix
            if (this.prefix) {
                const hasPrefix = value.indexOf(this.prefix) === 0
                if (!hasPrefix) {
                    value = this.prefix + value
                    if (caretPosition !== null) {
                        caretPosition += this.prefix.length
                        // if the caret was before the prefix, move it to the end of the prefix
                        if (caretPosition < this.prefix.length) {
                            caretPosition = this.prefix.length
                        }
                    }
                }
            }

            // add suffix
            if (this.suffix) {
                const hasSuffix = value.indexOf(this.suffix) === value.length - this.suffix.length
                if (!hasSuffix) {
                    value = value + this.suffix
                    if (caretPosition !== null) {
                        // if the caret was after the suffix, move it to the end of the value
                        if (caretPosition > value.length - this.suffix.length) {
                            caretPosition = value.length - this.suffix.length
                        }
                    }
                }
            }

            this.value = value

            // Update the caret position
            if (caretPosition !== null) {
                this.$nextTick(() => {
                    this.$refs.input.selectionEnd = caretPosition
                    this.$refs.input.selectionStart = caretPosition
                })
            }
        }
    },
    computed: {
        unmasked() {
            let value = this.value

            if (this.prefix) {
                value = value.replace(this.prefix, '')
            }

            if (this.suffix) {
                value = value.replace(this.suffix, '')
            }

            return value.replace(/,/g, '')
        }
    },
    watch: {
        value () {
            if (this.initialized) {
                this.$emit("update:modelValue", Number(this.unmasked))
            }
        },
        modelValue () {
            if (this.unmasked != this.modelValue) {
                this.initialized = false
                this.value = String(this.modelValue)
                this.applyMask(this.value)
                this.$nextTick(() => {
                    this.initialized = true
                })
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
