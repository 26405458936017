<script>
import ThrobMaskable from "./Maskable.vue"
import ThrobTable from "./Table.vue"
import ThrobPie from "./Pie.vue"
import ThrobLine from "./Line.vue"
import ThrobHeader from "./Header.vue"
import ThrobBar from "./Bar.vue"
import ThrobStatistic from "./Statistic.vue"
import ThrobImage from "./Image.vue"
import ThrobSentence from "./Sentence.vue"
import ThrobDatePicker from "./DatePicker.vue"

export default {
    name: 'ThrobRow',
    emits: ['updated'],
    props: {
        title: {
            type: String,
            default: 'Accordion'
        },
        data: {
            type: Object,
            default: () => ({
            })
        },
        meta: {
            type: Object,
            default: () => ({
            })
        },
        settings: {
            type: Object,
            default: () => ({
            })
        }
    },
    computed: {
        columns() {
            return 'columns' in this.data ? this.data.columns : 12
        },
        gap() {
            return 'gap' in this.data ? this.data.gap : 4
        },
        divide() {
            return 'divide' in this.data ? this.data.divide : 0
        },
        border() {
            return 'border' in this.data ? this.data.border : 0
        },
    },
    components: {
        ThrobMaskable,
        ThrobTable,
        ThrobPie,
        ThrobLine,
        ThrobHeader,
        ThrobBar,
        ThrobStatistic,
        ThrobSentence,
        ThrobDatePicker,
        ThrobImage
    },
    data() {
        return {
            cardKey: (new Date).getTime()
        }
    },
    methods: {
        updated(data) {
            this.$emit('updated', data)
        }
    }
}
</script>

<template>
    <section class="flex flex-col gap-4">
        <section
            class="grid grid-cols-1 items-stretch"
            :class="{
                ['lg:grid-cols-' + columns]: true,
                ['gap-' + gap]: true,
                ['border border-gray-200']: border,
                ['divide-gray-200 divide-y lg:divide-y-0 divide-x-0 lg:divide-x']: divide
            }"
        >
            <component
                v-for="(card, idx) in data.cards"
                :key="'card-' + idx + '-' + cardKey"
                class="flex flex-col"
                :class="{
                    ['col-span-' + card.size.columns]: card.size.columns,
                    ['row-span-' + card.size.rows]: card.size.rows,
                    ['col-start-' + card.size.colStart]: card.size.colStart,
                    ['row-start-' + card.size.rowStart]: card.size.rowStart,
                }"
                :style="{
                    'height': card.size.height ? `${card.size.height}px` : 'auto',
                    ...(card.meta.styles ? card.meta.styles : {})
                }"
                :is="'Throb' + card.class"
                :title="card.title"
                :data="card.data"
                :meta="card.meta"
                :settings="settings"
                @updated="updated"
            />
        </section>
    </section>
</template>

<style>
</style>
