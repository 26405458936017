<template>
    <Layout :auth='auth'>
        <Head :title='(memo.subject && memo.subject.length > 20) ? (memo.subject.substr(0, 15) + "...") : memo.subject' />
        <template v-slot:title>
            Memo
        </template>
        <template v-slot:actions>
            <btn white @click="back()">Back To Activity</btn>
        </template>
        <template v-slot:left-of-container>
            <div class="relative w-full lg:w-64 px-5 py-6 mr-6 rounded-lg shadow-lg bg-gray-50 sm:px-6 mb-8">
                <div class="flex flex-col gap-4">
                    <template v-if="isTeamMember">
                        <div class="flex gap-1">
                            <toggle v-model="m.is_internal" />
                            <label for="is_internal" class="block mt-px text-sm font-medium leading-5 text-gray-700">Is Internal?</label>
                        </div>
                        <div v-if="memo.client_id">
                            <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Client</label>
                            <Link
                                :href="$route('activity', {
                                    client: memo.client_id
                                })"
                                class="flex items-center gap-2 mt-1 text-sm font-medium hover:underline"
                            >
                                <img
                                    v-if="favicon"
                                    :src="favicon"
                                    class="w-5 h-5 mr-1"
                                />
                                <div class="flex flex-col">
                                    <span>{{ memo.client.name }}</span>
                                    <span class="text-xs text-gray-500" v-if="memo.project">{{ memo.project.name }}</span>
                                </div>
                            </Link>
                        </div>
                        <div v-else>
                            <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Client</label>
                            <div class="flex items-center gap-2 mt-1 text-sm">
                                <hgselect
                                    v-model="m.client_id"
                                    :options="options.clients"
                                >
                                    <template #value-prefix="props" >
                                        <img
                                            v-if="props.selected.length >= 1 && props.selected[0] in clientFavicons"
                                            :src="clientFavicons[props.selected[0]]"
                                            class="w-5 h-5 mr-1"
                                        >
                                    </template>
                                </hgselect>
                            </div>
                        </div>
                    </template>
                    <div v-if="memo.type == 'feedback'">
                        <label for="status" class="block text-sm font-medium leading-5 text-gray-700">Status</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <div
                                @click="changeStatus.show = true"
                                class="flex items-center justify-between cursor-pointer relative w-full text-left px-3 py-2 transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-md border border-gray-300 bg-white"
                            >
                                {{ options.statuses[m.status] }}
                                <i class="fal fa-check-square text-gray-400"></i>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <label for="status" class="block text-sm font-medium leading-5 text-gray-700">Status</label>
                        <div class="flex items-center gap-2 mt-1 text-sm">
                            <hgselect
                                v-model="m.status"
                                :options="options.statuses"
                            />
                        </div>
                    </div>
                    <div>
                        <label for="category" class="block text-sm font-medium leading-5 text-gray-700">Category</label>
                        <div class="flex items-center gap-2 mt-1 text-sm">
                            <hgselect
                                v-model="m.category"
                                :options="options.categories"
                            />
                        </div>
                    </div>
                    <div v-if="isTeamMember">
                        <label for="priority" class="block text-sm font-medium leading-5 text-gray-700">Priority</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="m.priority"
                                :options="options.priorities"
                            />
                        </div>
                    </div>
                    <div v-if="isTeamMember">
                        <label for="assigned_team" class="block text-sm font-medium leading-5 text-gray-700">Team</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="m.assigned_team"
                                :options="{
                                    '': 'Unassigned',
                                    ...options.teams
                                }"
                            />
                        </div>
                    </div>
                    <div v-if="isTeamMember">
                        <label for="assigned_to" class="block text-sm font-medium leading-5 text-gray-700">Assigned User</label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <hgselect
                                v-model="m.assigned_to"
                                :options="{
                                    '': 'Unassigned',
                                    ...options.users
                                }"
                            />
                        </div>
                        <a
                            v-if="!m.assigned_to"
                            href="#"
                            @click="assignToMe()"
                            class="inline-block mt-1 text-xs text-blue-600 hover:underline"
                        >Assign To Me</a>
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        <div class="flex flex-col gap-2">
                            <div class="flex items-center gap-2">
                                <i class="fal fa-eye fa-fw"></i>
                                <span
                                    @click="showWatchers = !showWatchers"
                                    :class="{
                                        'hover:underline cursor-pointer': isTeamMember
                                    }"
                                >{{ m.watcher_count }} watcher{{ m.watcher_count === 1 ? '' : 's' }}</span>
                                <a
                                    class="text-xs text-blue-600"
                                    v-if="!isWatching"
                                    href="#"
                                    @click.prevent="watch"
                                >Watch</a>
                                <a
                                    v-else
                                    href="#"
                                    class="text-xs text-blue-600"
                                    @click.prevent="unwatch()"
                                >Stop Watching</a>
                            </div>
                            <div v-if="isTeamMember && showWatchers" class="flex flex-col gap-2 pl-5 text-xs">
                                <template v-for="watcher in m.watchers" :key="watcher.id">
                                    <div class="flex items-center gap-1">
                                        <span
                                            class="text-red-400 hover:text-red-600 cursor-pointer mr-2"
                                            @click="unwatch(watcher.id)"
                                        >
                                            <i class="fa fa-times"></i>
                                        </span>
                                        <avatar :user="watcher" size=3 />
                                        <span
                                            :title="userEmail(watcher)"
                                        >{{ userName(watcher) }}</span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        <i class="fal fa-comments fa-fw"></i>
                        <span>{{ memo.comment_count }} comment{{ memo.comment_count === 1 ? '' : 's' }}</span>
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        <i class="fal fa-tag fa-fw"></i>
                        <span>Ticket #{{ memo.ticket_number }}</span>
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        <i class="fal fa-calendar fa-fw"></i>
                        <tooltip :title="dt(memo.created_at)">
                            Created {{ ago(memo.created_at) }}
                        </tooltip>
                    </div>
                    <template>
                        <template v-if="trelloCardIds && trelloCardIds.length">
                            <div v-for="card in trelloCards" :key="card.id">
                                <trello-card :card="card" />
                            </div>
                        </template>
                        <template v-else-if="createTrelloCard.connected">
                            <a
                                href="#"
                                @click.prevent="createTrelloCard.show = true"
                                class="text-blue-600 hover:underline text-xs"
                                >Create Trello Card</a>
                        </template>
                        <template v-if="createTrelloCard.show">
                            <div class="flex flex-col gap-2">
                                <hgselect
                                    v-model="createTrelloCard.board"
                                    :options="options.trelloBoards"
                                    placeholder="Select Board"
                                    @open="loadTrelloBoards"
                                />
                                <hgselect
                                    v-model="createTrelloCard.list"
                                    :options="options.trelloLists"
                                    placeholder="Select List"
                                />
                                <hgselect
                                    v-model="createTrelloCard.labels"
                                    :options="options.trelloLabels"
                                    placeholder="Select Labels"
                                    multiple
                                />
                                <btn green small @click="createTheTrelloCard" :disabled="!createTrelloCard.board || !createTrelloCard.list">Create Card</btn>
                            </div>
                        </template>
                    </template>
                    <template v-if="relatedMemos && relatedMemos.length">
                        <hr>
                        <div class="flex flex-col">
                            <h4 class="mb-2 text-sm uppercase font-medium text-gray-600">Related Memos</h4>
                            <div class="flex flex-col gap-3">
                                <div v-for="related in relatedMemos" :key="related.id" class="border-b pb-3">
                                    <div class="flex flex-col gap-2 text-sm">
                                        <Link
                                            :href="$route('activity.memo', related.id)"
                                            class="text-blue-600 hover:underline line-clamp-2"
                                        >{{ related.subject }}</Link>
                                        <div class="flex gap-2 items-center">
                                            <span
                                                :class="'inline-flex gap-x-1 items-center font-medium text-' + status(related.status).color"
                                            >
                                                <i
                                                    :class="{
                                                        [status(related.status).color]: true,
                                                        [status(related.status).icon]: true
                                                    }"
                                                ></i>
                                                {{ status(related.status).label }}
                                            </span>
                                            <span
                                                :class="'inline-flex gap-x-1 items-center font-medium text-' + priority(related.priority).color"
                                            >
                                                <i
                                                    :class="{
                                                        [priority(related.priority).color]: true,
                                                        [priority(related.priority).icon]: true
                                                    }"
                                                ></i>
                                                {{ priority(related.priority).label }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-if="m.id">
            <Teleport to="body">
                <div v-if="editorIsOpen" class="fixed inset-0 z-50 relative">
                    <div class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
                    <section class="fixed flex flex-col gap-6 inset-x-10 inset-y-6">
                        <div class="">
                            <div class="flex gap-4 w-full justify-end">
                                <btn
                                    blue
                                    @click="openPublishModal"
                                >Publish</btn>
                                <btn
                                    red
                                    @click="editorIsOpen = false"
                                ><i class="fa fa-times"></i></btn>
                            </div>
                        </div>
                        <div class="h-full bg-gray-400 shadow border bg-opacity-50 backdrop-blur-sm">
                            <iframe
                                ref="editorFrame"
                                :src="frameUrl"
                                class="w-full h-full"
                                @click.stop
                            ></iframe>
                        </div>
                        <form @submit.prevent="post" class="flex items-end gap-4">
                            <div class="w-full">
                                <label for="comment" class="sr-only">Comment</label>
                                <editor
                                    v-model="comment.comment"
                                    id="comment"
                                    name="comment"
                                    @keyup="isTyping"
                                    :placeholder="postVisibility == 'private' ? 'Leave a private internal note' : 'Post a comment'"
                                    :mentionables="mentionablesForPost"
                                    :class="{
                                        'bg-yellow-100 border-yellow-400': postVisibility == 'private',
                                        'bg-white': postVisibility == 'public'
                                    }"
                                />
                            </div>
                            <btn yellow @click='post' v-if="postVisibility == 'private'" class="whitespace-nowrap">
                                Add Private Note
                            </btn>
                            <btn blue @click='post' v-if="postVisibility == 'public'" class="whitespace-nowrap">
                                <template v-if="isTeamMember">
                                    Send Public Reply
                                </template>
                                <template v-else>
                                    Send Reply
                                </template>
                            </btn>
                        </form>
                    </section>
                </div>
            </Teleport>
            <drop-zone @dropped="addAttachments">
                <h1
                    class="flex items-center mb-2 text-3xl font-light text-gray-900"
                    :class="{
                        'bg-white': editing.subject,
                        'cursor-pointer': canEditMemo
                    }"
                    @focus="editing.subject = canEditMemo"
                    @blur="changed($event, 'subject')"
                    @keydown.enter.stop.prevent
                    :contenteditable="canEditMemo"
                >
                    {{ memo.ai_subject ?? memo.subject }}
                    <span
                        title="The client cannot see internal memos and will not be notified about updates."
                        v-if="memo.is_internal"
                        class="px-2 py-1 ml-2 text-xs font-medium text-blue-600 bg-blue-100 rounded-md"
                    >Internal Memo</span>
                    <span
                        v-if="!memo.is_internal && isTeamMember"
                        title="The client can see public memos and will be notified about updates."
                        class="px-2 py-1 ml-2 text-xs font-medium text-red-600 bg-red-100 rounded-md"
                    >Public Memo</span>
                </h1>
                <div v-if="isTeamMember && memo.summary" class="p-2 my-5 text-blue-600 border border-blue-300 rounded-lg bg-blue-50">
                    <i class="fas fa-microchip-ai fa-fw fa-xl"></i>
                    {{  memo.summary }}
                </div>
                <div v-if="memo.meta && memo.meta.url" class="flex items-center gap-1 mb-5">
                    <i class="fal fa-globe fa-fw"></i>
                    <a
                        :href="memo.meta.url"
                        target="_blank"
                        class="text-sm text-blue-600 hover:text-blue-500"
                    >{{ memo.meta.url }}</a>
                    <btn
                        v-if="isTeamMember || true"
                        class="ml-2"
                        outline
                        red
                        small
                        :href="'/activity/' + memo.id + '/editor'"
                    >Open Editor</btn>
                </div>
                <div
                    class="max-w-5xl px-5 py-3 mb-10 prose prose-lg bg-white border border-l-8 border-gray-100 rounded-md shadow border-l-gray-300 whitesp ace-pre-line"
                    :class="{
                        'bg-white': editing.description,
                        'cursor-pointer': canEditMemo
                    }"
                    @focus="editing.description = canEditMemo"
                    @blur="changed($event, 'description')"
                    :contenteditable="canEditMemo"
                    v-html="safe(memo.description)"
                />
                <template v-if="actions && actions.length">
                    <div class="flex items-center justify-between">
                        <h2 class="flex items-center gap-2 my-2 text-lg font-light text-gray-900">
                            <i class="text-base fa fa-list-check"></i>
                            Actions
                        </h2>
                    </div>
                    <div class="mt-2 mb-4">
                        <hr />
                    </div>
                    <div class="mb-4">
                        <ActionsList
                            :actions="actions"
                            :users="options.users"
                            @done="reloadMemo"
                        />
                    </div>
                </template>
                <template v-if="checkLists.length && isTeamMember">
                    <div class="flex items-center justify-between">
                        <h2 class="flex items-center gap-2 my-2 text-lg font-light text-gray-900">
                            <i class="text-base fal fa-check-square"></i>
                            Checklists
                        </h2>
                        <div class="flex items-center gap-2">
                            <btn small yellow @click="openChecklistModal">
                                Add Checklist
                            </btn>
                        </div>
                    </div>
                    <div class="mb-4">
                        <hr />
                    </div>
                    <template v-for="checklist in checkLists" :key="checklist.id">
                        <section class="px-5 py-3 bg-white border border-l-8 border-green-100 rounded-md shadow border-l-green-700 mb-4">
                            <div class="flex items-center justify-between">
                                <h3 class="text-base font-medium text-gray-900">{{ checklist.name }}</h3>
                                <div class="flex items-center gap-2">
                                    <btn small red outline @click="deleteChecklist(checklist)">
                                        Delete
                                    </btn>
                                </div>
                            </div>
                            <div class="my-2">
                                <ul class="flex flex-col gap-2 mb-2">
                                    <li v-for="item in checklist.items" :key="item.id">
                                        <div class="flex items-center gap-2">
                                            <input
                                                type="checkbox"
                                                v-model="item.completed"
                                                @change="updateChecklistItem(checklist, item)"
                                            />
                                            <span :class="{
                                                'text-gray-400 line-through': item.completed
                                            }">{{ item.description }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <template v-if="newChecklistItem.show && newChecklistItem.checklistId == checklist.id">
                                    <div class="flex flex-col gap-2">
                                        <inp
                                            v-model="newChecklistItem.description"
                                            placeholder="Add an item"
                                            ref="addItemInp"
                                            @keyup.enter="addChecklistItem"
                                            @keyup.escape="newChecklistItem.show = false"
                                        />
                                        <div class="flex items-center gap-2">
                                            <btn small gray @click="addChecklistItem()">Add an Item</btn>
                                            <btn small gray @click="newChecklistItem.show = false">Cancel</btn>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <btn small gray outline @click="startNewChecklistItem(checklist)">Add an Item</btn>
                                </template>
                            </div>
                        </section>
                    </template>
                </template>
                <div class="flex items-center justify-between">
                    <h2 class="flex items-center gap-2 my-2 text-lg font-light text-gray-900">
                        <i class="text-base fa fa-list"></i>
                        Activity
                    </h2>
                    <div class="flex items-center gap-2">
                        <btn small yellow @click="openChecklistModal" v-if="checkLists.length == 0 && isTeamMember">
                            Add Checklist
                        </btn>
                        <btn gray small @click="showActivityDetails = !showActivityDetails">
                            <template v-if="showActivityDetails">Hide</template>
                            <template v-else>Show</template>
                            Details
                        </btn>
                    </div>
                </div>
                <div class="mb-4">
                    <hr />
                </div>
                <div class="flex flex-col gap-1">
                    <div class="relative pb-4">
                        <span class="absolute top-5 left-5 -ml-px h-4 w-0.5 bg-gray-200" aria-hidden="true"></span>
                        <div class="relative flex items-start space-x-5">
                            <div class="relative">
                                <img
                                    class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                                    :src="userAvatar(auth.user)"
                                    alt="">
                            </div>
                            <div class="flex-1 min-w-0">
                                <nav class="flex mb-3 space-x-2 items-center" aria-label="Visibilty Tabs" v-if="isTeamMember">
                                    <a
                                        v-if="!memo.is_internal"
                                        href="#"
                                        class="px-4 py-2 text-sm font-bold border rounded-md hover:text-gray-700"
                                        :class="{
                                            'bg-blue-100 text-gray-700 ': postVisibility == 'public',
                                            'bg-white': postVisibility != 'public'
                                        }"
                                        @click.prevent="postVisibility = 'public'"
                                    >Public Reply</a>
                                    <a
                                        href="#"
                                        class="px-4 py-2 text-sm font-bold bg-white border rounded-md hover:text-gray-700"
                                        :class="{
                                            'bg-yellow-100 text-gray-700 border-yellow-400': postVisibility == 'private',
                                        }"
                                        @click.prevent="postVisibility = 'private'"
                                    >Internal Note</a>
                                    <span class="flex -space-x-3">
                                        <img
                                            class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full min-h-8 min-w-8"
                                            v-for="(user, idx) in here"
                                            :key="'user-' + user.id"
                                            :style="{ zIndex: 100 - idx }"
                                            :src="userAvatar(user)"
                                        />
                                    </span>
                                    <div class="text-sm text-gray-400" v-if="Object.keys(typing).length">
                                        {{ Object.keys(typing).join(', ') }} {{ Object.keys(typing).length == 1 ? 'is' : 'are' }} typing{{ typingIndicator.dots }}
                                    </div>
                                </nav>
                                <form @submit.prevent="post">
                                    <div
                                        :class="{
                                            'bg-yellow-100 border-yellow-400': postVisibility == 'private',
                                            'bg-white': postVisibility == 'public'
                                        }"
                                    >
                                        <div
                                            v-if="postVisibility == 'public' && isTeamMember"
                                            class="text-xs text-gray-500 pl-2 border border-gray-300 rounded-t-md pt-2 pb-3 bg-gray-50 flex flex-wrap gap-1 items-center -mb-1"
                                        >
                                            <span class="font-bold">To:</span>
                                            <template v-for="watcher in m.watchers.filter(watcher => watcher.id != auth.user.id)" :key="watcher.id">
                                                <a
                                                    href="#"
                                                    @click.prevent="unwatch(watcher.id)"
                                                    class="ml-1 whitespace-nowrap group hover:text-red-700 hover:underline cursor-pointer relative"
                                                >
                                                    {{ userName(watcher) }} &lt;{{ userEmail(watcher) }}&gt;<span class="group-[:nth-last-child(2)]:hidden">,</span>
                                                    <i class="text-[8px] fa fa-times absolute top-1/2 -translate-y-1/2 left-[-0.45rem] text-red-700 hidden group-hover:inline-block"></i>
                                                </a>
                                            </template>
                                            <div class="flex items-center gap-1 ml-1" v-if="newWatcher.adding">
                                                <input
                                                    class="py-0 px-1 bg-transparent !rounded-none !border-x-0 !border-t-0 placeholder:text-gray-300 w-auto leading-none"
                                                    type="email"
                                                    v-model="newWatcher.email"
                                                    placeholder="joe@email.net"
                                                    autofocus
                                                    @keyup.enter="addWatcher(newWatcher.email)"
                                                />
                                                <a href="#" class="text-green-600 ml-1" @click="addWatcher(newWatcher.email)">
                                                    <i class="fad fa-plus-square"></i>
                                                </a>
                                                <a href="#" class="text-gray-800 hover:text-red-600 ml-1" @click="newWatcher.adding = false">
                                                    <i class="fad fa-times-square"></i>
                                                </a>
                                            </div>
                                            <template v-else>
                                                <a href="#" class="text-gray-800 hover:text-green-600 ml-1" @click="newWatcher.adding = true">
                                                    <i class="fad fa-plus-square"></i>
                                                </a>
                                            </template>
                                        </div>
                                        <editor
                                            :key="'post-' + postVisibility"
                                            v-model="comment.comment"
                                            id="comment"
                                            name="comment"
                                            @keyup="isTyping"
                                            :placeholder="postVisibility == 'private' ? 'Leave a private internal note' : 'Post a comment'"
                                            :mentionables="mentionablesForPost"

                                        />
                                    </div>
                                    <div v-if="comment.attachments.length">
                                        <ul class="flex gap-2 text-xs items-center flex-wrap mt-2">
                                            <li><label for="attachments" class="font-medium leading-5 text-gray-700">Attachments:</label></li>
                                            <li
                                                v-for="(attachment, idx) in comment.attachments"
                                                :key="'attachment-' + attachment.id"
                                            >
                                                <span class="flex items-center gap-1">
                                                    <a :href="attachment.fullUrl" target="_blank" class="text-blue-400 underline">{{ attachment.fileName }}</a>
                                                    <a
                                                        href="#"
                                                        class="text-gray-600 hover:text-red-400"
                                                        @click.prevent="comment.attachments.splice(idx, 1)"
                                                    ><i class="fa fa-times"></i></a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex justify-between mt-3 gap-x-3">
                                        <div class="flex items-center gap-2">
                                            <input
                                                ref="file"
                                                type="file"
                                                class="hidden"
                                                @change="uploadFiles"
                                            />
                                            <btn gray @click="$refs.file.click()">
                                                Add Attachment
                                            </btn>
                                        </div>
                                        <div class="flex items-center gap-2">
                                            <btnGroup v-if="isTeamMember && postVisibility == 'public'">
                                                <btn green outline @click="openMacroModal">
                                                    Use Macro
                                                </btn>
                                                <BtnDropdown green outline v-if="macros.length" width="250">
                                                    <template v-slot:opener>
                                                        <i class="fa fa-angle-down"></i>
                                                    </template>
                                                    <ul>
                                                        <template
                                                            v-for="(macro, key) in macros"
                                                            :key="'macro-' + key"
                                                        >
                                                            <li
                                                                class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-6 pr-4 text-xs truncate whitespace-nowrap"
                                                                @click="useMacro(macro)"
                                                            >
                                                                {{ macro.name }}
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </BtnDropdown>
                                            </btnGroup>
                                            <btnGroup>
                                                <btn yellow @click='post' v-if="postVisibility == 'private'">
                                                    Add Private Note
                                                </btn>
                                                <btn @click='post' v-if="postVisibility == 'public'">
                                                    <template v-if="isTeamMember">
                                                        Send Public Reply
                                                    </template>
                                                    <template v-else>
                                                        Send Reply
                                                    </template>
                                                </btn>
                                                <BtnDropdown
                                                    :yellow="postVisibility == 'private'"
                                                    width="250"
                                                >
                                                    <template v-slot:opener>
                                                        <i class="fa fa-angle-down"></i>
                                                    </template>
                                                    <ul>
                                                        <template
                                                            v-for="(lbl, key) in options.statuses"
                                                            :key="'and-' + key"
                                                        >
                                                            <li
                                                                class="text-gray-900 hover:bg-gray-100 cursor-pointer select-none relative py-2 pl-6 pr-4 text-xs"
                                                                @click="changeStatusWithComment(key)"
                                                            >
                                                                and mark as {{ lbl }}
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </BtnDropdown>
                                            </btnGroup>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <activity
                        v-for='(activity, idx) in allActivities'
                        :key="'activity-' + activity.id"
                        :auth="auth"
                        :memo="memo"
                        :activity="activity"
                        :show-details="showActivityDetails"
                        :usersById="usersById"
                        :userAvatar="userAvatar"
                        :userName="userName"
                        :userNames="options.users"
                        :userEmail="userEmail"
                        :teamName="teamName"
                        @update="updateActivity(activity, idx, $event)"
                        @delete="deleteActivity(activity, idx)"
                        @deleteAttachment="deleteAttachment(activity, idx, $event)"
                    />
                </div>
            </drop-zone>
        </template>
        <template v-else>
            <label for="subject" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Subject</label>
            <inp v-model="m.subject" placeholder="What's this about?" />
            <label for="description" class="block mt-2 mb-1 text-sm font-medium leading-5 text-gray-700">Description</label>
            <inp multi-line v-model="m.description" placeholder="Give me more details" />
            <div class="relative pb-4">
                <span class="absolute top-5 left-5 -ml-px h-4 w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div class="relative flex flex-col items-start space-y-5">
                    <div v-if="comment.attachments.length">
                        <label for="attachments" class="block mt-2 mb-1 text-sm font-medium leading-5 text-gray-700">Attachments:</label>
                        <ul class="flex gap-1 text-xs">
                            <li
                                v-for="(attachment, idx) in comment.attachments"
                                :key="'attachment-' + attachment.id"
                            >
                                <a :href="attachment.fullUrl" target="_blank" class="text-blue-400 underline">{{ attachment.fileName }}</a>
                                <span v-if="idx < comment.attachments.length - 1">,</span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex justify-between w-full mt-3 gap-x-3">
                        <input
                            ref="file"
                            type="file"
                            class="hidden"
                            @change="uploadFiles"
                        />
                        <div class="flex items-center gap-2">
                            <btn gray small @click="$refs.file.click()">
                                Add Attachment
                            </btn>
                        </div>
                        <div class="flex flex-col items-end gap-2">
                            <div class="flex items-center gap-2">
                                <label
                                    @click="m.is_internal = !m.is_internal"
                                    class="flex items-center gap-1 mt-1 text-sm"
                                >
                                    <toggle v-model="m.is_internal" />
                                    <span class="mb-1">Is Internal Ticket?</span>
                                </label>
                                <btn blue small @click='createMemo' :disabled="!canCreateMemo">
                                    Create Feedback
                                </btn>
                            </div>
                        </div>
                    </div>
                    <alert v-if="!canCreateMemo" class="w-full">
                        Please fill out the subject, description, and client fields to create a feedback.
                    </alert>
                </div>
            </div>
        </template>
        <modal
            size='2xl'
            :show="changeStatus.show"
            @close='changeStatus.show = false'
            :close-on-background-click='true'
        >
            <div class="flex flex-col gap-6">
                <div>
                    <label for="status" class="block text-sm font-medium leading-5 text-gray-700 mb-1">Status</label>
                    <div class="relative mt-1 flex">
                        <btn
                            class="w-full whitespace-nowrap"
                            small
                            block
                            v-for="(status, idx) in Object.entries(options.statuses)"
                            :adjustClasses="(cls) => {
                                cls = cls.replace('rounded', '');
                                cls = cls.replace('border', 'border-y');

                                if (idx == 0) {
                                    cls += ' rounded-l-md border-x';
                                } else if (idx == Object.keys(options.statuses).length - 1) {
                                    cls += ' rounded-r-md border-r';
                                } else {
                                    cls += ' border-r';
                                }

                                return cls;
                            }"
                            @click="changeStatus.status = status[0]"
                            :key="'btn-' + status[0]"
                            :gray="changeStatus.status != status[0]"
                            :outline="changeStatus.status != status[0]"
                        >{{ status[1] }}</btn>
                    </div>
                </div>
                <div>
                    <label for="comment" class="block text-sm font-medium leading-5 text-gray-700 mb-1">
                        Comment
                        <span
                            v-if="changeStatus.status == 'resolved' || changeStatus.status == 'reopened'"
                            class="text-xs bg-red-600 text-red-50 px-1 py-[2px] rounded"
                        >Required</span>
                        <span v-show="changeStatus.generating" class="ml-2">
                            <i
                                class="fa fa-spinner-third fa-spin"
                            ></i>
                        </span>
                    </label>
                    <editor
                        v-model="changeStatus.note"
                        id="comment"
                        name="comment"
                        @keyup="isTyping"
                        placeholder="Status change comment..."
                        class="bg-white"
                    />
                </div>
                <btn
                    @click="saveStatus"
                    class="mt-4"
                    :disabled="!canSaveStatus"
                    :loading="changeStatus.saving"
                >Save Status</btn>
            </div>
        </modal>
        <modal
            size='4xl'
            :show="macroModal.show"
            @close='macroModal.show = false'
            :close-on-background-click='true'
        >
            <MacroModal @use="useMacro" />
        </modal>
        <modal
            size='xl'
            :show="checklistModal.show"
            @close='checklistModal.show = false'
            :close-on-background-click='true'
        >
            <div class="flex flex-col gap-4">
                <div>
                    <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Checklist Name</label>
                    <div class="flex items-center gap-2 mt-1 text-sm w-full">
                        <inp v-model="checklistModal.checklist.name" class="w-full" placeholder="Checklist Name" />
                    </div>
                </div>
                <btn green small @click="addChecklist" :disabled="!checklistModal.checklist.name">
                    Add Checklist
                </btn>
            </div>
        </modal>
    </Layout>
</template>

<script>
import btnGroup from "@/Common/btnGroup.vue"
import btn from "@/Common/btn.vue"
import avatar from "@/Common/avatar.vue"
import dropZone from "@/Common/dropZone.vue"
import inp from "@/Common/inp.vue"
import BtnDropdown from "@/Common/btnDropdown.vue"
import toggle from "@/Common/toggle.vue"
import editor from "@/Common/editor.vue"
import modal from "@/Common/modal.vue"
import Layout from "@/Common/Layout.vue"
import auth from "@/Common/Mixins/auth"
import avatarMixin from "@/Common/Mixins/avatar.js"
import tooltip from "@/Common/tooltip.vue"
import hgselect from "@/Common/hgselect.vue"
import activity from "@/Pages/Activity/Common/activity.vue"
import ActionsList from "@/Pages/Actions/List.vue"
import MacroModal from "@/Pages/Activity/Memo/MacroModal.vue"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"
import axios from "axios"
import { get, findIndex } from "lodash"
import { nextTick } from "vue"
import alert from "../../Common/alert.vue"
import sanitizeHtml from 'sanitize-html';
import Cookies from 'js-cookie'
import TrelloCard from "./Memo/TrelloCard.vue"

export default {
    name: 'ShowMemo',
    mixins: [auth, avatarMixin],
    components: {
        activity,
        avatar,
        btn, btnGroup,
        dropZone,
        BtnDropdown,
        editor,
        hgselect,
        inp,
        Head,
        Layout,
        Link,
        ActionsList,
        modal,
        toggle,
        alert,
        tooltip,
        MacroModal,
        TrelloCard
    },
    props: {
        macros: Array,
        auth: Object,
        memo: Object,
        relatedMemos: Array,
        activities: Array,
        clientsById: Object,
        usersById: Object,
        teamsById: Object,
        watching: Boolean,
        actions: Array,
        checklists: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            editorIsOpen: false,
            editing: {
                subject: false,
                description: false
            },
            changeStatus: {
                saving: false,
                generating: false,
                show: false,
                status: this.memo.status,
                note: ''
            },
            macroModal: {
                show: false,
                macros: []
            },
            newWatcher: {
                adding: false,
                email: ''
            },
            showWatchers: false,
            createTrelloCard: {
                connected: false,
                show: false,
                board: null,
                list: null,
                boards: [],
                lists: [],
                allLabels: []
            },
            m: {
                _reloading: false,
                id: this.memo.id,
                client_id: this.memo.client_id,
                subject: this.memo.subject,
                description: this.memo.description,
                status: this.memo.status,
                priority: this.memo.priority,
                assigned_to: this.memo.assigned_to,
                assigned_team: this.memo.assigned_team,
                is_internal: this.memo.is_internal,
                category: this.memo.category,
                watcher_count: this.memo.watcher_count,
                watchers: this.memo.watchers,
                meta: this.memo.meta
            },
            checkLists: this.checklists,
            checklistModal: {
                show: false,
                checklist: {
                    name: ''
                }
            },
            newChecklistItem: {
                show: false,
                checklistId: null,
                description: ''
            },
            showActivityDetails: false,
            allActivities: [
                ...this.activities
            ].reverse(),
            comment: {
                comment: '',
                attachments: []
            },
            isWatching: this.watching,
            postVisibility: (this.auth.user.type === 'team') ? 'private' : 'public',
            here: [],
            typing: {},
            typingIndicator: {
                dots: '',
                interval: null
            },
            userAuthToken: null,
            trelloCards: {}
        }
    },
    computed: {
        favicon() {
            if (this.memo.project && this.memo.project.favicon) {
                return this.memo.project.favicon
            } else if (this.memo.client && this.memo.client.favicon) {
                return this.memo.client.favicon
            }
            return null
        },
        trelloCardIds() {
            return this.allActivities.filter(a => a.type == 'trello_card').map(a => a.details.trello_card_id)
        },
        mentionablesForPost() {
            // If the ticket is internal or the post is private, then only team users can be mentioned
            if (this.m.is_internal || this.postVisibility == 'private') {
                return this.options.teamUsers
            }

            // Otherwise, no users can be mentioned
            return {}
        },
        frameUrl() {
            if (this.memo.meta && this.memo.meta.url) {
                const url = new URL(this.memo.meta.url);
                let host = url.origin;
                let path = url.pathname;
                let redirect = `${host}/mosaic/editor?mode=inline&path=${encodeURIComponent(path)}`;
                let token = this.userAuthToken;
                return `${host}/api/auth/relay?token=${token}&redirect=${encodeURIComponent(redirect)}`;
            }

            return null;
        },
        priorityLabels() {
            return {
                1: 'None',
                2: 'Low',
                3: 'Medium',
                4: 'High',
                5: 'Urgent'
            }
        },
        colors() {
            return {
                priorities: {
                    1: '#D5ECF4',
                    2: '#BBD8A0',
                    3: '#FFEF80',
                    4: '#FFD480',
                    5: '#FF9F80',
                },
                statuses: {
                    "new": "#CEAADD",
                    "in-progress": "#95CCF1",
                    "need-more-information": "#FFCF83",
                    "waiting-on-client": "#FEE581",
                    "resolved": "#93E9B7",
                    "reopened": "#FF9F80"
                }
            }
        },
        canSaveStatus() {
            return this.changeStatus.status != this.memo.status && (this.changeStatus.status != 'resolved' || this.changeStatus.note)
        },
        canEditMemo() {
            const createdBy = get(this.memo, 'created_by.id');
            return createdBy && createdBy == get(this.auth, 'user.id')
        },
        canCreateMemo() {
            return !!(this.m.subject && this.m.description && this.m.client_id);
        },
        isTeamMember() {
            return this.auth.user.type === 'team';
        },
        clientFavicons() {
            const clientFavicons = {}
            const clients = Object.values(this.clientsById || {})
            clients.forEach(client => {
                if (client.favicon) {
                    clientFavicons[client.id] = client.favicon
                }
            })
            return clientFavicons;
        },
        options() {
            const clientNames = {}
            const clients = Object.values(this.clientsById || {})
            clients.forEach(client => {
                clientNames[client.id] = client.name
            })
            const userNames = {}
            const users = Object.values(this.usersById || {})
            users.forEach(user => {
                userNames[user.id] = user.name
            })
            const ourTeamUserNames = {}
            users.forEach(user => {
                if (user.type == 'team') {
                    ourTeamUserNames[user.id] = user.name
                }
            })
            const teamNames = {}
            const teams = Object.values(this.teamsById || {})
            teams.forEach(team => {
                teamNames[team.id] = team.name
            })

            const statuses = {
                'new': 'New',
                'in-progress': 'In Progress',
                'need-more-information': 'Need More Information',
                'waiting-on-client': 'Waiting on Client',
                'resolved': 'Resolved',
            };

            if (this.memo.status == 'resolved' || this.memo.status == 'reopened') {
                statuses['reopened'] =  'Reopened'
            }

            const categories = {
                support: 'Support',
                billing: 'Billing',
                notification: 'Notification',
                promotion: 'Promotion',
                spam: 'Spam',
                other: 'Other',
            }

            return {
                clients: clientNames,
                users: userNames,
                teamUsers: ourTeamUserNames,
                teams: teamNames,
                categories,
                priorities: {
                    1: 'None',
                    2: 'Low',
                    3: 'Medium',
                    4: 'High',
                    5: 'Urgent'
                },
                statuses,
                trelloBoards: this.createTrelloCard.boards.reduce((acc, board) => {
                    acc[board.id] = board.name
                    return acc
                }, {}),
                trelloLists: this.createTrelloCard.lists.reduce((acc, list) => {
                    acc[list.id] = list.name
                    return acc
                }, {}),
                trelloLabels: this.createTrelloCard.allLabels.reduce((acc, label) => {
                    acc[label.id] = label.name ? `${label.name} (${label.color})` : label.color
                    return acc
                }, {}),
            }
        }
    },
    mounted() {
        if (this.auth.user.type === 'team') {
            this.loadTrelloCards();

            window.Echo.join(`memo.private.${this.memo.id}`)
                .here((users) => {
                    this.here = users.filter(user => user.id !== this.auth.user.id)
                })
                .joining((user) => {
                    if (user.id !== this.auth.user.id) {
                        this.here.push(user)
                    }
                })
                .leaving((user) => {
                    var idx = findIndex(this.here, {
                        id: user.id
                    })
                    this.here.splice(idx, 1)
                })
                .listen('MemoActivityDeleted', this.activityDeleted)
                .listen('MemoActivityCreated', this.activityCreated);
        }

        window.Echo.private(`memo.${this.memo.id}`)
            .listen('MemoActivityDeleted', this.activityDeleted)
            .listen('MemoActivityCreated', this.activityCreated)
            .listen('MemoUpdated', this.reloadMemo)
            .listenForWhisper('typing', (e) => {
                if (e.name in this.typing) {
                    clearTimeout(this.typing[e.name])
                }

                this.typing[e.name] = setTimeout(() => {
                    delete this.typing[e.name]
                }, 5000)
            })

        this.typingIndicator.interval = setInterval(() => {
            const length = (this.typingIndicator.dots.length + 1) % 4
            this.typingIndicator.dots = '.'.repeat(length)
        }, 250)
    },
    beforeUnmount() {
        window.Echo.leave(`memo.${this.memo.id}`)
        if (this.auth.user.type === 'team') {
            window.Echo.leave(`memo.private.${this.memo.id}`)
        }
        clearInterval(this.typingIndicator.interval)
    },
    methods: {
        openChecklistModal() {
            this.checklistModal.show = true;
        },
        async addChecklist() {
            const { data } = await axios.post(this.$route('activity.memo.checklist', this.memo.id), {
                name: this.checklistModal.checklist.name
            })
            this.checklistModal.show = false
            this.checklistModal.checklist.name = ''
            this.checkLists.push(data)
        },
        startNewChecklistItem(checklist) {
            this.newChecklistItem.show = true;
            this.newChecklistItem.checklistId = checklist.id;
            this.newChecklistItem.description = '';
            nextTick(() => {
                this.$refs.addItemInp[0].$refs.inp.focus()
            })
        },
        async deleteChecklist(checklist) {
            await axios.delete(this.$route('activity.memo.checklist.delete', {memo: this.memo.id, checklist: checklist.id}));
            this.checkLists = this.checkLists.filter(cl => cl.id !== checklist.id);
        },
        async addChecklistItem() {
            const checklist = this.checklists.find(checklist => checklist.id === this.newChecklistItem.checklistId);
            const description = this.newChecklistItem.description;
            if (!checklist || !description) {
                return;
            }

            this.newChecklistItem.description = '';

            if (!checklist.items) {
                checklist.items = [];
            }

            checklist.items.push({
                id: null,
                description,
                completed: false,
                completed_at: null,
            })

            const { data } = await axios.post(this.$route('activity.memo.checklist.item', {memo: this.memo.id, checklist: this.newChecklistItem.checklistId}), {
                description,
            })

            checklist.items.pop();
            checklist.items.push(data);

        },
        async updateChecklistItem(checklist, item) {
            await axios.post(this.$route('activity.memo.checklist.item.update', {memo: this.memo.id, checklist: checklist.id, item: item.id}), {
                description: item.description,
                completed: item.completed,
            })
        },
        async loadTrelloCards() {
            const { data } = await axios.get(this.$route('trello.check'))
            this.createTrelloCard.connected = data.result
            if (this.createTrelloCard.connected) {
                this.trelloCardIds.forEach(async id => {
                    if (!(id in this.trelloCards)) {
                        const card = await this.loadTrelloCard(id)
                        if (card) {
                            this.trelloCards[id] = card
                        }
                    }
                })
            }
        },
        async loadTrelloCard(id) {
            const { data } = await axios.get(this.$route('trello.card', id))
            return data
        },
        async createTheTrelloCard() {
            const { data } = await axios.post(this.$route('trello.create.card'), {
                board: this.createTrelloCard.board,
                list: this.createTrelloCard.list,
                labels: this.createTrelloCard.labels,
                name: this.m.subject,
                description: this.m.description,
                url: window.location.href
            })
            await this.addTrelloCardActivity(data.id, data.shortUrl)
            this.createTrelloCard.board = null
            this.createTrelloCard.list = null
            this.createTrelloCard.labels = []
            this.createTrelloCard.show = false
        },
        async loadTrelloBoards() {
            const { data } = await axios.get(this.$route('trello.boards'))
            this.createTrelloCard.boards = data
        },
        async loadTrelloLists(boardId) {
            this.createTrelloCard.lists = []
            const { data } = await axios.get(this.$route('trello.lists', boardId))
            this.createTrelloCard.lists = data
        },
        async loadTrelloLabels(boardId) {
            this.createTrelloCard.allLabels = []
            const { data } = await axios.get(this.$route('trello.labels', boardId))
            this.createTrelloCard.allLabels = data
        },
        async openEditor() {
            this.userAuthToken = await axios.get(this.$route('user.auth.token')).then(res => res.data.token);
            this.editorIsOpen = true;
        },
        useMacro(macro) {
            this.comment.comment = macro.body.content
            this.comment.attachments = macro.body.attachments
            this.macroModal.show = false
        },
        openMacroModal() {
            this.macroModal.show = true;
        },
        openPublishModal() {
            this.$refs.editorFrame.contentWindow.postMessage({
                action: 'publishNow'
            }, '*');
        },
        status(status) {
            const statuses = {
                'new': {
                    label: 'New',
                    icon: 'fa-fw fad fa-sparkles',
                    color: 'blue-500',
                },
                'in-progress': {
                    label: 'In Progress',
                    icon: 'fa-fw fad fa-cogs',
                    color: 'orange-500',
                },
                'need-more-information': {
                    label: 'Need More Information',
                    icon: 'fa-fw fad fa-message-question',
                    color: 'red-500',
                },
                'waiting-on-client': {
                    label: 'Waiting on Client',
                    icon: 'fa-fw fad fa-user-clock',
                    color: 'violet-500',
                },
                'resolved': {
                    label: 'Resolved',
                    icon: 'fa-fw fad fa-check-circle',
                    color: 'green-500',
                },
                'reopened': {
                    label: 'Reopened',
                    icon: 'fa-fw fad fa-exclamation-circle',
                    color: 'orange-500',
                }
            }
            return status in statuses ? statuses[status] : status
        },
        priority(priority) {
            const priorities = {
                '1': {
                    label: 'None',
                    icon: 'fa-fw fad fa-face-sleeping',
                    color: 'gray-500',
                },
                '2': {
                    label: 'Low',
                    icon: 'fa-fw fad fa-face-thinking',
                    color: 'blue-500',
                },
                '3': {
                    label: 'Medium',
                    icon: 'fa-fw fad fa-face-smile',
                    color: 'green-500',
                },
                '4': {
                    label: 'High',
                    icon: 'fa-fw fad fa-face-anxious-sweat',
                    color: 'orange-500',
                },
                '5': {
                    label: 'Urgent',
                    icon: 'fa-fw fad fa-face-scream',
                    color: 'red-500',
                }
            }
            return priority in priorities ? priorities[priority] :priority
        },
        async getResolutionMessage(threadId, count) {
            if (!threadId) {
                this.changeStatus.generating = true;
                const { data } = await axios.post(this.$route("ai.resolution", this.m.id))
                if (data.threadId) {
                    setTimeout(() => this.getResolutionMessage(data.threadId, 1), 500)
                }
                return;
            }

            const { data } = await axios.get(this.$route("ai.thread", threadId))
            if (!data || data.length === 0) {
                setTimeout(() => this.getResolutionMessage(threadId, count + 1), 500)
                return;
            }
            const mostRecentMessage = data[0]
            if (mostRecentMessage.role === 'assistant') {
                this.changeStatus.note = mostRecentMessage.content
                this.changeStatus.generating = false;
                return
            }

            if (count < 10) {
                setTimeout(() => this.getResolutionMessage(threadId, count + 1), 500)
            } else {
                this.changeStatus.generating = false;
            }
        },
        isTyping() {
            window.Echo.private(`memo.${this.memo.id}`)
                .whisper('typing', {
                    name: this.auth.user.name
                });
        },
        back() {
            const prevUrl = Cookies.get('previous-url')
            if (prevUrl.match(/^\/activity[^/]/)) {
                this.$inertia.visit(prevUrl)
            } else {
                this.$inertia.visit(this.$route('activity'))
            }
        },
        safe(html) {
            return sanitizeHtml(html, {
                disallowedTagsMode: 'recursiveEscape',
                transformTags: {
                    'a': function(tagName, attribs) {
                        return {
                            tagName,
                            attribs: {
                                ...attribs,
                                target: "_blank"
                            }
                        };
                    }
                }
            });
        },
        assignToMe() {
            this.m.assigned_to = this.auth.user.id;
            this.m.assigned_team = this.auth.user.teams[0].id;
        },
        activityDeleted($event) {
            this.reloadMemo();
            const index = this.allActivities.findIndex(activity => {
                return activity.id === $event.activity.id
            })
            if (index >= 0) {
                this.allActivities.splice(index, 1);
            }
        },
        activityCreated($event) {
            this.reloadMemo()
            const index = this.allActivities.findIndex(activity => {
                return activity.id === $event.activity.id
            })
            if (index === -1) {
                this.allActivities.unshift($event.activity);
            }
        },
        reloadMemo() {
            this.$inertia.reload({
                only: ['memo', 'actions'],
            })
        },
        dt (value) {
            const date = moment.utc(value).local()
            if (date.isSame(moment(), 'day')) {
                return date.format('h:mma');
            } else if (date.isSame(moment(), 'year')) {
                return date.format('MMM Do, h:mma');
            }
            return date.format('MMMM Do YYYY, h:mma');
        },
        ago (value) {
            return moment.utc(value).fromNow();
        },
        async changeStatusWithComment(status) {
            this.post()

            this.changeStatus.status = status
            this.changeStatus.note = ''
            await this.saveStatus()
        },
        async saveStatus() {
            this.changeStatus.saving = true

            this.m.status = this.changeStatus.status;

            this.$emit('update:memo', {
                ...this.memo,
                status: this.changeStatus.status
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    status: this.changeStatus.status,
                    note: this.changeStatus.note
                }
            )

            this.allActivities.unshift(data);

            this.changeStatus.saving = false
            this.changeStatus.note = ''
            this.changeStatus.show = false
        },
        post() {
            if (this.postVisibility === 'public') {
                this.addComment();
            } else {
                this.addPrivateNote();
            }
        },
        changed($event, key) {
            this.editing[key] = false
            this.m[key] = $event.target.innerText
        },
        addAttachments(files) {
            if (files.length) {
                this.uploadFiles(files, false);
            }
        },
        uploadFiles(files = null, automaticallyPost = false) {
            const promises = (Array.isArray(files) ? files : Array.from(this.$refs.file.files)).map((file) => {
                // upload file from $refs.file
                const formData = new FormData();
                formData.append('file', file);
                // update to media.update using axios
                return axios.post(this.$route('media.upload'), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            });

            Promise.all(promises).then((responses) => {
                // clear the file input
                this.$refs.file.value = null;
                responses.forEach(({ data }) => {
                    this.comment.attachments.push(data);
                })
                if (automaticallyPost) {
                    this.addComment();
                }
            })
        },
        async createMemo() {
            if (this.canCreateMemo) {
                const { data } = await axios.post(
                    this.$route("activity.memo.store"),
                    {
                        subject: this.m.subject,
                        description: this.m.description,
                        client_id: this.m.client_id,
                        is_internal: this.m.is_internal,
                        status: this.m.status,
                        priority: this.m.priority,
                        category: this.m.category,
                        assigned_to: this.m.assigned_to,
                        assigned_team: this.m.assigned_team,
                    }
                )
                this.$inertia.visit(this.$route('activity.memo', data.id))
            }
        },
        async addPrivateNote() {
            if (!this.comment.comment && !this.comment.attachments.length) {
                return;
            }
            const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type: 'note',
                    details: {
                        comment: this.comment.comment || `Uploaded ${this.comment.attachments.length} file${this.comment.attachments.length > 1 ? 's' : ''}`
                    },
                    attachments: this.comment.attachments
                }
            )

            this.comment.comment = '';
            this.comment.attachments = [];
            this.activityCreated({ activity: data })
            if (!this.isWatching) {
                this.watch()
            }
        },
        async addTrelloCardActivity(cardId, cardUrl) {
             const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type: 'trello_card',
                    details: {
                        trello_card_id: cardId,
                        trello_card_url: cardUrl
                    }
                }
            )
            this.activityCreated({ activity: data })
        },
        async addComment() {
            if (!this.comment.comment && !this.comment.attachments.length) {
                return;
            }

            let type = 'comment';

            if (this.comment.attachments.length && !this.comment.comment) {
                type = 'attachment';
            }

            const { data } = await axios.post(
                this.$route("activity.store", this.m.id),
                {
                    type,
                    details: type === 'comment' ? {
                        comment: this.comment.comment
                    } : null,
                    attachments: this.comment.attachments
                }
            )

            this.comment.comment = '';
            this.comment.attachments = [];
            this.allActivities.unshift(data);
            if (!this.isWatching) {
                this.watch()
            }
        },
        async updateActivity(activity, idx, $event) {
            axios.post(this.$route('activity.update', [this.m.id, activity.id]), {
                details: $event.details
            });
        },
        async deleteActivity(activity, idx) {
            this.allActivities.splice(idx, 1);
            axios.delete(this.$route('activity.delete', [this.m.id, activity.id]));
        },
        async deleteAttachment(activity, idx, id) {
            const mIdx = activity.media.findIndex(media => {
                return media.id === id
            })

            if (mIdx >= 0) {
                activity.media.splice(mIdx, 1);
            }

            if (activity.media.length === 0 && activity.type == "attachment") {
                return this.deleteActivity(activity, idx)
            } else {
                axios.delete(this.$route('activity.media.delete', [this.m.id, activity.id, id]));
            }
        },
        async addWatcher(email) {
            if (!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                return;
            }
            const { data } = await axios.post(this.$route('activity.memo.watch', [this.m.id]), { email });
            this.m.watcher_count += 1
            this.m.watchers.push(data.user)
            this.newWatcher.email = ''
        },
        async watch() {
            axios.post(this.$route('activity.memo.watch', [this.m.id]));
            this.m.watcher_count += 1
            this.isWatching = true
            this.m.watchers.push(this.auth.user)
        },
        async unwatch(watcherId) {
            if (!(watcherId && confirm('Are you sure you want to remove this watcher from this memo?'))) {
                return;
            }
            const data = watcherId ? { watcher_id: watcherId } : {}
            axios.delete(this.$route('activity.memo.unwatch', [this.m.id]), { data });

            this.m.watcher_count -= 1
            if (!watcherId || watcherId === this.auth.user.id) {
                this.isWatching = false
            }

            this.m.watchers = this.m.watchers.filter(watcher => watcher.id !== watcherId)
        }

    },
    watch: {
        trelloCardIds() {
            this.loadTrelloCards()
        },
        'createTrelloCard.board': function(val) {
            this.loadTrelloLists(val)
            this.loadTrelloLabels(val)
        },
        'changeStatus.status': function(val) {
            if (val === 'resolved') {
                this.getResolutionMessage()
            }
        },
        'memo': function() {
            this.m._reloading = true;
            this.m.client_id = this.memo.client_id;
            this.m.is_internal = this.memo.is_internal;
            this.m.subject = this.memo.subject;
            this.m.description = this.memo.description;
            this.m.status = this.memo.status;
            this.m.category = this.memo.category;
            this.m.priority = this.memo.priority;
            this.m.assigned_to = this.memo.assigned_to;
            this.m.assigned_team = this.memo.assigned_team;
            this.m.watchers = this.memo.watchers;
            this.m.meta = this.memo.meta;

            nextTick(() => {
                this.m._reloading = false;
            })
        },
        'm.assigned_to': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                assigned_to: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    assigned_to: this.m.assigned_to
                }
            )

            this.allActivities.unshift(data);
        },
        'm.is_internal': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                is_internal: val
            });

            await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    is_internal: this.m.is_internal
                }
            )
        },
        'm.assigned_team': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                assigned_team: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    assigned_team: this.m.assigned_team
                }
            )

            this.allActivities.unshift(data);
        },
        'm.client_id': async function (val) {
            if (this.m._reloading || !this.m.id) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                client_id: val
            });

            await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    client_id: this.m.client_id
                }
            )

            // extract domain name from the url
            const url = get(this.memo, 'meta.url', null)
            if (url) {
                const domain = url.replace(/(^\w+:|^)\/\//, '').replace(/\/.*$/, '');

                const {data: projectsForClient} = await axios.get(this.$route('clients.projects', this.m.client_id));

                if (projectsForClient.length == 0) {
                    return;
                }

                for (let project of projectsForClient) {
                    if ((project.domains || []).includes(domain)) {
                        break; //already associated with the domain
                    }
                }

                let projectId = null;

                if (projectsForClient.length == 1) {
                    if (confirm(`Would you like all future feedback from ${domain} to be associated with ${projectsForClient[0].name}?`)) {
                        projectId = projectsForClient[0].id;
                    }
                } else {
                    for (let project of projectsForClient) {
                        if (confirm(`Would you like all future feedback from ${domain} to be associated with ${project.name}?`)) {
                            projectId = project.id;
                            break;
                        }
                    }
                }

                if (projectId) {
                    await axios.post(
                        this.$route('projects.domain', projectId),
                        { domain }
                    )
                }
            }
        },
        'm.priority': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                priority: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    priority: this.m.priority
                }
            )

            this.allActivities.unshift(data);
        },
        'm.category': async function (val) {
            if (this.m._reloading) {
                return;
            }

            this.$emit('update:memo', {
                ...this.memo,
                category: val
            });

            const { data } = await axios.post(
                this.$route("activity.memo.update", this.m.id),
                {
                    '_method': 'PUT',
                    category: this.m.category
                }
            )

            this.allActivities.unshift(data);
        },
        'm.subject': async function (val) {
            if (this.m._reloading) {
                return;
            }

            if (this.canEditMemo) {
                this.$emit('update:memo', {
                    ...this.memo,
                    subject: val
                });

                await axios.post(
                    this.$route("activity.memo.update", this.m.id),
                    {
                        '_method': 'PUT',
                        subject: this.m.subject
                    }
                )
            }
        },
        'm.description': async function (val) {
            if (this.m._reloading) {
                return;
            }

            if (this.canEditMemo) {
                this.$emit('update:memo', {
                    ...this.memo,
                    description: val
                });

                await axios.post(
                    this.$route("activity.memo.update", this.m.id),
                    {
                        '_method': 'PUT',
                        description: this.m.description
                    }
                )
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
:deep(span.mention, span.suggestion) {
    background-color: #f1f2f3;
    padding: 0.15rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 1rem;
}
</style>
