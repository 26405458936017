<script setup>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
ChartJS.register(ArcElement, Tooltip, Legend)

const formatHours = function(num) {
    const hours = Number(Math.round(num * 10) / 10).toLocaleString()
    return hours + " hour" + (hours == 1 ? '' : 's')
}


const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    }
})

const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right'
        },
        colors: {
            enabled: false
        }
    }
}

const data = {
    datasets: [{
        data: [],
        backgroundColor: []
    }],
    labels: []
}

const colors = [
    '#10B981',
    '#3B82F6',
    '#F59E0B',
    '#EF4444',
    '#8B5CF6',
    '#6B7280',
    '#F472B6',
    '#34D399',
    '#FCD34D',
    '#F87171',
    '#818CF8',
    '#A5B4FC',
    '#D1FAE5',
    '#FEEBC8',
    '#FED7D7',
    '#F9A8D4',
    '#FECDD3',
    '#FEE2E2',
    '#FED7E2',
]


Object.keys($props.data).forEach((key, idx) => {
    data.labels.push(key)
    data.datasets[0].data.push($props.data[key])
    data.datasets[0].backgroundColor.push(colors[idx])
})
</script>

<template>
    <section class="flex flex-col">
        <h1 class="text-base font-semibold leading-6 text-gray-900 mb-4">
            {{ title }}
        </h1>
        <div class="flow-root border shadow bg-white p-4 flex-grow">
            <Pie :data="data" :options="options" />
        </div>
    </section>

</template>

<style>
canvas {
  height: 100% !important;
  max-width: 100% !important;
}
</style>
