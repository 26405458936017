import axios from "axios"

export default {
    methods: {
        hasPermission(perm) {
            return (this.auth.user.permissions || []).indexOf(perm) >= 0
        },
        async can (action, model, id) {
            const response = await axios(`/can/${action}/${model}${id ? '?id='+id : ''}`)
            return response.data === 1
        }
    }
}