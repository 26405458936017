<template>
    <number
        v-model="money"
        prefix="$"
        :autofocus="autofocus"
        :allowNegative="allowNegative"
    />
</template>

<script>
import number from './number.vue'

export default {
    components: { number },
    props: {
        modelValue: [Number, String],
        autofocus: {
            type: Boolean,
            default: false
        },
        allowNegative: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            money: String(this.modelValue),
        }
    },
    watch: {
        money () {
            if (this.money != String(this.modelValue)) {
                this.$emit("update:modelValue", Number(this.money))
            }
        },
        modelValue () {
            this.money = String(this.modelValue)
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
