<template>
    <Layout :auth="auth">
        <Head title="Services Report" />
        <template v-slot:title>Services Report</template>
        <div class="flex justify-end">
            <btnGroup>
                <btn small green :outline="activeService != 'eversite'" @click="activeService = 'eversite'">
                    Eversite
                </btn>
                <btn small green :outline="activeService != 'seo'" @click="activeService = 'seo'">
                    SEO
                </btn>
                <btn small green :outline="activeService != 'ppc'" @click="activeService = 'ppc'">
                    Search Ads
                </btn>
                <btn small green :outline="activeService != 'social'" @click="activeService = 'social'">
                    Social Media
                </btn>
            </btnGroup>
        </div>
        <div class="flex items-center gap-4 my-2">
            <div class="flex-grow aspect-[5] lg:aspect-[10]">
                <LineChart :data="totalChart.data" :options="totalChart.options" />
            </div>
            <div class="flex flex-col gap-2">
                <div
                    class="grid grid-cols-5 gap-x-1 text-center"
                >
                    <div class="col-span-1" :class="statusColor('active')">
                        <label class="font-medium opacity-75 uppercase text-sm">Active</label>
                        <div class="text-3xl">{{ totals.active || 0 }}</div>
                    </div>
                    <div class="col-span-1" :class="statusColor('delinquent')">
                        <label class="font-medium opacity-75 uppercase text-sm">Delinquent</label>
                        <div class="text-3xl">{{ totals.delinquent || 0 }}</div>
                    </div>
                    <div class="col-span-1" :class="statusColor('new')">
                        <label class="font-medium opacity-75 uppercase text-sm">New</label>
                        <div class="text-3xl">{{ (totals.new || 0) + (totals.onboarding || 0) }}</div>
                    </div>
                    <div class="col-span-1" :class="statusColor('paused')">
                        <label class="font-medium opacity-75 uppercase text-sm">Paused</label>
                        <div class="text-3xl">{{ totals.paused || 0 }}</div>
                    </div>
                    <div class="col-span-1" :class="statusColor('canceled')">
                        <label class="font-medium opacity-75 uppercase text-sm">Canceled</label>
                        <div class="text-3xl">{{ totals.canceled || 0 }}</div>
                    </div>
                </div>
                <div class="grid grid-cols-4 gap-2 text-center">
                    <div class="col-span-1 flex flex-col justify-center items-center">
                        <label class="font-medium opacity-75 uppercase text-xs">Bronze</label>
                        <div class="text-2xl font-black text-[#CD7F32]">{{ totals.Bronze || 0 }}</div>
                    </div>
                    <div class="col-span-1 flex flex-col justify-center items-center">
                        <label class="font-medium opacity-75 uppercase text-xs">Silver</label>
                        <div class="text-2xl font-black text-[#A0A0A0]">{{ totals.Silver || 0 }}</div>
                    </div>
                    <div class="col-span-1 flex flex-col justify-center items-center">
                        <label class="font-medium opacity-75 uppercase text-xs">Gold</label>
                        <div class="text-2xl font-black text-[#FFD700]">{{ totals.Gold || 0 }}</div>
                    </div>
                    <div class="col-span-1 flex flex-col justify-center items-center">
                        <label class="font-medium opacity-75 uppercase text-xs">Undecided</label>
                        <div class="text-2xl font-black text-black">{{ totals['Help Me Choose'] || 0 }}</div>
                    </div>
                </div>
                <div class="grid grid-cols-3 gap-2 text-center">
                    <div class="col-span-1">
                        <label class="font-medium opacity-75 uppercase text-sm">Total Current</label>
                        <div class="text-3xl">
                            {{ totals.total || 0 }}
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label class="font-medium opacity-75 uppercase text-sm">Retention Rate</label>
                        <div class="text-3xl">
                            {{ retentionRate }}%
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label class="font-medium opacity-75 uppercase text-sm">Avg Launch Time</label>
                        <div class="text-3xl">
                            {{ averageLaunchTime }} days
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
            <div
                class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
            >
                <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th
                                @click="order('project')"
                                class="cursor-pointer px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                <div class="flex items-center gap-2">
                                    <span>
                                        <span>{{ filteredProjects.length }}</span>
                                        Project<span v-if="filteredProjects.length != 1">s</span>
                                    </span>
                                    <span
                                        v-show="
                                            orderBy[0] == 'project'
                                            && orderBy[1] == 'asc'
                                        "
                                        ><i class="fas fa-caret-up"></i
                                    ></span>
                                    <span
                                        v-show="
                                            orderBy[0] == 'project'
                                            && orderBy[1] == 'desc'
                                        "
                                        ><i class="fas fa-caret-down"></i
                                    ></span>
                                </div>
                                <input
                                    @click.stop
                                    type="text"
                                    class="w-full mt-2 px-2 py-1 text-xs bg-white/40"
                                    v-model="query.project"
                                    placeholder="Showing all projects..."
                                />
                            </th>
                            <template v-for="(service, key) in {'eversite': 'Eversite', 'seo': 'SEO', 'ppc': 'Search Ads', 'social': 'Social Media'}" :key="key">
                                <th
                                    @click="order(key + '_status')"
                                    class="cursor-pointer px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                                >
                                    <div>
                                        {{ service }}
                                        <span
                                            v-show="
                                                orderBy[0] == key + '_status'
                                                && orderBy[1] == 'asc'
                                            "
                                            ><i class="fas fa-caret-up"></i
                                        ></span>
                                        <span
                                            v-show="
                                                orderBy[0] == key + '_status' &&
                                                    orderBy[1] == 'desc'
                                            "
                                            ><i class="fas fa-caret-down"></i
                                        ></span>
                                    </div>
                                    <div class="flex flex-col gap-2 mt-2">
                                        <hgselect
                                            @click.stop
                                            v-model="query[key]"
                                            multiple
                                            :options="{
                                                'level:Help Me Choose': 'Setup',
                                                'level:Bronze': 'Bronze',
                                                'level:Silver': 'Silver',
                                                'level:Gold': 'Gold',
                                                '--': '--',
                                                'status:active': 'Active',
                                                'status:canceled': 'Canceled',
                                                'status:delinquent': 'Delinquent',
                                                'status:free': 'Free',
                                                'status:lost': 'Lost',
                                                'status:new': 'New',
                                                'status:onboarding': 'Onboarding',
                                                'status:paused': 'Paused',
                                            }"
                                        > </hgselect>
                                    </div>
                                </th>
                            </template>
                            <th
                                @click="order('payments.first')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                First<br>Invoice
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.first' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.first' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('payments.last')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-xs leading-4 text-center font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Most Recent<br>Invoice
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.last' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'payments.last' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('weeks')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Weeks of<br>Service
                                <span
                                    v-show="
                                        orderBy[0] == 'weeks' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'weeks' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('start_date')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Start<br>Date
                                <span
                                    v-show="
                                        orderBy[0] == 'start_date' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'start_date' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('launch_date')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Launch<br>Date
                                <span
                                    v-show="
                                        orderBy[0] == 'launch_date' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'launch_date' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('ttl')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap"
                            >
                                Time To<br>Launch
                                <span
                                    v-show="
                                        orderBy[0] == 'ttl' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'ttl' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                v-if="hasPermission('manage:billing')"
                                @click="order('invoiced')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Invoiced
                                <span
                                    v-show="
                                        orderBy[0] == 'invoiced' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'invoiced' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                @click="order('hours')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Hours
                                <span
                                    v-show="
                                        orderBy[0] == 'hours' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'hours' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                            <th
                                v-if="hasPermission('manage:billing')"
                                @click="order('rate')"
                                class="align-top cursor-pointer px-6 py-3 bg-white text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider whitespace-nowrap w-2"
                            >
                                Rate
                                <span
                                    v-show="
                                        orderBy[0] == 'rate' &&
                                            orderBy[1] == 'asc'
                                    "
                                    ><i class="fas fa-caret-up"></i
                                ></span>
                                <span
                                    v-show="
                                        orderBy[0] == 'rate' &&
                                            orderBy[1] == 'desc'
                                    "
                                    ><i class="fas fa-caret-down"></i
                                ></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <template
                            v-for="(project) in filteredProjects"
                            :key="project.id"
                        >
                            <tr
                                :class="{
                                    'odd:bg-gray-50': !(timeToLaunch(project) == 0 && timeSinceFirstPayment(project) > 80),
                                    'bg-orange-100 border-b border-orange-200': 'Eversite' in project.services && timeToLaunch(project) == 0 && timeSinceFirstPayment(project) > 80,
                                }"
                            >
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-600"
                                >
                                    <div class="flex flex-col">
                                        <div class="flex gap-2 items-start">
                                            <Link
                                                :href="'/projects/' + project.id"
                                                class="hover:underline"
                                            >
                                                {{ project.name }}
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                                <template v-for="(service, key) in {'eversite': 'Eversite', 'seo': 'SEO', 'ppc': 'Search Ads', 'social': 'Social Media'}" :key="key">
                                    <td
                                        class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                    >
                                        <template v-if="project.services[service]">
                                            {{ translateLevel(project.services[service]?.level ?? '') }}
                                            <span :class="statusColor(project.services[service]?.status ?? '')">
                                            &bull; {{ translateStatus(project.services[service]?.status ?? '??') }}
                                            </span>
                                        </template>
                                    </td>
                                </template>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="project.firstPayment">{{
                                        date(project.firstPayment)
                                    }}</span
                                    ><span v-else>Never</span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="project.lastPayment">{{
                                        date(project.lastPayment)
                                    }}</span
                                    ><span v-else>Never</span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-left cursor-pointer"
                                >
                                    <span v-if="project.firstPayment && project.lastPayment">
                                        {{ numberOfWeeks(project) }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span v-if="project.start_date">
                                        {{ date(project.start_date, 'utc') }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span v-if="project.launch_date">
                                        {{ date(project.launch_date, 'utc') }}
                                    </span>
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    <span v-if="(project.start_date || project.firstPayment) && project.launch_date">
                                        {{ timeToLaunch(project) }} days
                                    </span>
                                </td>
                                <td
                                    v-if="hasPermission('manage:billing')"
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    {{ money(project.paid + project.unpaid) }}
                                </td>
                                <td
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                >
                                    {{ number(project.actual.hours) }}hr
                                </td>
                                <td
                                    v-if="hasPermission('manage:billing')"
                                    class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-right cursor-pointer"
                                    :class="{
                                        'text-gray-600':
                                            project.actual.hours == 0,
                                        'text-red-600':
                                            project.actual.hours > 0 &&
                                            (project.paid + project.unpaid) /
                                                project.actual.hours <
                                                50,
                                        'text-green-600':
                                            project.actual.hours > 0 &&
                                            (project.paid + project.unpaid) /
                                                project.actual.hours >=
                                                50
                                    }"
                                >
                                    <template v-if="project.actual.hours > 0">
                                        {{
                                            money(
                                                (
                                                    (project.paid +
                                                        project.unpaid) /
                                                    project.actual.hours
                                                ).toFixed(2)
                                            )
                                        }}/hr
                                    </template>
                                    <template v-else> -- </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="text-sm my-4 border-t border-gray-200 pt-4 flex items-center justify-between">
                <div>
                    <span class="bg-orange-100 border-b border-orange-200 px-2 py-1 rounded-md">Orange Rows</span> are Eversites which haven't launched but started over 80 days ago.
                </div>
                <label class="flex items-center gap-2">
                    <span>Hide Canceled</span>
                    <toggle v-model="hideCanceled" />
                </label>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue";
import btnGroup from "../../Common/btnGroup.vue";
import hgselect from "../../Common/hgselect.vue";
import btn from "../../Common/btn.vue";
import toggle from "../../Common/toggle.vue";
import LineChart from "../../Charts/Line.vue"
import { Head, Link } from "@inertiajs/vue3";
import auth from "../../Common/Mixins/auth"
import moment from "moment";

export default {
    name: "ServicesReport",
    components: {
        Layout,
        Head,
        Link,
        btnGroup,
        btn,
        LineChart,
        toggle,
        hgselect
    },
    mixins: [ auth ],
    props: {
        auth: Object,
        projects: Object,
        levelAnalytics: Object
    },
    data() {
        return {
            hideCanceled: true,
            activeService: 'eversite',
            orderBy: ["project", "asc"],
            query: {
                project: '',
                eversite: [],
                seo: [],
                ppc: [],
                social: [],
            }
        };
    },
    computed: {
        projectsWithActiveService() {
            const serviceToCheck = {
                'eversite': 'Eversite',
                'seo': 'SEO',
                'ppc': 'Search Ads',
                'social': 'Social Ads'
            }[this.activeService]

            return Object.values(this.projects).filter((p) => {
                return serviceToCheck in p.services
            })
        },
        retentionRate() {
            const serviceToCheck = {
                'eversite': 'Eversite',
                'seo': 'SEO',
                'ppc': 'Search Ads',
                'social': 'Social Ads'
            }[this.activeService]

            return Math.round(
                Object.values(this.projectsWithActiveService).filter(p => !(['lost','canceled','paused'].includes(p.services[serviceToCheck].status))).length
                / Object.values(this.projectsWithActiveService).filter(p => p.services[serviceToCheck].status !== 'lost').length
                * 100
            )
        },
        totals() {
            const totals = {
                total: 0,
            }

            Object.keys(this.projects).forEach(projectKey => {
                const project = this.projects[projectKey]
                Object.keys(project.services).forEach(service => {
                    const serviceToCheck = {
                        'eversite': 'Eversite',
                        'seo': 'SEO',
                        'ppc': 'Search Ads',
                        'social': 'Social Ads'
                    }[this.activeService]

                    if (service == serviceToCheck) {
                        const level = project.services[service].level
                        const status = project.services[service].status
                        totals[status] = (totals[status] || 0) + 1
                        if (!['canceled', 'paused', 'lost'].includes(status)) {
                            totals.total += 1
                            totals[level] = (totals[level] || 0) + 1
                        }
                    }
                })
            })
            return totals
        },
        mostRecentLevelAnalytics() {
            return this.levelAnalytics[this.activeService][Object.keys(this.levelAnalytics[this.activeService]).sort().pop()]
        },
        filteredProjects() {
            return Object.values(this.projects).filter(p => {
                if (this.query.project && !(p.name.toLowerCase().includes(this.query.project.toLowerCase()))) {
                    return false
                }
                if (this.hideCanceled) {
                    const allServicesAreCanceled = Object.values(p.services).reduce((acc, service) => {
                        return acc && (['canceled', 'paused'].includes(service.status))
                    }, true)

                    if (allServicesAreCanceled) {
                        return false
                    }
                }
                const services = {
                    'eversite': 'Eversite',
                    'seo': 'SEO',
                    'ppc': 'Search Ads',
                    'social': 'Social Ads'
                }
                for(const service in services) {
                    const serviceName = services[service]
                    if (this.query[service].length > 0) {
                        const statuses = this.query[service].filter(s => s.includes('status:')).map(s => s.split(':')[1])
                        const levels = this.query[service].filter(s => s.includes('level:')).map(s => s.split(':')[1])

                        if (serviceName in p.services) {
                            if (statuses.length > 0 && !statuses.includes(p.services[serviceName].status)) {
                                return false;
                            }
                            if (levels.length > 0 && !levels.includes(p.services[serviceName].level)) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                }
                return true
            }).sort((a, b) => {
                let valueA = a.name
                let valueB = b.name

                switch(this.orderBy[0]) {
                    case 'eversite_status':
                        valueA = this.translateLevel(a.services['eversite']?.level ?? '')
                        valueB = this.translateLevel(b.services['eversite']?.level ?? '')
                        break
                    case 'seo_status':
                        valueA = this.translateLevel(a.services['seo']?.level ?? '')
                        valueB = this.translateLevel(b.services['seo']?.level ?? '')
                        break
                    case 'ppc_status':
                        valueA = this.translateLevel(a.services['ppc']?.level ?? '')
                        valueB = this.translateLevel(b.services['ppc']?.level ?? '')
                        break
                    case 'social_status':
                        valueA = this.translateLevel(a.services['social']?.level ?? '')
                        valueB = this.translateLevel(b.services['social']?.level ?? '')
                        break
                    case 'payments.first':
                        valueA = a.firstPayment
                        valueB = b.firstPayment
                        break
                    case 'payments.last':
                        valueA = a.lastPayment
                        valueB = b.lastPayment
                        break
                    case 'weeks':
                        valueA = this.numberOfWeeks(a)
                        valueB = this.numberOfWeeks(b)
                        break
                    case 'launch_date':
                        valueA = a.launch_date
                        valueB = b.launch_date
                        break
                    case 'ttl':
                        valueA = this.timeToLaunch(a)
                        valueB = this.timeToLaunch(b)
                        break
                    case 'invoiced':
                        valueA = a.paid + a.unpaid
                        valueB = b.paid + b.unpaid
                        break
                    case 'hours':
                        valueA = a.actual.hours
                        valueB = b.actual.hours
                        break
                    case 'rate':
                        valueA = (a.paid + a.unpaid) / a.actual.hours
                        valueB = (b.paid + b.unpaid) / b.actual.hours
                        break
                }

                if (this.orderBy[1] == 'asc') {
                    return String(valueA || '').localeCompare(String(valueB || ''))
                }
                return String(valueB || '').localeCompare(String(valueA || ''))
            })
        },
        averageLaunchTime() {
            const launchTimes = Object.values(this.projects).map(p => this.timeToLaunch(p)).filter(t => t > 0)
            if (launchTimes.length == 0) {
                return 0
            }
            return Math.round(launchTimes.reduce((a, b) => a + b) / launchTimes.length)
        },
        totalChart() {
            /**
             * levelAnalytics is an objects with date strings as keys and service levels as values
             * {
             *  "2024-07-23": {
             *      "Beginner": 12,
             *      "Intermediate": 7,
             *      "Advanced": 2
             *  },
             *  "2024-07-24": {
             *      ...
             *  }
             *
             * It can be assumed that all dates from the first to the last payment date will be present in the object
             */

            const serviceNames = {
                'eversite': 'Eversite',
                'seo': 'SEO',
                'ppc': 'Search Ads',
                'social': 'Social Ads'
            }

            const activeService = serviceNames[this.activeService]
            let labels = Object.keys(this.levelAnalytics[activeService]).sort();
            const data = {
                labels,
                datasets: [{
                    label: "Setup",
                    borderColor: '#000000',
                    backgroundColor: '#000000',
                    pointStyle: false,
                    fill: 'origin',
                    data: labels.map((label) => this.levelAnalytics[activeService][label]['Help Me Choose'])
                },{
                    label: "Bronze",
                    borderColor: '#c28342',
                    backgroundColor: '#c28342',
                    pointStyle: false,
                    fill: 'origin',
                    data: labels.map((label) => this.levelAnalytics[activeService][label].Bronze)
                },
                {
                    label: "Silver",
                    borderColor: '#c0c0c0',
                    backgroundColor: '#c0c0c0',
                    pointStyle: false,
                    fill: 'origin',
                    data: labels.map((label) => this.levelAnalytics[activeService][label].Silver)
                },
                {
                    label: "Gold",
                    borderColor: '#f9d949',
                    backgroundColor: '#f9d949',
                    pointStyle: false,
                    fill: 'origin',
                    data: labels.map((label) => this.levelAnalytics[activeService][label].Gold)
                },
            ]}

            return {
                data,
                options: {
                    scales: {
                        y: {
                            stacked: true,
                            display: false
                        },
                        x: {
                            display: false,
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        axis: 'x',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: 'index'
                        }
                    },
                    responsive:true,
                    maintainAspectRatio: false
                }
            }
        }
    },
    methods: {
        olderThan(date, days) {
            if (!days) {
                return moment.utc().diff(moment.utc(date, "YYYY-MM-DD HH:mm:ss"), "days")
            }
            return moment.utc().diff(moment.utc(date, "YYYY-MM-DD HH:mm:ss"), "days") > days
        },
        timeSinceFirstPayment(project) {
            const start = project.firstPayment;
            if (!start) {
                return 0;
            }

            return moment.utc().diff(
                moment.utc(start, "YYYY-MM-DD HH:mm:ss"),
                "days"
            );
        },
        timeToLaunch(project) {
            const start = project.start_date || project.firstPayment;
            const launch = project.launch_date;
            if (!start || !launch) {
                return 0;
            }

            return moment.utc(launch, "YYYY-MM-DD").diff(
                moment.utc(start, "YYYY-MM-DD HH:mm:ss"),
                "days"
            );
        },
        numberOfWeeks(project) {
            if (!project.firstPayment || !project.lastPayment) {
                return 0;
            }
            return moment
                    .utc(project.lastPayment, "YYYY-MM-DD HH:mm:ss")
                    .diff(
                        moment.utc(
                            project.firstPayment,
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        "weeks"
                    ) + 1
        },
        translateLevel(level) {
            if (level == 'Help Me Choose') {
                return 'Setup'
            }
            return level
        },
        translateStatus(status) {
            const statuses = {
                active: 'Active',
                canceled: 'Canceled',
                paused: 'Paused',
                delinquent: 'Delinquent',
                new: 'New',
                onboarding: 'Onboarding',
                lost: 'Lost',
                free: 'Free'
            }
            return statuses[status] || status
        },
        statusColor(status) {
            const colors = {
                active: 'text-green-600',
                new: 'text-green-600',
                onboarding: 'text-green-600',
                delinquent: 'text-red-600',
                canceled: 'text-gray-500',
                paused: 'text-purple-600',
                lost: 'text-orange-500',
                free: 'text-blue-600'
            }
            return colors[status] || 'text-gray-600'
        },
        money(num) {
            if (Number(num) >= 0) {
                let value = Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `$${value}`;
            } else {
                let value = Math.abs(Number(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `-$${value}`;
            }
        },
        number(num) {
            if (Number(num) >= 0) {
                let value = Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `${value}`;
            } else {
                let value = Math.abs(Number(num)).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                });
                return `-${value}`;
            }
        },
        datetime(dt) {
            let date = moment.utc(dt, "YYYY-MM-DD HH:mm:ss").local();
            if (
                date.format("YYYY") ==
                moment
                    .utc()
                    .local()
                    .format("YYYY")
            ) {
                return date.format("MMM Do, h:mma");
            }
            return date.format("MMM Do YYYY, h:mma");
        },
        date(dt, tz = 'local') {
            let date = moment.utc(dt, "YYYY-MM-DD HH:mm:ss")[tz]();
            console.log(dt, date)
            if (
                date.format("YYYY") ==
                moment
                    .utc()
                    .local()
                    .format("YYYY")
            ) {
                return date.format("MMM Do");
            }
            return date.format("MMM Do YYYY");
        },
        order(key) {
            if (this.orderBy[0] == key) {
                this.orderBy = [key, this.orderBy[1] == "asc" ? "desc" : "asc"];
            } else {
                this.orderBy = [key, "asc"];
            }
        }
    },
    watch: {
    }
};
</script>

<style lang="postcss" scoped></style>
