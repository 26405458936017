<template>
    <number
        v-model="percentage"
        suffix="%"
        :autofocus="autofocus"
        :allowNegative="allowNegative"
    />
</template>

<script>
import number from './number.vue'

export default {
    components: { number },
    props: {
        modelValue: [Number, String],
        autofocus: {
            type: Boolean,
            default: false
        },
        allowNegative: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            percentage: Math.round(Number(this.modelValue) * 100),
        }
    },
    watch: {
        percentage () {
            if (Number(this.percentage) != Number(this.modelValue)) {
                this.$emit("update:modelValue", (Number(this.percentage) / 100).toFixed(2))
            }
        },
        modelValue () {
            if (Number(this.percentage) != (Number(this.modelValue) * 100)) {
                this.percentage = Math.round(Number(this.modelValue) * 100)
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
