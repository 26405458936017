<template>
    <div v-if="e" @click="closeDropdowns" class="relative text-gray-800">
        <template v-if="showHistory">
            <div class="overflow-auto max-h-96">
                <entry-history :clientName="clientName" :sprintName="sprintName" :entry="e" />
            </div>
            <div class="mt-6 flex justify-center">
                <btn
                    blue outline
                    @click='showHistory = false'
                >Back To Form</btn>
            </div>
        </template>
        <template v-else>
            <div class="absolute top-0 right-0 z-50">
                <div @click.stop="openDropdownMenu = !openDropdownMenu" class="hover:text-red-700 px-2 -mt-1 -mr-2">
                    <i class="far fa-ellipsis-v cursor-pointer"></i>
                    <transition
                        enter-class="transition ease-out duration-100"
                        enter-active-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-class="transition ease-in duration-75"
                        leave-active-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                    >
                        <div
                            v-if="openDropdownMenu"
                            class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <div class="py-1">
                                <a
                                    @click.prevent.stop='showHistory = true; openDropdownMenu = false;'
                                    href="#"
                                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                                >
                                    <i
                                        class="fal fa-history mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    Show History
                                </a>
                                <a
                                    v-if="!e.flat_rate"
                                    @click.prevent.stop='e.flat_rate = true; openDropdownMenu = false;'
                                    href="#"
                                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                                >
                                    <i
                                        class="fal fa-file-invoice-dollar mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    Use Flat Rate Billing
                                </a>
                                <a
                                    v-if="e.flat_rate"
                                    @click.prevent.stop='e.flat_rate = false; openDropdownMenu = false;'
                                    href="#"
                                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                                >
                                    <i
                                        class="fal fa-file-invoice-dollar mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    Use Hourly Billing
                                </a>
                                <a
                                    @click.prevent.stop='changeWeek = !changeWeek; openDropdownMenu = false;'
                                    href="#"
                                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                                >
                                    <i
                                        v-if="changeWeek"
                                        class="fal fa-times mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    <i
                                        v-else
                                        class="fal fa-calendar mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    Change Week
                                </a>
                                <a
                                    v-if="showDelete"
                                    @click.prevent.stop='deleteEntry'
                                    href="#"
                                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                                >
                                    <i
                                        v-if="deleting"
                                        class="fal fa-spinner-third fa-spin mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    <i
                                        v-else
                                        class="fal fa-trash mr-3 text-gray-400 group-hover:text-gray-500"
                                    ></i>
                                    Delete Entry
                                </a>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="mb-4" v-if="changeWeek">
                <label for="week" class="block text-sm font-medium leading-5 text-gray-700">Week</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <hgselect
                        ref="week-dropdown"
                        v-model="e.week"
                        :options="weeks"
                    />
                </div>
            </div>
            <div class="mb-4">
                <label for="user_id" class="block text-sm font-medium leading-5 text-gray-700">Team Member</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <hgselect
                        ref="user-dropdown"
                        v-model="e.user_id"
                        :loading="options.users == null"
                        :options="options.users"
                    />
                </div>
            </div>
            <div class="mb-4" v-if='selectByTag'>
                <label for="clientTag" class="flex justify-between text-sm font-medium leading-5 text-gray-700">
                    Client Tag
                    <a href='#' @click.stop.prevent='selectByTag = false; clientTag = null;' class="text-gray-500 hover:text-blue-700 text-xs font-normal" v-if='selectByTag'>Do Not Filter By Tag</a>
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <hgselect
                        ref="client-dropdown"
                        v-model="clientTag"
                        :options="options.clientTags"
                    />
                </div>
            </div>
            <div class="mb-4">
                <label for="client" class="flex justify-between text-sm font-medium leading-5 text-gray-700">
                    Client(s)
                    <a href='#' @click.stop.prevent='selectByTag = true' class="text-gray-500 hover:text-blue-700 text-xs font-normal" v-if='allowMultipleClients && !selectByTag'>Filter By Tag</a>
                    <a href='#' @click.stop.prevent='selectAllClients()' class="text-gray-500 hover:text-blue-700 text-xs font-normal" v-if='selectByTag'>Select All</a>
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <hgselect
                        ref="client-dropdown"
                        v-model="e.client_id"
                        :loading="options.clients == null"
                        :multiple='allowMultipleClients'
                        :options="options.clients"
                    />
                </div>
            </div>
            <div class="mb-4">
                <div class="grid grid-cols-2 gap-5">
                    <div>
                        <label for="type" class="block text-sm font-medium leading-5 text-gray-700">Type of Work</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <hgselect
                                ref="type-dropdown"
                                v-model="e.type"
                                :loading="options.types == null"
                                :options="options.types"
                            />
                        </div>
                    </div>
                    <div>
                        <label for="status" class="block text-sm font-medium leading-5 text-gray-700">Status</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <hgselect
                                ref="status-dropdown"
                                v-model="e.status"
                                :loading="options.statuses == null"
                                :options="options.statuses"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-2">
                        <label for="billable" class="block text-sm font-medium leading-5 text-gray-700">Billable?</label>
                        <div class="grid grid-cols-2 gap-2 mt-1">
                            <btn
                                blue
                                block
                                :outline="e.billable === null || e.billable == false"
                                @click='toggleBillable(true)'
                            >Yes</btn>
                            <btn
                                red
                                block
                                :outline="e.billable === null || e.billable == true"
                                @click='toggleBillable(false)'
                            >No</btn>
                        </div>
                    </div>
                    <div>
                        <label for="rate" class="block text-sm font-medium leading-5 text-gray-700">At What Rate?</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <money
                                :key="e.id + '-rate'"
                                v-model="e.rate"
                                class="block w-full sm:text-sm sm:leading-5"
                                placeholder="$0.00"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="e.billable">
                <div class="grid grid-cols-1 gap-6 mb-4">
                    <div>
                        <template v-for="(client_id, idx) in e.client_id" :key="'lbl-sprint-' + idx">
                            <label for="sprint" class="block text-sm font-medium leading-5 text-gray-700 mt-2">
                                Bill Against
                                <span v-if='e.client_id.length > 1' class="text-gray-500">({{ options.clients[client_id] }})</span>
                            </label>
                            <div
                                class="mt-1 relative rounded-md shadow-sm"
                            >
                                <hgselect
                                    ref="sprint-dropdown"
                                    v-model="e.sprint_id[e.client_id[idx]]"
                                    multiple
                                    seperate-with=""
                                    :loading="options.sprintsByClient[client_id] == null"
                                    :options="{
                                        ...options.sprintsByClient[client_id],
                                        '--': '--',
                                        'new-sprint': 'Request New Sprint',
                                        'new-invoice': 'Request New Post-Billed Invoice',
                                    }"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <div class="mb-4">
                <div class="grid gap-5 grid-cols-2">
                    <div>
                        <label for="planned" class="block text-sm font-medium leading-5 text-gray-700">Planned</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <number
                                v-model="e.planned"
                                class="block w-full sm:text-sm sm:leading-5"
                                placeholder="0"
                            />
                            <span v-if='!e.flat_rate' class="absolute top-0 right-0 py-2 px-2 text-gray-600">
                                {{ money(e.billableGoal) }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <label for="actual" class="block text-sm font-medium leading-5 text-gray-700">
                            Actual
                        </label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <number
                                v-model="e.actual"
                                class="block w-full sm:text-sm sm:leading-5"
                                placeholder="0"
                            />
                            <span v-if='!e.flat_rate' class="absolute top-0 right-0 py-2 px-2 text-gray-600">
                                {{ money(e.billableNow) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="e.billable">
                <template v-if="e.flat_rate">
                    <div class="mb-4">
                        <div class="grid grid-cols-2 gap-5">
                            <div>
                                <label for="billableGoal" class="block text-sm font-medium leading-5 text-gray-700">
                                    Billable Goal
                                </label>
                                <div class="mt-1 relative rounded-md shadow-sm">
                                    <money
                                        :key="e.id + '-billableGoal'"
                                        v-model="e.billableGoal"
                                        class="block w-full sm:text-sm sm:leading-5"
                                        placeholder="$0.00"
                                    />
                                </div>
                            </div>
                            <div>
                                <label for="billableNow" class="block text-sm font-medium leading-5 text-gray-700">
                                    Billable Now
                                </label>
                                <div class="mt-1 relative rounded-md shadow-sm">
                                    <money
                                        :key="e.id + '-billableNow'"
                                        v-model="e.billableNow"
                                        class="block w-full sm:text-sm sm:leading-5"
                                        placeholder="$0.00"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="Object.keys(sprintAmountBreakdowns).length > 1">
                <div class="mb-4">
                    <label @click="showAmountBreakdown = !showAmountBreakdown" class="block text-sm font-medium leading-5 text-gray-700 cursor-pointer">
                        <span v-if="showAmountBreakdown"><i class="fas fa-caret-down"></i></span>
                        <span v-else><i class="fas fa-caret-right"></i></span>
                        Amount Breakdown
                    </label>
                    <ul role="list" class="divide-y divide-gray-200" v-if="showAmountBreakdown && Object.keys(sprintAmountBreakdowns).length > 1">
                        <li
                            v-for="(amount, sprint_id) in sprintAmountBreakdowns"
                            :key="'amount-' + sprint_id"
                            class="py-2">
                            <div class="flex justify-between space-x-3">
                                <div class="min-w-0 flex-1">
                                    <a href="#" class="block focus:outline-none">
                                        <p class="text-xs font-medium text-gray-900 truncate">{{ sprintNames[sprint_id] }}</p>
                                        <p class="text-xs text-gray-500 truncate">{{ clientNameBySprint[sprint_id] }}</p>
                                    </a>
                                </div>
                                <time datetime="2021-01-27T16:35" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                    {{ money(amount) }}
                                    <span
                                        v-if="sprintBalance(sprint_id) < 0"
                                        class="inline-block text-center bg-red-100 text-red-800 border border-red-200 rounded-full px-2 whitespace-nowrap"
                                    >{{ money(Math.abs(sprintBalance(sprint_id))) }} over</span>
                                    <span
                                        v-else-if="sprintBalance(sprint_id) != null"
                                        class="inline-block text-center bg-green-100 text-green-800 border border-green-200 rounded-full px-2 whitespace-nowrap"
                                    >{{ money(sprintBalance(sprint_id)) }} left</span>
                                </time>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
            <div class="mb-4">
                <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <textarea
                        v-model="e.description"
                        class="block w-full sm:text-sm sm:leading-5"
                        placeholder="Detailed description of what work was done"
                        rows="2"
                    ></textarea>
                </div>
            </div>
            <div class="mb-4">
                <label @click='showTicketNumbers = !showTicketNumbers' for="ticket_numbers" class="block text-sm font-medium leading-5 text-gray-700 cursor-pointer">
                    <span v-if="showTicketNumbers"><i class="fas fa-caret-down"></i></span>
                    <span v-else><i class="fas fa-caret-right"></i></span>
                    Ticket Numbers
                </label>
                <div class="mt-1 relative rounded-md shadow-sm" v-if="showTicketNumbers">
                    <textarea
                        v-model="e.ticket_numbers"
                        class="block w-full sm:text-sm sm:leading-5"
                        placeholder="Relevant GitLab/ZenDesk Ticket numbers"
                        :rows="numberOfNewLinesIn(e.ticket_numbers)"
                    ></textarea>
                </div>
            </div>
            <template v-if='unpaidSprintSelected'>
                <div class="bg-red-50 border-l-4 border-red-400 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <p class="text-sm text-red-700">
                                You have selected a work order that has not yet been paid.
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <div
                class="mt-6 flex justify-center"
                v-if="!hideButtons"
            >
                <btn
                    :disabled="saving"
                    red outline
                    @click='$emit("cancel")'
                >Cancel</btn>
                <div class="flex-grow text-right">
                    <btn
                        :loading="saving"
                        blue
                        @click='save'
                    >Save Changes</btn>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import btn from "./btn.vue"
import toggle from "./toggle.vue"
import modal from "./modal.vue"
import money from "./money.vue"
import number from "./number.vue"
import alert from "./alert.vue"
import hgselect from "./hgselect.vue"
import entryHistory from "./entryHistory.vue"
import axios from "axios"
import { get, clone, pickBy, find, difference } from "lodash"
import moment from "moment"
import { nextTick } from "vue"

export default {
    name: 'TimeEntryForm',
    props: {
        modelValue: Object,
        allowMultipleClients: {
            type: Boolean,
            default: true
        },
        showDelete: {
            type: Boolean,
            default: true
        },
        hideButtons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        btn, hgselect, money, number, modal, alert, entryHistory, toggle
    },
    data() {
        const e = clone(this.modelValue);
        e.client_id = e.client_id ? [e.client_id] : []
        if (e.client_id.length) {
            const cid = e.client_id[0]
            if (e.request_sprint) {
                e.sprint_id = {
                    [cid]: ['new-sprint']
                }
            } else if (e.request_invoice) {
                e.sprint_id = {
                    [cid]: ['new-invoice']
                }
            } else if(e.sprint_id) {
                e.sprint_id = {
                    [cid]: [e.sprint_id]
                }
            } else {
                e.sprint_id = {
                    [cid]: []
                }
            }
        }

        return {
            e,
            showTicketNumbers: !!this.modelValue.ticket_numbers,
            showAmountBreakdown: false,
            original: clone(this.modelValue),
            selectByTag: false,
            clientTag: null,
            changeWeek: false,
            showHistory: false,
            openDropdownMenu: false,
            saving: false,
            deleting: false,
            optionsData: null,
            sprintAmounts: {},
            sprintAmountsKey: (new Date).getTime()
        }
    },
    computed: {
        unpaidSprintSelected() {
            if (this.optionsData) {
                var client_ids = Object.keys(this.e.sprint_id || {})
                for(var idx = 0; idx < client_ids.length; idx++) {
                    var client_id = client_ids[idx]
                    var sprint_ids = this.e.sprint_id[client_id] || []
                    for(var x = 0; x < sprint_ids.length; x++) {
                        var sprint_id = sprint_ids[x]
                        var sprint = find(this.optionsData.sprints, {id: sprint_id})
                        if (sprint && !sprint.is_paid) {
                            return true
                        }
                    }
                }
            }
            return false
        },
        sprintAmountBreakdowns() {
            let obj = clone(this.e)
            if (!obj.billable) {
                return {}
            }

            // Let's split the hours and bills across all the clients
            let count = this.e.client_id.length
            let actual = obj.actual / count
            let planned = obj.planned / count
            let billableNow = obj.billableNow / count
            let billableGoal = obj.billableGoal / count

            obj.actual = actual
            obj.planned = planned
            obj.billableNow = billableNow
            obj.billableGoal = billableGoal

            var sprintAmounts = {}

            for(var idx = 0; idx < this.e.client_id.length; idx++) {
                obj.client_id = this.e.client_id[idx]

                // We need to sort these sprints by the amount remaining
                // so we can spread the amount remaining around these sprints
                // in a way to evenly distributes them but also fills up
                var sortedSprints = [].concat(this.e.sprint_id[obj.client_id])
                var sprintsAmountRemaining = {}
                sortedSprints.sort((a, b) => {
                    var aSprint = find(this.optionsData.sprints, {id: a})
                    var bSprint = find(this.optionsData.sprints, {id: b})
                    var aAmount = get(this.sprintAmounts, a, 0)
                    var bAmount = get(this.sprintAmounts, b, 0)
                    var aRemaining = aSprint ? (aSprint.maximum - aAmount) : 0
                    var bRemaining = bSprint ? (bSprint.maximum - bAmount) : 0
                    sprintsAmountRemaining[a] = aRemaining
                    sprintsAmountRemaining[b] = bRemaining
                    if (aRemaining > bRemaining) {
                        return 1
                    }
                    if (bRemaining > aRemaining) {
                        return -1
                    }
                    return 0
                })

                var amountToDistribute = obj.billableNow;
                var numberOfSprintsRemaining = sortedSprints.length
                sortedSprints.forEach((sprintId) => {
                    var remaining = sprintsAmountRemaining[sprintId]
                    // If we have more available than the average amount remaining,
                    // set the average, otherwise use up the remaining - unless its
                    // the last or only one.
                    if (remaining <= 0) {
                        sprintAmounts[sprintId] = 0
                    } else if (numberOfSprintsRemaining == 1 || remaining > amountToDistribute / numberOfSprintsRemaining) {
                        sprintAmounts[sprintId] = Math.round((amountToDistribute / numberOfSprintsRemaining) * 100) / 100
                    } else {
                        sprintAmounts[sprintId] = remaining
                    }
                    amountToDistribute -= sprintAmounts[sprintId]
                    numberOfSprintsRemaining -= 1
                })
            }

            return sprintAmounts
        },
        clientIdSet() {
            return !!this.original.client_id
        },
        weeks() {
            let weeks = []
            for (let i = -1; i < 104; i++) {
                weeks.push(
                    moment()
                    .startOf("week")
                    .subtract(i, 'weeks')
                    .format('YYYY-MM-DD')
                )
            }
            return weeks
        },
        sprintNames() {
            const names = {}
            this.optionsData.sprints.forEach((sprint) => {
                names[sprint.id] = sprint.name
            })
            return names
        },
        projectNames() {
            const names = {}
            this.optionsData.projects.forEach((project) => {
                names[project.id] = project.name
            })
            return names
        },
        projectsPerClient() {
            const projects = {}
            this.optionsData.projects.forEach((project) => {
                if (!(project.client_id in projects)) {
                    projects[project.client_id] = []
                }
                projects[project.client_id].push(project)
            })
            return projects
        },
        clientNameBySprint() {
            const names = {}
            this.optionsData.sprints.forEach((sprint) => {
                names[sprint.id] = this.optionsData.clients[sprint.client_id]
            })
            return names
        },
        options() {
            const options = {
                key: this.sprintAmountsKey + 1,
                weeks: this.optionsData ? this.optionsData.weeks : null,
                users: this.optionsData ? this.optionsData.users : null,
                clients: this.optionsData ? this.optionsData.clients : null,
                clientTags: this.optionsData ? Object.keys(this.optionsData.clientTags) : null,
                types: this.optionsData ? this.optionsData.types : null,
                sprintsByClient: {
                },
                statuses: {
                    active: "Active",
                    pending: "Pending",
                    complete: "Complete"
                },
                sprintStatuses: {
                    active: "Active",
                    finished: "Finished",
                    archived: "Archived"
                }
            }

            if (this.optionsData && !(this.e.user_id in options.users)) {
                options.users[this.e.user_id] = this.e.user_id in this.optionsData.inactiveUsers ? this.optionsData.inactiveUsers[this.e.user_id] : this.e.user_id
            }

            if (this.clientTag) {
                const _clients = {}
                this.optionsData.clientTags[this.clientTag].forEach(client_id =>{
                    _clients[client_id] = options.clients[client_id]
                })
                const keys = Object.keys(_clients)
                keys.sort(function(a, b) {
                    var aClient = _clients[a]
                    var bClient = _clients[b]
                    if (aClient > bClient) {
                       return 1
                    } else if (aClient < bClient) {
                        return -1
                    }
                    return 0
                });
                const clients = {}
                keys.forEach((key) => {
                    clients[key] = _clients[key]
                })
                options.clients = clients
                var new_client_id = []
                var new_sprint_id = {}
                for(var idx = 0; idx < this.e.client_id.length; idx++) {
                    var client_id = this.e.client_id[idx]
                    if (client_id in options.clients) {
                        new_client_id.push(client_id)
                        new_sprint_id[client_id] = this.e.sprint_id[client_id]
                    }
                }

                if (difference(this.e.client_id, new_client_id).length) {
                    this.e.client_id = new_client_id
                }
                this.e.sprint_id = new_sprint_id
            }

            var client_ids = this.e.client_id
            client_ids.forEach((client_id) => {
                if (this.optionsData && 'sprints' in this.optionsData) {
                    options.sprintsByClient[client_id] = {}
                    Object.values(
                        pickBy(
                            this.optionsData.sprints,
                            { client_id }
                        )
                    ).filter((sprint) => {
                        if (sprint.status == 'active') {
                            return true
                        }

                        var sprintArrays = this.e.sprint_id ? Object.values(this.e.sprint_id) : []
                        for(var idx = 0; idx < sprintArrays.length; idx++) {
                            if ((sprintArrays[idx] || []).indexOf(sprint.id) >= 0) {
                                return true
                            }
                        }

                        return false
                    }).forEach((sprint) => {
                        var icon = ''
                        if (sprint.is_paid) {
                            icon = '<span><i class="fal fa-fw fa-check text-green-600" title="Work Order is paid for!"></i></span>'
                        } else {
                            icon = '<span><i class="fal fa-fw fa-times text-red-600" title="Work Order has not been paid for!"></i></span>'
                        }

                        let sprintAmount = get(this.sprintAmounts, sprint.id, 0)
                        let amount = this.money(sprintAmount)
                        if (sprintAmount > Number(sprint.maximum)) {
                            amount = `<span class='text-red-600'>${amount}</span>`
                        }
                        let projectName = '';
                        const projectCount = sprint.client_id in this.projectsPerClient ? this.projectsPerClient[sprint.client_id].length : 0;
                        if (sprint.project_id && projectCount > 1) {
                            projectName = `<span class="text-xs text-gray-600">${this.projectName(sprint.project_id)}</span>`
                        }
                        options.sprintsByClient[client_id][sprint.id] =
                            `<div class="flex w-full">
                                <span class="flex-shrink flex flex-col truncate">
                                    <span>${sprint.name}</span>
                                    ${projectName}
                                </span>
                                <span class="ml-auto text-xs text-gray-600" style="white-space: nowrap;">${amount} / ${this.money(sprint.maximum)} ${icon}</span>
                            </div>`

                        if (sprint.status != "active") {
                            options.sprintsByClient[client_id][sprint.id] += "<span class='ml-1 text-red-800'>" + sprint.status[0].toUpperCase() + sprint.status.slice(1) + '</span>'
                        }
                    })
                }
            })
            return options
        }
    },
    mounted() {
        this.loadOptions(() => {
            if (this.e.client_id) {
                this.loadSprintOptions(() => {
                    this.loadSprintAmounts(this.e.client_id)
                })
            }
        })
    },
    methods: {
        loadSprintAmounts(client_ids) {
            if (!Array.isArray(client_ids)) {
                client_ids = [client_ids]
            }
            client_ids.forEach((client_id) => {
                if (this.options.sprintsByClient && this.options.sprintsByClient[client_id]) {
                    Object.keys(this.options.sprintsByClient[client_id]).forEach((sprint_id) => {
                        if (!(sprint_id in this.sprintAmounts)) {
                            axios.get(this.$route("sprints.amount", sprint_id)).then(({data}) => {
                                this.sprintAmounts[sprint_id] = Number(data.amount)
                                this.sprintAmountsKey = (new Date).getTime()
                            })
                        }
                    })
                }
            })
        },
        sprintBalance(sprint_id) {
            if (this.optionsData) {
                const sprint = find(this.optionsData.sprints, {id: sprint_id})
                if (sprint && this.sprintAmountBreakdowns[sprint.id] > 0) {
                    // if we opened the found with the sprint already selected,
                    // we need to substract our current value from this sprint
                    // otherwise we will go over, even if we aren't because this
                    // entry will be double counted.
                    let amount = Number(get(this.sprintAmounts, sprint.id, 0))
                    if (this.original.sprint_id == sprint.id) {
                        amount -= this.sprintAmountBreakdowns[sprint.id]
                    }

                    return Number(sprint.maximum) - (amount + (this.sprintAmountBreakdowns[sprint.id]))
                }
            }
            return null
        },
        clientName(entry) {
            return get(this.options.clients, entry.client_id, '--')
        },
        projectName(project_id) {
            return get(this.projectNames, project_id, '--');
        },
        sprintName(entry) {
            return get(this.sprintNames, entry.sprint_id, '--')
        },
        money(num) {
            var n = Number(num)
            var symbol = n >= 0 ? '$' : '-$'
            return (
                symbol + Math.abs(n).toLocaleString(undefined, { minimumFractionDigits: 2 })
            )
        },
        numberOfNewLinesIn(str) {
            if (str) {
                let matches = str.match(/\n/g)
                if (matches) {
                    return Math.min(matches.length + 1, 5)
                }
            }
            return 1
        },
        toggleBillable(value) {
            this.e['billable'] = value === undefined ? !this.e.billable : value
        },
        async deleteEntry() {
            if (confirm("Are you sure you want to delete this?")) {
                this.deleting = true
                let obj = clone(this.e)
                await axios.delete(
                    this.$route("time_entries.destroy", obj.id),
                )
                this.deleting = false
                this.$emit('deleted', obj)
            }
        },
        async save() {
            var allSprintsSet = true
            if (this.e.sprint_id) {
                Object.values(this.e.sprint_id).forEach((sprint_ids) => {
                    allSprintsSet = allSprintsSet && sprint_ids.length
                })
            }

            if (this.e.billable && !allSprintsSet) {
                if (!confirm("You have marked this as billable but did not choose an work order to bill against. Are you sure?")) {
                    return false;
                }
            }

            this.saving = true
            let obj = clone(this.e)
            if (!obj.billable) {
                obj.rate = null;
                obj.billableNow = null;
                obj.billableGoal = null;
            }

            // Let's split the hours and bills across all the clients
            let count = this.e.client_id.length
            let actual = obj.actual / count
            let planned = obj.planned / count
            let billableGoal = obj.billableGoal / count

            for(var idx = 0; idx < this.e.client_id.length; idx++) {
                obj.request_sprint = false;
                obj.request_invoice = false;
                obj.client_id = this.e.client_id[idx]

                if (!this.e.billable || !(obj.client_id in this.e.sprint_id)) {
                    this.e.sprint_id[obj.client_id] = []
                }

                if (this.e.sprint_id[obj.client_id].length == 1) {
                    if (this.e.sprint_id[obj.client_id][0] == 'new-sprint') {
                        obj.request_sprint = true;
                        this.e.sprint_id[obj.client_id] = [];
                    } else if (this.e.sprint_id[obj.client_id][0] == 'new-invoice') {
                        obj.request_invoice = true;
                        this.e.sprint_id[obj.client_id] = [];
                    }
                }

                const numSprints = Math.max(1, this.e.sprint_id[obj.client_id].length)
                obj.actual = actual / numSprints
                obj.planned = planned / numSprints
                obj.billableGoal = billableGoal / numSprints

                for (var sidx = 0; sidx < numSprints; sidx++) {
                    obj.sprint_id = sidx in this.e.sprint_id[obj.client_id] ? this.e.sprint_id[obj.client_id][sidx] : null
                    if (!(numSprints == 1 && !obj.sprint_id)) {
                        obj.billableNow = this.sprintAmountBreakdowns[obj.sprint_id]
                    }
                    obj.flat_rate = obj.flat_rate || (obj.actual && obj.billableNow / obj.actual !== Number(obj.rate))

                    if (obj.id) {
                        await axios.post(
                            this.$route("time_entries.update", obj.id),
                            {
                                '_method': 'PUT',
                                ...obj
                            }
                        )
                        obj.id = null
                    } else {
                        await axios.post(
                            this.$route("time_entries.store"),
                            obj
                        )
                    }
                }
            }

            // Since an entry might create more than one (like if
            // there is an overage, so let's just reload them)
            this.$emit('reloadWithSprints')
            this.$emit('saved', obj)
            this.saving = false
        },
        async loadOptions(callback) {
            const response = await axios.get('/time_entries/get/options')
            this.optionsData = response.data
            nextTick(() => {
                callback && callback()
            })
        },
        async loadSprintOptions(callback) {
            if (this.e.client_id) {
                this.optionsData.sprints = []
                for(let idx in this.e.client_id) {
                    const response = await axios.get(`/time_entries/get/${this.e.client_id[idx]}/sprints/options`)
                    this.optionsData.sprints.push(...response.data.sprints)
                }
                nextTick(() => {
                    callback && callback()
                })
            } else {
                callback && callback()
            }
        },
        closeDropdowns() {
            this.openDropdownMenu = false
            const refs = [
                'client-dropdown', 'user-dropdown',
                'sprint-dropdown', 'type-dropdown',
                'status-dropdown'
            ]
            refs.forEach((ref) => {
                if (this.$refs[ref]) {
                    if (Array.isArray(this.$refs[ref])) {
                        this.$refs[ref].forEach((r) => {
                            r.close()
                        })
                    } else {
                        this.$refs[ref].close()
                    }
                }
            })
        },
        selectAllClients() {
            this.e.client_id = Object.keys(this.options.clients)
        }
    },
    watch: {
        selectByTag() {
            if (!this.selectByTag) {
                this.clientTag = null
            }
        },
        'e.sprint_id': {
            deep: true,
            handler() {
                const client_ids = Object.keys(this.e.sprint_id || {})
                client_ids.forEach((client_id) => {
                    if (this.e.sprint_id[client_id] && this.e.sprint_id[client_id].length > 1) {
                        if (
                            this.e.sprint_id[client_id].length == 2 &&
                            this.e.sprint_id[client_id].indexOf('new-sprint') >= 0 &&
                            this.e.sprint_id[client_id].indexOf('new-invoice') >= 0
                        ) {
                            // the user is switching between requests, keep the second one.
                            this.e.sprint_id[client_id] = [this.e.sprint_id[client_id][1]]
                            return;
                        }

                        // if the new-sprint or new-invoice is the last item in the array (most recently
                        // selected one), then remove the others.
                        const newSprintIndex = this.e.sprint_id[client_id].indexOf('new-sprint')
                        const newInvoiceIndex = this.e.sprint_id[client_id].indexOf('new-invoice')

                        if (newSprintIndex === (this.e.sprint_id[client_id].length - 1)) {
                            this.e.sprint_id[client_id] = ['new-sprint']
                            return;
                        }
                        if (newInvoiceIndex === (this.e.sprint_id[client_id].length - 1)) {
                            this.e.sprint_id[client_id] = ['new-invoice']
                            return;
                        }

                        // If the new-sprint or new-invoice is not the most recently selected sprint,
                        // then remove it.
                        if (newSprintIndex >= 0 && newSprintIndex < (this.e.sprint_id[client_id].length - 1)) {
                            this.e.sprint_id[client_id].splice(newSprintIndex, 1)
                            return;
                        }

                        if (newInvoiceIndex >= 0 && newInvoiceIndex < (this.e.sprint_id[client_id].length - 1)) {
                            this.e.sprint_id[client_id].splice(newInvoiceIndex, 1)
                            return;
                        }
                    }
                })
            }
        },
        'e.type' () {
            // Change the rate based on the default
            this.e.rate = Number(this.optionsData.defaultRates[this.e.type]);
            // Then check if the client has overridden it
            if (this.e.client_id) {
                this.e.client_id.forEach((client_id) => {
                    if (this.optionsData) {
                        var rates = this.optionsData.clientRates[client_id];
                        if (rates && this.e.type in rates) {
                            this.e.rate = Number(rates[this.e.type]);
                        }
                    }
                })
            }
        },
        'e.client_id' () {
            if (this.e.client_id) {
                this.loadSprintOptions(() => {
                    this.loadSprintAmounts(this.e.client_id)
                })
            }
            if (this.optionsData) {
                this.e.client_id.forEach((client_id) => {
                    var rates = this.optionsData.clientRates[client_id];

                    if (rates && this.e.type in rates) {
                        this.e.rate = Number(rates[this.e.type]);
                    } else {
                        this.e.rate = Number(this.optionsData.defaultRates[this.e.type]);
                    }

                    if (!this.e.sprint_id) {
                        this.e.sprint_id = {}
                    }
                    if (!(client_id in this.e.sprint_id)) {
                        this.e.sprint_id[client_id] = []
                    }
                })
            }
            Object.keys(this.e.sprint_id).forEach((client_id) => {
                if (!this.e.sprint_id) {
                    this.e.sprint_id = {}
                }
                if (this.e.client_id.indexOf(client_id) === -1) {
                    delete this.e.sprint_id[client_id]
                }
            })
        },
        'e.actual' () {
            if (this.e && !this.e.flat_rate) {
                this.e.billableNow = Number(this.e.actual) * Number(this.e.rate)
            }
        },
        'e.planned' () {
            if (this.e && !this.e.flat_rate) {
                this.e.billableGoal = Number(this.e.planned) * Number(this.e.rate)
            }
        },
        'e.rate' () {
            if (this.e && !this.e.flat_rate) {
                this.e.billableGoal = Number(this.e.planned) * Number(this.e.rate)
                this.e.billableNow = Number(this.e.actual) * Number(this.e.rate)
            }
        },
        'e.flat_rate' () {
            if (this.e && !this.e.flat_rate) {
                this.e.billableGoal = Number(this.e.planned) * Number(this.e.rate)
                this.e.billableNow = Number(this.e.actual) * Number(this.e.rate)
            }
        },
        e: {
            deep: true,
            handler() {
                this.$emit("update:modelValue", this.e)
            }
        }
    }
}
</script>

<style lang="postcss" scoped>

</style>
