<template>
    <span
        aria-hidden="true"
        class="h-4 w-4 mr-3 rounded-full flex items-center justify-center"
        :class="{
            'bg-gray-200': gray || (!green && !blue && !red),
            'bg-green-200': green,
            'bg-blue-200': blue,
            'bg-red-200': red,
        }"
    >
        <span
            class="h-2 w-2 rounded-full"
            :class="{
                'bg-gray-400': gray || (!green && !blue && !red),
                'bg-green-400': green,
                'bg-blue-400': blue,
                'bg-red-400': red,
            }"
        ></span>
    </span>
</template>

<script>
export default {
    name: 'glow',
    props: {
        green: {
            type: Boolean,
            default: false
        },
        blue: {
            type: Boolean,
            default: false
        },
        red: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
    }
}
</script>
