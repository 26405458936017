<template>
    <Layout :auth='auth'>
        <Head :title="client ? client.name : 'Create Client'" />
        <template v-slot:title>
            <template v-if="client">{{ client.name }}</template>
            <template v-else>Create Client</template>
        </template>
        <template v-slot:actions>
            <btn v-if="client" white :href="'/clients/'+client.id" outline class="mr-2">
                Back To Client
            </btn>
            <btn v-if="client" white @click="save" :loading="saving">
                Save Client
            </btn>
            <btn v-else white @click="save" :loading="saving">
                Create Client
            </btn>
        </template>

        <form action="#" method="POST" @submit.prevent="save">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Client Information
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        This information will be used in various places in the
                        app.
                    </p>
                    <label
                        class="mt-4 flex items-center"
                        @click="is_active = !is_active"
                    >
                        <toggle v-model="is_active" />
                        Client is Active
                    </label>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label
                            for="name"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <inp
                            type="text"
                            name="name"
                            v-model="name"
                        ></inp>
                        <error v-if="errors.name">{{
                            errors.name[0]
                        }}</error>
                    </div>

                    <div class="grid grid-cols-3 gap-6 mt-6">
                        <div class="col-span-3">
                            <label
                                for="website"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Website
                            </label>
                            <inp
                                name="website"
                                v-model="website"
                                prefix="http://"
                                placeholder="www.example.com"
                            />
                            <error v-if="errors.website">{{
                                errors.website[0]
                            }}</error>
                        </div>
                    </div>

                    <div class="mt-6">
                        <label
                            for="description"
                            class="block text-sm leading-5 font-medium text-gray-700 mb-1"
                        >
                            Tags
                        </label>
                        <div class="rounded-md shadow-sm">
                            <hgselect
                                name="tags"
                                v-model="tags"
                                :create-new="addClientTag"
                                :options="availableTags"
                                multiple
                                placeholder="Add some tags..."
                            ></hgselect>
                        </div>
                        <error v-if="errors.tags">{{
                            errors.tags[0]
                        }}</error>
                    </div>


                    <div class="mt-6">
                        <label
                            for="description"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Description
                        </label>
                        <div class="rounded-md shadow-sm">
                            <textarea
                                name="description"
                                v-model="description"
                                rows="3"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                placeholder="you@example.com"
                            ></textarea>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">
                            Brief description of the client.
                        </p>
                        <error v-if="errors.description">{{
                            errors.description[0]
                        }}</error>
                    </div>

                    <div class="mt-6">
                        <label class="block text-sm leading-5 font-medium text-gray-700">
                            Client Logo
                        </label>
                        <div class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div class="text-center">
                                <img
                                    v-if="logoPreview"
                                    :src="logoPreview"
                                    class="mx-auto h-12 w-12 text-gray-400"
                                />
                                <svg
                                    v-else
                                    class="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                >
                                    <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <p class="mt-1 text-sm text-gray-600">
                                    <span
                                        @click.prevent="$refs.logo.click()"
                                        class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                                    >
                                        Upload a file
                                    </span>
                                </p>
                                <p class="mt-1 text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                                <input
                                    ref="logo"
                                    class="hidden"
                                    type="file"
                                    @change="selectLogo"
                                />
                                <error v-if="errors.logo">{{
                                    errors.logo[0]
                                }}</error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 mt-10">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Contact Information
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        How to get in touch with the client
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label
                            for="contact_name"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Name
                        </label>
                        <div class="rounded-md shadow-sm">
                            <inp
                                type="text"
                                name="contact_name"
                                v-model="contact_name"
                            />
                        </div>
                        <error v-if="errors.contact_name">{{
                            errors.contact_name[0]
                        }}</error>
                    </div>

                    <div class="mt-5">
                        <label
                            for="contact_email"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Email Address
                        </label>
                        <div class="rounded-md shadow-sm">
                            <input
                                type="email"
                                name="contact_email"
                                v-model="contact_email"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                        <error v-if="errors.contact_email">{{
                            errors.contact_email[0]
                        }}</error>
                    </div>

                    <div class="mt-5">
                        <label
                            for="contact_phone"
                            class="block text-sm leading-5 font-medium text-gray-700"
                        >
                            Phone Number
                        </label>
                        <div class="rounded-md shadow-sm">
                            <input
                                type="text"
                                name="contact_phone"
                                v-model="contact_phone"
                                class="mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                        <error v-if="errors.contact_phone">{{
                            errors.contact_phone[0]
                        }}</error>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 mt-10">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Billing Information
                    </h3>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div>
                        <label class="block text-sm leading-5 font-medium text-gray-700">
                            Default Rate Overrides
                        </label>
                        <div class="grid grid-cols-2 gap-3 mt-2">
                            <div v-for="(rate, slug) in rates" :key='"rate-" + slug'>
                                <label class="text-sm mb-1 block">{{ type(slug, 'name', slug) }}</label>
                                <money
                                    v-model="rates[slug]"
                                    class="block w-full sm:text-sm sm:leading-5"
                                    :placeholder="'$' + type(slug, 'rate', '')"
                                />
                            </div>
                        </div>
                        <error v-if="errors.rates">{{
                            errors.rates[0]
                        }}</error>
                    </div>
                    <div class="mt-3">
                        <label class="block text-sm leading-5 font-medium text-gray-700">
                            Add Rate Overrides
                        </label>
                        <div class="grid grid-cols-2 gap-3 mt-2">
                            <hgselect v-model="newRateOverride" :options="typeOptions" />
                            <btn @click='addOverride' :disabled='newRateOverride == null'>Add Override</btn>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import error from "../../Common/error.vue"
import inp from "../../Common/inp.vue"
import btn from "../../Common/btn.vue"
import money from "../../Common/money.vue"
import toggle from "../../Common/toggle.vue"
import { get, find } from "lodash"
import hgselect from '../../Common/hgselect.vue'
import { Head, usePage } from '@inertiajs/vue3'
import axios from "axios"

export default {
    components: { btn, money, toggle, inp, Head, Layout, error, hgselect },
    props: {
        auth: Object,
        clients: Array,
        types: Array,
        client: {
            type: Object,
            default: null
        },
        defaultRates: Object,
    },
    data() {
        const launch_date = (this.client && this.client.launch_date)
                            ? this.client.launch_date.split("T")[0]
                            : null
        return {
            newRateOverride: null,
            saving: false,
            id: this.client ? this.client.id : null,
            name: this.client ? this.client.name : "",
            status: this.client ? this.client.status : "active",
            launch_date,
            ga4_property_id: this.client ? this.client.ga4_property_id : null,
            google_ads_customer_ids: this.client ? this.client.google_ads_customer_ids : null,
            sem_rush_project_id: this.client ? this.client.sem_rush_project_id : null,
            sem_rush_campaigns_ids: this.client ? this.client.sem_rush_campaigns_ids : null,
            website: this.client ? this.client.website : "",
            domains: (this.client ? this.client.domains : []) || [],
            availableDomains: (this.client ? this.client.domains : []) || [],
            description: this.client ? this.client.description : "",
            tags: (this.client ? this.client.tags : []) || [],
            availableTags: (this.client ? this.client.tags : []) || [],
            logo: null,
            logoPreview: this.client ? this.client.logo : "",
            contact_name: this.client ? this.client.contact_name : "",
            contact_email: this.client ? this.client.contact_email : "",
            contact_phone: this.client ? this.client.contact_phone : "",
            is_active: this.client ? this.client.is_active : true,
            rates: get(this.client, 'rates', {}) || {},
            ga4: {
                changing: false,
                loading: false,
                accounts: null,
                properties: null,
                account_id: null,
            },
            gads: {
                changing: false,
                loading: false,
                customers: this.client ? this.client.google_ads_customer_ids : null
            },
            semRush: {
                changing: false,
                loading: false,
                projects: null
            }
        }
    },
    computed: {
        errors() {
            return usePage().props.errors;
        },
        statusOptions() {
            return {
                active: 'Active',
                canceled: 'Canceled',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            };
        },
        typeOptions() {
            var options = {}
            this.types.filter((type) => {
                return !(type.slug in this.rates)
            }).forEach((type) => {
                options[type.slug] = type.name
            })
            return options
        },
    },
    methods: {
        async getSEMRushProjects() {
            this.semRush.loading = true
            const { data } = await axios.get(`/semrush/projects`)
            this.semRush.loading = false
            const projects = {}
            Object.entries(data).forEach(([id, name]) => {
                projects[id] = `${name} (${id})`
            })
            this.semRush.projects = projects
        },
        async getGAdsCustomers() {
            this.gads.loading = true
            const { data } = await axios.get(`/gads/customers`)
            this.gads.loading = false
            const customers = {}
            Object.entries(data).forEach(([id, name]) => {
                customers[id] = `${name} (${id})`
            })
            this.gads.customers = customers
        },
        async getGA4Properties() {
            this.ga4.loading = true
            const { data } = await axios.get(`/ga4/properties/${this.ga4.account_id}`)
            this.ga4.loading = false
            const properties = {}
            Object.entries(data).forEach(([id, name]) => {
                properties[id] = `${name} (${id})`
            })
            this.ga4.properties = properties
        },
        async getGA4Accounts() {
            this.ga4.loading = true
            const { data } = await axios.get('/ga4/accounts')
            this.ga4.loading = false
            const accounts = {}
            Object.entries(data).forEach(([id, name]) => {
                accounts[id] = `${name} (${id})`
            })
            this.ga4.accounts = accounts
        },
        addClientTag(tag) {
            if (this.availableTags.indexOf(tag) == -1) {
                this.availableTags.push(tag)
            }
            if (this.tags.indexOf(tag) == -1) {
                this.tags.push(tag)
            }
        },
        addClientDomain(domain) {
            if (this.availableDomains.indexOf(domain) == -1) {
                this.availableDomains.push(domain)
            }
            if (this.domains.indexOf(domain) == -1) {
                this.domains.push(domain)
            }
        },
        addOverride() {
            const type = find(this.types, {slug: this.newRateOverride})
            this.rates[this.newRateOverride] = type.rate
            this.newRateOverride = null
        },
        type(slug, key, def) {
            const type = find(this.types, { slug })
            return get(type, key, def)
        },
        async save() {
            this.saving = true
            const data = new FormData()

            data.append("name", this.name || "")
            data.append("website", this.website || "")
            data.append("description", this.description || "")

            if (this.logo) {
                data.append("logo", this.logo, "logo")
            }

            this.tags.forEach((tag) => {
                data.append(`tags[]`, tag)
            })

            data.append("contact_name", this.contact_name || "")
            data.append("contact_email", this.contact_email || "")
            data.append("contact_phone", this.contact_phone || "")

            const keys = Object.keys(this.rates)
            keys.forEach((key) => {
                if (this.rates[key]) {
                    data.append(`rates[${key}]`, this.rates[key])
                }
            })
            data.append("is_active", this.is_active ? 1 : 0)

            try {
                if (this.id) {
                    data.append("_method", "PUT")
                    await axios.post(
                        this.$route("clients.update", this.id),
                        data
                    )
                } else {
                    await axios.post(this.$route("clients.store"), data)
                }
            } catch (err) {
                console.error(err)
            }
            this.saving = false
        },
        selectLogo(event) {
            this.logo = event.target.files[0]
            var fr = new FileReader()
            fr.onload = () => {
                this.logoPreview = fr.result
            }
            fr.readAsDataURL(event.target.files[0])
        }
    }
}
</script>

<style lang="postcss" scoped></style>
