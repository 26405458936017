<script setup>
const $props = defineProps({
    title: {
        type: String,
        default: 'Table'
    },
    data: {
        type: Object,
        default: () => ({
        })
    },
    meta: {
        type: Object,
        default: () => ({
        })
    },
    settings: {
        type: Object,
        default: () => ({
        })
    }
})

const parse = (value) => {
    if (Array.isArray(value)) {
        return value.map(v => parse(v)).join(' ')
    }

    if (typeof value === 'string') {
        if (value[0] == '=') {
            // We have a formula - lovely
            value.replace(/\$(.+?)\b/g, (match, key) => {
                value = value.replace(match, $props.settings[key])
            })
            const parts = value.split('|')
            value = eval(parts.shift().slice(1))
            // the rest are formatters
            parts.forEach(part => {
                switch(part) {
                    case 'money':
                    case 'currency':
                        value = Number(value).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        break;
                    case 'percentage':
                        value = `${value * 100}%`
                        break;
                }
            })
        }
    }

    return value;
}

</script>

<template>
    <div :class=meta.class :style=meta.style v-html="parse(data.value)">
    </div>
</template>

<style>
</style>
