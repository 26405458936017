<template>
  <modal @close='$emit("close")' overflowVisible size="2xl">
        <div class="overflow-x-auto">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Work Order
                            </th>
                            <th class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Remaining
                            </th>
                            <th class="cursor-pointer whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Amount To Apply
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(name, id) in options" :key="'sprint-' + id" class="bg-gray-50 odd:bg-gray-100">
                            <td class="cursor-pointer whitespace-nowrap px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                {{ name }}
                            </td>
                            <td class="cursor-pointer whitespace-nowrap px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                {{ money(findSprint(id).maximum - findSprint(id).amount) }}
                            </td>
                            <td class="cursor-pointer whitespace-nowrap px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                <money
                                    v-model="apply[id]"
                                    class="block w-full sm:text-sm sm:leading-5"
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                        <tr class="border-t bg-white">
                            <td colspan="2" class="cursor-pointer whitespace-nowrap px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                Total Overage Remaining
                            </td>
                            <td class="cursor-pointer whitespace-nowrap px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                {{ money(overage) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-2">
            <div class="mt-5 text-center">
                <btn
                    :loading="fixing"
                    :disabled="fixing || !canFix"
                    @click="fix"
                >Fix Overages</btn>
            </div>
        </div>
    </modal>
</template>

<script>
import btn from "../Common/btn.vue"
import money from "../Common/money.vue"
import modal from "../Common/modal.vue"
import hgselect from '../Common/hgselect.vue'
import { find } from 'lodash'
import axios from 'axios'

export default {
    components: { btn, modal, money, hgselect },
    props: {
        sprint: Object,
        entry: Object,
        sprints: Array
    },
    data () {
        return {
            fixing: false,
            apply: {}
        }
    },
    computed: {
        amountToCover() {
            let total = 0
            Object.keys(this.apply).forEach((sprint_id) => {
                total += Number(this.apply[sprint_id])
            })
            return total
        },
        overage() {
            var total = Number(this.sprint.amount) - Number(this.sprint.maximum)
            total -= this.amountToCover 
            return total
        },
        options() {
            const options = {}
            this.sprints.forEach((sprint) => {
                if (
                    this.sprint.client_id === sprint.client_id
                    && this.sprint.id !== sprint.id
                    && sprint.maximum
                    && (sprint.maximum - sprint.amount) > 0
                ) {
                    options[sprint.id] = sprint.name
                }
            })
            return options
        },
        canFix() {
            return true
        }
    },
    methods: {
        async fix() {
            if (this.canFix) {
                this.fixing = true

                const sprint_ids = Object.keys(this.apply)
                for(var idx = 0; idx < sprint_ids.length; idx++) {
                    var sprint_id = sprint_ids[idx]
                    var amount = Number(this.apply[sprint_id])
                    if (amount) {
                        var sprint = find(this.sprints, { id: sprint_id })
                        await axios.post(
                            this.$route("sprints.adjustment.create", sprint_id),
                            {
                                amount,
                                description: 'Transfer from ' + this.sprint.name
                            }
                        )
                        await axios.post(
                            this.$route("sprints.adjustment.create", this.sprint.id),
                            {
                                amount: -1 * amount,
                                description: 'Transfer to ' + sprint.name
                            }
                        )
                    }
                }

                // // We need to first create a negative entry to get
                // // this one back into shape
                // var entry = {
                //     week: DateTime.local().startOf("week").toISODate(),
                //     client_id: this.sprint.client_id,
                //     sprint_id: this.sprint.id,
                //     description: 'Work Order Adjustment',
                //     user_id: this.$page.auth.user.id,
                //     billable: 1,
                //     status: 'complete',
                //     flat_rate: true,
                //     planned: 0,
                //     actual: 0,
                //     billableNow: -1 * this.amountToCover,
                //     billableGoal: 0
                // }


                // await axios.post(this.$route("time_entries.store"), entry)

                // // now duplicate this entry and apply the amounts to
                // // different sprints
                // const sprint_ids = Object.keys(this.apply)
                // for (var idx = 0; idx < sprint_ids.length; idx++) {
                //     const sprint_id = sprint_ids[idx]
                //     if (this.apply[sprint_id]) {
                //         delete entry._method
                //         entry.sprint_id = sprint_id
                //         entry.billableNow = Number(this.apply[sprint_id])
                //         await axios.post(
                //             this.$route("time_entries.store"),
                //             entry
                //         )
                //     }
                // }

                this.fixing = false
                this.$emit("close")
            }
        },
        findSprint(id) {
            return find(this.sprints, {id})
        },
        money(num) {
            return (
                "$" +
                Number(num).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                })
            )
        }
    }
}
</script>

<style>

</style>