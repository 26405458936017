<template>
    <div>
        <error v-if="Object.keys(otherErrors).length">
            <div class="mb-4" v-html='otherErrors.join("<br>")'></div>
        </error>
        <div class="flex flex-col gap-y-4">
            <div>
                <label
                    for="name"
                    class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                >
                    Name
                </label>
                <inp v-model="u.name" />
                <error v-if="allErrors.name">{{
                    allErrors.name[0]
                }}</error>
            </div>
            <div>
                <label
                    for="email"
                    class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                >
                    Email
                </label>
                <inp v-model="u.email" type="email" />
                <error v-if="allErrors.email">{{
                    allErrors.email[0]
                }}</error>
            </div>
            <div>
                <label
                    for="phone"
                    class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                >
                    Phone Number
                </label>
                <inp v-model="u.phone" />
                <error v-if="allErrors.phone">{{
                    allErrors.phone[0]
                }}</error>
            </div>
            <div>
                <label
                    for="password"
                    class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                >
                    <template v-if="u.id">Change</template> Password
                </label>
                <inp v-model="u.password" type="password" />
                <error v-if="allErrors.password">{{
                    allErrors.password[0]
                }}</error>
            </div>
            <div>
                <label
                    for="password_confirmation"
                    class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                >
                    Confirm Password
                </label>
                <inp v-model="u.password_confirmation" type="password" />
                <error v-if="allErrors.password_confirmation">{{
                    allErrors.password_confirmation[0]
                }}</error>
            </div>
        </div>
        <div class="text-center mt-6" v-if="!hideButtons">
            <btn
                :loading="saving"
                blue
                @click='save'
            >
                <template v-if="u.id">Save Changes</template>
                <template v-else>Create User</template>
            </btn>
            <btn
                class="ml-2"
                :disabled="saving"
                red outline
                @click='$emit("cancel")'
            >Cancel</btn>
        </div>
    </div>
</template>

<script>
import btn from "./btn.vue"
import inp from "./inp.vue"
import error from "./error.vue"
import toggle from "./toggle.vue"
import hgselect from "./hgselect.vue"
import axios from "axios"
import { get } from "lodash"
import { usePage } from '@inertiajs/vue3'

export default {
    name: 'UserForm',
    props: {
        modelValue: Object,
        errors: {
            type: Object,
            default() {
                return {}
            }
        },
        hideButtons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        btn, hgselect, toggle, inp, error
    },
    data() {
        return {
            u: {
                ...this.modelValue,
                password: '',
                password_confirmation: '',
            },
            saving: false,
            saveErrors: {}
        }
    },
    computed: {
        allErrors() {
            return {
                ...this.errors,
                ...this.saveErrors
            }
        },
        otherErrors() {
            const formErrors = ['name', 'phone', 'email', 'password', 'password_confirmation']
            const otherErrors = []
            for (const key in this.errors) {
                if (!formErrors.includes(key)) {
                    otherErrors.push(...this.errors[key])
                }
            }
            return otherErrors
        }
    },
    mounted() {
    },
    methods: {
        async save () {
            this.saving = true
            try {
                await axios.post(
                    this.$route("users.store"),
                    this.u
                )
                this.$emit("saved")
            } catch(err) {
                this.saveErrors = get(err.response, 'data.errors', {})
            }
            this.saving = false
        }
    },
    watch: {
        u: {
            handler() {
                this.$emit("update:modelValue", this.u)
            },
            deep: true
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
