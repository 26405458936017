<template>
    <Layout :auth='auth'>
        <Head :title="project.name" />
        <template v-slot:title>{{ project.name }} for {{ client.name }}</template>
        <template v-slot:actions>
            <btn class="mr-4" gray :href="$route('clients.show', client.id)">
                <i class="fa fa-arrow-left mr-2"></i>
                Back to Client
            </btn>
            <btn class="mr-4" white outline @click="startMerge = true" v-if="Object.keys(otherProjects).length >= 1">
                <i class="fa fa-spinner-third fa-spin" v-if="merging"></i>
                Merge Project
            </btn>
            <btn class="mr-4" red @click="archive">
                <i class="fa fa-spinner-third fa-spin" v-if="archiving"></i>
                Archive Project
            </btn>
            <btn white :href="$route('projects.edit', project.id)">
                Edit Project
            </btn>
        </template>

        <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                <div class="flex items-center flex-wrap">
                    <span class="flex-grow">Project Information</span>
                    <img
                        v-if="client.logo"
                        :src="$url(client.logo)"
                        class="h-8 object-cover"
                    />
                </div>
            </h3>
        </div>
        <div class="px-4 py-5 sm:p-0">
            <dl>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    v-if="project.name"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Name
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ project.name }}
                    </dd>
                </div>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Status
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ translateStatus(project.status) }}
                    </dd>
                </div>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Start Date
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ date(project.start_date) || 'Not Started' }}
                    </dd>
                </div>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Launch Date
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ date(project.launch_date) || 'Not Launched' }}
                    </dd>
                </div>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    v-if="(project.domains || []).length > 0"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Domains
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        <ul class="flex flex-col gap-2">
                            <li v-for="domain in project.domains" :key="domain.id">
                                {{ domain }}
                            </li>
                        </ul>
                    </dd>
                </div>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    v-if="project.trello_board"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Trello Board
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        <a :href="project.trello_board.url" target="_blank">
                            {{ project.trello_board.name }}
                        </a>
                    </dd>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Services
                    </dt>
                    <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" >
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="text-left bg-gray-100 px-2 py-1">Service</th>
                                    <th class="text-left bg-gray-100 px-2 py-1">Level</th>
                                    <th class="text-left bg-gray-100 px-2 py-1">Setup Invoice</th>
                                    <th class="text-left bg-gray-100 px-2 py-1">Last Recurring Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="service in project.services" :key="service.id">
                                    <tr>
                                        <td class="px-2 py-1">{{ service.name }}</td>
                                        <td class="px-2 py-1">{{ service.level }}</td>
                                        <td class="px-2 py-1">
                                            <template v-if="recentInvoices[service.qb_setup_item_id]?.date">
                                                <Link :href="$route('payments.show', recentInvoices[service.qb_setup_item_id]?.payment_id)" target="_blank" class="text-blue-600 hover:underline">
                                                    {{ recentInvoices[service.qb_setup_item_id]?.payment_number }}
                                                </Link>
                                                &mdash;
                                                {{ date(recentInvoices[service.qb_setup_item_id]?.date)}}
                                            </template>
                                            <template v-else-if="hasPermission('manage:billing')">
                                                <label class="flex items-center gap-2">
                                                    <input type="checkbox" v-model="sendSetupInvoicesFor" :value="service.id" />
                                                    Send Invoice
                                                </label>
                                            </template>
                                            <template v-else><em>None</em></template>
                                        </td>
                                        <td class="px-2 py-1">
                                            <template v-if="recentInvoices[service.qb_item_id]?.date">
                                                <Link :href="$route('payments.show', recentInvoices[service.qb_item_id]?.payment_id)" target="_blank" class="text-blue-600 hover:underline">
                                                    {{ recentInvoices[service.qb_item_id]?.payment_number }}
                                                </Link>
                                                &mdash;
                                                {{ date(recentInvoices[service.qb_item_id]?.date) }}
                                            </template>
                                            <template v-else><em>None</em></template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <template v-if="hasPermission('manage:billing') && sendSetupInvoicesFor.length > 0">
                            <p class="text-sm text-gray-500 mt-3">
                                <btn small @click="sendSetupInvoice" :disabled="creatingInvoice">
                                    <i class="fa fa-spinner-third fa-spin mr-2" v-if="creatingInvoice"></i>
                                    Send Setup Invoices For {{ sendSetupInvoicesFor.length }} Service<template v-if="sendSetupInvoicesFor.length !== 1">s</template>
                                </btn>
                            </p>
                        </template>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="flex flex-col gap-5 ml-5">
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select
                        v-model="activeTab"
                        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                        <option value="activity">Activity</option>
                        <option value="work-orders">Work Orders</option>
                        <option value="payments">Payments</option>
                        <option value="time-entries">Time Entries</option>
                        <option value="users">User Accounts</option>
                    </select>
                </div>
                <div class="flex gap-4 border-t pt-4">
                    <btn
                        v-if="project.ga4_property_id || project.sem_rush_project_id"
                        :href="'/analytics/' + project.id"
                    >View Site Analytics</btn>
                    <btn
                        v-if="project.ga4_property_id"
                        :href="'/case-study/' + project.id"
                    >View Case Study</btn>
                </div>
            </div>

            <template v-if="activeTab == 'activity'">
                <div class="py-5 sm:p-0">
                </div>
            </template>
        </div>

        <modal :show="startMerge" @close='startMerge = false' overflow-visible>
            <p class="mb-2">Merge this project into another one. All of this projects time entries, memos, etc will be copied over to the other one.</p>
            <p class="italic mb-4">The other project will retain all of it's details and information.</p>
            <p class="font-bold mb-4">{{ project.name }} will be archived and it's details will be lost.</p>
            <div>
                <label for="project" class="block text-sm leading-5 font-medium text-gray-700">Merge <b>{{ project.name }}</b> Into...</label>
                <hgselect
                    id="project"
                    v-model="projectToMergeWith"
                    class="mt-1"
                    :options="otherProjects"
                ></hgselect>
                <btn class="mt-4" red @click="doMerge" :disabled="!projectToMergeWith">
                    <i class="fa fa-spinner-third fa-spin" v-if="merging"></i>
                    Merge Project
                </btn>
            </div>
        </modal>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import alert from "../../Common/alert.vue"
import btn from "../../Common/btn.vue"
import hgselect from "../../Common/hgselect.vue"
import checkbox from "../../Common/checkbox.vue"
import modal from "../../Common/modal.vue"
import { Head, Link } from '@inertiajs/vue3'
import moment from 'moment'
import axios from 'axios'
import { clone } from 'lodash'
import auth from "../../Common/Mixins/auth"

export default {
    mixins: [ auth ],
    components: {
        Layout, btn, alert, modal, hgselect, checkbox,
        Link, Head
    },
    props: {
        auth: Object,
        client: {
            type: Object,
            default: null
        },
        project: Object,
        projects: Array,
        recentInvoices: Object
    },
    data() {
        return {
            merging: false,
            startMerge: false,
            projectToMergeWith: null,
            sendSetupInvoicesFor: [],
            creatingInvoice: false
        }
    },
    computed: {
        otherProjects() {
            const projects = clone(this.projects)
            delete projects[this.project.id];
            return projects
        }
    },
    methods: {
        async sendSetupInvoice() {
            if (confirm("Are you sure you want to send setup invoices for " + this.sendSetupInvoicesFor.length + " service" + (this.sendSetupInvoicesFor.length !== 1 ? 's' : '') + "?")) {
                this.creatingInvoice = true
                await axios.post(this.$route("projects.sendSetupInvoice", this.project.id), {
                    services: this.sendSetupInvoicesFor
                })
                this.creatingInvoice = false
                this.$inertia.reload({ only: ['recentInvoices'] })
            }
        },
        date(date) {
            if (String(date).match(/^\d+$/)) {
                return moment.unix(date).format('MMM D, YYYY')
            }
            return date ? moment(date).format('MMM D, YYYY') : null
        },
        translateStatus(status) {
            const statuses = {
                active: 'Active',
                canceled: 'Canceled',
                delinquent: 'Delinquent',
                paused: 'Paused',
                lost: 'Lost',
                free: 'Free'
            }
            return statuses[status] || status
        },
        async archive() {
            if (confirm("Are you sure you want to archive this project?")) {
                this.archiving = true
                await axios.delete(
                    this.$route("projects.destroy", this.project.id)
                )
                this.$inertia.visit(this.$route("clients.show", this.client.id))
                this.archiving = false
            }
        },
        async doMerge () {
            if (confirm("Are you sure you want to merge these projects?")) {
                this.merging = true
                await axios.get(
                    this.$route("projects.merge", {
                        from: this.project.id,
                        into: this.projectToMergeWith
                    })
                )
                this.$inertia.visit(this.$route("clients.show", this.client.id))
                this.merging = false
            }
        },
    }
}
</script>

<style lang="postcss" scoped></style>
