<template>
    <Layout :auth='auth'>
        <Head title="Promotion Codes" />
        <template v-slot:title>
            <i class="fal fa-utensils"></i>
            Promotion Codes
        </template>
        <div class="p-4">
            <table v-if="promotions.data.length" class="min-w-full divide-y divide-gray-200 shadow">
                <thead>
                    <tr>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Code
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Description
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Amount
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Usage
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            Active
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                            End Date
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr class="even:bg-gray-50" v-for="promotion in promotions.data" :key="promotion.id">
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            <code>{{ promotion.code }}</code>
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            {{ promotion.discount_type == 'percentage' ? `-${Number(promotion.discount_amount).toFixed(0)}%` : `-$${Number(promotion.discount_amount).toFixed(2)}` }}
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            {{ promotion.description }}
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            {{ promotion.usage_count }} / {{ promotion.usage_limit || 'Unlimited' }}
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            {{ promotion.end_date ? dt(promotion.end_date) : 'Never' }}
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            <toggle
                                v-model="promotion.is_active"
                                @update:modelValue="updatePromotion(promotion, 'is_active', $event)"
                            />
                        </td>
                        <td class="px-6 py-3 whitespace-nowrap text-sm text-blue-700 leading-5 font-medium text-gray-900">
                            <a href="#" @click.prevent="deletePromotion(promotion)">
                                <i class="fas fa-trash-alt"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav v-if="promotions.next_page_url || promotions.prev_page_url" class="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6" aria-label="Pagination">
                <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium" v-html="promotions.from"></span>
                    to
                    <span class="font-medium" v-html="promotions.to"></span>
                    of
                    <span class="font-medium" v-html="promotions.total"></span>
                    results
                    </p>
                </div>
                <div class="flex justify-between flex-1 sm:justify-end">
                    <Link
                        :href="promotions.prev_page_url"
                        class="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        :class="{
                            'opacity-50 pointer-events-none': promotions.from <= 1
                        }"
                    >Previous</Link>
                    <Link
                        :href="promotions.next_page_url"
                        class="relative inline-flex items-center px-3 py-2 ml-3 text-sm font-semibold text-gray-900 bg-white rounded-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        :class="{
                            'opacity-50 pointer-events-none': promotions.to >= promotions.total
                        }"
                    >Next</Link>
                </div>
            </nav>
            <div class="md:grid md:grid-cols-3 md:gap-6 mt-10">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Promo Codes
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        Promotional codes that can be used by Eversite clients to get discounts on Setup fees
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <template v-if="newPromo.errors">
                        <div class="bg-red-50 border border-red-200 p-4 rounded-md mb-4">
                            <ul class="text-sm leading-5 text-red-700">
                                <li v-for="(error, key) in newPromo.errors" :key="key">
                                    <span v-for="(msg, idx) in error" :key="key + '-' + idx">
                                        {{ msg }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <div class="grid grid-cols-2 gap-4 border-t pt-4">
                        <div>
                            <label
                                for="promo_code"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                            >
                                Code
                            </label>
                            <div class="relative">
                                <inp v-model="newPromo.code" />
                                <div class="absolute top-1/2 right-2 -translate-y-1/2">
                                    <i class="fal fa-refresh text-gray-400 hover:text-gray-600 cursor-pointer" @click="generateCode"></i>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label
                                for="promo_description"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                            >
                                Description
                            </label>
                            <inp v-model="newPromo.description" />
                        </div>
                        <div>
                            <label
                                for="promo_discount_amount"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                            >
                                Discount Amount
                            </label>
                            <template v-if="newPromo.discount_type == 'percentage'">
                                <number v-model="newPromo.discount_amount" suffix="%" class="w-full" />
                            </template>
                            <template v-else>
                                <money v-model="newPromo.discount_amount" class="w-full" />
                            </template>
                        </div>
                        <div>
                            <label
                                for="promo_discount_type"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                            >
                                Discount Type
                            </label>
                            <hgselect v-model="newPromo.discount_type" :options="{percentage: 'Percentage', fixed: 'Fixed Amount'}" />
                        </div>
                        <div>
                            <label
                                for="promo_usage_limit"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2"
                            >
                                Usage Limit
                            </label>
                            <number v-model="newPromo.usage_limit" class="w-full" />
                            <small>(leave blank for unlimited)</small>
                        </div>
                        <div>
                            <label
                                for="promo_end_date"
                                class="block text-sm leading-5 font-medium text-gray-700 mb-2 flex justify-between items-end"
                            >
                                End Date
                                <span class="text-right text-xs uppercase ml-auto inline-block text-gray-500">
                                    <a href="#" @click.prevent="newPromo.end_date = null">Clear</a>
                                </span>
                            </label>
                            <inp type="date" v-model="newPromo.end_date" />
                            <small>(leave blank for no expiration)</small>
                        </div>
                        <div class="col-span-2 flex justify-end">
                            <btn
                                :disabled="!canCreatePromo"
                                @click="createPromo"
                            >Create Promo</btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue"
import money from "../../Common/money.vue"
import inp from "../../Common/inp.vue"
import number from "../../Common/number.vue"
import btn from "../../Common/btn.vue"
import toggle from "../../Common/toggle.vue"
import hgselect from "../../Common/hgselect.vue"
import axios from "axios"
import { Head, Link } from '@inertiajs/vue3'
import moment from "moment"

export default {
    name: "PromotionCodes",
    props: {
        auth: Object,
        promotions: Object,
    },
    components: { btn, Head, Link, Layout, inp, hgselect, number, money, toggle },
    data() {
        return {
            newPromo: {
                code: '',
                description: '',
                discount_amount: 0,
                discount_type: 'percentage',
                usage_limit: '',
                end_date: null,
                is_active: true,
                errors: null
            }
        }
    },
    mounted() {
    },
    computed: {
        canCreatePromo() {
            return (this.newPromo.code || '').length > 0 && this.newPromo.discount_amount > 0
        }
    },
    methods: {
        async updatePromotion(promotion, key, value) {
            await axios.put(this.$route('promos.update', promotion.id), {
                [key]: value
            })
            this.$inertia.reload()
        },
        dt(date) {
            return moment(date).format('Y-MM-DD')
        },
        async deletePromotion(promotion) {
            if (confirm('Are you sure you want to delete this promotion?')) {
                await axios.delete(this.$route('promos.destroy', promotion.id))
                this.$inertia.reload()
            }
        },
        async createPromo() {
            if (!this.canCreatePromo) {
                return
            }

            try {
                await axios.post(
                    this.$route("promos.store"), this.newPromo
                )
            } catch (error) {
                this.newPromo.errors = error.response.data.errors
            }

            this.$inertia.reload()
        },

        generateCode() {
            this.newPromo.code = (Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 7)).toUpperCase()
        }
    }
}
</script>

<style lang="postcss" scoped></style>
