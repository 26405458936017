<template>
    <div class="relative">
        <input type="text" ref='input' :value="formatted" readonly @click="toggle()"/>
        <Teleport to="body">
            <div
                v-if="open"
                class="fixed inset-0 w-full h-full bg-white opacity-0 z-40"
                @click="toggle()"
            ></div>
            <div
                v-if="open"
                class="grid grid-cols-2 absolute border shadow-lg w-[500px] p-5 z-50 bg-white divide-x"
                :style="{ top: position.top + 'px', left: position.left + 'px' }"
            >
                <div v-for='(year, yidx) in years' :key='"year-" + yidx' class="grid grid-cols-3 first:pr-4 last:pl-4 gap-px">
                    <div class='col-span-3 text-center font-bold flex items-center justify-between text-sm mb-2 px2'>
                        <a href="#" @click.stop.prevent="years[yidx]--">
                            <i class="fa fa-arrow-left"></i>
                        </a>
                        {{ years[yidx] }}
                        <a href="#" @click.stop.prevent="years[yidx]++">
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                    <a
                        href="#"
                        v-for="(mon, num) in months"
                        :key="'month-' + num"
                        class="text-center text-xs p-1.5"
                        :class="{
                            'bg-blue-500 text-white rounded-l-lg': from[1] == num && from[0] == years[yidx],
                            'bg-blue-500 text-white rounded-r-lg': to[1] == num && to[0] == years[yidx],
                            'bg-blue-200': isInBetween(num, years[yidx])
                        }"
                        @mouseover="mouseover(num, years[yidx])"
                        @click.stop.prevent="clicked(num, years[yidx])"
                    >
                        {{ mon }}
                    </a>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
const STATE_IDLE = 0;
const STATE_PICKING_TO = 1;
export default {
    props: {
        modelValue: [Array],
        autofocus: {
            type: Boolean,
            default: false
        },
        allowNegative: {
            type: Boolean,
            default: true
        }
    },
    data() {
        let from = [0, 0]
        let to = [0, 0]
        if (this.modelValue && this.modelValue.length == 2) {
            from = this.modelValue[0].split('-').slice(0, 2).map(Number)
            to = this.modelValue[1].split('-').slice(0, 2).map(Number)
        }
        return {
            state: STATE_IDLE,
            years: [
                // this year
                from[0] || new Date().getFullYear(),
                // next year
                to[0] || (new Date().getFullYear() + 1)
            ],
            open: false,
            position: null,
            from,
            to
        }
    },
    computed: {
        months() {
            return {
                1: 'Jan',
                2: 'Feb',
                3: 'Mar',
                4: 'Apr',
                5: 'May',
                6: 'Jun',
                7: 'Jul',
                8: 'Aug',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec'
            }
        },
        formatted() {
            // If the from and to are the same, just show the month
            if (this.from[0] == this.to[0] && this.from[1] == this.to[1]) {
                return this.months[this.from[1]] + ' ' + this.from[0]
            }
            // if the years are the same, just show the months
            if (this.from[0] == this.to[0]) {
                return this.months[this.from[1]] + ' to ' + this.months[this.to[1]] + ' ' + this.from[0]
            }
            // show the full date
            return this.months[this.from[1]] + ' ' + this.from[0] + ' to ' + this.months[this.to[1]] + ' ' + this.to[0]
        }
    },
    methods: {
        toggle() {
            this.open = !this.open

            // get the position of the input, releative to the document
            this.position = {
                top: this.$refs.input.getBoundingClientRect().top + this.$refs.input.clientHeight + window.scrollY,
                left: this.$refs.input.getBoundingClientRect().left + window.scrollX
            }

            if (!this.open) {
                const from = this.from[0] + '-' + ('0' + this.from[1]).slice(-2) + '-01'
                const to = this.to[0] + '-' + ('0' + this.to[1]).slice(-2) + '-01'
                this.$emit('update:modelValue', [from, to])
            }
        },
        isInBetween(num, year) {
            const check = year + (num/12)
            const from = this.from[0] + (this.from[1]/12)
            const to = this.to[0] + (this.to[1]/12)
            return check > from && check < to
        },
        mouseover(num, year) {
            if (this.state == STATE_PICKING_TO) {
                this.to = [year, num]
            }
        },
        clicked(num, year) {
            if (this.state == STATE_IDLE) {
                this.from = [year, num]
                this.to = [year, num]
                this.state = STATE_PICKING_TO
            } else {
                this.to = [year, num]
                this.state = STATE_IDLE
                this.toggle()
            }
        }
    }
}
</script>

<style lang="postcss" scoped>
</style>
