<template>
    <Layout ref="layout" :auth="auth">
        <template v-slot:title>Eversite Portal</template>
        <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 rounded-xl border border-gray-200 overflow-hidden">
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Revenue</dt>
                <dd class="text-xs font-medium text-gray-700">+4.75%</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">$405,091.00</dd>
            </div>
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Overdue invoices</dt>
                <dd class="text-xs font-medium text-rose-600">+54.02%</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">$12,787.00</dd>
            </div>
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Outstanding invoices</dt>
                <dd class="text-xs font-medium text-gray-700">-1.39%</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">$245,988.00</dd>
            </div>
            <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
                <dt class="text-sm font-medium leading-6 text-gray-500">Expenses</dt>
                <dd class="text-xs font-medium text-rose-600">+10.18%</dd>
                <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">$30,156.00</dd>
            </div>
        </dl>
        <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-6">
            <li class="overflow-hidden rounded-xl border border-gray-200 bg-white">
                <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-white p-6">
                <img src="https://tailwindui.com/img/logos/48x48/tuple.svg" alt="Tuple" class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10">
                <div class="text-sm font-medium leading-6 text-gray-900">Tuple</div>
                <div class="relative ml-auto">
                    <button type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-0-button" aria-expanded="false" aria-haspopup="true">
                    <span class="sr-only">Open options</span>
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                    </svg>
                    </button>
                </div>
                </div>
                <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div class="flex justify-between gap-x-4 py-3">
                    <dt class="text-gray-500">Last invoice</dt>
                    <dd class="text-gray-700"><time datetime="2022-12-13">December 13, 2022</time></dd>
                </div>
                <div class="flex justify-between gap-x-4 py-3">
                    <dt class="text-gray-500">Amount</dt>
                    <dd class="flex items-start gap-x-2">
                    <div class="font-medium text-gray-900">$2,000.00</div>
                    <div class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/10">Overdue</div>
                    </dd>
                </div>
                </dl>
            </li>
            <li class="overflow-hidden rounded-xl border border-gray-200 bg-white">
                <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-white p-6">
                    <img src="https://tailwindui.com/img/logos/48x48/savvycal.svg" alt="SavvyCal" class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10">
                    <div class="text-sm font-medium leading-6 text-gray-900">SavvyCal</div>
                    <div class="relative ml-auto">
                        <button type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-1-button" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Open options</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                        </svg>
                        </button>
                    </div>
                </div>
                <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Last invoice</dt>
                        <dd class="text-gray-700"><time datetime="2023-01-22">January 22, 2023</time></dd>
                    </div>
                    <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Amount</dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-gray-900">$14,000.00</div>
                            <div class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Paid</div>
                        </dd>
                    </div>
                </dl>
            </li>
            <li class="overflow-hidden rounded-xl border border-gray-200 bg-white">
                <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-white p-6">
                    <img src="https://tailwindui.com/img/logos/48x48/reform.svg" alt="Reform" class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10">
                    <div class="text-sm font-medium leading-6 text-gray-900">Reform</div>
                    <div class="relative ml-auto">
                        <button type="button" class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" id="options-menu-2-button" aria-expanded="false" aria-haspopup="true">
                        <span class="sr-only">Open options</span>
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
                        </svg>
                        </button>
                    </div>
                </div>
                <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Last invoice</dt>
                        <dd class="text-gray-700"><time datetime="2023-01-23">January 23, 2023</time></dd>
                    </div>
                    <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-gray-500">Amount</dt>
                        <dd class="flex items-start gap-x-2">
                        <div class="font-medium text-gray-900">$7,600.00</div>
                        <div class="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Paid</div>
                        </dd>
                    </div>
                </dl>
            </li>
            </ul>

    </Layout>
</template>

<script>
import Layout from "../../Common/Layout.vue";
import auth from "../../Common/Mixins/auth";
import ListItem from "../Messages/ListItem.vue";

export default {
    mixins: [auth],
    components: { Layout, ListItem },
    props: {
        auth: Object
    },
    data() {
        return {};
    },
    mounted() {},
    beforeUnmount() {}
};
</script>
