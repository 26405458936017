<template>
    <div v-if="p && options" @click="closeDropdowns" class="relative">
        <div class="absolute top-0 right-0 z-50" v-if="showDelete">
            <div @click.stop="openDropdownMenu = !openDropdownMenu" class="hover:text-red-700 px-2 -mt-1 -mr-2">
                <i class="far fa-ellipsis-v cursor-pointer"></i>
                <transition
                    enter-class="transition ease-out duration-100"
                    enter-active-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-class="transition ease-in duration-75"
                    leave-active-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <div
                        v-if="openDropdownMenu"
                        class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <div class="py-1">
                            <a
                                v-if="showDelete"
                                @click.prevent.stop='deletePayment'
                                href="#"
                                class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"
                            >
                                <i
                                    v-if="deleting"
                                    class="fas fa-spinner-third fa-spin mr-3 text-gray-400 group-hover:text-gray-500"
                                ></i>
                                <i
                                    v-else
                                    class="fas fa-trash mr-3 text-gray-400 group-hover:text-gray-500"
                                ></i>
                                Delete Payment
                            </a>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="mb-4" v-if="errors && errors.length">
            <div class="rounded-md bg-red-50 p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <i class="fa fa-times text-red-400"></i>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                            There were {{ errors.length }} error{{ errors.length == 1 ? '' : 's' }} with your submission
                        </h3>
                        <div class="mt-2 text-sm text-red-700">
                            <ul class="list-disc pl-5 space-y-1">
                                <li v-for="(error,idx) in errors" :key="'err-' + idx">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="!p.id">
            <div class="mb-4">
                <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Import Invoice From QBO</label>
                <div class="flex gap-x-4 items-center">
                    <div class="mt-1 relative rounded-md shadow-sm flex-grow">
                        <input
                            v-model="checkingInvoiceNumber"
                            class="block w-full sm:text-sm sm:leading-5"
                            placeholder="Invoice Number"
                        />
                    </div>
                    <btn
                        :loading="checkingInvoice"
                        blue
                        outline
                        @click='checkInvoice'
                    >Check QBO</btn>
                </div>
                <div class="my-2" v-if="checkingInvoiceError">
                    <alert color="red">{{ checkingInvoiceError }}</alert>
                </div>
            </div>
            <hr class="mb-4" />
        </template>
        <div class="mb-4">
            <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Invoice Number</label>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input
                    type="text"
                    v-model="p.number"
                    class="block w-full sm:text-sm sm:leading-5"
                    placeholder="Invoice Number"
                />
            </div>
        </div>
        <div class="mb-4">
            <label for="client" class="block text-sm font-medium leading-5 text-gray-700">Client</label>
            <div class="mt-1 relative rounded-md shadow-sm">
                <hgselect
                    ref="client-dropdown"
                    v-model="p.client_id"
                    :options="options.clients"
                />
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6 items-center mb-4">
            <div>
                <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Amount</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <money
                        v-model="p.amount"
                        class="block w-full sm:text-sm sm:leading-5"
                        placeholder="$0.00"
                    />
                </div>
            </div>
            <div>
                <div class="flex items-center mt-5">
                    <button
                        @click="p.is_paid = !p.is_paid"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="toggleLabel"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        :class="{
                            'bg-blue-600': p.is_paid,
                            'bg-gray-200': !p.is_paid,
                        }"
                    >
                        <span class="sr-only">Use setting</span>
                        <span
                            aria-hidden="true"
                            class=" inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                            :class="{
                                'translate-x-5': p.is_paid,
                                'translate-x-0': !p.is_paid
                            }"
                        ></span>
                    </button>
                    <span class="ml-3 -mt-1" id="toggleLabel">
                        <span class="text-sm font-medium text-gray-900">Has Been Paid</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>
            <div class="mt-1 relative rounded-md shadow-sm">
                <textarea
                    v-model="p.description"
                    class="block w-full sm:text-sm sm:leading-5"
                    placeholder="Detailed description of what work was done"
                ></textarea>
            </div>
        </div>
        <div class="text-center">
            <btn :loading="saving" blue @click='save'>Save Changes</btn>
            <btn
                class="ml-2"
                :disabled="saving"
                red outline
                @click='$emit("cancel")'
            >Cancel</btn>
        </div>
        <div class="text-center mt-2 text-sm text-gray-500">
            <a href="#" @click.prevent="checkAgainstQBO">
                <span v-if="qboCheck.loading">
                    <i class="fa fa-spinner-third fa-spin mr-2"></i>
                </span>
                Show Details from QBO
            </a>
        </div>
        <modal :show='qboCheck.open' @close='qboCheck.open = false;' overflowVisible>
            <div class="flex flex-col gap-2">
                <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-2 sm:grid sm:grid-cols-3 sm:gap-4" v-for="(value, key) in qboCheckDetails.meta" :key="('invoice-meta-' + key)">
                        <dt class="text-sm font-medium text-gray-500">{{ key }}</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ value }}</dd>
                    </div>
                </dl>
                <h3 class="text-lg my-2">Invoices Lines</h3>
                <div class="max-h-64 overflow-auto">
                    <dl class="sm:divide-y sm:divide-gray-200 odd:bg-gray-100 p-2" v-for="(line, idx) in qboCheckDetails.lines" :key="'line-' + idx">
                        <div class="py-2 sm:grid sm:grid-cols-3 sm:gap-4" v-for="(value, key) in line">
                            <dt class="text-sm font-medium text-gray-500">{{ key }}</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ value }}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div class="mt-5 text-xs cursor-pointer text-gray-600" @click="(qboCheck.rawOpen = !qboCheck.rawOpen)">
                <span>
                    <i
                        class="fa fa-caret-right mr-1 transform"
                        :class="{
                            'rotate-90': qboCheck.rawOpen,
                        }"
                    ></i>
                </span>
                Show Raw Data
            </div>
            <div class="text-xs max-h-64 overflow-auto p-2 bg-gray-100 mt-1" v-if="qboCheck.rawOpen">
                <pre>{{ qboCheck.invoice }}</pre>
            </div>
        </modal>
    </div>
</template>

<script>
import btn from "./btn.vue"
import modal from '../Common/modal.vue'
import money from "./money.vue"
import hgselect from "./hgselect.vue"
import axios from "axios"
import moment from 'moment';
import { get, clone, pickBy } from "lodash"

export default {
    name: 'PaymentForm',
    props: {
        forClient: String,
        payment: Object,
        users: Array,
        showDelete: Boolean
    },
    components: {
        btn, hgselect, money, modal
    },
    data() {
        return {
            p: null,
            qboCheck: {
                open: false,
                invoice: null,
                rawOpen: false,
                loading: false,
            },
            openDropdownMenu: false,
            saving: false,
            deleting: false,
            checkingInvoice: false,
            checkingInvoiceNumber: '',
            checkingInvoiceError: null,
            optionsData: null,
            errors: []
        }
    },
    computed: {
        qboCheckDetails() {
            const data = {
                meta: {},
                lines: []
            }
            if (this.qboCheck.invoice) {
                data.meta['Created At'] = this.formateDateTime(get(this.qboCheck.invoice, 'MetaData.CreateTime'))
                data.meta['Updated At'] = this.formateDateTime(get(this.qboCheck.invoice, 'MetaData.LastUpdatedTime'))
                data.meta['Document Number'] = get(this.qboCheck.invoice, 'DocNumber')
                data.meta['Txn Date'] = get(this.qboCheck.invoice, 'TxnDate')
                data.meta['Total'] = get(this.qboCheck.invoice, 'TotalAmt')
                data.meta['Due Date'] = get(this.qboCheck.invoice, 'DueDate')
                data.meta['Balance'] = get(this.qboCheck.invoice, 'Balance')
                this.qboCheck.invoice.Line.forEach((line) => {
                    if (line.DetailType == 'SalesItemLineDetail') {
                        const l = {}
                        l['Number'] = get(line, 'LineNum')
                        const itemRef = Number(get(line, 'SalesItemLineDetail.ItemRef'))
                        l['Type'] = itemRef in this.qboCheck.items ? this.qboCheck.items[itemRef] : ''
                        l['Description'] = get(line, 'Description')
                        l['Amount'] = get(line, 'Amount')
                        data.lines.push(l)
                    }
                })
            }
            return data
        },
        newPayment() {
            return {
                number: '',
                client_id: this.forClient,
                sprint_id: '',
                is_paid: false,
                description: ''
            }
        },
        options() {
            if (this.p && this.optionsData) {
                const options = {
                    clients:  this.optionsData.clients,
                    sprints: {},
                }
                if (this.p.client_id) {
                    Object.values(
                        pickBy(
                            this.optionsData.sprints,
                            { client_id: this.p.client_id }
                        )
                    ).forEach((sprint) => {
                        options.sprints[sprint.id] = sprint.name
                    })
                }
                return options
            }
            return null
        }
    },
    mounted() {
        this.p = this.payment ? clone(this.payment) : clone(this.newPayment)
        this.loadOptions()
    },
    methods: {
        formateDateTime(dt) {
            return moment.utc(dt, 'YYYY-MM-DD HH:mm:ss').local().format('MMM Do YYYY, h:mma')
        },
        async checkAgainstQBO() {
            this.qboCheck.loading = true
            const { data } = await axios.get(this.$route("payments.qbo", this.p.id));
            this.qboCheck.loading = false
            if (data.success) {
                this.qboCheck.invoice = data.invoice[0];
                this.qboCheck.items = data.items;
                this.qboCheck.open = true
            } else {
                alert("Whoops, something went wrong. Please try again.")
            }
        },
        async save() {
            this.saving = true
            let obj = clone(this.p)
            try {
                if (obj.id) {
                    obj._method = "PUT"
                    await axios.post(
                        this.$route("payments.update", obj.id),
                        obj
                    )
                } else {
                    await axios.post(
                        this.$route("payments.store"),
                        obj
                    )
                }
                this.$emit('saved')
            } catch(e) {
                const errors = get(e, 'response.data.errors');
                if (errors) {
                    this.errors = []
                    var es = Object.values(errors)
                    es.forEach(errs => {
                        this.errors = this.errors.concat(errs)
                    })
                } else {
                    this.errors = ['Sorry, an error occurred.']
                }
            }
            this.saving = false
        },
        async loadOptions() {
            const response = await axios.get('/payments/get/options')
            this.optionsData = response.data
        },
        closeDropdowns() {
            this.openDropdownMenu = false
        },
        async deletePayment() {
            if (confirm("Are you sure you want to delete this payment?")) {
                await axios.delete(this.$route("payments.destroy", this.p.id))
                this.$emit('deleted')
            }
        },
        async checkInvoice() {
            this.checkingInvoice = true
            const { data } = await axios.post(this.$route("qbo.invoice.check"), {
                number: this.checkingInvoiceNumber,
            })
            this.checkingInvoice = false

            if (data.success) {
                this.$inertia.visit(`/payments/${data.payment.id}`)
            } else {
                this.checkingInvoiceError = data.message
            }
        }
    },
    watch: {
        payment() {
            this.p = this.payment ? clone(this.payment) : clone(this.newPayment)
        }
    }
}
</script>

<style lang="postcss" scoped>

</style>
