export const colors = [
    '#10B981',
    '#3B82F6',
    '#F59E0B',
    '#EF4444',
    '#8B5CF6',
    '#6B7280',
    '#F472B6',
    '#34D399',
    '#FCD34D',
    '#F87171',
    '#818CF8',
    '#A5B4FC',
    '#D1FAE5',
    '#FEEBC8',
    '#FED7D7',
    '#F9A8D4',
];
