<template>
    <a :href="card.url" target="_blank" class="block bg-white rounded-lg shadow"
    :class="{
        'closed': card.closed,
    }">
        <div class="p-4">
            <h4 class="text-xs text-gray-600">{{ card.board.name }} / {{ card.list.name }}</h4>
            <h3 class="text-sm text-gray-800 mb-2 truncate">{{ card.name }}</h3>
            <div class="flex items-center justify-between">
                <div class="flex flex-wrap gap-2">
                    <span
                        v-for="label in card.labels"
                        :key="label.id"
                        class="px-2 py-[2px] rounded-full text-[10px] font-semibold min-w-5 text-gray-800"
                        :class="{
                            [`bg-${label.color}-400`]: true,
                        }"
                    >
                        {{ label.name }}
                    </span>
                </div>
                <div class="flex flex-wrap -space-x-2">
                    <span v-for="member in card.members" :key="member.id">
                        <img :src="gravatar(member)" :alt="member.fullName" class="w-6 h-6 rounded-full inline-block border border-white">
                    </span>
                </div>
            </div>
        </div>
    </a>
</template>

<script setup>
defineProps({
    card: Object
});

const gravatar = (member) => {
    if (member.avatarHash) {
        return 'https://trello-members.s3.amazonaws.com/' + member.id + '/' + member.avatarHash + '/170.png';
    }
    return null;
}
</script>

<style scoped>
    .closed {
        background: repeating-linear-gradient(
            135deg,
            #f8f4dcaa,
            #f8f4dcaa 5px,
            #e6e0c2aa 5px,
            #e6e0c2aa 10px
        );
    }
</style>
